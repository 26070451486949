<template>
  <XPage v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card>
          <div slot="header">
            <span>所有角色</span>
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="newRole"
              style="float: right"
            >
              新增角色
            </el-button>
          </div>
          <el-table :data="rows" stripe v-auto="160">
            <el-table-column
              label="角色编号"
              width="180"
              prop="roleId"
            ></el-table-column>
            <el-table-column label="角色名称" prop="roleName"></el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope" v-if="scope.row.roleId != '0'">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="delRole(scope.row)"
                >
                  删除角色
                </el-button>

                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="editRole(scope.row)"
                >
                  菜单授权
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card v-auto="85">
          <div slot="header">
            <span>功能列表 ({{ current.roleName }})</span>
            <el-button
              type="primary"
              @click="saveRoles"
              icon="el-icon-check"
              style="float: right"
              :disabled="!current.roleId"
            >
              保存
            </el-button>
          </div>

          <!-- :default-expanded-keys="expanded" -->
          <el-tree
            :data="tree"
            show-checkbox
            :accordion="true"
            ref="functree"
            node-key="funcId"
            :default-checked-keys="checked"
            :props="{ children: 'submenu', label: 'title' }"
          ></el-tree>
        </el-card>
      </el-col>
    </el-row>
  </XPage>
</template>

<script>
import _mixin_query from "../../mixins/query";
export default {
  name: "Roles",
  mixins: [_mixin_query],
  data() {
    return {
      tree: [],
      select: [],
      checked: [],
      expanded: [],
      current: {
        roleId: "",
        roleName: "",
      },
    };
  },
  mounted() {
    this.get();
    this.getFuncs();
  },
  methods: {
    getFuncs() {
      this.tree = [];
      this.$http
        .get("/foreclosure/api/funcs")
        .then((resp) => {
          let ret = resp.data;
          this.tree = ret.data;
          console.log(this.tree);
          ret.data.map((item) => {
            if (item.submenu.length > 0) {
              this.expanded.push(item.funcId);
            }
          });
        })
        .catch((err) => {});
    },
    get() {
      this.loading = true;
      this.rows = [];
      this.$http
        .get(this.getUrl(), {
          params: this.prms,
        })
        .then((resp) => {
          let ret = resp.data;
          this.rows = ret.data;
          this.$store.commit("updateRoleList", ret.data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getUrl() {
      return "/foreclosure/api/roles";
    },
    editRole(row) {
      this.current.roleId = row.roleId;
      this.current.roleName = row.roleName;
      this.loading = true;
      this.$http
        .get("/foreclosure/api/role/funcs/" + row.roleId)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.checked = [];
          ret.data.map((item) => {
            this.checked.push(item.funcId);
          });
          this.$refs["functree"].setCheckedKeys(this.checked);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    delRole(row) {
      this.$confirm("是否确定删除该角色?", "提示")
        .then((accept) => {
          this.loading = true;
          this.$http
            .post("/foreclosure/api/role/remove", row)
            .then((resp) => {
              this.$message.success("删除成功");
              this.loading = false;
              this.get();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((deny) => {});
    },
    saveRoles() {
      let keys = this.$refs["functree"].getCheckedKeys();
      let halfKeys = this.$refs["functree"].getHalfCheckedKeys();
      halfKeys.map((key) => {
        keys.push(key);
      });
      if (keys.length == 0) {
        this.$message.error("未选择任何权限,无法保存");
        return;
      }
      this.loading = true;
      this.$http
        .post("/foreclosure/api/save/role/funcs", {
          roleId: this.current.roleId,
          funcs: keys,
        })
        .then((resp) => {
          this.loading = false;
          this.$message.success("保存成功");
          this.current.roleId = "";
          this.current.roleName = "";
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    newRole() {
      let validator = (val) => {
        if (val == "" || val == null || val == undefined) {
          return "请输入角色名称";
        } else if (val.length > 200) {
          return "名称过长";
        } else {
          return true;
        }
      };
      this.$prompt("请输入角色名称", "新建角色", {
        inputValidator: validator,
      })
        .then(({ value }) => {
          this.loading = true;
          this.$http
            .post("/foreclosure/api/role/save", { roleName: value })
            .then((resp) => {
              this.loading = false;
              this.$message.success("角色保存成功");
              this.get();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch();
    },
  },
};
</script>

<style></style>
