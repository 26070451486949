<template>
  <x-page v-loading="loading">
    <!-- 房源信息审核上架 -->
  </x-page>
</template>

<script>
export default {
  name: "HouseInfoApprove",
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
