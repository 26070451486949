<template>
  <!-- 版本控制 -->
  <XPage v-loading="loading">
    <el-table :data="rows" stripe border>
      <el-table-column label="#" type="index" width="50"></el-table-column>
      <el-table-column label="地区ID" prop="id" width="150"></el-table-column>
      <el-table-column
        label="版本号"
        prop="versionCode"
        width="250"
      ></el-table-column>
      <el-table-column
        label="升级文件ID"
        prop="fileId"
      ></el-table-column>
      <el-table-column label="版本升级说明" prop="remark"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="doEdit(scope.row)"
          >
            上传新版本
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer :visible.sync="show" direction="rtl" :wrapperClosable="false">
      <div style="padding:15px;">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="地区ID">
            <el-input v-model="form.id" disabled placeholder=""></el-input>
          </el-form-item>

          <el-form-item label="版本号" prop="versionCode">
            <el-tooltip :content="versionCodeHint" placement="bottom-start">
              <el-input
                v-model="form.versionCode"
                placeholder="请输入新版本号"
              ></el-input>
            </el-tooltip>
          </el-form-item>

          <input
            type="file"
            ref="fileinput"
            style="display:none;"
            @change="fileChange"
          />

          <el-form-item label="升级文件" prop="fileId">
            <el-input v-model="form.fileId" placeholder="点击选择文件" readonly>
              <el-button
                type="text"
                icon="el-icon-folder-opened"
                @click="fileClick"
                slot="suffix"
              ></el-button>
            </el-input>
          </el-form-item>

          <el-form-item label="版本说明" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="6"
              placeholder=""
            ></el-input>
          </el-form-item>

          <el-form-item style="text-align:right;">
            <el-button type="primary" icon="el-icon-upload" @click="doSave">
              上传版本
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </XPage>
</template>

<script>
export default {
  name: "VersionControl",
  data() {
    return {
      loading: false,
      rows: [],
      form: {
        id: "",
        versionCode: "",
        remark: "",
        fileId: "",
      },
      rules: {
        versionCode: [
          { required: true, message: "请输入版本号", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入版本说明", trigger: "change" },
        ],
        fileId: [
          { required: true, message: "选择要上传的文件", trigger: "change" },
        ],
      },
      file: null,
      versionCodeHint: "",
      show: false,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    doSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.show = false;
          this.loading = true;
          let fd = new FormData();
          fd.append("id", this.form.id);
          fd.append("versionCode", this.form.versionCode);
          fd.append("remark", this.form.remark);
          fd.append("file", this.file);
          this.$http
            .post("/foreclosure/api/versions/save", fd)
            .then((resp) => {
              this.loading = false;
              this.$message.success("上传成功");
              this.query();
            })
            .catch((err) => {
              this.show = true;
              this.loading = false;
            });
        }
      });
    },
    fileClick() {
      this.form.fileId = "";
      this.$refs["fileinput"].click();
    },
    fileChange(e) {
      this.file = e.target.files[0];
      let name = this.file.name;
      let arr = name.split(".");
      let suffix = arr[arr.length - 1].toUpperCase();
      e.target.value = "";
      if (suffix != "ZIP") {
        this.$alert("请选择ZIP文件!");
        return;
      }
      this.form.fileId = name;
    },
    doEdit(row) {
      this.form.id = row.id;
      this.form.versionCode = "";
      this.versionCodeHint = "当前版本号:" + row.versionCode;
      this.form.remark = "";
      this.form.fileId = "";
      this.show = true;
    },
    query() {
      this.loading = true;
      this.$http
        .get("/foreclosure/api/versions/list")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.rows = ret.data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
