<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <el-form ref="theform" :model="form" label-width="80px" :rules="rules">
      <el-row>
        <el-col :span="6">
          <el-form-item label="办理结果" prop="result">
            <el-select v-model="form.result" placeholder="">
              <el-option label="同意" value="1"></el-option>
              <el-option label="不同意" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="办理意见" prop="comment">
            <el-input v-model="form.comment" placeholder=""></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" icon="el-icon-check" @click="doHandle">
              提交
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="taskHis" border stripe>
      <el-table-column
        label="#"
        type="index"
        width="40"
        header-align="center"
      ></el-table-column>
      <el-table-column
        width="150"
        property="name"
        label="流程节点"
        header-align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        width="150"
        property="assignee"
        label="经办人"
        header-align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="comment"
        label="办理意见"
        header-align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column width="160" show-overflow-tooltip label="开始时间" header-align="center">
        <template slot-scope="scope">
          {{ scope.row.startTime}}
        </template>
      </el-table-column>
      <el-table-column width="160" show-overflow-tooltip  label="完成时间" header-align="center">
        <template slot-scope="scope">
          {{ scope.row.endTime}}
        </template>
      </el-table-column>
      
    </el-table>
    <img style="display: block; width: 80%; margin: 10px auto" :src="imgData" />
  </div>
</template>

<script>
export default {
  name: "GeneralFlowHandler",
  data() {
    return {
      taskHis: [],
      loading: false,
      form: {
        result: "1",
        comment: "",
        procId: "",
      },
      imgData: "",
      rules: {
        result: [
          { required: true, message: "请选择办理结果", trigger: "change" },
        ],
        comment: [
          { required: true, message: "请输入办理意见", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    loadData(id) {
      this.form.procId = id;
      this.taskHis = [];
      this.form.result = "1";
      this.form.comment = "";
      this.$refs["theform"].clearValidate();
      this.query();
      this.loadImg();
    },
    loadImg() {
      //'data:image/png;base64,'
      let fd = new FormData();
      fd.append("procId", this.form.procId);
      this.loading = true;
      this.$http
        .post("/foreclosure/api/flowchart", fd)
        .then((resp) => {
          let ret = resp.data;
          this.imgData = "data:image/png;base64," + ret.data;
        })
        .catch((err) => {});
    },
    doHandle() {
      this.$refs["theform"].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("procId", this.form.procId);
          fd.append("result", this.form.result);
          fd.append("comment", this.form.comment);
          this.loading = true;
          this.$http
            .post("/foreclosure/api/todo/handle", fd)
            .then((resp) => {
              this.loading = false;
              this.$message.success("办理成功");
              this.$emit("done");
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    query() {
      let fd = new FormData();
      fd.append("procId", this.form.procId);
      this.loading = true;
      this.$http
        .post("/foreclosure/api/task/history", fd)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.taskHis = ret.data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
