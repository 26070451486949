<template>
  <!-- <dv-border-box-11 :title="title" :titleWidth="400">  -->
  <div :id="id" :option="option" style="height: 95%; padding-top: 70px" />
  <!-- </dv-border-box-11>-->
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "BlockEchartBar",
  props: { title: String, id: String, option: Object },
  methods: {
    initData() {
      var chartDom = document.getElementById(this.id);
      var myChart = echarts.init(chartDom);
      var option = this.option;

      setInterval(function () {
        // if (option.series[0].type == "bar") {
        //   option.series[0].type = "line";
        //   option.series[1].type = "line";
        //   option.series[2].type = "line";
        // } else {
        //   option.series[0].type = "bar";
        //   option.series[1].type = "bar";
        //   option.series[2].type = "bar";
        // }

        myChart.setOption(option);
      }, 6000);
      option && myChart.setOption(option);
    },
  },
  mounted() {
    //  this.initData();
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.scenic-data {
  height: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}
.scenic-ul {
  list-style: none;
  font-size: 23px;
  padding-top: 70px;
  width: 80%;
}
.scenic-ul li {
  padding: 5px;
  margin-top: 10px;
}
.scenic-ul-title {
  color: #409eff;
}
.scenic-ul-content {
  color: #ffbc40;
  float: right;
}
</style>
