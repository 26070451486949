<template>
	<div>
		<h2 style="text-align:center;">修改密码</h2>
    <el-form :model="f"
			         :rules="rules"
			         ref="cform"
			         label-position="right"
			         label-width="100px">
				<el-form-item label="原密码:"
				              prop="oldPwd">
					<el-input v-model="f.oldPwd"
					          :type="inputType"
					          placeholder="请输入原密码"></el-input>
				</el-form-item>
				<el-form-item label="新密码:"
				              prop="newPwd">
					<el-input v-model="f.newPwd"
					          :type="inputType"
					          placeholder="请输入新密码">
					</el-input>
				</el-form-item>
				<el-form-item label="再输一次:"
				              prop="newPwdr">
					<el-input v-model="f.newPwdr"
					          :type="inputType"
					          placeholder="再输一次新密码">
					</el-input>
				</el-form-item>
				<el-form-item style="text-align:right;">
					<el-button type="text"
					           icon="el-icon-view"
					           @click="viewPwd"></el-button>
					<el-button type="primary"
					           @click="save"
					           :loading="loading"
					           icon="el-icon-check">确认修改</el-button>

				</el-form-item>
			</el-form>
		</div>
</template>

<script>

export default {
  name: "ChangePassword",
  data() {
    let oldCheck = (r, v, cb) => {
      if (!v) {
        return cb(new Error("原密码不能为空"));
      }
      let fd = new FormData();
      fd.append("password",v);
      this.$http
        .post("/foreclosure/api/validate/password",fd)
        .then(resp => {
          let ret = resp.data;
          if(ret.data){
            cb();
          }else{
            cb(new Error("原密码错误"));
          }
        })
        .catch(err => {
          cb(new Error("原密码错误"));
        });
    };
    let newCheck = (r, v, cb) => {
      if (!v) {
        return cb(new Error("新密码不能为空"));
      }
      let reg = /^[a-zA-Z0-9]{4,10}$/;
      if (!reg.test(v)) {
        return cb(new Error("请输入4~10位的数字或字母."));
      }

      if (this.f.oldPwd) {
        if (v === this.f.oldPwd) {
          return cb(new Error("新密码不能与旧密码一样"));
        }
      }
      cb();
    };
    let newrCheck = (r, v, cb) => {
      if (!v) {
        return cb(new Error("请再次输入新密码"));
      }
      if (v !== this.f.newPwd) {
        return cb(new Error("两次输入的密码不一样"));
      }
      cb();
    };
    return {
      loading: false,
      inputType: "password",
      f: {
        oldPwd: "",
        newPwd: "",
        newPwdr: ""
      },
      rules: {
        oldPwd: [{ validator: oldCheck, trigger: "blur" }],
        newPwd: [{ validator: newCheck, trigger: "blur" }],
        newPwdr: [{ validator: newrCheck, trigger: "blur" }]
      }
    };
  },
  methods: {
    save: function() {
      this.$refs.cform.validate(valid => {
        if (valid) {
          this.loading = true;
          let fd = new FormData();
          fd.append("opwd",this.f.oldPwd);
          fd.append("npwd",this.f.newPwd);
          this.$http
            .post(
              "/foreclosure/api/change/password",fd
            )
            .then(resp => {
              this.loading = false;
              this.$emit("success");
              this.$message.success("修改密码成功!");
              this.$tls.clearJson(this.f);
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    viewPwd: function() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    }
  }
};
</script>

<style>
</style>
