<template>
  <div id="data-view">
    <x-page v-loading="loading">
      <TopHeader title="领导驾驶舱" />
      <div class="main-content-nj">
        <div class="main-content-1">
          <dv-border-box-1 class="block-data">
            <span class="bt-jsc">全市法拍房数量分析</span>
            <div style="text-align:center"><el-button type="success" @click="detail">
                查看明细
              </el-button></div>
            <BlockEchartBar1 ref="rc1" :id="'block-data-bar-rc1'" :option="R1Config"
              style="width: 100%;height: 100%;margin-top: -80px" />
          </dv-border-box-1>
          <dv-border-box-1 class="block-data">
            <span class="bt-jsc">全市法拍房金额分析</span>
            <div style="text-align:center"><el-button type="success" @click="detail1">
                查看明细
              </el-button></div>
            <BlockEchartBar1 ref="rc2" :id="'block-data-bar-rc2'" :option="R2Config"
              style="width: 100%;height: 100%;margin-top: -80px" />
          </dv-border-box-1>
        </div>
        <div class="main-content-2">
          <!-- <dv-border-box-8 class="block-data-map">
            <div id="t2" class="dt"></div>
          </dv-border-box-8> -->
          <div id="t2" class="dt"></div>
        </div>
        <div class="main-content-1">
          <dv-border-box-1 class="block-data">
            <span class="bt-jsc">业绩工作量统计</span>
            <div style="text-align:center"><el-button type="success" @click="detail2">
                查看明细
              </el-button></div>
            <dv-scroll-ranking-board :config="t2" style="width:450px;height:300px;margin-top: 0px;margin-left: 60px;" />
          </dv-border-box-1>
          <dv-border-box-1 class="block-data">
            <span class="bt-jsc">交易差额分析</span>
            <!-- <div style="text-align:center"><el-button type="success" @click="detail3">
                查看明细
              </el-button></div> -->
            <div class="div">
              <dv-capsule-chart :config="t0" style="width:450px;height:450px;margin-top: -0px;margin-left: 60px;" />
            </div>
          </dv-border-box-1>
        </div>
      </div>
    </x-page>

    <el-dialog background-color:black :close-on-click-modal="false" width="1200px" :visible.sync="show">
      <div class="mx-fp">
        <dv-border-box-1>
          <span class="bt-jsc">各区法拍房数量明细</span>
          <BlockEchartBar ref="mc1" :id="'block-data-bar-mc1'" :option="M1Config"
            style="width: 90%;height: 90%;margin-left: 40px" />
        </dv-border-box-1>
      </div>
    </el-dialog>

    <el-dialog background-color:black :close-on-click-modal="false" width="1200px" :visible.sync="show1">
      <div class="mx-fp">
        <dv-border-box-1>
          <span class="bt-jsc">各区法拍房金额明细</span>
          <BlockEchartBar ref="mc2" :id="'block-data-bar-mc2'" :option="M2Config"
            style="width: 90%;height: 90%;margin-left: 40px" />
        </dv-border-box-1>
      </div>
    </el-dialog>

    <el-dialog background-color:black :close-on-click-modal="false" width="1200px" :visible.sync="show2">
      <div class="mx-fp">
        <dv-border-box-1>
          <span class="bt-jsc">上周工作量统计</span>
          <dv-scroll-board :config="mc3" style="width: 90%;height: 90%;margin-left: 50px;margin-top: 40px;" />
        </dv-border-box-1>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import router from '@/router';
import nj from "./nj.json";
import * as echarts from "echarts";
import BlockEchartBar from "../../components/BlockEchartBar.vue";
import BlockEchartBar1 from "../../components/BlockEchartBar1.vue";
import BlockEchartPie from "../../components/BlockEchartPie.vue";
import config from "./Cockpit.js";
export default {
  name: "Cockpit",
  components: {
    BlockEchartBar,
    BlockEchartBar1,
    BlockEchartPie,
  },
  data() {
    return {
      loading: false,
      R1Config: {},
      M1Config: {},
      R2Config: {},
      M2Config: {},
      R3Config: {},
      M3Config: {},
      t0: {},
      T0: [],
      T1: {
        qpj: "",
        pgj: "",
        scj: "",
        cjj: "",
      },
      t2: {},
      T2: [],
      mc3: {},
      MC3: {},
      show: false,
      show1: false,
      show2: false,
      area1: "",
      area2: "",
      area3: "",
      area4: "",
      area5: "",
      area6: "",
      area7: "",
      area8: "",
      area9: "",
      area10: "",
      area11: "",
    };
  },
  methods: {
    detail() {
      this.show = true;
      this.loading = true;
      this.$http
        .post("/foreclosure/api/home/querydetail")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          console.log("处理图2");
          //处理M1
          this.M1Config.xAxis.data = [];
          this.M1Config.series[0].data = [];
          this.M1Config.series[1].data = [];
          this.M1Config.series[2].data = [];
          ret.M1.forEach((item) => {

            if (item.disPoolArea == "320102") {
              item.disPoolArea = "玄武区";
            } else if (item.disPoolArea == "320104") {
              item.disPoolArea = "秦淮区";
            } else if (item.disPoolArea == "320105") {
              item.disPoolArea = "建邺区";
            } else if (item.disPoolArea == "320106") {
              item.disPoolArea = "鼓楼区";
            } else if (item.disPoolArea == "320111") {
              item.disPoolArea = "浦口区";
            } else if (item.disPoolArea == "320113") {
              item.disPoolArea = "栖霞区";
            } else if (item.disPoolArea == "320114") {
              item.disPoolArea = "雨花台区";
            } else if (item.disPoolArea == "320115") {
              item.disPoolArea = "江宁区";
            } else if (item.disPoolArea == "320116") {
              item.disPoolArea = "六合区";
            } else if (item.disPoolArea == "320117") {
              item.disPoolArea = "溧水区";
            } else if (item.disPoolArea == "320118") {
              item.disPoolArea = "高淳区";
            } else if (item.disPoolArea == "320100") {
              item.disPoolArea = "全  市";
            }

            this.M1Config.xAxis.data.push(item.disPoolArea);
            this.M1Config.series[0].data.push(item.p1);
            this.M1Config.series[1].data.push(item.p2);
            this.M1Config.series[2].data.push(item.p3);
          });
          this.$refs.mc1.initData();
        })
        .catch((err) => {
          this.loading = false;
        });

    },

    detail1() {
      this.show1 = true;
      this.loading = true;
      this.$http
        .post("/foreclosure/api/home/queryamtdetail")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          console.log("处理图3");
          //处理M2
          this.M2Config.xAxis.data = [];
          this.M2Config.series[0].data = [];
          this.M2Config.series[1].data = [];
          this.M2Config.series[2].data = [];
          ret.M2.forEach((item) => {
            if (item.disPoolArea == "320102") {
              item.disPoolArea = "玄武区";
            } else if (item.disPoolArea == "320104") {
              item.disPoolArea = "秦淮区";
            } else if (item.disPoolArea == "320105") {
              item.disPoolArea = "建邺区";
            } else if (item.disPoolArea == "320106") {
              item.disPoolArea = "鼓楼区";
            } else if (item.disPoolArea == "320111") {
              item.disPoolArea = "浦口区";
            } else if (item.disPoolArea == "320113") {
              item.disPoolArea = "栖霞区";
            } else if (item.disPoolArea == "320114") {
              item.disPoolArea = "雨花台区";
            } else if (item.disPoolArea == "320115") {
              item.disPoolArea = "江宁区";
            } else if (item.disPoolArea == "320116") {
              item.disPoolArea = "六合区";
            } else if (item.disPoolArea == "320117") {
              item.disPoolArea = "溧水区";
            } else if (item.disPoolArea == "320118") {
              item.disPoolArea = "高淳区";
            } else if (item.disPoolArea == "320100") {
              item.disPoolArea = "全  市";
            }

            this.M2Config.xAxis.data.push(item.disPoolArea);
            this.M2Config.series[0].data.push(item.a1);
            this.M2Config.series[1].data.push(item.a2);
            this.M2Config.series[2].data.push(item.a3);
          });
          this.$refs.mc2.initData();
        })
        .catch((err) => {
          this.loading = false;
        });

    },
    detail2() {
      this.show2 = true;
      this.loading = true;
      this.mc3 = {};
      this.MC3 = [];
      this.$http
        .post("/foreclosure/api/home/queryworkdetail")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          console.log("处理图4");
          //处理M3
          ret.M3.forEach((item) => {
            this.MC3.push(
              [
                '<span class="indexFp">' + item.employeeNo + '</span>',
                '<span class="indexFp">' + item.employeeName + '</span>',
                '<span class="indexFp">' + item.counth + '</span>',
                '<span class="indexFp">' + item.countd + '</span>',
                '<span class="indexFp">' + item.rate * 100 + '%' + '</span>',
              ]
            );
          });
          this.drawMC3();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    detail3() { },
    query() {
      this.t0 = {};
      this.T0 = [];
      this.t2 = {};
      this.T2 = [];
      this.loading = true;
      this.$http
        .post("/foreclosure/api/home/query")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          console.log("处理图1");
          //处理Q1
          this.R1Config.xAxis.data = [];
          this.R1Config.series[0].data = [];
          ret.Q1.forEach((item) => {
            this.R1Config.xAxis.data.push(item.status);
            this.R1Config.series[0].data.push(item.count);
          });
          //处理Q2
          this.R2Config.xAxis.data = [];
          this.R2Config.series[0].data = [];
          ret.Q2.forEach((item) => {
            this.R2Config.xAxis.data.push(item.status);
            this.R2Config.series[0].data.push(item.tranPrice);
          });
          //处理Q3
          this.R3Config.series[0].data = [];
          ret.Q3.forEach((item) => {
            if (item.disPoolArea == "320102") {
              item.disPoolArea = "玄武区";
            } else if (item.disPoolArea == "320104") {
              item.disPoolArea = "秦淮区";
            } else if (item.disPoolArea == "320105") {
              item.disPoolArea = "建邺区";
            } else if (item.disPoolArea == "320106") {
              item.disPoolArea = "鼓楼区";
            } else if (item.disPoolArea == "320111") {
              item.disPoolArea = "浦口区";
            } else if (item.disPoolArea == "320113") {
              item.disPoolArea = "栖霞区";
            } else if (item.disPoolArea == "320114") {
              item.disPoolArea = "雨花台区";
            } else if (item.disPoolArea == "320115") {
              item.disPoolArea = "江宁区";
            } else if (item.disPoolArea == "320116") {
              item.disPoolArea = "六合区";
            } else if (item.disPoolArea == "320117") {
              item.disPoolArea = "溧水区";
            } else if (item.disPoolArea == "320118") {
              item.disPoolArea = "高淳区";
            } else if (item.disPoolArea == "320100") {
              item.disPoolArea = "全  市";
            }

            if (item.disPoolArea != "全  市") {
              let data = {};
              data.value = item.count;
              data.name = item.disPoolArea;
              this.R3Config.series[0].data.push(data);
            }
          });
          //处理T1
          let val1 = 0;
          let val2 = 0;
          let val3 = 0;
          let val4 = 0;
          let val5 = 0;
          if (!G.isEmpty(ret.T1)) {
            val1 = parseFloat(ret.T1.qpj / ret.T1.pgj).toFixed(2);
            val2 = parseFloat(ret.T1.qpj / ret.T1.scj).toFixed(2);
            val3 = parseFloat((ret.T1.cjj - ret.T1.pgj) / ret.T1.pgj).toFixed(2);
            val4 = parseFloat((ret.T1.cjj - ret.T1.scj) / ret.T1.scj).toFixed(2);
            val5 = parseFloat((ret.T1.cjj - ret.T1.qpj) / ret.T1.qpj).toFixed(2);
          }

          //处理T0
          this.T0 = [
            { name: "评估价折扣率", value: val1 },
            { name: "市场价折扣率", value: val2 },
            { name: "评估价溢价率", value: val3 },
            { name: "市场价溢价率", value: val4 },
            { name: "起拍价溢价率", value: val5 },
          ];

          this.T2 = [
            {
              name: '李周口',
              value: 55
            },
            {
              name: '王南阳',
              value: 120
            },
            {
              name: '胡西峡',
              value: 78
            },
            {
              name: '驻马店',
              value: 66
            },
            {
              name: '张新乡',
              value: 80
            },
            {
              name: '陈信阳',
              value: 45
            },
            {
              name: '雷漯河',
              value: 29
            }
          ];

          this.$refs.rc1.initData();
          this.$refs.rc2.initData();
          this.drawnjmap();
          this.drawT0();
          this.drawT2();
        })
        .catch((err) => {
          this.loading = false;
        });

    },
    drawT0() {
      this.t0 = {
        data: this.T0,
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '单位',
        showValue:true,
      }
    },

    drawT2() {
      this.t2 = {
        radius: '50%',
        waitTime: 3000,
        rowNum: 5,
        data: this.T2,
        unit: '%',
        sort: true,
      }
    },

    drawMC3() {
      this.mc3 = {
        headerHeight: 55,
        waitTime: 4000,
        carousel: 'single',
        header: ['<span class="block-title-fp">员工编号</span>', '<span class="block-title-fp">员工姓名</span>', '<span class="block-title-fp">上周总工时</span>', '<span class="block-title-fp">上周录入天数</span>', '<span class="block-title-fp">完成率</span>'],
        data: this.MC3,
        align: ['center', 'center', 'center', 'center', 'center'],
        headerBGC: "#6b81a3",
        oddRowBGC: "#97cbe7",
        evenRowBGC: "#cee5ee",
        color: "#000000",
        rowNum: 5,
        // indexHeader: '排名',
        // index: true,
      };
    },

    drawnjmap() {

      var myChart = echarts.init(document.getElementById("t2"));
      this.$echarts.registerMap("nj", nj);
      var data = this.R3Config.series[0].data;
      data.sort(function (a, b) {
        return a.value - b.value;
      });
      const mapOption = {
        visualMap: {
          left: "right",
          // min: 50,
          // max: 100,
          inRange: {
            // prettier-ignore
            color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        series: [
          {
            id: "population",
            type: "map",
            roam: true,
            map: "nj",
            animationDurationUpdate: 2000,
            universalTransition: true,
            data: data,
            label: {
              show: true,
            },
          },
        ],
      };
      const barOption = {
        xAxis: {
          name: "单位：户",
          type: "value",
          axisLabel: {
            color: "#ffffff",
          },
          nameTextStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#ffffff",
            rotate: 30,
          },
          data: data.map(function (item) {
            return item.name;
          }),
        },
        animationDurationUpdate: 2000,
        series: {
          type: "bar",
          show: true,
          id: "population",
          data: data.map(function (item) {
            return item.value;
          }),
          universalTransition: true,
        },
      };
      let currentOption = mapOption;
      myChart.setOption(mapOption);
      setInterval(function () {
        currentOption = currentOption === mapOption ? barOption : mapOption;
        myChart.setOption(currentOption, true);
      }, 6000);
    }
    // drawnjmap() {
    //   var myChart = echarts.init(document.getElementById("t2"));
    //   this.$echarts.registerMap("nj", nj);
    //   window.addEventListener("resize", () => {
    //     //openingBankChart.resize();
    //   });
    //   let option = {
    //     // 地图背景色
    //     backgroundColor: "",
    //     geo: {
    //       map: "nj",
    //     },
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     series: [
    //       {
    //         type: "map",
    //         map: "nj",
    //         geoIndex: 1,
    //         zoom: 1.2,
    //         showLegendSymbol: false, // 存在legend时显示
    //         tooltip: {
    //           show: false,
    //         },
    //         roam: false,
    //         label: {
    //           show: true,
    //           emphasis: {
    //             show: true,
    //           }, 
    //         },
    //         itemStyle: {
    //           areaColor: "#ffffff",
    //           borderColor: "#ccd3e4",
    //           borderWidth: 1,
    //           color: "#ccd3e4",
    //           fontSize: 10,
    //           fontFamily: "Arial",
    //           emphasis: {
    //             opacity: 1,
    //             borderColor: "#f18355",
    //             borderWidth: "3",
    //             areaColor: "#8FBC8F"
    //           },
    //         },
    //         // data:[{ name: "南京市", value: [115.820721, 32.896672] }],
    //         data: [
    //           {
    //             name: '玄武区', value: this.area1,
    //             // label: {
    //             //   normal: {
    //             //     show: true,
    //             //     formatter: function (params) {
    //             //       return params.name + "\n" + params.value;    //地图上展示文字 + 数值
    //             //     },

    //             //   }
    //             // },
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#8FBC8F'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '秦淮区', value: this.area2,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#996699'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '建邺区', value: this.area3,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#e5c68b'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '鼓楼区', value: this.area4,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#96bfff'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '浦口区', value: this.area5,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#C1FFC1'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '栖霞区', value: this.area6,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#FFFF00'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '雨花台区', value: this.area7,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#DB7093'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '江宁区', value: this.area8,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#32c5e9'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '六合区', value: this.area8,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#66CC66'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '溧水区', value: this.area8,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#FF6633'     //地图颜色
    //               }
    //             }
    //           },
    //           {
    //             name: '高淳区', value: this.area8,
    //             itemStyle: {
    //               normal: {
    //                 areaColor: '#FFCC00'     //地图颜色
    //               }
    //             }
    //           },
    //         ]
    //       },
    //     ],
    //   };
    //   myChart.setOption(option);
    // }
  },
  mounted() {
    this.query();
    this.R1Config = config.R1;
    this.R2Config = config.R2;
    this.M1Config = config.M1;
    this.M2Config = config.M2;
    this.R3Config = config.R3;

  },
  components: { router }
}
</script>

<style lang="less">
#data-view {
  // background-color: #030409;
  background-image: url("../../assets/pageBg.png");
  color: #ffffff;
  font-family: "lcdd", "Microsoft YaHei";
  font-size: 18px;
  font-weight: normal;

  .main-content-nj {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .main-content-1 {
    height: 850px;
    width: 35%;
    flex: 1;
    display: inline;
    flex-direction: row;
    justify-content: center;
  }

  .main-content-2 {
    height: 850px;
    width: 25%;
    flex: 1;
    display: inline;
    flex-direction: row;
    justify-content: center;
  }

  .block-data {
    height: 48%;
    width: 95%;
    left: 2%;
    box-sizing: border-box;
  }

  .block-data-map {
    height: 96%;
    width: 95%;
    left: 2%;
    box-sizing: border-box;
  }
}

.dt {
  height: 800px;
  width: 95%;
  margin: 0 auto;
}

.bt-jsc {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
  display: block;
}

.mx-fp {
  height: 800px;
  width: 100%;
  background-image: url("../../assets/pageBg.png");
  color: #ffffff;
}

.div {
  display: inline-block;
  margin: auto;
}

.block-title-fp {
  font-size: 18px;
  color: #ffffff
    /* font-family: "微软雅黑"; */
}

.indexFp {
  font-size: 16px;
  color: #000000
    /* font-family: "微软雅黑"; */
}
</style>