<template>
  <XPage>
    <el-card class="drawer-naw-cardone">
      <div class="sessionOne">
        <span style="font-size: 15px;font-weight: bolder">员工姓名 ：{{ this.employeeName }} </span>
        <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">员工编号：{{ this.employeeNo }} </span>
        <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">周报名称：{{
            this.manMouthReport.reportTime
          }} </span>
      </div>
      <div class="threeTabs">
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane label="本周日志总结" name="first">
            <el-table :data="manMouthReports" stripe>
              <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
              <el-table-column label="姓名" prop="employeeName" align="center"></el-table-column>
              <el-table-column label="日报时间" prop="reportTime" align="center"></el-table-column>
              <el-table-column label="工作属性" prop="jobProperties" align="center"></el-table-column>
              <el-table-column label="工作时长" prop="workOurs" align="center"></el-table-column>
              <el-table-column label="日报详细内容" prop="details" align="center" width="300px"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="周小结预览" name="second">
            <!--              <div>-->
            <!--                  <el-form :model="mouthReportCollecyion" ref="userForms">-->
            <!--                    <el-table :data="mouthReportCollecyion.mouthReportCollecyions" stripe>-->
            <!--                      <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>-->
            <!--                      <el-table-column label="姓名" align="center">-->
            <!--                        <template slot-scope="scope">-->
            <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.employeeName'"-->
            <!--                                        style="margin-top: 20px">-->
            <!--                            <el-input v-model="scope.row.employeeName"></el-input>-->
            <!--                          </el-form-item>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column label="周报名称" align="center" width="150px">-->
            <!--                        <template slot-scope="scope">-->
            <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.jobProperties'"-->
            <!--                                        :rules="rules.jobProperties" style="margin-top: 20px">-->
            <!--                            <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 140px">-->
            <!--                              <el-option-->
            <!--                                  v-for="item in options"-->
            <!--                                  :key="item.value"-->
            <!--                                  :label="item.label"-->
            <!--                                  :value="item.value">-->
            <!--                              </el-option>-->
            <!--                            </el-select>-->
            <!--                          </el-form-item>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column label="周报小结详细内容" prop="details" align="center" width="400px">-->
            <!--                        <template slot-scope="scope">-->
            <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.details'"-->
            <!--                                        :rules="rules.details" style="margin-top: 20px">-->
            <!--                            <el-input v-model="scope.row.details" type="textarea" :rows="2"-->
            <!--                                      style="width: 380px"></el-input>-->
            <!--                          </el-form-item>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                      <el-table-column label="周工作时长" prop="workOurs" align="center">-->
            <!--                        <template slot-scope="scope">-->
            <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.workOurs'"-->
            <!--                                        :rules="rules.workOurs" style="margin-top: 20px">-->
            <!--                            <el-input v-model="scope.row.workOurs" style="width: 80px"></el-input>-->
            <!--                          </el-form-item>-->
            <!--                        </template>-->
            <!--                      </el-table-column>-->
            <!--                    </el-table>-->
            <!--                  </el-form>-->
            <!--                </div>-->
            <div style="margin-left: 20px">
              <div style="border: 1px solid #000000;border-bottom:0px;padding: 5px">
                <el-descriptions :column="3">
                  <el-descriptions-item label="周报名称">{{ this.manMouthReport.reportTime }}
                  </el-descriptions-item>
                  <el-descriptions-item label="周报时间">{{ this.mouthDate }}
                  </el-descriptions-item>
                  <el-descriptions-item label="填报人">{{ this.employeeName }}
                  </el-descriptions-item>
                  <el-descriptions-item label="本周工作时长">{{ this.workTotalTime }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div style="border: 1px solid #000000;border-style: double;padding: 5px ">
                <el-descriptions :column="1" style="margin-top: 10px">
                  <el-descriptions-item label="本周小结">
                    <el-input v-model="manMouthReport.details" type="textarea" :rows="8"
                              style="width: 100%;color: #303133" readonly></el-input>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div style="border: 1px solid #000000;border-style: double;border-top:0px;padding: 5px">
                <el-descriptions :column="1" style="margin-top: 10px">
                  <el-descriptions-item label="下周工作计划">
                    <el-input v-model="manMouthReport.detailPlan" type="textarea" :rows="8"
                              style="width: 100%;" readonly></el-input>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>

  </XPage>
</template>

<script>
export default {
  name: 'mouthReport',
  props: {manMouthReports:[],activeName:""},
  components: {},

  data() {
    return {
      employeeName: "",
      employeeNo: "",
      manMouthReport: {},
      mouthDate: "",
      workTotalTime:"",
      prm: {id: ""}
    }
  },
  created() {
    this.getUserMessage();
    this.getTotalTime();
  }
  ,
  methods: {
    /**
     * 获取工作总时间
     */
    getTotalTime(){
      this.manMouthReports.forEach(item=>{
        this.workTotalTime=0,
        this.workTotalTime=parseInt(item.workOurs)+this.workTotalTime
      })
    },

    getUserMessage() {
      this.$http.get("/foreclosure/report/daily/getUserMessage").then
      ((resp) => {
        this.employeeName = resp.data.data.employeeName
        this.employeeNo = resp.data.data.employeeNo
      }).catch(
          (err) => {
            this.$message.error("初始化人员基本信息失败！")
          }
      )
    },
    getManMouthReport(id) {
      this.prm.id = id
      this.$http.post("/foreclosure/report/daily/getManMouthReport", this.prm)
          .then((resp) => {
            this.manMouthReport = resp.data.data
            this.getReportname(this.manMouthReport)
          }).catch((err) => {
        this.$message.error("预览本周小结内容错误")
      })
    },
    /**
     * 获取周报名称
     * @param infoDate
     */
    getReportname(infoDate) {
      var startDate = new Date(this.manMouthReport.startDate);
      var startFullYear = startDate.getFullYear();
      var startMonth = startDate.getMonth() + 1;
      var startDay = startDate.getDate();
      var endDate = new Date(this.manMouthReport.endDate);
      var endFullYear = endDate.getFullYear();
      var endMonth = endDate.getMonth() + 1;
      var endDay = endDate.getDate();
      this.mouthDate = startFullYear + "年" + startMonth + "月" + startDay + "日--" + endFullYear + "年" + endMonth + "月" + endDay
    }

  },
}

</script>

<style>


</style>