<template xmlns:el-col="http://www.w3.org/1999/html">
  <XPage v-loading="loading">

    <div style="height: 30px">
      <el-col :span="4">
        <weather style="margin-bottom: 0px"></weather>
      </el-col>
      <el-col :span="20"><span style="margin-left:15%;color: #3a8ee6;font-size:x-large">{{ this.employeeName }} 你好!祝你度过愉快的一天。</span>
      </el-col>
    </div>
    <div class="oneDiv" :style="{ height: windowHeight *0.9 + 'px' }">
      <div class="twoDiv">

        <el-row>
          <el-col :span="6">
            <el-row>
              <div style="margin-top: 5px">
                <el-button round type="text" style="color: #15ff2c" @click="$bus.$emit('openMenu', '2001')"><i
                    class="iconfont icon-kejiqiye"></i> 房源信息库
                </el-button>
                <el-button round type="text" style="color: #15ff2c" @click="$bus.$emit('openMenu', '9001')"><i
                    class="iconfont icon-shebei"></i> 客源信息库
                </el-button>
              </div>
            </el-row>
          </el-col>
          <el-col :span="12">
            <span class="oneSpan">个人工作台</span>
          </el-col>
          <el-col :span="6">
            <el-button round type="text" style="color: #15ff2c;margin-left: 60%;margin-top: 5px" v-if="showflag"
                       @click="drawerVisiable"><i
                class="iconfont icon-kejiqiye"></i> 工作任务安排
            </el-button>
          </el-col>
        </el-row>
      </div>


      <div class="threeDiv">
        <el-col :span="8">
          <div>
            <el-row>
              <calendar :startFatherMessage="startFatherMessage" style="margin-left: 3px"></calendar>
            </el-row>
          </div>
          <div>
            <el-row style="margin-top: 5px">
              <el-button type="success" icon="el-icon-s-platform" @click="addEditWorkForm" round
                         style="margin-left:10px">
                添加日志
              </el-button>
              <el-button type="success" icon="el-icon-s-platform" @click="savaAllMessage" round
                         style="margin-left:10px">
                保存所有
              </el-button>
              <el-button type="success" icon="el-icon-s-platform" @click="refreshAlldata" round
                         style="margin-left:10px">
                刷新
              </el-button>
            </el-row>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="fivDiv">
            <span class="twoSpan">本周工作计划</span>
          </div>
          <div class="eitDiv">
            <el-input :readonly=this.inputFlag class="oneinput-border-style" v-model="planParams.details"
                      type="textarea"
                      :rows="14" @input="savechange"></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sixDiv">
            <span class="twoSpan">本周工作总结</span>
          </div>
          <div class="eitDiv">
            <el-input :readonly=this.inputFlag class="twoinput-border-style" v-model="planParams.mouthDetails"
                      type="textarea"
                      :rows="14" @input="savechange"></el-input>
          </div>
        </el-col>
        <el-col :span="8">
          <el-row>
            <el-table :data="viewCollecyions" style="width: 100%;height: 140px" :height=120
                      :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}">
              <el-table-column label="工作性质" prop="jobNature"></el-table-column>
              <el-table-column label="任务数" prop="allCount"></el-table-column>
              <el-table-column label="已完成" prop="finishCount"></el-table-column>
              <el-table-column label="延期完成" prop="delayFinishCount"></el-table-column>
              <el-table-column label="未完成" prop="noFinishCount"></el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="echart" id="mychart" :style="myChartStyle"></div>
            </el-col>
            <el-col :span="12" style="">
              <div class="twoechart" id="mychart2" :style="myChartStyle"></div>
            </el-col>
          </el-row>
        </el-col>
      </div>
      <div class="fourDiv" :style="{ height: windowHeight *0.9*0.35 + 'px' }">
        <el-row>
          <!--          <el-table :data="supportCollecyions" style="width: 100%;"
                              :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}">
                      <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                      <el-table-column label="工作规划" prop="importance"></el-table-column>
                      <el-table-column label="重要程度" prop="allwork"></el-table-column>
                      <el-table-column label="开始日期" prop="finishwork"></el-table-column>
                      <el-table-column label="截止日期" prop="finishRatio"></el-table-column>
                      <el-table-column label="当前进度" prop="finishRatio"></el-table-column>
                      <el-table-column label="状态提醒" prop="finishRatio"></el-table-column>
                      <el-table-column label="备注" prop="finishRatio"></el-table-column>
                    </el-table>-->
          <el-form :model="editWorkCollecyion" ref="userForm">
            <el-table :style="{ height: windowHeight *0.9*0.44+'px' }" :height=this.windowHeight*0.9*0.44
                      style="font-size: 15px;width: 100%" :data="editWorkCollecyion.editWorkCollecyions" stripe
                      :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}">
              <el-table-column label="序号" type="index" width="50px" align="center"></el-table-column>
              <!--                <el-table-column label="姓名" align="center">
                                <template slot-scope="scope">
                                  <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.employeeName'"
                                                style="margin-top: 20px">
                                    <el-input v-model="scope.row.employeeName"></el-input>
                                  </el-form-item>
                                </template>
                              </el-table-column>
                              <el-table-column label="日报时间" align="center" width="150px">
                                <template slot-scope="scope">
                                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.reportTime'"
                                                :rules="rules.reportTime" style="margin-top: 20px">
                                    <el-date-picker value-format="yyyy-MM-dd" style="width:140px" v-model="scope.row.reportTime"
                                                    type="date" placeholder="选择日期"
                                                    @input="changeInsertShow(scope.row.reportTime, scope.$index)"
                                    >
                                    </el-date-picker>
                                  </el-form-item>
                                </template>
                              </el-table-column>-->
              <el-table-column label="工作性质" prop="jobNature" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.jobNature'"
                                style="height: 12px">
                    <el-select class="elselectOne" v-model="scope.row.jobNature" placeholder="请选择"
                               style="width: 150px;font-weight: bolder" :disabled="inputFlag">
                      <el-option
                          v-for="item in jobNatureOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作属性" prop="jobProperties" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.jobProperties'"
                                style="height: 12px">
                    <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 150px"
                               :disabled="inputFlag">
                      <el-option
                          v-for="item in jobPropertiesOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作内容" prop="details" align="center" width="300px">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.details'"
                                :rules="rules.details" style="height: 12px">
                    <el-input :readonly="inputFlag" v-model="scope.row.details" type="textarea" :rows="1"
                              style="width: 260px" @input="savechange" @focus="savechanges(scope)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作时间" prop="workOurs" width="120px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.workOurs'"
                                style="height: 12px">
                    <el-input
                        min="0"
                        max="8"
                        oninput="if(value>8){value=8};if(value<0){value=0};if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)};value=value.replace(/[^\d]/g,'.')"
                        v-model="scope.row.workOurs"
                        style="width: 110px;padding-bottom: 1px"
                        @input="savechange"
                        :readonly="inputFlag">
                    </el-input>
                    <!--                              onkeyup="this.value=this.value.replace(/\D/g,'')"-->
                    <!--                              onafterpaste="this.value=this.value.replace(/\D/g,'')"-->
                    <!--                              oninput="value=value.replace(/^(0+)|[^\d]+/g, '').slice(0,1)"-->
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="完成情况" prop="finishState" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.finishState'"
                                style="height: 14px;">
                    <el-select v-model="scope.row.finishState" placeholder="请选择" style="width: 150px"
                               :disabled="inputFlag">
                      <el-option
                          v-for="item in finishStateOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="领导要求完成时间" prop="completionTime" align="center" width="300px">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.completionTime'"
                                :rules="rules.details" style="height: 12px">
                    <el-input :readonly="true" v-model="scope.row.completionTime" type="text" :rows="1"
                              style="width: 180px" ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="工作确认状态" prop="confirmStatus" align="center" width="100px">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.confirmStatus'"
                                :rules="rules.confirmStatus" style="height: 12px">
                    <el-input :readonly="true" v-model="scope.row.confirmStatus=='1'?'已确认':scope.row.confirmStatus=='0'?'未确认':scope.row.confirmStatus=='-1'?'已退回':'未确认'" type="area"
                              style="width: 80px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="操作" prop="details" align="right">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete" @click="remove(scope.row, scope.$index)"
                             style="margin-bottom: 10px">

                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-row>
      </div>
    </div>


    <el-dialog :title="title" :visible.sync="show" style="width: 80%;margin-left: 13%;margin-top: 10%"
               @close="closEvent">
      <el-card>
        <el-input v-model="editdetails" type="textarea" :rows="15" style="width: 100%"></el-input>
      </el-card>
    </el-dialog>
  </XPage>
</template>

<script>

import calendar from "@/views/paperAction/Calendar";
import * as echarts from "echarts";
import Weather from "@/views/paperAction/Weather";

export default {
  name: "DailyPaperAction",
  components: {Weather, calendar},
  data() {
    return {
      title: "工作内容编辑",
      show: false,
      detailsindex: "",
      editdetails: "",
      addTaskCollecyion: {
        addTaskCollecyions: []
      },
      queryDate: {
        queryDates: []
      },

      showflag: false,
      queryRatio: "",
      drawloading: false,
      drawShowFlag: false,
      employeeName: "",
      employeeNo: "",
      inputFlag: true,
      xData: [], //横坐标
      yData: [], //数据
      myChartStyle: {float: "left", width: "100%", height: "180px"}, //图表样式
      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度
      refreshData: "",
      changeFlage: 0,
      calendarDate: "",
      loading: false,
      queryDailyParm: {
        queryDailyTime: "",
      },
      value: "",

      editWorkCollecyion: {
        editWorkCollecyions: []
      },
      planParams: {
        details: "",
        mouthDetails: "",
        planStartTime: "",
      },
      WorkStatusParam: {
        planStartTime: "",
      },
      weekDetails: "",
      viewCollecyions: [],
      supportCollecyions: [
        {importance: "重要", allwork: "4", finishwork: "2", finishRatio: "50%"},
      ],
      getPlanParam: {
        startTime: "",
      },
      jobPropertiesOptions: [{
        value: '1',
        label: '客户信息维护'
      }, {
        value: '2',
        label: '房屋信息维护'
      }, {
        value: '3',
        label: '外出看房'
      }, {
        value: '4',
        label: '外出接洽客户'
      }, {
        value: '5',
        label: '文案编辑'
      }, {
        value: '6',
        label: '尽职报告编写'
      }, {
        value: '7',
        label: '其他工作'
      }, {
        value: '8',
        label: '视频拍摄'
      }],
      jobNatureOptions: [
        {
          value: '3',
          label: '领导安排'
        }, {
          value: '1',
          label: '个人工作'
        }],
      finishStateOptions: [{
        value: '100',
        label: '全部完成100%'
      }, {
        value: '80',
        label: '基本完成80%'
      }, {
        value: '50',
        label: '部分完成50%'
      }, {
        value: '0',
        label: '未完成0%'
      }],
      rules: {
        reportTime: [{
          required: true,
          message: "请选择日报日期",
          trigger: ["change"],
        }, {
          validator: function (rule, value, callback) {
            // if (value > 24 || value < 0) {
            //   callback(new Error("工作时间填写有误"));
            // } else (
            //     callback()
            // )

            var now = new Date();
            var y = now.getFullYear();
            var m = now.getMonth() + 1;
            var d = now.getDate();
            if (m <= 9) {
              m = "0" + m;
            }
            if (d <= 9) {
              d = "0" + d;
            }
            var start = y + "-" + m + "-" + d;
            var selectDate = new Date(Date.parse(value));
            var date = new Date(Date.parse(start));
            if (date > selectDate) {
              callback(new Error("不可分配之前的工作！"));
            } else {
              callback()
            }
          },
          trigger: ["blur", "change"],
        }],
        jobProperties: [{
          required: true,
          message: "请选择工作属性",
          trigger: ["blur", "change"],
        }],
        /*       finishState: [{
                 required: true,
                 message: "请选择完成情况",
                 trigger: ["blur", "change"],
               }],
               jobNature: [{
                 required: true,
                 message: "请选择工作性质",
                 trigger: ["blur", "change"],
               }],*/
        acceptTaskMan: [{
          required: true,
          message: "请选择责任人",
          trigger: ["blur", "change"],
        }],
        /*        finishState: [{
                  required: true,
                  message: "请选择完成情况",
                  trigger: ["blur", "change"],
                }],
                jobNature: [{
                  required: true,
                  message: "请选择工作性质",
                  trigger: ["blur", "change"],
                }],*/
        workOurs: [
          //     {
          //   required: true,
          //   message: "工作时间必填",
          //   trigger: ["blur", "change"],
          // },
          //   {
          //   validator: function (rule, value, callback) {
          //     if (value > 24 || value < 0) {
          //       callback(new Error("工作时间填写有误"));
          //     } else (
          //         callback()
          //     )
          //
          //   },
          //   trigger: ["blur", "change"],
          // }
        ],
        /*  details: [{
            required: true,
            message: "工作内容必填",
            trigger: ["blur", "change"],
          }],*/
      }
    }
  },
  created() {
    sessionStorage.setItem("changeFlage", this.changeFlage)
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
  },
  mounted() {
    this.autoAddPlan();
    const that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };
    this.houseStatus();
    this.getInputFlag();
    this.queryWorkStatus();
    this.getUserMessage();
    this.refreshData = setInterval(() => {
      this.queryWorkStatus()
    }, 1000 * 500)
  },
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
  methods: {

    refreshAlldata() {
      this.queryDailyReport();
    },


    // drawerVisiable() {
    //   this.drawShowFlag = true;
    //   this.queryDate.queryDates = []
    //   this.queryDate.queryDates.push(this.newTodayHandle());
    //   this.queryDate.queryDates.push(this.newTodayHandle());
    //   this.querySendTask();
    //
    // },

    // initShowFlag() {
    //   this.$http.get("/foreclosure/report/daily/initshowflag").then
    //   ((resp) => {
    //     if (resp.data.data.showFlag == "1") {
    //       this.showflag = true;
    //     }
    //     if (resp.data.data.allValidUsers.length > 0) {
    //       resp.data.data.allValidUsers.forEach(item => {
    //         if (item.userId != this.employeeNo) {
    //           this.allValidUsers.push(item)
    //         }
    //       })
    //
    //     }
    //
    //   }).catch((err) => {
    //     this.$message.info("获取人员权限失败")
    //   })
    // },
    getUserMessage() {
      this.$http.get("/foreclosure/report/daily/getUserMessage").then
      ((resp) => {
        this.employeeName = resp.data.data.employeeName
        this.employeeNo = resp.data.data.employeeNo

      }).catch(
          (err) => {
            this.$message.error("初始化人员基本信息失败！")
          }
      )
    },
    /**
     * 右上角table,初始化本周工作的完成情况
     */
    queryWorkStatus() {
      this.WorkStatusParam.planStartTime = this.newDateWeekHandle();
      this.$http.post("/foreclosure/report/daily/queryWorkStatus", this.WorkStatusParam)
          .then((resp) => {

            this.viewCollecyions = resp.data.data.queryState;
            this.queryRatio = resp.data.data.queryRatio;
            this.initpieEcharts();
          })
    },
    /**
     * 右上角柱状图的方法
     */
    houseStatus() {
      this.WorkStatusParam.planStartTime = this.newDateWeekHandle();
      this.$http.post("/foreclosure/report/daily/houseStatus", this.WorkStatusParam)
          .then((resp) => {

            this.xData = ["一拍", "二拍", "变卖", "再次拍卖"];
            var res = resp.data.data;
            const map = new Map(Object.entries(res))
            this.yData.push(map.get("一拍") == undefined ? 0 : map.get("一拍"))
            this.yData.push(map.get("二拍") == undefined ? 0 : map.get("二拍"))
            this.yData.push(map.get("变卖") == undefined ? 0 : map.get("变卖"))
            this.yData.push(map.get("再次拍卖") == undefined ? 0 : map.get("再次拍卖"))

            this.initEcharts();

          })
    },

    /**
     * 保存日志
     */
    saveEditWorkForm() {

      if (this.editWorkCollecyion.editWorkCollecyions.length == 0) {
        return
      }
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.editWorkCollecyion.editWorkCollecyions.forEach(item => {

            item.reportTime = this.calendarDate;
            item.planStartTime = this.newDateWeekHandle(this.calendarDate);
          })
          this.$http
              .post("/foreclosure/report/daily/saveDailyReport", this.editWorkCollecyion.editWorkCollecyions)
              .then((resp) => {
                this.loading = false;
                if (resp.data.code == 0) {
                  this.$message.success("日报保存成功！")
                  this.queryWorkStatus()
                  this.queryDailyReport()
                }
              }).catch((err) => {
            this.loading = false;
            this.$message.error("日报保存失败！")
          })
        } else {
          this.$message.error("日报保存失败！")
        }
      });

    },
    /**
     * 查询日报
     */
    queryDailyReport() {

      this.loading = true;
      this.queryDailyParm.queryDailyTime = this.calendarDate;
      this.$http.post("/foreclosure/report/daily/queryDailyReport", this.queryDailyParm)
          .then((resp) => {
            this.loading = false;
            if (resp.data.data.data == "") {
              this.editWorkCollecyion.editWorkCollecyions = []
            } else {

              this.editWorkCollecyion.editWorkCollecyions = resp.data.data.data;
            }
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },

    /**
     * 日报删除按钮的作用
     * @param row
     * @param index
     */
    remove(row, index) {
      this.$http.post("/foreclosure/report/daily/removedaily", row)
          .then((resp) => {
            if (resp.data.data > 0) {
              this.$message.info("领导新增任务不可删除！")
              return
            }
            this.editWorkCollecyion.editWorkCollecyions.splice(index, 1)
            this.drawloading = false;
            this.queryDailyReport()
          })
          .catch((err) => {
            this.$message.error("删除失败")
          })
    },

    /**
     * 添加待编辑日报
     */
    addEditWorkForm() {
      /*      if(this.editWorkCollecyion.editWorkCollecyions.length==5){
              this.$message.info("添加失败，每天最多填写5份日志。")
              return;
            }*/
      this.editWorkCollecyion.editWorkCollecyions.push({userName: "张三", details: ""})
      this.addTaskCollecyion.addTaskCollecyions.push({reportTime: this.newTodayHandle()})
    },


    /**
     * 进入的时候判断本周有没有计划信息，如果没有的话，那么自动生成一条计划
     */
    autoAddPlan() {
      this.getPlanParam.startTime = this.newDateWeekHandle();
      this.$http.get("/foreclosure/report/daily/getPlanReportByTime", {params: this.getPlanParam})
          .then((resp) => {
            if (resp.data.data == "0") {
              this.planParams.details = "",
                  this.planParams.planStartTime = this.getPlanParam.startTime,
                  this.addAcessPlanReport();
            }
          }).catch((err) => {
        this.$message.error("初始化计划失败")
      })
    },

    /**
     * 初始化计划
     */
    addAcessPlanReport() {
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.$http.post("/foreclosure/report/daily/addPlanReport", this.planParams)
          .then((resp) => {
            this.loading = false;
          }).catch(
          (err) => {
            this.loading = false;
          }
      )
    },

    /**
     * 保存计划
     */
    addPlanReport() {
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.$http.post("/foreclosure/report/daily/addPlanReport", this.planParams)
          .then((resp) => {
            this.loading = false;
            this.$message.success("计划保存成功")
          }).catch(
          (err) => {
            this.loading = false;
            this.$message.error("计划保存失败！")
          }
      )
    },
    /**
     * 保存周报
     */

    addMouthReport() {

      var nextMonday = new Date(this.nextWeekDayHandle());
      var currentMonday = new Date(this.calendarDate);

      if (this.planParams.mouthDetails) {

        if (nextMonday.getTime() <= currentMonday.getTime()) {
          this.$message.info("周报保存失败，不可填本周之后的周报！")
          return;
        }
        this.loading = true;
        this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
        this.$http.post("/foreclosure/report/daily/addMouthReport", this.planParams)
            .then((resp) => {
              this.loading = false;
              this.$message.success("周报保存成功")
            }).catch(
            (err) => {
              this.loading = false;
              this.$message.error("周报保存失败！")
            }
        )
      }
    },
    /**
     * 查询计划
     */
    queryPlanReport() {
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.$http.post("/foreclosure/report/daily/queryPlanReport", this.planParams)
          .then((resp) => {
            this.loading = false;
            this.planParams.details = resp.data.data.details;
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },

    /**
     * 查询周报
     */
    queryMouthReport() {
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.$http.post("/foreclosure/report/daily/queryMouthReport", this.planParams)
          .then((resp) => {
            this.loading = false;
            this.planParams.mouthDetails = resp.data.data.details;
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },


    /**
     * 保存按钮，保存所有的信息
     */
    savaAllMessage() {
      this.addPlanReport()
      this.addMouthReport()
      this.saveEditWorkForm()
      this.changeFlage = 0;
      sessionStorage.setItem("changeFlage", this.changeFlage)
    },

    startFatherMessage(name) {
      if (this.changeFlage > 0) {
        this.$confirm('数据有变动但未保存，是否需要保存？', '提示', {
          cancelButtonText: '否',
          confirmButtonText: '是',
          type: 'warning'
        }).then(() => {
          this.addPlanReport()
          this.addMouthReport()
          this.saveEditWorkForm()
          this.changeFlage = 0
          sessionStorage.setItem("changeFlage", this.changeFlage)
        }).catch(() => {
          this.calendarDate = name.day;
          this.queryPlanReport();
          this.queryMouthReport();
          this.queryDailyReport();
          this.getInputFlag();
          this.changeFlage = 0;
          sessionStorage.setItem("changeFlage", this.changeFlage)
        });
      } else {
        this.calendarDate = name.day;
        this.queryPlanReport();
        this.queryMouthReport();
        this.queryDailyReport();
        this.getInputFlag();
      }


    },

    initpieEcharts() {
      // 基本柱状图
      const option = {
        title: {
          text: '完成率',
          subtext: this.queryRatio + '%',
          left: 'center',
          top: 61.5,
          textStyle: {
            color: '#1890fe'
          }
        },
        yAxis: {show: false},
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            data: [
              {value: this.queryRatio, itemStyle: {color: '#1890fe'}},
              {value: 100 - this.queryRatio, itemStyle: {color: '#f1f1f1'}},
            ],

            label: {
              show: false,
              position: 'center',

            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'blod'
              },
            }
          }
        ]
      };
      const myChart2 = echarts.init(document.getElementById("mychart2"));
      myChart2.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart2.resize();
      });
    },

    initEcharts() {
      // 基本柱状图
      const option = {
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: -20   //值>0向右倾斜，值<0则向左倾斜
          },
          data: this.xData
        },
        color: ['#FBAAA7'],
        yAxis: {show: false},

        series: [
          {
            type: "bar", //形状为柱状图
            data: this.yData,
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'black'
              }
            },
          }
        ]
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    savechange() {
      this.changeFlage = this.changeFlage + 1;
      sessionStorage.setItem("changeFlage", this.changeFlage)

    },

    savechanges(row) {
      this.changeFlage = this.changeFlage + 1;
      sessionStorage.setItem("changeFlage", this.changeFlage)
      this.editdetails = row.row.details
      this.detailsindex = row
      this.show = true

    },
    closEvent() {
      this.detailsindex.row.details = this.editdetails
    },


    getInputFlag() {

      //本周一是几号

      var monday = new Date(this.newDateWeekHandle());
      var weekend = new Date(this.newWeekendHandle());
      var date = new Date(this.newTodayHandle(this.calendarDate));

      if ((date.getTime() >= monday.getTime()) && (date.getTime() <= weekend.getTime())) {
        this.inputFlag = false;
      } else {
        this.inputFlag = true;
      }
    },
    /**
     * 计算本周一是几号
     * @param row
     */
    newDateWeekHandle(day) {

      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var nowTime = now.getTime();
      var day = now.getDay();
      if (day == 0) {
        day = 7;
      }
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime;
      var time = new Date(MondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },
    /**
     * 计算是几号
     * @param row
     */
    newTodayHandle(day) {
      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var y = now.getFullYear();
      var m = now.getMonth() + 1;
      var d = now.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },

    /**
     * 计算下周一是几号
     * @param row
     */
    nextWeekDayHandle() {
      var now = new Date();
      var nowTime = now.getTime();
      var day = now.getDay();
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime;
      var nextMondayTime = MondayTime + (7) * oneDayTime;
      var time = new Date(nextMondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },


    /**
     * 计算本周日是几号
     * @param row
     */
    newWeekendHandle(day) {

      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var nowTime = now.getTime();
      var day = now.getDay();
      if (day == 0) {
        day = 7;
      }
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime + (7 - day) * oneDayTime;
      var time = new Date(MondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },
getCurrentTime() {
  var date = new Date();//当前时间
  var year = date.getFullYear() //返回指定日期的年份
  var month = this.repair(date.getMonth() + 1);//月
  var day = this.repair(date.getDate());//日
  var hour = this.repair(date.getHours());//时
  var minute = this.repair(date.getMinutes());//分
  var second = this.repair(date.getSeconds());//秒

  //当前时间
  var curTime = year + "-" + month + "-" + day
      + " " + hour + ":" + minute + ":" + second;
  return curTime;
},

//补0

 repair(i){
  if (i >= 0 && i <= 9) {
    return "0" + i;
  } else {
    return i;
  }
}


  },
};
</script>

<style>
.oneSpan {
  font-size: x-large;
  font-weight: bolder;
  color: #e1eaf7;
  display: block;
  margin-top: 5px;
  margin-left: 42%;
}

.twoSpan {
  font-size: medium;
  font-weight: bolder;
  color: #e1eaf7;
  text-align: center;
  display: block;
  margin-top: -1px;
}

.oneDiv {
  border: 2px solid #303133;
  margin-top: 5px;
}

/*.one750pxDiv {
  height: 750px;
  border: 2px solid #303133;
  margin-top: 30px;
}*/

.twoDiv {
  height: 6%;
  background-color: #88AE99
}

.threeDiv {
  height: 300px;
  margin-top: 5px;
}


.fourDiv {
  height: 20%;
}


/** 默认样式 */
.oneinput-border-style {
  border: 3px solid #EEA371;
  border-radius: 5px;
  margin-left: 5px;
  width: 100%;
  margin-top: 3px;
}

.twoinput-border-style {
  border: 3px solid #88AE99;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  margin-top: 3px;
}


.fivDiv {
  width: 90%;
  margin-left: 8px;
  background-color: #EEA371;
  border-radius: 10px;
}

.eitDiv {
  width: 90%;
}

.sixDiv {
  width: 90%;
  margin-left: 12px;
  background-color: #88AE99;
  border-radius: 10px;
}

.echart {
  /*margin-top: 30px;*/
}

/*.elselectOne{*/
/*::v-deep */
/*}*/


</style>


