<template xmlns:el-col="http://www.w3.org/1999/html">
  <XPage v-loading="loading">


    <div class="oneDivs" :style="{ height: windowHeight  + 'px' }">
      <div class="divTwo">
        <el-row>
          <el-form :model="queryParam">
            <el-col :span="4">
              <el-form-item label="员工姓名:">
                <el-select class="elselectOne" v-model="queryParam.userId" placeholder="请选择" style="width: 150px;"
                           @change="querySalesReport(queryParam.userId)">
                  <el-option
                      v-for="item in queryUSers"
                      :key="item.userId"
                      :label="item.aac003"
                      :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <div class="twoDivs">
        <el-row>
          <el-col :span="18">
            <span class="oneSpans">工作计划查询</span>
          </el-col>
        </el-row>
      </div>
      <div class="threeDivs">
        <el-row>
          <el-col :span="8">
            <div>
              <el-row>
                <calendar :startFatherMessage="startFatherMessage" style="margin-left: 3px"></calendar>
              </el-row>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="fivDivs">
              <span class="twoSpans">本周工作计划</span>
            </div>
            <div class="eitDivs">
              <el-input :readonly=this.inputFlag class="oneinput-border-styles" v-model="planParams.details"
                        type="textarea"
                        :rows="13"></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="sixDivs">
              <span class="twoSpans">本周工作总结</span>
            </div>
            <div class="eitDivs">
              <el-input :readonly=this.inputFlag class="twoinput-border-styles" v-model="planParams.mouthDetails"
                        type="textarea"
                        :rows="13"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <el-row>
              <el-table :data="viewCollecyions" style="width: 100%;height: 100px"
                        :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}"
              :height=100>
                <el-table-column label="工作性质" prop="jobNature"></el-table-column>
                <el-table-column label="任务数" prop="allCount"></el-table-column>
                <el-table-column label="已完成" prop="finishCount"></el-table-column>
                <el-table-column label="延期完成" prop="delayFinishCount"></el-table-column>
                <el-table-column label="未完成" prop="noFinishCount"></el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="echart" id="mychart3" :style="myChartStyle"></div>
              </el-col>
              <el-col :span="12">
                <div class="twoechart" id="mychart4" :style="myChartStyle" ></div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="fourDiv" :style="{ height: windowHeight *0.9*0.5 + 'px' }">
        <el-row>
          <!--          <el-table :data="supportCollecyions" style="width: 100%;"
                              :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}">
                      <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                      <el-table-column label="工作规划" prop="importance"></el-table-column>
                      <el-table-column label="重要程度" prop="allwork"></el-table-column>
                      <el-table-column label="开始日期" prop="finishwork"></el-table-column>
                      <el-table-column label="截止日期" prop="finishRatio"></el-table-column>
                      <el-table-column label="当前进度" prop="finishRatio"></el-table-column>
                      <el-table-column label="状态提醒" prop="finishRatio"></el-table-column>
                      <el-table-column label="备注" prop="finishRatio"></el-table-column>
                    </el-table>-->
          <el-form :model="editWorkCollecyion" ref="userForm" :style="{ height: windowHeight *0.9*0.5 + 'px' }">
            <el-table :style="{ height: windowHeight *0.9*0.5+'px' }" :height=this.windowHeight*0.9*0.5
                      style="font-size: 15px;width: 100%" :data="editWorkCollecyion.editWorkCollecyions" stripe
                      :header-cell-style="{background:'#EEA371',color:'#FFFFFF'}">
              <el-table-column label="序号" type="index" width="50px" align="center"></el-table-column>
              <!--                <el-table-column label="姓名" align="center">
                                <template slot-scope="scope">
                                  <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.employeeName'"
                                                style="margin-top: 20px">
                                    <el-input v-model="scope.row.employeeName"></el-input>
                                  </el-form-item>
                                </template>
                              </el-table-column>
                              <el-table-column label="日报时间" align="center" width="150px">
                                <template slot-scope="scope">
                                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.reportTime'"
                                                :rules="rules.reportTime" style="margin-top: 20px">
                                    <el-date-picker value-format="yyyy-MM-dd" style="width:140px" v-model="scope.row.reportTime"
                                                    type="date" placeholder="选择日期"
                                                    @input="changeInsertShow(scope.row.reportTime, scope.$index)"
                                    >
                                    </el-date-picker>
                                  </el-form-item>
                                </template>
                              </el-table-column>-->
              <el-table-column label="工作性质" prop="jobNature" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.jobNature'"
                                style="height: 12px">
                    <el-input :readonly="inputFlag" v-model="scope.row.jobNature" type="textarea" :rows="1">

                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作属性" prop="jobProperties" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.jobProperties'"
                                style="height: 12px">
                    <el-input :readonly="inputFlag" v-model="scope.row.jobProperties" type="textarea" :rows="1">
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作内容" prop="details" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.details'"
                                :rules="rules.details" style="height: 12px">
                    <el-input :readonly="inputFlag" v-model="scope.row.details" type="textarea" :rows="1" @focus="savechanges(scope)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作时间" prop="workOurs" width="120px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.workOurs'"
                                style="height: 12px">
                    <el-input onkeyup="this.value=this.value.replace(/\D/g,'')"
                              onafterpaste="this.value=this.value.replace(/\D/g,'')"
                              oninput="value=value.replace(/^(0+)|[^\d]+/g, '').slice(0,1)"
                              v-model="scope.row.workOurs"
                              style="width: 110px;padding-bottom: 1px"
                              :readonly="inputFlag">
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="完成情况" prop="finishState" width="160px" align="center">
                <template slot-scope="scope">
                  <el-form-item size="mini" :prop="'editWorkCollecyions.' + scope.$index + '.finishState'"
                                style="height: 14px;">
                    <el-input :readonly="inputFlag" v-model="scope.row.finishState" type="textarea" :rows="1">
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
<!--              <el-table-column label="操作" prop="details" align="right">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete" @click="remove(scope.row, scope.$index)"
                             style="margin-bottom: 10px">

                  </el-button>
                </template>
              </el-table-column>-->
            </el-table>
          </el-form>
        </el-row>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="show" style="width: 80%;margin-left: 13%;margin-top: 10%" >
      <el-card>
        <el-input v-model="editdetails" type="textarea" :rows="15" style="width: 100%"></el-input>
      </el-card>
    </el-dialog>
  </XPage>
</template>

<script>

import calendar from "@/views/paperAction/Calendar";
import * as echarts from "echarts";

export default {
  name: "MouthPaperAction",
  components: {calendar},
  data() {
    return {
      title:"工作内容查看",
      show:false,
      editdetails:"",
      queryUSers: [],
      queryParam: {
        userId: ""
      },
      employeeName: "",
      employeeNo: "",
      inputFlag: true,
      xData: [], //横坐标
      yData: [], //数据
      myChartStyle: {float: "left", width: "100%", height: "180px"}, //图表样式
      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度
      refreshData: "",
      changeFlage: 0,
      calendarDate: "",
      loading: false,
      pieDataName:[],
      pieDataTime:[],
      queryDailyParm: {
        queryDailyTime: "",
        userId:"",
      },
      value: "",
      editWorkCollecyion: {
        editWorkCollecyions: [],
      },
      planParams: {
        details: "",
        mouthDetails: "",
        planStartTime: "",
        userId:"",
      },
      WorkStatusParam: {
        planStartTime: "",
        userId:"",
      },
      weekDetails: "",
      viewCollecyions: [],
      supportCollecyions: [
        {importance: "重要", allwork: "4", finishwork: "2", finishRatio: "50%"},
      ],
      getPlanParam: {
        startTime: "",
      },
      jobPropertiesOptions: [{
        value: '1',
        label: '客户信息维护'
      }, {
        value: '2',
        label: '房屋信息维护'
      }, {
        value: '3',
        label: '外出看房'
      }, {
        value: '4',
        label: '外出接洽客户'
      }, {
        value: '5',
        label: '视频资料编辑'
      }, {
        value: '6',
        label: '尽职报告编写'
      }, {
        value: '7',
        label: '其他工作'
      }],
      jobNatureOptions: [
        {
          value: '3',
          label: '领导安排'
        }, {
          value: '1',
          label: '重要'
        }, {
          value: '2',
          label: '重要'
        }],
      finishStateOptions: [{
        value: '100',
        label: '全部完成100%'
      }, {
        value: '80',
        label: '基本完成80%'
      }, {
        value: '50',
        label: '部分完成50%'
      }, {
        value: '0',
        label: '未完成0%'
      }],
      rules: {
        /*  reportTime: [{
           required: true,
           message: "请选择日报日期",
           trigger: ["change"],
         }, {
          validator: function (rule, value, callback) {
             // if (value > 24 || value < 0) {
             //   callback(new Error("工作时间填写有误"));
             // } else (
             //     callback()
             // )

             var date=new Date()
             var selectDate = new Date(Date.parse(value));

             if(date<selectDate)
             {
               callback(new Error("日报时间有误"));
             }else{
               callback()
             }
           },
           trigger: ["blur", "change"],
         }],*/
        /*   jobProperties: [{
             required: true,
             message: "请选择工作属性",
             trigger: ["blur", "change"],
           }],
           finishState: [{
             required: true,
             message: "请选择完成情况",
             trigger: ["blur", "change"],
           }],
           jobNature: [{
             required: true,
             message: "请选择工作性质",
             trigger: ["blur", "change"],
           }],*/
        workOurs: [
          //     {
          //   required: true,
          //   message: "工作时间必填",
          //   trigger: ["blur", "change"],
          // },
          //   {
          //   validator: function (rule, value, callback) {
          //     if (value > 24 || value < 0) {
          //       callback(new Error("工作时间填写有误"));
          //     } else (
          //         callback()
          //     )
          //
          //   },
          //   trigger: ["blur", "change"],
          // }
        ],
        /*  details: [{
            required: true,
            message: "工作内容必填",
            trigger: ["blur", "change"],
          }],*/
      }
    }
  },
  created() {
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
  },
  mounted() {
    const that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };
    this.queryWorkStatus();
    this.initAuthorityUser();
    this.refreshData = setInterval(() => {
      this.queryWorkStatus()
    }, 1000 * 500)
  },
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
  methods: {


    savechanges(row) {
      this.editdetails=row.row.details
      this.show=true

    },

    /**
     * 初始化可以查询的员工集合
     */
    initAuthorityUser() {
      this.$http.get("/foreclosure/report/daily/initAuthorityUser")
          .then((resp) => {
            
            this.queryUSers = resp.data.data
            this.getUserMessage();
          }).catch((err) => {
        this.$message.info("初始化可查询的人员失败！")
      })
    },
    getUserMessage() {
      this.$http.get("/foreclosure/report/daily/getUserMessage").then
      ((resp) => {
        this.employeeName = resp.data.data.employeeName
        this.employeeNo = resp.data.data.employeeNo
        
        this.queryParam.userId = resp.data.data.employeeNo
/*
        this.queryPlanReport();
*/
        this.queryPlanReport();
        this.queryMouthReport();
        this.queryDailyReport();
        this.houseStatus();
        this.workStatus();
      }).catch(
          (err) => {
            this.$message.error("初始化人员基本信息失败！")
          }
      )
    },
    /**
     * 右上角table,初始化本周工作的完成情况
     */
    queryWorkStatus() {

      this.WorkStatusParam.planStartTime = this.newDateWeekHandle();
      this.$http.post("/foreclosure/report/daily/secQueryWorkStatus", this.WorkStatusParam)
          .then((resp) => {
            this.viewCollecyions = resp.data.data.queryState;
          })
    },
    /**
     * 右上角柱状图的方法
     */
/*    houseStatus() {
      this.WorkStatusParam.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.WorkStatusParam.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/houseStatusByUserID", this.WorkStatusParam)
          .then((resp) => {
            this.xData = ["客户信息维护", "房屋信息维护", "外出看房", "外出接洽客户","视频资料编辑","尽职报告编写","其他工作"];
            this.yData=[];
            var res = resp.data.data;
            const map = new Map(Object.entries(res))
            this.yData=[],
            this.yData.push(map.get("1") == undefined ? 0 : map.get("1"))
            this.yData.push(map.get("2") == undefined ? 0 : map.get("2"))
            this.yData.push(map.get("3") == undefined ? 0 : map.get("3"))
            this.yData.push(map.get("4") == undefined ? 0 : map.get("4"))
            this.yData.push(map.get("5") == undefined ? 0 : map.get("5"))
            this.yData.push(map.get("6") == undefined ? 0 : map.get("6"))
            this.yData.push(map.get("7") == undefined ? 0 : map.get("7"))
            this.initEcharts();
          })
    },*/
    houseStatus() {
      this.WorkStatusParam.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.WorkStatusParam.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/houseStatusByUserID", this.WorkStatusParam)
          .then((resp) => {
            this.xData = ["任务数", "已完成任务数", "退回任务数"];
            this.yData=[];
            var res = resp.data.data;
            const map = new Map(Object.entries(res))
            this.yData=[],
            this.yData.push(map.get("cunt") == undefined ? 0 : map.get("cunt"))
            this.yData.push(map.get("finishCunt") == undefined ? 0 : map.get("finishCunt"))
            this.yData.push(map.get("confirmCunt") == undefined ? 0 : map.get("confirmCunt"))
            this.initEcharts();
          })
    },

    /**
     * 右上角饼柱状图的方法
     */
    workStatus() {
      this.WorkStatusParam.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.WorkStatusParam.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/houseStatusByUserIDT", this.WorkStatusParam)
          .then((resp) => {
            this.pieDataName = ["客户信息维护", "房屋信息维护", "外出看房", "外出接洽客户","视频资料编辑","尽职报告编写","其他工作"];
            this.pieDataTime=[];
            var res = resp.data.data;
            const map = new Map(Object.entries(res))
            this.pieDataTime.push(map.get("1") == undefined ? 0 : map.get("1"));
            this.pieDataTime.push(map.get("2") == undefined ? 0 : map.get("2"));
            this.pieDataTime.push(map.get("3") == undefined ? 0 : map.get("3"));
            this.pieDataTime.push(map.get("4") == undefined ? 0 : map.get("4"));
            this.pieDataTime.push(map.get("5") == undefined ? 0 : map.get("5"));
            this.pieDataTime.push(map.get("6") == undefined ? 0 : map.get("6"));
            this.pieDataTime.push(map.get("7") == undefined ? 0 : map.get("7"));
            console.log(this.pieDataTime)
            this.initpieEcharts();
          })
    },

    /**
     * 查询日报
     */
    queryDailyReport() {
      
      this.loading = true;
      this.queryDailyParm.queryDailyTime = this.calendarDate;
      this.queryDailyParm.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/queryDailyReport", this.queryDailyParm)
          .then((resp) => {
            
            this.loading = false;
            this.editWorkCollecyion.editWorkCollecyions = resp.data.data.data;
            this.editWorkCollecyion.editWorkCollecyions.forEach(
                item => {
                  item.jobNature = item.jobNature == "1" ? "重要" : item.jobNature == "2" ? "一般" : "领导安排"
                  item.jobProperties = item.jobProperties == "1" ? "客户信息维护" : item.jobProperties == "2" ? "房屋信息维护" : item.jobProperties == "3" ? "外出看房" : item.jobProperties == "4" ? "外出接洽客户" : item.jobProperties == "5" ? "视频资料编辑" : item.jobProperties == "6" ? "尽职报告编写" : "其他工作"
                  item.finishState = item.finishState == "100" ? "全部完成100%" : item.finishState == "80" ? "基本完成80%" : item.finishState == "50" ? "部分完成50%" : "未完成0%"

                }
            )
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },


    /**
     * 查询计划
     */
    queryPlanReport() {
      
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.planParams.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/queryPlanReport", this.planParams)
          .then((resp) => {
            this.loading = false;
            this.planParams.details = resp.data.data.details;
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },

    /**
     * 查询周报
     */
    queryMouthReport() {
      this.loading = true;
      this.planParams.planStartTime = this.newDateWeekHandle(this.calendarDate);
      this.planParams.userId=this.queryParam.userId;
      this.$http.post("/foreclosure/report/daily/queryMouthReport", this.planParams)
          .then((resp) => {
            this.loading = false;
            this.planParams.mouthDetails = resp.data.data.details;
          })
          .catch(
              (err) => {
                this.loading = false;
                this.$message.error("查询本周计划失败！")
              }
          )
    },




    /**
     * 选择日历触发查询
     * @param name
     */
    startFatherMessage(name) {
        this.calendarDate = name.day;
        this.queryPlanReport();
        this.queryMouthReport();
        this.queryDailyReport();
        this.houseStatus();
        this.workStatus();
    },

    /**
     * 选择人员触发查询
     * @param userid
     */
    querySalesReport(userid) {
      this.queryPlanReport();
      this.queryMouthReport();
      this.queryDailyReport();
      this.houseStatus();
      this.workStatus();
      this.queryWorkStatus();

    },

    initpieEcharts() {
      // 基本柱状图
      const option = {
        title: {
           text: '周工作时间分布图',
          // subtext: '44.4%',
          left: 'center',
          textStyle: {
            color: '#1890fe'
          }
        },
        tooltip: {
          trigger: 'item'
        },
      /*  legend: {
          type: 'scroll',
          orient: 'horizontal', // vertical
        },*/
        series: [
          {
            type: 'pie',
            // radius: ['50%', '70%'],
            data: [
              {value: this.pieDataTime[0], name:this.pieDataName[0]+"（小时）",itemStyle: {color: '#32CD99'} },
              {value: this.pieDataTime[1], name:this.pieDataName[1]+"（小时）",itemStyle: {color: '#5959AB'} },
              {value: this.pieDataTime[2], name:this.pieDataName[2]+"（小时）",itemStyle: {color: '#6F4242'}},
              {value: this.pieDataTime[3], name:this.pieDataName[3]+"（小时）",itemStyle: {color: '#97694F'} },
              {value: this.pieDataTime[4], name:this.pieDataName[4]+"（小时）",itemStyle: {color: '#871F78'}},
              {value: this.pieDataTime[5], name:this.pieDataName[5]+"（小时）",itemStyle: {color: '#FF0000'} },
              {value: this.pieDataTime[6] , name:this.pieDataName[6]+"（小时）",itemStyle: {color: '#1890fe'}},
            ],

            label: {
              show: false,
              position: 'center',

            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      const myChart4 = echarts.init(document.getElementById("mychart4"));
      myChart4.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart4.resize();
      });
    },

    initEcharts() {
      // 基本柱状图
      const option = {
        // title:{
        //   text: "周工作总结",
        //   text
        // },
        tooltip:{
          valueFormatter: function (value){
            return value+'人/天';
          }
        },
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: -44   //值>0向右倾斜，值<0则向左倾斜
          },
          data: this.xData
        },
        color: ['#FBAAA7'],
        yAxis: {show: false},

        series: [
          {
            type: "bar", //形状为柱状图
            data: this.yData,
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'black'
              }
            },

          }
        ]
      };
      const myChart3 = echarts.init(document.getElementById("mychart3"));
      myChart3.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart3.resize();
      });
    },


    /**
     * 计算本周一是几号
     * @param row
     */
    newDateWeekHandle(day) {

      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var nowTime = now.getTime();
      var day = now.getDay();
      if (day == 0) {
        day = 7;
      }
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime;
      var time = new Date(MondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },

    /**
     * 计算下周一是几号
     * @param row
     */
    nextWeekDayHandle() {
      var now = new Date();
      var nowTime = now.getTime();
      var day = now.getDay();
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime;
      var nextMondayTime = MondayTime + (7) * oneDayTime;
      var time = new Date(nextMondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },


    /**
     * 计算本周日是几号
     * @param row
     */
    newWeekendHandle(day) {

      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var nowTime = now.getTime();
      var day = now.getDay();
      if (day == 0) {
        day = 7;
      }
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime + (7 - day) * oneDayTime;
      var time = new Date(MondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },


  },
};
</script>

<style>
.oneSpans {
  font-size: x-large;
  font-weight: bolder;
  color: #e1eaf7;
  display: block;
  margin-top: 5px;
  margin-left: 60%;
}

.twoSpans {
  font-size: medium;
  font-weight: bolder;
  color: #e1eaf7;
  text-align: center;
  display: block;
  margin-top: -1px;
}

.oneDivs {
  border: 2px solid #303133;
  margin-top: 0px;
}

/*.one750pxDiv {
  height: 750px;
  border: 2px solid #303133;
  margin-top: 30px;
}*/

.twoDivs {
  height: 6%;
  background-color: #88AE99
}

.threeDivs {
  height: 35%;
  margin-top: 5px;
}

.fourDiv {
  height: 45%;
  margin-top: 0px;
}


/** 默认样式 */
.oneinput-border-styles {
  border: 3px solid #EEA371;
  border-radius: 5px;
  margin-left: 5px;
  width: 100%;
  margin-top: 3px;
}

.twoinput-border-styles {
  border: 3px solid #88AE99;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  margin-top: 3px;
}


.fivDivs {
  width: 90%;
  margin-left: 8px;
  background-color: #EEA371;
  border-radius: 10px;
}

.eitDivs {
  width: 90%;
}

.sixDivs {
  width: 90%;
  margin-left: 12px;
  background-color: #88AE99;
  border-radius: 10px;
}

.echart {
  /*margin-top: 30px;*/
}

.divTwo {
  margin-top: 20px;
  margin-left: 20px;
}


</style>

