<template>
  <div class="simplediv"  >
    <div id="he-plugin-simple"></div>
  </div>　　
</template>
<script>
export default {
  name: "Weather",

  mounted() {
    window.WIDGET = {
      "CONFIG": {
        "modules": "01234",
        "background": "1",
        "tmpColor": "FFFFFF",
        "tmpSize": "18",
        "cityColor": "FFFFFF",
        "citySize": "18",
        "aqiColor": "FFFFFF",
        "aqiSize": "18",
        "weatherIconSize": "18",
        "alertIconSize": "18",
        "padding": "0px 0px 0px 0px",
        "shadow": "0",
        "language": "auto",
        "borderRadius": "10",
        "fixed": "true",
        "vertical": "top",
        "horizontal": "left",
        "city": "CN101190101",
        "left": "10",
        "top": "3",
        "key": "7a324bc845504a23a4d02e310f2df188"
      }};
    (function (d) {
      var c = d.createElement('link')
      c.rel = 'stylesheet'
      c.href = require('./he-simple.css');
      // c.href = 'https://widget.heweather.net/simple/static/css/he-simple.css?v=1.4.0'
      var s = d.createElement('script');
      // s.src = 'https://widget.heweather.net/simple/static/js/he-simple.js?v=1.4.0';
       s.href = require('./he-simple.js');
      var sn = d.getElementsByTagName('script')[0]
      sn.parentNode.insertBefore(c, sn)
      sn.parentNode.insertBefore(s, sn)
    })(document)
    var script = document.createElement('script');
    script.type = 'text/javascript';
    // script.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
</script>
<style>
.simplediv{
  width: 30px;
  height: 30px;
  z-index: 999;
  position: absolute;
}
</style>