<template>
  <XPage v-loading="loading">
    <el-form :model="prm" :inline="true">
      <el-form-item label="房源标题">
        <el-input v-model="prm.title" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          icon="el-icon-search"
          :loading="loading"
          @click="search"
        >
          检索
        </el-button>
      </el-form-item>
    </el-form>

    <el-table :data="houseList" stripe v-auto>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column
        label="房源编号"
        width="150"
        prop="houseId"
      ></el-table-column>
      <el-table-column label="房源标题" prop="title"></el-table-column>
      <el-table-column
        label="当前价格"
        width="150"
        prop="initialPrice"
      ></el-table-column>
      <el-table-column
        label="市场价"
        width="150"
        prop="marketPrice"
      ></el-table-column>
      <el-table-column
        label="开始时间"
        width="220"
        prop="startTime"
      ></el-table-column>
      <el-table-column
        label="结束时间"
        width="220"
        prop="endTime"
      ></el-table-column>
      <el-table-column
        label="围观人数"
        width="120"
        prop="viewerCount"
      ></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-add" @click="addOpp(scope.row)">
            增加商机
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :total="total"
        :current-page.sync="prm.page"
        :page-size="prm.size"
        @current-change="query"
        layout="total,prev,pager,next"
      ></el-pagination>
    </div>

    <el-dialog title="新增商机" :visible.sync="showAdd">
      <el-form
        :model="deal"
        ref="dealForm"
        label-width="160px"
        style="width: 100%; margin: 20px auto"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="房源编号:" prop="houseId">
              <el-input
                v-model="deal.houseId"
                disabled
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="简要说明:" prop="digest">
              <el-input v-model="deal.digest" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="房源名称:">
              <el-input v-model="deal.houseName" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户姓名:" prop="buyerName">
              <el-input v-model="deal.buyerName" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户手机号:" prop="buyerPhone">
              <el-input v-model="deal.buyerPhone" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="" style="text-align: right; margin-right: 20px">
          <el-button type="primary" @click="save" icon="el-icon-check">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </XPage>
</template>

<script>
export default {
  name: "Opportunities",
  data() {
    return {
      loading: false,
      prm: {
        title: "",
        page: 1,
        size: 15,
      },
      deal: {},
      houseList: [],
      total: 0,
      showAdd: false,
      rules: {
        digest: [
          { required: true, message: "请选择商机简要说明", trigger: "change" },
        ],
        buyerName: [
          { required: true, message: "请选择客户姓名", trigger: "change" },
        ],
        buyerPhone: [
          { required: true, message: "请选择客户手机号", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.prm.page = 1;
      this.query();
    },
    query() {
      this.loading = true;
      console.log(this.prm);
      this.$http
        .post("/foreclosure/house/info/list", this.prm)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          let ret = resp.data;
          this.houseList = ret.data;
          this.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    addOpp(row) {
      this.deal = {};
      this.deal.houseId = row.houseId;
      this.deal.houseName = row.title;
      this.showAdd = true;
    },
    save() {
      this.loading = true;
      this.$http
        .post("/foreclosure/house/opp/add", this.deal)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          let ret = resp.data;
          if (ret.code == 0) {
            this.$message.success("新增成功！");
          } else {
            this.$message.success(ret.$message);
          }
          this.showAdd = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
