<template>
  <x-page v-loading="loading">
    <el-form :model="prms" :inline="true">
      <el-form-item label="姓名" class="elformitem">
        <input v-model="prms.realName"/>
      </el-form-item>
      <el-form-item label="身份证" class="elformitem">
        <input v-model="prms.certNo"/>
      </el-form-item>
      <el-form-item label="昵称" class="elformitem">
        <input v-model="prms.nickName"/>
      </el-form-item>
      <el-form-item label="查询有收藏人员信息" class="elformitem">
        <el-select v-model="prms.haveCollect" clearable>
          <el-option v-for='(item, index) in options' :key='index' :label='item.name' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" class="elformitem">
        <el-button type="primary" icon="el-icon-search" @click="search">
          检索
        </el-button>
      </el-form-item>
    </el-form>
    <el-scrollbar v-auto="200">
      <el-table :data="users" stripe>
        <el-table-column label="#" width="50" type="index"></el-table-column>
        <el-table-column
            label="姓名"
            prop="realName"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="微信名"
            prop="nickName"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="微信头像"
            prop="avatar"
            width="70"
        >
          <template slot-scope="scope" >
            <el-image
            :src="scope.row.avatar"
            style="width: 60px;height:60px;justify-content: center;align-items: center;"
            >
            </el-image>

          </template>
        </el-table-column>
        <el-table-column
            label="身份证号"
            prop="certNo"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="VIP等级"
            prop="vip"
            width="70"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="手机号码"
            prop="mobile"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="性别"
            prop="gender"
            width="60"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="年龄"
            prop="age"
            width="60"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="邮箱"
            prop="email"
            width="140"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="职业"
            prop="work"
            width="120"
            show-overflow-tooltip
        ></el-table-column>

        <el-table-column
            label="创建日期"
            prop="createDate"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="更新日期"
            prop="updateDate"
            width="120"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            label="经办人"
            prop="operator"
            width="120"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="关注该人员" width="120" prop="value">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.flag"
                active-value=1
                inactive-value=0
                @change="attention(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>


        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">

            <el-button
                type="text"
                icon="el-icon-files"
                style="margin-left: 0px"
                @click="sign(scope.row)"
            >
              签订协议
            </el-button>

            <el-button
                type="text"
                icon="el-icon-files"
                style="margin-left: 0px"
                @click="queryMpUserCollect(scope.row)"
            >
              查看收藏
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </el-scrollbar>
    <div style="text-align: right">
      <el-pagination
          @size-change="query"
          @current-change="query"
          :current-page.sync="prms.page"
          :page-sizes="[15, 30, 45, 60]"
          :page-size.sync="prms.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
    </div>

    <el-dialog title="房屋收藏信息" :visible.sync="show" v-auto="150">

      <el-table :data="houseList" border stripe>
        <el-table-column label="#" width="50" type="index"></el-table-column>
        <el-table-column
            property="title"
            show-overflow-tooltip
            label="名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            property="houseType"
            show-overflow-tooltip
            label="类型"
            align="center"
        >
        </el-table-column>
        <el-table-column
            property="itemAddress"
            show-overflow-tooltip
            label="地区"
            align="center"
        >
        </el-table-column>
        <el-table-column
            property="builtArea"
            show-overflow-tooltip
            label="建筑面积"
            align="center"
        >
        </el-table-column>
        <el-table-column
            property="inside_AREA"
            show-overflow-tooltip
            label="套内面积"
            align="center"
        >
        </el-table-column>
        <el-table-column
            property="purpose"
            show-overflow-tooltip
            label="用途"
            align="center"
        >
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="完善人员信息并签订协议" :visible.sync="signshow">
      <el-form :model="formParms" :rules="rules" label-width="160px" style="width: 100%; margin: 20px auto">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realName">
              <el-input
                  v-model="formParms.realName"
                  placeholder=""
                  style="width: 100px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证" prop="certNo">
              <el-input
                  v-model="formParms.certNo"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="职业" prop="work">
              <el-input v-model="formParms.work"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="formParms.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="formParms.age" style="width: 60px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="gender">
              <el-select v-model="formParms.gender" clearable>
                <el-option v-for='(item, index) in genders' :key='index' :label='item.name'
                           :value='item.value'></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="VIP等级" prop="vip">
              <el-input v-model="formParms.vip" style="width: 60px" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="formParms.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="调整协议状态" prop="signFlag">
              <el-select v-model="formParms.signFlag" clearable>
                <el-option v-for='(item, index) in signFlags' :key='index' :label='item.name'
                           :value='item.value'></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="" style=" text-align: right;margin-left: 30px">
          <el-button type="primary" icon="el-icon-check" @click="signAndUpdate">
            保存
          </el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
  </x-page>
</template>

<script>
export default {
  name: 'Customers',
  data() {
    return {
      loading: false,
      loadingkc: false,
      show: false,
      prms: {
        realName: "",
        certNo: "",
        nickName:"",
        haveCollect: "",
        page: 1,
        size: 15,
        value: 1,
      },
      options: [{name: "是", value: 1}],
      total: 0,
      users: [],
      houseList: [],
      formParms: {},
      signshow: false,

      signFlags: [{name: "正常", value: "1"}, {name: "暂停", value: "0"}],
      genders: [{name: "女", value: "0"}, {name: "男", value: "1"}],
      rules: {
        realName: [
          {required: true, message: "姓名为必填项", trigger: "change"},
        ],
        certNo: [
          {required: true, message: "身份证为必填项", trigger: "change"},
        ],
        mobile: [
          {required: true, message: "手机号必填", trigger: "change"},
        ],


      },
    }
  },
  methods: {
    attention(row) {
      debugger
      console.log(row)
      this.$http
          .post("/foreclosure/api/mp/person/addAttention", row)
          .then((resp) => {

          })
          .catch((err) => {

          })
    },
    search() {
      this.prms.page = 1
      this.query()
    },
    query() {
      this.loading = true
      this.$http
          .post("/foreclosure/api/mp/person/queryPersonMessage", this.prms)
          .then((resp) => {
            
            this.loading = false
            let ret = resp.data
            this.users = ret.data
            this.total = ret.total
          })
          .catch((err) => {
            this.loading = false
          })
    },

    queryMpUserCollect(row) {
      this.show = true
      this.loadingkc = true
      this.$http
          .post("/foreclosure/api/mp/person/queryMpUserCollect", row)
          .then((resp) => {
            this.loadingkc = false
            let ret = resp.data
            this.houseList = ret.data
          })
          .catch((err) => {
            this.loading = false
          })
    },

    sign(row) {
      this.signshow = true
      this.formParms = row
    },
    /**
     * 签订协议并且保存修改后的人员信息
     */
    signAndUpdate() {

      this.$http.post("/foreclosure/api/mp/person/signAndUpdate", this.formParms)
          .then((resp) => {
            
            let ret=resp.data
            if(ret.code==0){
              this.$message.success("更新成功！")
              this.signshow=false
              this.query()
            }else{
              this.$message.error("更新失败！")
            }
          })
          .catch((err) => {
          })

    }
  },
  mounted() {
    this.search();
  },

}
</script>

<style>
.elformitem {
  margin-top: 20px;
  margin-left: 20px
}


</style>


