/** 自动注册.vue文件，不需要再手动维护 */
const EXCLUDED_FILES = ["App", "Index", "Login", "Welcome"];
const vueFiles = require.context("./", true, /\.vue$/);
const Views = {
  name: "Views",
  install: function (Vue, option) {
    vueFiles.keys().forEach((key) => {
      const component = vueFiles(key).default;
      //console.warn(component);
      if (EXCLUDED_FILES.indexOf(component.name) < 0) {
        Vue.component(component.name, component);
      }
    });
  },
};

export default Views;