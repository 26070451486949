<template>
  <router-view/>
</template>

<script>
export default {
  name:'App',
  methods:{
    initCodeList(){
      this.$http
        .get("/foreclosure/app/codelist")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          let codeList = ret.data;
          for (let key in codeList) {
            localStorage.removeItem(key);
            localStorage.setItem(key, JSON.stringify(codeList[key]));
          }
          //this.$message.success("二级代码更新完毕");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  mounted(){
    this.initCodeList();
  }
}
</script>

<style>

</style>