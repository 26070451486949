<template>
  <div>
    <!--搜索栏-->
    <div style="margin: 20px 0px 0px 0px">
      <el-form :model="housePrms" :inline="true" label-width="80px" size="mini">
        <el-descriptions title="检索条件" :column="2" border>
          <el-descriptions-item label="名称">
            <el-input v-model="housePrms.title" clearable></el-input
          ></el-descriptions-item>
          <el-descriptions-item label="小区">
            <el-input
              style="width: 100%"
              v-model="housePrms.commName"
              clearable
            ></el-input
          ></el-descriptions-item>
          <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
          <el-descriptions-item label="备注">
            <el-tag size="small">学校</el-tag>
          </el-descriptions-item>
          <el-descriptions-item
            label="联系地址"
            :contentStyle="{ 'text-align': 'right' }"
            >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
          >
        </el-descriptions>

        <el-form-item label="名称">
          <el-input v-model="housePrms.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="小区">
          <el-input
            style="width: 100%"
            v-model="housePrms.commName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="板块">
          <el-input
            style="width: 100%"
            v-model="housePrms.plate"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-input
            style="width: 100%"
            v-model="housePrms.region"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学区">
          <el-input
            style="width: 100%"
            v-model="housePrms.schDis"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="预算范围(万元)">
          <el-col :span="10">
            <el-input-number
              v-model="housePrms.budgetLow"
              :step="10"
              :min="0"
              style="width: 100%"
            ></el-input-number>
          </el-col>
          <el-col :span="2" style="text-align: center">-</el-col>
          <el-col :span="10">
            <el-input-number
              v-model="housePrms.budgetHigh"
              :step="10"
              :min="0"
              style="width: 100%"
            ></el-input-number>
          </el-col>
        </el-form-item>
      </el-form>

      <el-table ref="houseTable" :data="houseList" style="width: 100%">
        <el-table-column prop="url" label="缩略图" width="100">
          <template slot-scope="scope">
            <el-image
              :src="scope.url"
              style="
                float: left;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
              "
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="名称"> </el-table-column>
        <el-table-column prop="commName" label="小区"> </el-table-column>
        <el-table-column prop="plate" label="板块"> </el-table-column>
        <el-table-column prop="region" label="区域"> </el-table-column>
        <el-table-column prop="schDis" label="学区"> </el-table-column>
        <el-table-column prop="budget" label="市场价"> </el-table-column>
        <el-table-column prop="startingPrice" label="起拍价"> </el-table-column>
      </el-table>
      <div style="text-align: right">
        <el-pagination
          @size-change="query"
          @current-change="query"
          :current-page.sync="query.page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size.sync="housePrms.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="housePrms.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IHCard",
  props: {},
  data: function () {
    return {
      housePrms: {
        title: "",
        commName: "",
        plate: "",
        region: "",
        schDis: "",
        budgetLow: 0,
        budgetHigh: 0,
        page: 1,
        size: 5,
        total: 0,
      },
      houseList: [],
    };
  },
  mounted() {},

  methods: {
    query() {
      this.loading = true;
      this.$http
        .get("/foreclosure/house/query/list/" + this.housePrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.houseList = ret.data;
          this.housePrms.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    getAa10(code, value) {
      let list = JSON.parse(localStorage.getItem(code));
      let res = value;
      list.forEach((item) => {
        if (item.aaa102 == value) {
          res = item.aaa103;
        }
      });
      return res;
    },
  },
};
</script>

<style>
</style>
