export default {
  R1: {
    xAxis: {
      data: [],
      name: '',
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    yAxis: {
      name: '单位：户',
      yAxisIndex: 1,
      minInterval: 1,
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    grid: {
      bottom: 100
    },
    series: [
      {
        name: '',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          //color:'#FF8800'
          normal: {
            label: {
              show: true, //开启显示数值
              position: 'top', //数值在上方显示
              textStyle: {  //数值样式
                color: '#ffffff',   //字体颜色
                fontSize: 14  //字体大小
              }
            },
            color: function (params) {
              var colorList = ['#96bfff', '#C1FFC1', '#FFFF00']
              return colorList[params.dataIndex]
            },
          }
        },
        data: []
      },
    ]
  },

  R2: {
    xAxis: {
      data: [],
      name: '',
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    yAxis: {
      name: '单位：万元',
      yAxisIndex: 1,
      minInterval: 1,
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    grid: {
      bottom: 100
    },
    series: [
      {
        name: '',
        type: 'bar',
        stack: 'one',
        itemStyle: {
          //color:'#FF8800'
          normal: {
            label: {
              show: true, //开启显示数值
              position: 'top', //数值在上方显示
              textStyle: {  //数值样式
                color: '#ffffff',   //字体颜色
                fontSize: 14  //字体大小
              }
            },
            color: function (params) {
              var colorList = ['#96bfff', '#C1FFC1', '#FFFF00']
              return colorList[params.dataIndex]
            },
          }
        },
        data: []
      },
    ]
  },

  R3: {
    legend: {
      top: 'top'
    },
    series: [
      {
        center: ['45%', '50%'],
        name: '参保人数',
        type: 'pie',
        radius: '65%',
        data: [
        ],
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}}\n{value|{c} 万人}',
          minMargin: 5,
          edgeDistance: 55,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: '#999'
            }
          }
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },

  M1: {
    legend: {
      textStyle: {
        fontSize: 12,//字体大小
        color: '#ffffff'//字体颜色
      },
      data: ['一拍', '二拍', '变卖'],
      right: '10%'
    },
    xAxis: {
      data: [],
      name: '',
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    yAxis: {
      name: '(单位：户)',
      yAxisIndex: 1,
      minInterval: 1,
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    grid: {
      bottom: 100
    },
    series: [
      {
        name: '一拍',
        type: 'bar',
        stack: 'one',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
      {
        name: '二拍',
        type: 'bar',
        stack: 'two',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
      {
        name: '变卖',
        type: 'bar',
        stack: 'three',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
    ]
  },

  M2: {
    legend: {
      textStyle: {
        fontSize: 12,//字体大小
        color: '#ffffff'//字体颜色
      },
      data: ['一拍', '二拍', '变卖'],
      right: '10%'
    },
    xAxis: {
      data: [],
      name: '',
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    yAxis: {
      name: '单位：万元',
      yAxisIndex: 1,
      minInterval: 1,
      nameTextStyle: {
        color: '#ffffff',
        fontSize: 12,
      },
      axisLabel: {
        textStyle: {
          color: '#ffffff',
          fontSize: 12,//字体大小
        }
      },
    },
    grid: {
      bottom: 100
    },
    series: [
      {
        name: '一拍',
        type: 'bar',
        stack: 'one',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
      {
        name: '二拍',
        type: 'bar',
        stack: 'two',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
      {
        name: '变卖',
        type: 'bar',
        stack: 'three',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        },
        data: []
      },
    ]
  },
}