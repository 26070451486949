<template>
  <XPage v-loading="loading">
    <div class="m-naw">
      <el-card>
        <div class="group">
          <h4>查询条件:</h4>
        </div>
        <div class="grouptwo">
          <span>周报日期 : </span>
          <span>
          <el-date-picker value-format="yyyy-MM-dd" style="width:130px" v-model="queryprms.startDate" type="date"
                          placeholder="选择日期">
          </el-date-picker>
        </span>
          <span>周报名称 : </span>
          <span>
              <el-select v-model="queryprms.reportTime" placeholder="请选择" clearable>
              <el-option
                  v-for="item in options"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
              </el-select>
          </span>
          <el-button type="primary" icon="el-icon-search" @click="queryMouthReport" round style="margin-left:20px">
            查询
          </el-button>

          <!--        <span style="margin-left:100px">新建日报时间 : </span>-->
          <span>
          <span style="margin-left:15%">选择新建周报开始日期 : </span>
          <el-date-picker value-format="yyyy-MM-dd" style="width:130px;margin-left:10px"
                          v-model="prm.newDate" type="date"
                          :picker-options="pickerOptions"
                          placeholder="选择日期">
                      </el-date-picker>
          <el-button type="success" icon="el-icon-s-platform" @click="addEditWor" round style="margin-left:10px">
            新建
          </el-button>
        </span>

        </div>

      </el-card>
      <el-card class="cardTwo">
        <el-scrollbar v-auto>
          <el-table :data="mouthReportCollecyions" stripe>
            <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
            <el-table-column label="姓名" prop="employeeName" align="center"></el-table-column>
            <el-table-column label="周报名称" prop="reportTime" align="center"></el-table-column>
            <el-table-column label="周报小结内容" prop="details" align="center"></el-table-column>
            <el-table-column label="周工作时长" prop="workOurs" align="center"></el-table-column>
            <!--            <el-table-column label="操作" width="50" align="center">
                          <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-edit" @click="viewWork(scope.row)">

                            </el-button>
                          </template>
                        </el-table-column>-->
            <el-table-column label="查看详情" align="center">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-open" @click="viewDetails(scope.row)"
                           style="width: 20px;height: 20px" size=“mini”>
                </el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-scrollbar>

        <div style="text-align: right">
          <el-pagination
              @size-change="changeSize"
              @current-change="changePageFn"
              :current-page.sync="queryprms.page"
              :page-sizes="[10, 15, 45, 60]"
              :page-size.sync="queryprms.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="queryprms.total"
          ></el-pagination>
        </div>
      </el-card>


      <el-drawer class="drawer-naw" title="每周小结维护页" :visible.sync="workEditShow" size="75%">
        <el-card class="drawer-naw-cardone">
          <div class="sessionOne">
            <span style="font-size: 15px;font-weight: bolder">员工姓名 ：{{ this.employeeName }} </span>
            <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">员工编号：{{ this.employeeNo }} </span>
            <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">周报名称：2023年第8周报告 </span>
          </div>
          <div class="threeTabs">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="本周日志总结" name="first">
                <el-table :data="workCollecyions" stripe>
                  <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                  <el-table-column label="姓名" prop="employeeName" align="center"></el-table-column>
                  <el-table-column label="日报时间" prop="reportTime" align="center"></el-table-column>
                  <el-table-column label="工作属性" prop="jobProperties" align="center"></el-table-column>
                  <el-table-column label="工作时长" prop="workOurs" align="center"></el-table-column>
                  <el-table-column label="日报详细内容" prop="details" align="center" width="300px"></el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="本周小结维护" name="second">
                <!--              <div>-->
                <!--                  <el-form :model="mouthReportCollecyion" ref="userForms">-->
                <!--                    <el-table :data="mouthReportCollecyion.mouthReportCollecyions" stripe>-->
                <!--                      <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>-->
                <!--                      <el-table-column label="姓名" align="center">-->
                <!--                        <template slot-scope="scope">-->
                <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.employeeName'"-->
                <!--                                        style="margin-top: 20px">-->
                <!--                            <el-input v-model="scope.row.employeeName"></el-input>-->
                <!--                          </el-form-item>-->
                <!--                        </template>-->
                <!--                      </el-table-column>-->
                <!--                      <el-table-column label="周报名称" align="center" width="150px">-->
                <!--                        <template slot-scope="scope">-->
                <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.jobProperties'"-->
                <!--                                        :rules="rules.jobProperties" style="margin-top: 20px">-->
                <!--                            <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 140px">-->
                <!--                              <el-option-->
                <!--                                  v-for="item in options"-->
                <!--                                  :key="item.value"-->
                <!--                                  :label="item.label"-->
                <!--                                  :value="item.value">-->
                <!--                              </el-option>-->
                <!--                            </el-select>-->
                <!--                          </el-form-item>-->
                <!--                        </template>-->
                <!--                      </el-table-column>-->
                <!--                      <el-table-column label="周报小结详细内容" prop="details" align="center" width="400px">-->
                <!--                        <template slot-scope="scope">-->
                <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.details'"-->
                <!--                                        :rules="rules.details" style="margin-top: 20px">-->
                <!--                            <el-input v-model="scope.row.details" type="textarea" :rows="2"-->
                <!--                                      style="width: 380px"></el-input>-->
                <!--                          </el-form-item>-->
                <!--                        </template>-->
                <!--                      </el-table-column>-->
                <!--                      <el-table-column label="周工作时长" prop="workOurs" align="center">-->
                <!--                        <template slot-scope="scope">-->
                <!--                          <el-form-item size="small" :prop="'mouthReportCollecyions.' + scope.$index + '.workOurs'"-->
                <!--                                        :rules="rules.workOurs" style="margin-top: 20px">-->
                <!--                            <el-input v-model="scope.row.workOurs" style="width: 80px"></el-input>-->
                <!--                          </el-form-item>-->
                <!--                        </template>-->
                <!--                      </el-table-column>-->
                <!--                    </el-table>-->
                <!--                  </el-form>-->
                <!--                </div>-->
                <div style="margin-left: 20px">
                  <el-form :model="mouthReports">
                    <div style="border: 1px solid #000000;border-bottom:0px;padding: 5px">
                      <el-descriptions :column="3">
                        <el-descriptions-item label="周报名称">2023年第8周报告
                        </el-descriptions-item>

                        <el-descriptions-item label="周报日期">2023年2月8月
                        </el-descriptions-item>

                        <el-descriptions-item label="填报人">{{ this.employeeName }}
                        </el-descriptions-item>

                        <el-descriptions-item label="本周工作时长">{{ this.workOurs }}小时
                        </el-descriptions-item>
                      </el-descriptions>

                    </div>

                    <div style="border: 1px solid #000000;border-style: double;padding: 5px">
                      <el-descriptions :column="1" style="margin-top: 10px">
                        <el-descriptions-item label="本周小结">

                          <el-form-item size="small" :prop="mouthReports.details" style="margin-top: 20px;">
                            <el-input v-model="mouthReports.details" type="textarea" :rows="8"
                                      style="width: 100%;"></el-input>
                          </el-form-item>
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                    <div style="border: 1px solid #000000;border-style: double;border-top:0px;padding: 5px">
                      <el-descriptions :column="1" style="margin-top: 10px">
                        <el-descriptions-item label="下周工作计划">
                          <el-form-item size="small" :prop="mouthReports.detailPlan"
                                        style="margin-top: 20px;margin-bottom: 1px">
                            <el-input v-model="mouthReports.detailPlan" type="textarea" :rows="8"
                                      style="width: 100%;"></el-input>
                          </el-form-item>
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                    <div style="margin-left: 90%;margin-top: 30px">
                      <el-button type="success" round @click="updalodMouthReport()">
                        提交周报
                      </el-button>
                    </div>

                  </el-form>
                </div>

              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </el-drawer>
      <el-drawer class="drawer-naw" title="每周小结预览" :visible.sync="workPaperShow" size="75%">
        <mouthReport :manMouthReports="manMouthReports" :activeName="childActiveName"  ref="mouthReport"></mouthReport>
      </el-drawer>
    </div>
  </XPage>
</template>

<script>
import mouthReport from "@/views/baseinfo/mouthReport";

export default {
  name: "MouthPaperAction_bak",
  components: {mouthReport},
  data() {
    return {
      childActiveName:"first",
      employeeName: "",
      fEmployeeName: "",
      workOurs: "",
      employeeNo: "",
      prms: {
        startDate: "",
        endDate: "",
      },
      activeName: 'first',
      mouthReports: {
        startDate: "",
        details: "",
        detailPlan: "",
      },
      loading: false,
      workPaperShow: false,
      mouthReportCollecyions: [],
      workCollecyions: [
      ],
      prm: {newDate: ""},
      workEditShow: false,
      manMouthReports: [],
      total: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getDay() != 1;
        },
        firstDayOfWeek: 1,
      },
      queryprms: {
        startDate: "",
        reportTime: "",
        page: 1,
        size: 15,
        total: 0,
      },
      options: []

    }
  },

  created() {
    this.newDateWeekHandle();
    this.getDate();
    this.queryMouthReport();
    this.getUserMessage();
  },
  methods: {
    getUserMessage() {
      this.$http.get("/foreclosure/report/daily/getUserMessage").then
      ((resp) => {
        this.employeeName = resp.data.data.employeeName
        this.employeeNo = resp.data.data.employeeNo
      }).catch(
          (err) => {
            this.$message.error("初始化人员基本信息失败！")
          }
      )
    },
    queryMouthReport() {
      this.loading = true
      this.$http.post("/foreclosure/report/daily/queryMouthReport", this.queryprms)
          .then((resp) => {
            this.loading = false
            this.mouthReportCollecyions = resp.data.data.records
            this.queryprms.total = resp.data.data.total
          }).catch((err) => {
        this.loading = false
        this.$message.error("查询每周小结报告失败！")
      })
    },
    getDate() {
      var date = new Date();
      var year = date.getFullYear();
      // 一年第一天是周几
      var first = new Date(year, 0, 1).getDay()
      // 计算一年有多少天
      if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) {
        var allyears = 366
      } else {
        var allyears = 365
      }
      // 计算一年有多少周
      var week = parseInt((allyears + first) / 7)
      if (((allyears + first) % 7) != 0) {
        week += 1
      }
      for (let i = 1; i <= week; i++) {
        this.options.push(year + "年第" + i + "周报告")
      }
      return week
    },
    /**
     * 计算本周一是几号
     * @param row
     */
    newDateWeekHandle() {
      var now = new Date();
      var nowTime = now.getTime();
      var day = now.getDay();
      var oneDayTime = 86400 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime;
      var time = new Date(MondayTime);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;
      this.prm.newDate = start
    },
    addEditWor() {
      if (this.prm.newDate == '' || this.prm.newDate == null) {
        this.$message.info("选择新建周报开始日期！")
        return
      }
      this.$http.get("/foreclosure/report/daily/existsOrNot", {
        params: this.prm
      })
          .then((resp) => {
            if (resp.data.data == 1) {
              this.$message.info("本周周报已经完成，不可重复新建！")
              return
            } else {
              this.workEditShow = true;
              this.activeName="first";
              this.$http.post("/foreclosure/report/daily/queryAllDailyReport", this.prm)
                  .then((resp) => {
                    this.workCollecyions = resp.data.data;
                    this.workOurs=0,
                    this.workCollecyions.forEach(
                        item => {
                          this.workOurs = parseInt(item.workOurs)+this.workOurs
                        }
                    )
                  }).catch(
              )
            }
          }).catch(
          (err) => {
            this.$message.error("查询是否存在周报失败！")
          }
      )
    },
    updalodMouthReport() {
      let allOurs = 0;
      this.workCollecyions.forEach(item => {
        allOurs = parseInt(item.workOurs) + parseInt(allOurs)
      })
      this.mouthReports.startDate = this.prm.newDate
      this.mouthReports.workOurs = allOurs
      this.$http.post("/foreclosure/report/daily/saveMouthReport", this.mouthReports)
          .then((resp) => {
            if (resp.data.code == 0) {
              this.$message.success("周报提交成功！")
              this.mouthReports.detailPlan = ""
              this.mouthReports.details = ""
              this.workEditShow = false
              this.queryMouthReport();
            }
          })
          .catch((err) => {
            this.$message.error("每周小结提交出错！")
          })

    },
    viewDetails(row) {
      this.loading = true
      this.$http.post("/foreclosure/report/daily/queryAllDailyReportById", row).then(
          (resp) => {
            this.loading = false
            this.manMouthReports = resp.data.data
            this.$nextTick(() => {
              this.$refs.mouthReport.getManMouthReport(row.id);
            })
            this.workPaperShow = true;
          }
      ).catch((err) => {
        this.loading = false
        this.$message.error("预览每周小结失败！")
      })

    },
    changeSize(val) {
      this.prms.size = val
      this.search()
    },
    changePageFn(val) {
      this.prms.page = val
      this.search()
    },

  },
};
</script>

<style>
/* *** */
.m-naw {
  margin-top: 20px;
  margin-left: 10px;

}

.m-naw .group {
  border: 1px solid #e8e8e8;
  border-top-color: aqua;
  padding-left: 5px;
  padding-bottom: 10px;
  height: 30px;

}

.m-naw .grouptwo {
  border: 1px solid #e8e8e8;
  margin-top: -1px;
  padding: 10px;
  padding-left: 10px;
}

.threeTabs {
  margin-left: 0px;

}
</style>


