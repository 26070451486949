<template>
  <div style="display: flex">
    <div style="width: 100%">
      <div style="margin-bottom: 20px">
                    <span style="margin-left: 55px">
                      <el-tag size="small" style="margin-right: 20px">房源标题</el-tag>
                      {{ houseDetails.title }}</span
                    >
        <div style="float: right; margin-right: 5.6%">
          <el-button
              style="width: 100px"
              type="primary"
              @click="openMap"
              icon="el-icon-location-outline"
          >
            百度地图
          </el-button>
        </div>
      </div>
      <el-row>
        <el-col :span="24">
          <el-card style=" margin-bottom: 20px; margin-left: 20px">
            <div style="margin-left: 20px;">
              <div style="margin-bottom: 10px; vertical-align: middle;">
                <el-row>
                  <el-col :span="8">
                    <el-tag size="small"><span style="">房源信息</span>
                    </el-tag>
                  </el-col>
                  <el-col :span="8">
                    <el-tag size="small"><span style="">开始时间</span>
                    </el-tag>
                    {{
                      houseDetails.startTime
                    }}
                  </el-col>
                  <el-col :span="8">
                    <el-tag size="small"><span style="">起拍价</span>
                    </el-tag>
                    {{
                      houseDetails.initialPriceBig
                    }}
                  </el-col>
                </el-row>
              </div>
              <div style="display: flex">
                <div>
                  <el-image :src="houseDetails.url"
                            style="width: 150px; height: 100px"
                            :preview-src-list="houseDetails.detailedUrl"
                  ></el-image>
                </div>
                <div style="margin-left: 20px">
                  <el-descriptions :column="3">
                    <el-descriptions-item label="是否法拍">{{
                        houseDetails.houseDetailsInfoRepo.frenchBeat
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="一拍/二拍">{{
                        houseDetails.houseDetailsInfoRepo.status
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="保证金">{{
                        houseDetails.houseDetailsInfoRepo.bond
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="评估价">{{
                        houseDetails.houseDetailsInfoRepo.consultPrice
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="所在位置">{{
                        houseDetails.houseDetailsInfoRepo.itemAddress
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="其它费用">{{
                        houseDetails.houseDetailsInfoRepo.othFee
                      }}
                    </el-descriptions-item>
                    <!--                    <el-descriptions-item label="开拍时间">{{-->
                    <!--                        houseDetails.startTime-->
                    <!--                      }}-->
                    <!--                    </el-descriptions-item>-->
                    <!--                    <el-descriptions-item label="起拍价">{{-->
                    <!--                        houseDetails.initialPriceBig-->
                    <!--                      }}-->
                    <!--                    </el-descriptions-item>-->
                    <el-descriptions-item label="优先购买权人">{{
                        houseDetails.houseDetailsInfoRepo.priorityPurchase.split(" ")[0]
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="加价幅度">{{
                        houseDetails.houseDetailsInfoRepo.markupRange
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="面积">{{
                        houseDetails.houseDetailsInfoRepo.builtArea + "平方米"
                      }}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </div>
              <div style="text-align: right; margin-right: 5%;">
                <el-button type="primary" icon="el-icon-search" @click="toAliUrl" style="width: 100px">
                  尽职报告
                </el-button>
                <el-button type="primary" icon="el-icon-connection" @click="toAliUrl" style="width: 100px">
                  阿里链接
                </el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-card style="margin-top: 20px; margin-left: 20px">
        <div style="margin-left: 20px">
          <!--              <div style="margin-bottom: 20px; vertical-align: middle;">-->
          <!--                <el-tag size="small"><span style="margin-bottom: 20px">补充背调信息</span>-->
          <!--                </el-tag>-->
          <!--              </div>-->
          <el-tabs v-model="activeName">
            <el-tab-pane label="补充信息" name="first">
              <div style="margin-left: 20px">
                <el-descriptions :column="3">
                  <el-descriptions-item label="小区名称">{{
                      houseDetails.houseDetailsInfoRepo.commName
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="是否凶宅">{{
                      houseDetails.houseDetailsInfoRepo.hanutedHouse == "是" ? "是" : "否"
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="有无租赁">{{
                      houseDetails.houseDetailsInfoRepo.isLease == "有" ? "有" : "无"
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="是否成交">{{
                      houseDetails.houseDetailsInfoRepo.isDeal
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="学区">
                    <el-tag
                        effect="plain"
                        v-for="(int, index) in houseDetails.intSchoolList"
                        :key="index"
                    >{{ int.content }}
                    </el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="区域">
                    <el-tag
                        effect="plain"
                        v-for="(int, index) in houseDetails.intAreaList"
                        :key="index"
                    >{{ int.content }}
                    </el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="交通">
                    <el-tag
                        effect="plain"
                        v-for="(int, index) in houseDetails.itrafficList"
                        :key="index"
                    >{{ int.content }}
                    </el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="板块">
                    <el-tag
                        effect="plain"
                        v-for="(int, index) in houseDetails.intBlockList"
                        :key="index"
                    >{{ int.content }}
                    </el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="土地">{{
                      houseDetails.houseDetailsInfoRepo.land
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="市场价">{{
                      houseDetails.marketPriceB
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="所属街道">{{
                      houseDetails.houseDetailsInfoRepo.street
                    }}
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="1">
                  <el-descriptions-item label="小区介绍">{{
                      houseDetails.houseDetailsInfoRepo.commIntr
                    }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div style="text-align: right; margin-right: 5%;">
                <el-button type="primary" icon="el-icon-connection" @click="toBkUrl" style="width: 100px">
                  贝壳链接
                </el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="教育信息" name="second">
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item>
                  <i
                      class="el-icon-s-opportunity"
                  ></i> 长宁幼儿园
                  <span style="float: right; margin-right: 5px">直线 584m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-s-opportunity"
                  ></i> 长宁中学
                  <span style="float: right; margin-right: 5px">直线 540m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-s-opportunity"
                  ></i> 长宁高中
                  <span style="float: right; margin-right: 5px">直线 402m</span>
                </el-descriptions-item>
              </el-descriptions>
            </el-tab-pane>
            <el-tab-pane label="交通信息" name="third">
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item>
                  <i
                      class="el-icon-odometer"
                  ></i> 大窑路口
                  <span style="float: right; margin-right: 5px">直线 615m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-odometer"
                  ></i> 黄瑶路口
                  <span style="float: right; margin-right: 5px">直线 639m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-odometer"
                  ></i> 大瑶路口
                  <span style="float: right; margin-right: 5px">直线 596m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  {{ houseDetails.houseDetailsInfoRepo.traffic }}
                </el-descriptions-item>
              </el-descriptions>
            </el-tab-pane>
            <el-tab-pane label="医疗信息" name="fourth">
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item>
                  <i
                      class="el-icon-first-aid-kit"
                  ></i> 北辰口腔
                  <span style="float: right; margin-right: 5px">直线 680m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-first-aid-kit"
                  ></i> 胡长峰诊所
                  <span style="float: right; margin-right: 5px">直线 915m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-first-aid-kit"
                  ></i> 中西医结合诊所
                  <span style="float: right; margin-right: 5px">直线 952m</span>
                </el-descriptions-item>
              </el-descriptions>
            </el-tab-pane>
            <el-tab-pane label="周边配套" name="fifth">
              <el-descriptions :column="1" :colon="false">
                <el-descriptions-item>
                  <i
                      class="el-icon-shopping-cart-full"
                  ></i> 平价苏果超市
                  <span style="float: right; margin-right: 5px">直线 1114m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-shopping-cart-full"
                  ></i> 天兴超市
                  <span style="float: right; margin-right: 5px">直线 1130m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  <i
                      class="el-icon-shopping-cart-full"
                  ></i> 文江超市
                  <span style="float: right; margin-right: 5px">直线 1156m</span>
                </el-descriptions-item>
                <el-descriptions-item>
                  {{ houseDetails.houseDetailsInfoRepo.periphery }}
                </el-descriptions-item>
              </el-descriptions>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>

      <el-card style="margin-top: 20px; margin-left: 20px">
        <div>
          <div style="display: flex">
            <div style="margin-bottom: 10px; margin-left: 20px">
              <el-tag size="small"><span>预计成交价</span>
              </el-tag>
            </div>
            <div style="margin-bottom: 10px; margin-left: 25%">
              报名人数
              <el-input-number
                  v-model="signPeoNum"
                  :step="5"
                  :min="0"
                  style="width: 50%"
              ></el-input-number>
            </div>
            <div>
              <el-button type="success" @click="calculation(houseDetails)">
                计 算
              </el-button>
            </div>
          </div>

          <el-table :data="forInvisList" stripe style="width: 90%; margin-right: 2%; margin-left: 2%">
            <el-table-column
                property="peoNum"
                show-overflow-tooltip
                label="报名人数"
                align="center"
            >
            </el-table-column>
            <el-table-column
                property="forTranPrice"
                show-overflow-tooltip
                label="预测成交价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                property="dj"
                show-overflow-tooltip
                label="单价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                property="qs3"
                show-overflow-tooltip
                label="一套房"
                align="center"
            >
            </el-table-column>
            <el-table-column
                property="qs4"
                show-overflow-tooltip
                label="二套房"
                align="center"
            >
            </el-table-column>
            <el-table-column
                property="qs5"
                show-overflow-tooltip
                label="三套房"
                align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HouseDet',
  props: {
    houseId: String,
    cinfo: {},
  },
  components: {},
  data() {
    return {
      initialPrice: 0,
      loading: false,
      activeName: 'first',
      forInvisList: [],
      signPeoNum: 0,
      prms: {
        houseId: "",
        houseType: "",
        page: 1,
        size: 15,
      },
      houseDetails: {
        title: "",
        isDeal: "",
        marketPrice: "",
        detailedUrl: "",
        intAreaList: [],
        intBlockList: [],
        intSchoolList: [],
        itrafficList: [],
        houseDetailsInfoRepo: {
          commIntr: "",
          traffic: "",
          periphery: "",
          commName: "",
          builtArea: "",
          hanutedHouse: "",
          frenchBeat: "",
          bond: "",
          consultPrice: "",
          itemAddress: "",
          othFee: "",
          priorityPurchase: "",
          markupRange: "",
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    calculation(detInfo) {
      let qs1 = 0.01
      let qs2 = 0.01
      if (parseFloat(detInfo.houseDetailsInfoRepo.builtArea) > 90) {
        qs1 = 0.015
        qs2 = 0.02
      }
      this.forInvisList = []
      this.initialPrice = detInfo.initialPrice
      for (let i = 0; i < this.signPeoNum; i = i + 5) {
        this.initialPrice = this.initialPrice * 1.1
        this.forInvisList.push({
          peoNum: i + "~" + (i + 5),
          forTranPrice: parseFloat(this.initialPrice / 10000).toFixed(2) + "万元",
          // qs1: parseFloat(this.initialPrice * 0.01).toFixed(2) + "元",
          // qs2: parseFloat(this.initialPrice * 0.01).toFixed(2) + "元",
          qs3: parseFloat(this.initialPrice * qs1).toFixed(2) + "元",
          qs4: parseFloat(this.initialPrice * qs2).toFixed(2) + "元",
          qs5: parseFloat(this.initialPrice * 0.03).toFixed(2) + "元",
          dj: parseFloat(this.initialPrice / parseFloat(detInfo.houseDetailsInfoRepo.builtArea)).toFixed(2) + "元",
        })
      }
    },
    toAliUrl() {

    },
    toBkUrl() {

    },
    openMap() {
      window.open('https://map.baidu.com/', '_blank')
    },
    queryHouseInfo(houseId) {
      this.forInvisList = []
      this.prms.houseId = houseId
      this.loading = true;
      this.$http
          .post("/foreclosure/api/houseInfo/queryDetHouseInfo", this.prms)
          .then((resp) => {
            this.loading = false;
            let ret = resp.data.data.records
            this.houseList = resp.data.data.records
            for (let key in this.houseList) {
              this.houseList[key].houseLableLst = []
              // 转换成万元
              this.houseList[key].initialPriceBig = parseFloat(this.houseList[key].initialPrice / 10000).toFixed(2) + "万元"
              let areaList = this.houseList[key].houseDetailsInfoRepo.intAreaList.replace("[", "").replace("]", "").split(", ")
              if (!(areaList.length === 1 && areaList[0] === "")) {
                this.houseList[key].intAreaList = []
                for (let i = 0; i < areaList.length; i++) {
                  this.houseList[key].intAreaList.push({content: areaList[i]})
                  this.houseList[key].houseLableLst.push(areaList[i])

                }
              }
              this.houseList[key].marketPriceB = parseFloat(this.houseList[key].marketPrice / 10000).toFixed(2) + "万元"

              let blockList = this.houseList[key].houseDetailsInfoRepo.intBlockList.replace("[", "").replace("]", "").split(", ")
              if (!(blockList.length === 1 && blockList[0] === "")) {
                this.houseList[key].intBlockList = []
                for (let i = 0; i < blockList.length; i++) {
                  this.houseList[key].intBlockList.push({content: blockList[i]})
                  this.houseList[key].houseLableLst.push(blockList[i])
                }
              }
              let schoolList = this.houseList[key].houseDetailsInfoRepo.intSchoolList.replace("[", "").replace("]", "").split(", ")
              if (!(schoolList.length === 1 && schoolList[0] === "")) {
                this.houseList[key].intSchoolList = []
                for (let i = 0; i < schoolList.length; i++) {
                  this.houseList[key].intSchoolList.push({content: schoolList[i]})
                  this.houseList[key].houseLableLst.push(schoolList[i])
                }
              }

              let itrafficList = this.houseList[key].houseDetailsInfoRepo.itrafficList.replace("[", "").replace("]", "").split(", ")
              if (!(itrafficList.length === 1 && itrafficList[0] === "")) {
                this.houseList[key].itrafficList = []
                for (let i = 0; i < itrafficList.length; i++) {
                  this.houseList[key].itrafficList.push({content: itrafficList[i]})
                  this.houseList[key].houseLableLst.push(itrafficList[i])
                }
              }
            }
            this.houseLstRe = JSON.parse(JSON.stringify(this.houseList))
            this.total = resp.data.total;
            this.houseDetails = this.houseLstRe[0]
            console.log(this.houseDetails)
          })
          .catch(() => {
            this.loading = false;
          });
    },
  }
}
</script>