import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from "element-ui";
//定制的ELEMENT主题
import "./style/index.css";
import axios from "axios";
//自定义CSS
import "./style/app.css";
//ICONFONT,图标扩展
import "./style/iconfont/iconfont.css";

import xmiv from 'xmiv'
import 'xmiv/lib/xmiv.css'

//data-view
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import { borderBox1 } from '@jiaminghi/data-view'
Vue.use(borderBox1)

//echarts
import * as echarts from 'echarts';
//import 'echarts/extension/bmap/bmap';
Vue.prototype.$echarts = echarts;


//Vuesax
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

//业务模块
import Views from "./views/index";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

//JS工具
import "./tools.js";
//自定义组件
import Comps from "./components/index";

import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', TreeSelect)

import {
  Message,
  MessageBox
} from "element-ui";

import key from 'keymaster'

Vue.prototype.$key = key;

// Element界面库
Vue.use(ElementUI, {
  size: "mini",
});

Vue.use(Comps);

Vue.use(xmiv)

// 事件总线
let bus = new Vue();
Vue.prototype.$bus = bus;

// AXIOS添加请求拦截器
//let loadingInstance;
axios.interceptors.request.use(
  function (config) {
    config.headers = {
      "Access-Token": store.state.token,
    };
    //console.log("Access-Token:" + store.state.token)
    //config.baseURL = "/api";
    /*loadingInstance = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.5)'
  });*/
    return config;
  },
  function (error) {
    //loadingInstance.close();
    return Promise.reject(error);
  }
);

// AXIOS添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    //console.log(response)
    //loadingInstance.close();
    if (response.data.code && response.data.code != 0) {
      Message({
        type: "error",
        message: response.data.message,
      });
      //MessageBox.alert(response.data.message,"业务处理失败");
      return Promise.reject(response.data.message);
    }
    return response;
  },
  function (error) {
    console.log("=====Response Error Inteceptor====");
    if (error && error.response) {
      if (error.response.status == 403) {
        store.commit("logout");
        router.push({ name: 'login' });
      } else {
        Message({
          type: "error",
          message: error.response.data.message,
        });
      }
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

//导入业务模块
Vue.use(Views);

//异常捕获
Vue.config.errorHandler = (error, vm) => {
  Message({
    type: "error",
    message: error,
  });
};

let payload = localStorage.getItem('orz_payload');
if (payload) {
  store.commit("login", JSON.parse(payload));
  router.push({ name: 'index' })
}

router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    if (store.state.isAuthorized) {
      next({ name: 'index' });
    } else {
      next()
    }
  } else {
    if (store.state.isAuthorized) {
      next();
    } else {
      next({ name: 'login' })
    }
  }
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
