<template>
  <XPage v-loading="loading">
    <div class="m-naw">
      <el-card>
        <div class="group">
          <h4>查询条件:</h4>
        </div>
        <div class="grouptwo">
          <span>日报时间 : </span>
          <span>
<!--          <el-date-picker value-format="yyyy-MM-dd" style="width:130px" v-model="prms.startDate" type="date"
                          placeholder="选择日期">
          </el-date-picker>
          至
          <el-date-picker value-format="yyyy-MM-dd" style="width:130px" v-model="prms.endDate" type="date"
                          placeholder="选择日期">
          </el-date-picker>-->

                <el-date-picker
                    v-model="prms.queryDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
               </el-date-picker>
          <el-button type="primary" icon="el-icon-search" @click="search" round style="margin-left:20px">
            查询
          </el-button>
        </span>

          <!--        <span style="margin-left:100px">新建日报时间 : </span>-->
          <span>
<!--          <el-date-picker style="width:130px" v-model="newDate" type="date" placeholder="选择日期">-->
            <!--          </el-date-picker>-->
          <el-button type="success" icon="el-icon-s-platform" @click="addEditWor" round style="margin-left:45%;">
            新建
          </el-button>
        </span>

        </div>

      </el-card>

      <el-card class="cardTwo">
        <el-scrollbar v-auto>
          <el-table :data="workCollecyions" stripe>
            <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
            <el-table-column label="姓名" prop="employeeName" align="center"></el-table-column>
            <el-table-column label="日报时间" prop="reportTime" align="center"></el-table-column>
            <el-table-column label="工作属性" prop="jobProperties" align="center"></el-table-column>
            <el-table-column label="工作时长" prop="workOurs" align="center"></el-table-column>
            <el-table-column label="日报详细内容" prop="details" align="center" width="300px"></el-table-column>
            <el-table-column label="操作" width="50" align="center">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-edit" @click="viewWork(scope.row)" > <!--:disabled="(!(!scope.row.mouthId))"-->

                </el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-scrollbar>

        <div style="text-align: right">
          <el-pagination
              @size-change="changeSize"
              @current-change="changePageFn"
              :current-page.sync="prms.page"
              :page-sizes="[10, 15, 45, 60]"
              :page-size.sync="prms.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="prms.total"
          ></el-pagination>
        </div>
      </el-card>
      <el-drawer class="drawer-naw" title="日报信息填报页" :visible.sync="workEditShow" size="75%">
        <el-card class="drawer-naw-cardone">
          <div class="sessionOne">
            <span style="font-size: 15px;font-weight: bolder">员工姓名 ：{{ this.employeeName }} </span>
            <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">员工编号：{{this.employeeNo}}</span>
            <el-button type="success" round @click="addEditWorkForm" style="margin-left: 50%">新增日报</el-button>
            <el-button type="success" round @click="saveEditWorkForm">保存</el-button>
          </div>
        </el-card>
        <el-card class="drawer-naw-cardtwo">
          <div v-loading="drawloading">
            <el-form :model="editWorkCollecyion" ref="userForm">
              <el-table :data="editWorkCollecyion.editWorkCollecyions" stripe v-auto>
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
<!--                <el-table-column label="姓名" align="center">
                  <template slot-scope="scope">
                    <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.employeeName'"
                                  style="margin-top: 20px">
                      <el-input v-model="scope.row.employeeName"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>-->
                <el-table-column label="日报时间" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.reportTime'"
                                  :rules="rules.reportTime" style="margin-top: 20px">
                      <el-date-picker value-format="yyyy-MM-dd" style="width:140px" v-model="scope.row.reportTime"
                                      type="date" placeholder="选择日期"
                                      @input="changeInsertShow(scope.row.reportTime, scope.$index)"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="工作属性" align="center">
                  <template slot-scope="scope">
                    <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.jobProperties'"
                                  :rules="rules.jobProperties" style="margin-top: 20px">
                      <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 100px">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="工作时间" prop="workOurs" align="center">
                  <template slot-scope="scope">
                    <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.workOurs'"
                                  :rules="rules.workOurs" style="margin-top: 20px">
                      <el-input v-model="scope.row.workOurs" style="width: 50px"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="日报详细内容" prop="details" align="center" width="400px">
                  <template slot-scope="scope">
                    <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.details'"
                                  :rules="rules.details" style="margin-top: 20px">
                      <el-input v-model="scope.row.details" type="textarea" :rows="2" style="width: 380px"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="操作" prop="details" align="right">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-delete" @click="remove(scope.row, scope.$index)">

                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>

          </div>
        </el-card>

      </el-drawer>

      <el-drawer class="drawer-naw" title="日报信息修改页" :visible.sync="updateShowing" size="75%">
        <el-card class="drawer-naw-cardone">
          <div class="sessionOne">
            <span style="font-size: 15px;font-weight: bolder">员工姓名 ：{{this.employeeName}} </span>
            <span style="font-size: 15px;font-weight: bolder;margin-left: 30px">员工编号：{{this.employeeNo}} </span>
            <el-button type="success" round @click="saveUpdateWorkForm" style="margin-left: 60%">保存</el-button>
          </div>
        </el-card>
        <el-card class="drawer-naw-cardtwo">
          <el-form :model="updateCollecyion" ref="userForms">
            <el-table :data="updateCollecyion.updateCollecyions" stripe v-auto>
              <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
              <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                  <el-form-item size="small" :prop="'updateCollecyions.' + scope.$index + '.employeeName'"
                                style="margin-top: 20px">
                    <el-input v-model="scope.row.employeeName"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="日报时间" align="center" width="200">
                <template slot-scope="scope">
                  <el-form-item size="small" :prop="'updateCollecyions.' + scope.$index + '.reportTime'"
                                :rules="rules.reportTime" style="margin-top: 20px">
                    <el-date-picker value-format="yyyy-MM-dd" style="width:160px" v-model="scope.row.reportTime"
                                    type="date" placeholder="选择日期"
                                    @input="changeInsertShow(scope.row.reportTime, scope.$index)"
                    >
                    </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作属性" align="center" width="150px">
                <template slot-scope="scope">
                  <el-form-item size="small" :prop="'updateCollecyions.' + scope.$index + '.jobProperties'"
                                :rules="rules.jobProperties" style="margin-top: 20px">
                    <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 140px">
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="工作时间" prop="workOurs" align="center">
                <template slot-scope="scope">
                  <el-form-item size="small" :prop="'updateCollecyions.' + scope.$index + '.workOurs'"
                                :rules="rules.workOurs" style="margin-top: 20px">
                    <el-input v-model="scope.row.workOurs" style="width: 80px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="日报详细内容" prop="details" align="center" width="400px">
                <template slot-scope="scope">
                  <el-form-item size="small" :prop="'updateCollecyions.' + scope.$index + '.details'"
                                :rules="rules.details" style="margin-top: 20px">
                    <el-input v-model="scope.row.details" type="textarea" :rows="2" style="width: 380px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-card>
      </el-drawer>
    </div>
  </XPage>
</template>

<script>

export default {
  name: "DailyPaperAction_bak",
  data() {
    return {
      loading: false,
      drawloading: false,
      updateShowing: false,
      userName: "",
      employeeName:"",
      employeeNo:"",
      prms: {
        startDate: "",
        endDate: "",
        queryDate:"",
        page: 1,
        size: 15,
        total: 0,
      },
      prm: {
        id: "",
        page: 1,
        size: 1,

      },
      total: "",
      newDate: "",
      workCollecyions: [],
      editWorkCollecyion: {
        editWorkCollecyions: [],
      },
      updateCollecyion: {
        updateCollecyions: [],
      },
      workEditShow: false,
      options: [{
        value: '意向阶段',
        label: '意向阶段'
      }, {
        value: '带看阶段',
        label: '带看阶段'
      }, {
        value: '成交阶段',
        label: '成交阶段'
      }],

      rules: {
        reportTime: [{
          required: true,
          message: "请选择日报日期",
          trigger: ["change"],
        }, {
          validator: function (rule, value, callback) {
            // if (value > 24 || value < 0) {
            //   callback(new Error("工作时间填写有误"));
            // } else (
            //     callback()
            // )

            var date=new Date()
            var selectDate = new Date(Date.parse(value));

            if(date<selectDate)
            {
              callback(new Error("日报时间有误"));
            }else{
              callback()
            }
          },
          trigger: ["blur", "change"],
        }],
        jobProperties: [{
          required: true,
          message: "请选择工作属性",
          trigger: ["change"],
        }],
        workOurs: [{
          required: true,
          message: "工作时间必填",
          trigger: ["blur", "change"],
        }, {
          validator: function (rule, value, callback) {
            if (value > 24 || value < 0) {
              callback(new Error("工作时间填写有误"));
            } else (
                callback()
            )

          },
          trigger: ["blur", "change"],
        }],
        details: [{
          required: true,
          message: "工作内容必填",
          trigger: ["blur", "change"],
        }],
      }

    }
  },
  created() {

    this.search()
    this.getUserMessage()
  },
  methods: {
    getUserMessage(){
      this.$http.get("/foreclosure/report/daily/getUserMessage").then
      ((resp)=>{
        this.employeeName=resp.data.data.employeeName
        this.employeeNo=resp.data.data.employeeNo
      }).catch(
          (err)=>{
            this.$message.error("初始化人员基本信息失败！")
          }
      )
    },
    search() {
      this.loading = true
      this.prms.startDate=""
      this.prms.endDate=""
      if(this.prms.queryDate!=null&&this.prms.queryDate!=''){
      this.prms.startDate=this.prms.queryDate[0]
      this.prms.endDate=this.prms.queryDate[1]
      }
      this.$http.post("/foreclosure/report/daily/queryDailyReport", this.prms)
          .then((resp) => {
            this.workCollecyions = resp.data.data.records
            this.prms.total = resp.data.data.total
            this.loading = false
          }).catch(
          (error) => {
            this.loading = false
            this.$message.error("查询失败！")
          }
      )
    },
    viewWork(row) {
      if(row.mouthId){
       this.$message.info("该日志已经生成周报，不允许修改！")
        return
      }

      this.prm.id = row.id
      this.updateShowing = true
      this.$http.post("/foreclosure/report/daily/queryDailyReport", this.prm)
          .then((resp) => {
            console.log(resp.data.data.records)
            this.updateCollecyion.updateCollecyions = resp.data.data.records
          }).catch((err) => {
        this.$message.error("查询本条记录失败")
      })

    },

    addEditWor() {
      this.workEditShow = true;
    },
    addEditWorkForm() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.$http.get("/foreclosure/report/daily/getName")
              .then((resp) => {
                this.userName = resp.data.data
                this.editWorkCollecyion.editWorkCollecyions.push({employeeName: this.userName})
              }).catch((err) => {
            this.editWorkCollecyion.editWorkCollecyions.push()
          })
        }
      });

    },
    saveEditWorkForm() {
      if(this.editWorkCollecyion.editWorkCollecyions.length==0){
        this.$message.info("请新增日报信息！")
        return
      }
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.drawloading = true;
          this.$http
              .post("/foreclosure/report/daily/saveDailyReport", this.editWorkCollecyion.editWorkCollecyions)
              .then((resp) => {
                this.drawloading = false;
                if (resp.data.code == 0) {
                  this.$message.success("保存日报成功！")
                  this.editWorkCollecyion.editWorkCollecyions = []
                  this.prms.page = 1
                  this.prms.size = 15
                  this.search()
                }
                this.workEditShow = false;
              }).catch((err) => {
            this.drawloading = false;
            this.$message.error("保存日报失败！")
          })
        }
      });

    },


    saveUpdateWorkForm() {
      
      this.$refs.userForms.validate((valid) => {
        if (valid) {
          this.drawloading = true;
          this.$http
              .post("/foreclosure/report/daily/saveDailyReport", this.updateCollecyion.updateCollecyions)
              .then((resp) => {
                this.drawloading = false;
                if (resp.data.code == 0) {
                  this.$message.success("修改日报成功！")
                  this.updateCollecyion.updateCollecyions = []
                  this.prms.page = 1
                  this.prms.size = 15
                  this.prms.id = ''
                  this.search()
                }
                this.updateShowing = false;
              }).catch((err) => {
            this.drawloading = false;
            this.$message.error("保存日报失败！")
          })
        }
      });

    },
    remove(row, index) {
      this.editWorkCollecyion.editWorkCollecyions.splice(index, 1)
    },

    changeInsertShow(row, index) {
      let i = 0;
      this.editWorkCollecyion.editWorkCollecyions.forEach(
          (item) => {
            if (item.reportTime == row) {
              i++;
              if (i > 1) {
                this.$message.success("日报时间重复，请重新选择！")
                this.editWorkCollecyion.editWorkCollecyions[index].reportTime = ''
              }
            }
          }
      )
    },
    changeSize(val) {
      this.prms.size = val
      this.search()
    },
    changePageFn(val) {
      this.prms.page = val
      this.search()
    },


  },
};
</script>

<style>
/* *** */
.m-naw {
  margin-top: 20px;
  margin-left: 10px;

}

.m-naw .group {
  border: 1px solid #e8e8e8;
  border-top-color: aqua;
  padding-left: 5px;
  padding-bottom: 10px;
  height: 30px;

}

.m-naw .grouptwo {
  border: 1px solid #e8e8e8;
  margin-top: -1px;
  padding: 10px;
  padding-left: 10px;
}

.m-naw .group .groupDate {
  border: 1px solid #e8e8e8;


}

.m-naw .cardTwo {
  margin-top: 20px;
}


.m-naw .drawer-naw .sessionOne {
  border: 1px solid #e8e8e8;
  border-top-color: aqua;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
  height: 30px;

}


.m-naw .drawer-naw .drawer-naw-cardone {
  margin-left: 30px;
  margin-right: 30px
}

.m-naw .drawer-naw .drawer-naw-cardtwo {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  height: 70%;
}
</style>


