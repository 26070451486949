<template>
  <XPage v-loading="loading">
    <el-form :model="prm" :inline="true">
      <el-form-item label="销售人员姓名">
        <el-input v-model="prm.userName" clearable></el-input>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input v-model="prm.idno" clearable></el-input>
      </el-form-item>
      <el-form-item label="备案号">
        <el-input v-model="prm.recordId" clearable></el-input>
      </el-form-item>
      <el-form-item label="所属门店">
        <el-input v-model="prm.company" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model="prm.status" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          icon="el-icon-search"
          :loading="loading"
          @click="search"
        >
          检索
        </el-button>
        <el-button type="danger" icon="el-icon-plus" @click="add">
          新增
        </el-button>

        <span style="color: #e0e0e0; font-size: 11px; margin: 0 15px">{{
          eventId
        }}</span>
      </el-form-item>
    </el-form>

    <el-table :data="users" stripe v-auto>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column
        label="销售人员ID"
        width="100"
        prop="userId"
      ></el-table-column>
      <el-table-column
        label="销售人员姓名"
        width="100"
        prop="userName"
      ></el-table-column>
      <el-table-column label="性别" prop="gend" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.gend == '2'">女</span>
          <span v-else>男</span>
        </template>
      </el-table-column>
      <el-table-column
        label="身份证号"
        width="150"
        prop="idno"
      ></el-table-column>
      <el-table-column label="手机号" prop="tel" width="150"></el-table-column>
      <el-table-column
        label="经济人备案编号"
        width="150"
        prop="recordId"
      ></el-table-column>
      <el-table-column
        label="所属门店"
        width="220"
        prop="company"
      ></el-table-column>
      <el-table-column label="服务年限" prop="years"></el-table-column>
      <!--  <el-table-column
        label="个人标签"
        width="220"
        prop="label"
      ></el-table-column>-->
      <el-table-column
        label="从业时间"
        width="120"
        prop="workTime"
      ></el-table-column>
      <el-table-column label="简介" prop="brief"></el-table-column>
      <el-table-column label="状态" width="220" fixed="right">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :disabled="scope.row.userId == $store.state.userid"
            active-value="1"
            active-text="正常"
            inactive-value="0"
            inactive-text="停用"
            @change="modifyStatus(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            :disabled="scope.row.id == '0000'"
            @click="editUser(scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-refresh"
            @click="remove(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :total="total"
        :current-page.sync="prm.page"
        :page-size="prm.size"
        @current-change="query"
        layout="total,prev,pager,next"
      ></el-pagination>
    </div>

    <el-drawer
      direction="rtl"
      :show-close="false"
      :wrapperClosable="false"
      title="基本信息维护"
      :withHeader="false"
      :visible.sync="show"
      size="60%"
    >
      <el-form
        :model="user"
        ref="userForm"
        label-width="160px"
        style="width: 100%; margin: 20px auto"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="销售人员ID:" prop="userId">
              <el-input
                v-model="user.userId"
                :disabled="operation !== 'new'"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="销售人员姓名:" prop="userName">
              <el-input v-model="user.userName" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别:" prop="gend">
              <el-input v-model="user.gend" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号:" prop="idno">
              <el-input v-model="user.idno" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号:">
              <el-input v-model="user.tel" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经济人备案编号:" prop="recordId">
              <el-input v-model="user.recordId" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="服务年限:" prop="years">
              <el-input
                v-model="user.years"
                placeholder=""
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- <el-form-item label="个人标签:" prop="label">
          <el-input v-model="user.label" placeholder=""></el-input>
        </el-form-item>-->
            <el-form-item label="从业时间:" prop="workTime">
              <el-input v-model="user.workTime" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属门店:" prop="company">
              <el-input
                v-model="user.company"
                placeholder=""
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="简介:" prop="brief">
              <el-input v-model="user.brief" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="头像:" prop="img">
          <el-upload
            ref="logo"
            action="#"
            :file-list="logoList"
            :on-change="change"
            list-type="picture-card"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="" style="text-align: right; margin-right: 20px">
          <el-button type="primary" @click="save" icon="el-icon-check">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-dialog title="新增销售人员" :visible.sync="showUc">
      <el-table :data="ucList" stripe>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="登录名" prop="userId"></el-table-column>
        <el-table-column label="姓名" prop="aac003"></el-table-column>
        <el-table-column label="身份证号" prop="aac002"></el-table-column>
        <el-table-column label="手机号" prop="mobile"></el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-check"
              :disabled="scope.row.id == '0000'"
              @click="check(scope.row)"
            >
              选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </XPage>
</template>

<script>
export default {
  name: "SalesMan",
  props: {
    aac003: String,
    eventId: String,
  },
  data() {
    const userIdValidator = (r, v, cb) => {
      if (this.operation != "new") {
        return cb();
      }
      if (v == "" || v == null || v == undefined) {
        return cb(new Error("请输入登录账号"));
      }
      let fd = new FormData();
      fd.append("userId", v);
      this.$http
        .post("/foreclosure/api/userid/exists", fd)
        .then((resp) => {
          let ret = resp.data;
          if (ret.data) {
            cb(new Error("登录账号已存在!"));
          } else {
            cb();
          }
        })
        .catch((err) => {
          cb(new Error(err));
        });
    };
    return {
      prm: {
        userName: "",
        idno: "",
        recordId: "",
        company: "",
        status: "",
        page: 1,
        size: 10,
      },
      showUc: false,
      loading: false,
      users: [],
      ucList: [],
      total: 0,
      uctotal: 0,
      title: "新增用户",
      show: false,
      user: {
        userId: "",
        userName: "",
        gend: "",
        idno: "",
        tel: "",
        recordId: "",
        company: "",
        years: "",
        label: "",
        workTime: "",
        brief: "",
        img: "",
        status: "",
      },
      operation: "new",
      authShow: false,
      biz: [],
      qry: [],
      select: [],
      aab324s: [],
      loadingAuth: false,
      ucprm: {
        aac003: "",
        page: 1,
        size: 10,
      },
      logoList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      edit: false,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    auth(row) {},
    check(row) {
      this.logoList = [];
      this.title = "新增用户";
      this.$tls.clearJson(this.user);
      this.user.userId = row.userId;
      this.user.userName = row.aac003;
      this.user.tel = row.mobile;
      this.user.idno = row.aac002;
      if (!G.isEmpty(row.img)) {
        this.$http
          .get("/foreclosure/sales/man/get/img/" + row.img)
          .then((resp) => {
            this.loading = false;
            let url = resp.data.data;
            if (!G.isEmpty(url)) {
              let logo = {};
              logo.name = this.user.userId + ".jpg";
              logo.url = "data:image/jpeg;base64," + url;
              this.logoList.push(logo);
            }
          })
          .catch((err) => {});
      }
      this.showUc = false;
      this.show = true;
      this.operation = "new";
    },
    save() {
      this.loading = true;
      this.$http
        .post("/foreclosure/sales/man/save", this.user)
        .then((resp) => {
          console.log(this.logoList.length);
          if (this.logoList.length > 0) {
            let formData1 = new FormData();
            formData1.append("file", this.logoList[0].raw);
            formData1.append("userId", this.user.userId);
            this.$http
              .post("/foreclosure/sales/man/save/img", formData1)
              .then((resp) => {});
          }

          this.loading = false;
          this.$message.success("保存成功");
          this.show = false;
          this.query();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    remove(row) {
      this.$confirm("确定要删除[" + row.userName + "]的信息嘛?")
        .then(() => {
          this.loading = true;
          this.$http
            .get("/foreclosure/sales/man/remove/" + row.userId)
            .then((resp) => {
              this.loading = false;
              this.query();
              this.$message.success("删除成功");
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    editUser(row) {
      this.logoList = [];
      this.title = "修改用户";
      this.user = row;
      if (!G.isEmpty(row.img)) {
        this.$http
          .get("/foreclosure/sales/man/get/img/" + row.img)
          .then((resp) => {
            this.loading = false;
            let url = resp.data.data;
            if (!G.isEmpty(url)) {
              let logo = {};
              logo.name = this.user.userId + ".jpg";
              logo.url = "data:image/jpeg;base64," + url;
              this.logoList.push(logo);
            }
          })
          .catch((err) => {});
      }
      this.show = true;
      this.operation = "edit";
    },
    add() {
      /* this.title = "新增用户";
      this.$tls.clearJson(this.user);
      this.user.aae100 = "1";
      this.show = true;
      this.operation = "new";*/
      this.queryUc();
      this.showUc = true;
    },
    modifyStatus(row) {
      this.loading = true;
      this.$http
        .get("/foreclosure/sales/man/status/" + row.userId + "/" + row.status)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("修改人员状态失败" + err);
          row.status = row.status === "1" ? "0" : "1";
        });
    },
    search() {
      this.prm.page = 1;
      this.query();
    },
    query() {
      this.loading = true;
      console.log(this.prm);
      this.$http
        .post("/foreclosure/sales/man/list", this.prm)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          let ret = resp.data;
          this.users = ret.data;
          this.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryUc() {
      this.loading = true;
      console.log(this.ucprm);
      this.$http
        .post("/foreclosure/sales/users", this.ucprm)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          let ret = resp.data;
          this.ucList = ret.data;
          this.uctotal = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleRemove(file) {
      this.$refs["logo"].clearFiles();
      this.logoList = [];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file) {},
    change(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.edit = true;
      this.logoList = fileList;
      // this.ab01.logoUrl =this.logoList[0].raw;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
