<template>
  <div>
    <div>
      <span style="margin-left: 25px">
        <el-tag size="small" style="margin-right: 20px">客户编号</el-tag>
        {{ cinfo.clientId }}</span
      >
    </div>
    <div :style="{ height: windowHeight - 60 + 'px' }" class="drawScroll">
      <el-card style="margin: 10px">
        <div
          style="
            float: left;
            width: 10%;
            display: table-cell;
            vertical-align: middle;
          "
        >
          <el-tag size="small"> <span>基本信息</span></el-tag>
        </div>
        <div style="float: left; width: 90%">
          <el-descriptions :column="3">
            <el-descriptions-item label="客户姓名">{{
              cinfo.clientName
            }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{
              getAa10("GEND", cinfo.gend)
            }}</el-descriptions-item>
            <el-descriptions-item label="来源/途径">{{
              cinfo.source
            }}</el-descriptions-item>
            <el-descriptions-item label="客户等级">{{
              getAa10("CLIENT_LVL", cinfo.clientLvl)
            }}</el-descriptions-item>
            <el-descriptions-item label="客户状态">{{
              getAa10("CLIENT_STAS", cinfo.status)
            }}</el-descriptions-item>
            <el-descriptions-item label="当前进展">{{
              getAa10("PROGRESS", cinfo.progress)
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>

      <el-card style="margin: 10px">
        <div
          style="
            float: left;
            width: 10%;
            display: table-cell;
            vertical-align: middle;
          "
        >
          <el-tag size="small"> <span>客户特征</span></el-tag>
        </div>
        <div style="float: left; width: 90%">
          <el-descriptions :column="3" class="noColon">
            <el-descriptions-item :label="cinfo.description">
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>

      <el-card style="margin: 10px">
        <div
          style="
            float: left;
            width: 10%;
            display: table-cell;
            vertical-align: middle;
          "
        >
          <el-tag size="small"> <span>联系方式</span></el-tag>
        </div>
        <div style="float: left; width: 90%">
          <el-descriptions :column="3">
            <el-descriptions-item v-if="cinfo.tel" label="联系电话"
              >{{ cinfo.tel }}
            </el-descriptions-item>
            <el-descriptions-item v-if="cinfo.wechat" label="微信号"
              >{{ cinfo.wechat }}
            </el-descriptions-item>
            <el-descriptions-item v-if="cinfo.tiktok" label="抖音号"
              >{{ cinfo.tiktok }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>

      <el-card style="margin: 10px">
        <div
          style="
            float: left;
            width: 10%;
            display: table-cell;
            vertical-align: middle;
          "
        >
          <el-tag size="small"> <span>客户意向</span></el-tag>
        </div>
        <div style="float: left; width: 90%; position: relative; top: -1px">
          <el-descriptions :column="1">
            <el-descriptions-item>
              <template slot="label">
                <i
                  class="el-icon-place"
                  style="font-weight: 500; margin-right: 5px"
                ></i
                >区域
              </template>
              <el-tag v-for="tag in intAreaList" :key="tag" effect="plain">
                {{ tag }}</el-tag
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i
                  class="el-icon-guide"
                  style="font-weight: 500; margin-right: 5px"
                ></i
                >板块
              </template>
              <el-tag v-for="tag in intBlockList" :key="tag" effect="plain">
                {{ tag }}</el-tag
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i
                  class="el-icon-school"
                  style="font-weight: 500; margin-right: 5px"
                ></i
                >学区
              </template>
              <el-tag v-for="tag in intSchoolList" :key="tag" effect="plain">
                {{ tag }}</el-tag
              >
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>

      <el-card style="margin: 10px; padding-bottom: 5px">
        <div
          style="
            float: left;
            width: 10%;
            display: table-cell;
            vertical-align: middle;
          "
        >
          <el-tag size="small"> <span> 跟进记录</span></el-tag>
        </div>
        <!--  <div style="float: right; margin-bottom: 10px">
                <el-tag
                  @click="addRecord"
                  effect="plain"
                  type="success"
                  class="tagSuccessButton"
                >
                  新增
                </el-tag>
                <el-tag
                  @click="delRecord"
                  effect="plain"
                  type="danger"
                  class="tagDangerButton"
                  >作废
                </el-tag>
              </div>-->
        <div style="float: left; width: 90%">
          <el-table
            :data="recordList"
            stripe
            ref="recordTable"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            max-height="550"
          >
            <el-table-column
              label="次数"
              width="50"
              type="index"
            ></el-table-column>
            <el-table-column prop="recordDate" label="日期" width="100">
            </el-table-column>
            <el-table-column prop="operator" label="对接人" width="100">
            </el-table-column>
            <el-table-column
              prop="followType"
              label="对接方式"
              class-name="FOLLOW_TYPE"
              width="100"
              :formatter="tableColumnFomat"
            >
            </el-table-column>
            <el-table-column prop="content" label="跟进记录"> </el-table-column>
          </el-table>
          <div style="text-align: right">
            <el-pagination
              @size-change="queryRecord"
              @current-change="queryRecord"
              :current-page.sync="recordPrms.page"
              :page-sizes="[5, 10, 15, 20]"
              :page-size.sync="recordPrms.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordPrms.total"
            ></el-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "IClient",
  props: {
    clientId: String,
    cinfo: {},
  },
  data: function () {
    return {
      recordPrms: {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      },
      intAreaList: [],
      intBlockList: [],
      intSchoolList: [],
      recordList: [],
      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度

      flag: false,
      recordSelection: [],
    };
  },
  mounted() {
    this.recordPrms.clientId = this.clientId;
    this.query();
    this.queryRecord();
    var that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    console.log(this.windowHeight);
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };
  },
  methods: {
    query() {
      this.loading = true;
      this.$http
        .get("/foreclosure/client/info/detail/" + this.clientId)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.cinfo = ret.data;

          this.cinfo.intList.forEach((item) => {
            if (item.intType == "1") {
              this.intAreaList.push(item.content);
            }
            if (item.intType == "2") {
              this.intBlockList.push(item.content);
            }
            if (item.intType == "3") {
              this.intSchoolList.push(item.content);
            }
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryRecord() {
      this.loading = true;
      this.$http
        .post("/foreclosure/client/record/list", this.recordPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.recordList = ret.data;
          this.recordPrms.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getAa10(code, value) {
      let list = JSON.parse(localStorage.getItem(code));
      let res = value;
      list.forEach((item) => {
        if (item.aaa102 == value) {
          res = item.aaa103;
        }
      });
      return res;
    },
    tableColumnFomat(row, column) {
      return this.getAa10(column.className, row[column.property]);
    },
  },
};
</script>

<style>
</style>
