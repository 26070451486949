<template>
  <el-container style="width: 100%; height: 100%">
    <el-menu
      :collapse-transition="false"
      :collapse="isCollapse"
      @select="handleSelect"
      :unique-opened="true"
      background-color="#252A2F"
      text-color="#fff"
      :style="{ minWidth: isCollapse ? '64px' : '250px' }"
    >
      <span
        style="
          color: #fff;
          display: inline-block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
        "
      >
        {{ isCollapse ? "菜单" : "菜单" }}
      </span>
      <el-tooltip content="显示/收起侧边菜单" placement="right">
        <a
          class="a-style"
          href="javascript:void(0)"
          @click="isCollapse = !isCollapse"
        >
          <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </a>
      </el-tooltip>
      <!--
      <el-submenu v-for="_menu in menus" :key="_menu.index" :index="_menu.name">
        <template slot="title">
          <i
            :class="'iconfont ' + _menu.icon"
            style="
              font-size: 18px;
              color: #409eff;
              margin-right: 8px;
              margin-left: 3px;
            "
          ></i>
          <span slot="title">{{ _menu.title }}</span>
        </template>
        <el-menu-item
          v-for="_submenu in _menu.submenu"
          :key="_submenu.name"
          :index="_submenu.name"
        >
          <i
            v-if="_submenu.icon"
            :class="'iconfont ' + _submenu.icon"
            style="font-size: 16px; color: #409eff; margin-right: 5px"
          ></i>
          <i v-else class="el-icon-caret-right"></i>
          {{ _submenu.title }}
        </el-menu-item>
      </el-submenu>
      -->
      <el-menu-item
        v-for="_submenu in menus"
        :key="_submenu.name"
        :index="_submenu.name"
      >
        <i
          v-if="_submenu.icon"
          :class="'iconfont ' + _submenu.icon"
          style="color: #409eff; padding-left: 5px"
        ></i>
        <i v-else class="el-icon-caret-right"></i>
        <span slot="title" style="padding-left: 5px">
          {{ _submenu.title }}
        </span>
      </el-menu-item>
    </el-menu>

    <!-- style="height: 100%;box-sizing:border-box;" -->
    <el-main style="padding: 0; margin: 0">
      <el-tabs
        v-model="currentTab"
        @tab-remove="removeTab"
        style="height: 100%; box-sizing: border-box; overflow: auto; padding: 0"
        type="border-card"
      >
        <el-tab-pane
          v-for="(item, index) in tabs"
          :key="'tab' + index"
          :name="item.name"
          :closable="item.close"
        >
          <span slot="label">
            <i
              v-if="item.icon"
              :class="'iconfont ' + item.icon"
              style="font-size: 16px; color: crimson"
            ></i>
            {{ item.title }}
          </span>
          <!--9527:增加传参机制-->
          <component :is="item.component" v-bind="item.extra"></component>
        </el-tab-pane>
      </el-tabs>
      <div
        v-drag
        style="
          position: absolute;
          top: 40px;
          right: 5px;
          height: 36px;
          width: 165px;
          line-height: 36px;
          z-index: 2;
          padding-left: 8px;
          padding-right: 8px;
          background: rgba(245, 247, 255, 0.6);
          border-radius: 3px;
          text-align: center;
        "
      >
        <!--工作流-->
        <el-popover
          v-model="isViewingTodos"
          placement="bottom"
          width="666"
          trigger="hover"
        >
          <div style="text-align: right; padding-bottom: 10px">
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              :loading="loadingTodos"
              @click="getTodos"
            >
              刷新
            </el-button>
          </div>
          <el-table :data="todos" border stripe max-height="366">
            <el-table-column
              label="#"
              type="index"
              width="40"
              header-align="center"
            ></el-table-column>
            <el-table-column
              width="250"
              property="eventName"
              label="办件名称"
              header-align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="创建时间" header-align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              property="taskName"
              label="办件环节"
              header-align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作" width="80" header-align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleTodo(scope.row)">
                  立即处理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <i
            :class="todos.length > 0 ? 'el-icon-receiving' : 'el-icon-tickets'"
            :style="{
              fontSize: '24px',
              color: todos.length > 0 ? 'crimson' : '#BCBCBC',
              marginRight: '5px',
            }"
            slot="reference"
          ></i>
        </el-popover>
        <!--消息-->
        <el-tooltip content="消息管理" placement="bottom-start">
          <el-button
            type="text"
            style="margin-right: 5px"
            @click="showMsgPanel"
          >
            <i
              :class="
                unreadPrivateMsgs.length > 0
                  ? 'el-icon-message-solid'
                  : 'el-icon-message'
              "
              :style="{
                fontSize: '24px',
                color: unreadPrivateMsgs.length > 0 ? 'crimson' : '#BCBCBC',
              }"
            ></i>
          </el-button>
        </el-tooltip>
        <!--
        <el-tooltip :content="unread + '条未读消息'" placement="bottom-start">
          <el-popover
            placement="bottom"
            width="320"
            trigger="manual"
            v-model="showMsgs"
            @show="openList"
          >
            <div
              style="height: 360px; overflow-y: auto"
              v-loading="loadingMsgs"
            >
              <a
                v-for="(item, index) in msgs"
                :key="'am' + index"
                href="javascript:void(0)"
                :style="{
                  color: item.read == 0 ? '#000000' : 'gray',
                  background: index % 2 == 0 ? '#F9FAFBFF' : '#FFFFFF',
                }"
                @click.prevent="Read(item)"
                class="jf-list"
              >
                <div>
                  <el-badge is-dot :hidden="item.read > 0">
                    <span style="font-size: 14px; font-weight: bold">
                      {{ item.title }}
                    </span>
                  </el-badge>
                </div>
                <span style="font-size: 12px; color: #bcbcbc">
                  发布时间:{{ item.aae036 }}
                </span>
              </a>
            </div>
            <el-button
              type="text"
              slot="reference"
              style="margin-right: 5px"
              @click="showMsgs = !showMsgs"
            >
              <i
                :class="
                  unread > 0 ? 'el-icon-message-solid' : 'el-icon-message'
                "
                :style="{
                  fontSize: '24px',
                  color: unread > 0 ? 'crimson' : '#BCBCBC',
                }"
              ></i>
            </el-button>
          </el-popover>
        </el-tooltip>
        -->

        <!--修改密码-->
        <el-popover placement="bottom" width="320" v-model="showChangePassword">
          <ChangePassword
            @success="showChangePassword = false"
          ></ChangePassword>
          <el-button type="text" slot="reference" style="margin-right: 5px">
            <i
              class="el-icon-set-up"
              style="font-size: 24px; color: #409eff"
            ></i>
          </el-button>
        </el-popover>
        <!--关闭菜单-->
        <el-tooltip content="关闭所有已打开的菜单" placement="bottom-start">
          <el-button type="text" @click="closeAllTabs">
            <i
              class="el-icon-files"
              style="font-size: 24px; color: crimson"
            ></i>
          </el-button>
        </el-tooltip>
        <!--退出登录-->
        <el-dropdown @command="handleDropClick">
          <img
            style="
              height: 24px;
              width: 24px;
              margin-left: 10px;
              border-radius: 50%;
            "
            :src="
              '/public/' +
              ($store.state.avatar ? $store.state.avatar : 'default.png')
            "
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="uploadPhoto">
              <i class="el-icon-edit" style="color: #409eff"></i>
              &nbsp;修改头像
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <i class="el-icon-switch-button" style="color: #409eff"></i>
              &nbsp;退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--<el-tooltip content="退出" placement="bottom-start">
          <el-button type="text" @click="logout">
            <i
              class="el-icon-switch-button"
              style="font-size: 24px; color: #409eff"
            ></i>
          </el-button>
        </el-tooltip>
        -->
      </div>
    </el-main>

    <el-dialog :title="title" width="60%" :visible.sync="showMsg">
      <div v-html="msg"></div>
    </el-dialog>

    <el-dialog
      title="待办任务处理"
      width="888px"
      :visible.sync="showHandler"
      append-to-body
      :close-on-click-modal="false"
    >
      <GeneralFlowHandler ref="gfh" @done="processDone"></GeneralFlowHandler>
    </el-dialog>

    <el-dialog title="修改头像" width="200px" :visible.sync="showEditAvatar">
      <img
        style="
          width: 160px;
          height: 160px;
          display: block;
          margin: 5px auto;
          border-radius: 50%;
          border: 4px solid #409eff;
        "
        :src="
          '/public/' +
          ($store.state.avatarFull ? $store.state.avatarFull : 'default.png')
        "
        v-loading="loading"
      />
      <div style="text-align: center; margin: 20px auto">
        <el-button
          :loading="loading"
          type="success"
          round
          icon="el-icon-edit"
          @click="$refs['fileInput1'].click()"
        >
          修改头像
        </el-button>
      </div>
      <input
        type="file"
        ref="fileInput1"
        style="display: none"
        @change="fileChange"
      />
    </el-dialog>

    <div
      class="private-msg"
      v-for="item in unreadPrivateMsgs"
      :key="item.msgId"
    >
      <div style="height: 36px; line-height: 36px">
        <img
          :src="'/public/' + (item.avatar ? item.avatar : 'default.png')"
          style="
            border: 2px solid #409eff;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin: 4px 10px;
            vertical-align: bottom;
          "
        />
        <span style="vertical-align: bottom; display: inline-block">
          {{ item.fromName }}
        </span>

        <el-button
          circle
          type="danger"
          style="position: absolute; top: 5px; right: 5px"
          icon="el-icon-check"
          :loading="reading"
          @click="setRead(item.msgId)"
        ></el-button>
      </div>
      <div style="height: 108px; overflow-y: scroll; padding: 0 15px">
        <b style="display: block; padding: 5px 0">
          {{ item.title ? item.title : "新私信..." }}
        </b>
        <span style="text-indent: 10px">{{ item.content }}</span>
      </div>
      <div
        style="
          height: 36px;
          line-height: 36px;
          padding: 0 10px;
          text-align: right; ;
        "
      >
        <span style="font-size: 12px; color: #bcbcbc">
          {{ item.createTime }}
        </span>
      </div>
    </div>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="msgPanelVisible"
      width="1024px"
      :show-close="true"
    >
      <el-tabs v-loading="reading">
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-edit"></i>
            新建消息
          </span>
          <el-row>
            <el-col :span="8">
              <div style="height: 512px; overflow: scroll">
                <el-tree
                  :data="orgUserTree"
                  default-expand-all
                  show-checkbox
                  node-key="id"
                  :expand-on-click-node="false"
                  :check-on-click-node="true"
                  @check="orgUserTreeCheck"
                  ref="orgUserTree"
                >
                  <span
                    slot-scope="{ node, data }"
                    :style="{
                      color: data.nodeType == 'user' ? '#409EFF' : '#BCBCBC',
                    }"
                  >
                    <i
                      :class="[
                        'iconfont',
                        data.nodeType == 'user'
                          ? 'icon-userplus-fill'
                          : 'icon-xitongguanli_zuzhijiagou',
                      ]"
                    ></i>
                    <span style="margin-left: 5px">{{ node.label }}</span>
                  </span>
                </el-tree>
              </div>
            </el-col>
            <el-col :span="16">
              <el-form
                label-width="80px"
                :model="msgDto"
                :rules="msgRules"
                ref="msgform"
              >
                <el-form-item label="收件人" prop="toUsers">
                  <el-tag v-for="item in msgDto.toUsers" :key="item.id">
                    {{ item.label }}
                  </el-tag>
                </el-form-item>
                <el-form-item label="标题">
                  <el-input v-model="msgDto.title"></el-input>
                </el-form-item>
                <el-form-item label="正文" prop="content">
                  <el-input
                    v-model="msgDto.content"
                    type="textarea"
                    :rows="15"
                  ></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                  <el-button icon="el-icon-close" @click="cancelMsg">
                    取消
                  </el-button>
                  <el-button
                    type="primary"
                    icon="el-icon-check"
                    @click="sendMsg"
                  >
                    发送
                  </el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-postcard"></i>
            收件箱
          </span>
          <div style="text-align: right; margin-bottom: 5px">
            <el-button
              type="primary"
              plain
              icon="el-icon-refresh"
              @click="searchReceived"
            >
              刷新
            </el-button>
          </div>
          <el-table :data="myReceived" :height="460" :max-height="460">
            <el-table-column width="66">
              <template slot-scope="scope">
                <img
                  style="
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2px solid #409eff;
                  "
                  :src="
                    '/public/' +
                    (scope.row.avatar ? scope.row.avatar : 'default.png')
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="fromName"
              label="发件人姓名"
              width="100"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="fromUser"
              label="发件人ID"
              width="100"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="title"
              label="标题"
              width="120"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="content"
              label="内容"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="createTime"
              label="发送时间"
              width="150"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="readTime"
              label="收件时间"
              width="150"
            ></el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  v-if="!scope.row.readTime"
                  type="text"
                  @click="setRead(scope.row.msgId)"
                >
                  已读
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="text-align: right">
            <el-pagination
              background
              :current-page.sync="recv.pageNum"
              @current-change="queryReceived"
              layout="total,prev,pager,next"
              :page-size="recv.pageSize"
              :total="recv.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-position"></i>
            发件箱
          </span>
          <div style="text-align: right; margin-bottom: 5px">
            <el-button
              type="primary"
              plain
              icon="el-icon-refresh"
              @click="searchSent"
            >
              刷新
            </el-button>
          </div>
          <el-table :data="mySent" :height="460" :max-height="460">
            <el-table-column width="66">
              <template slot-scope="scope">
                <img
                  style="
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2px solid #409eff;
                  "
                  :src="
                    '/public/' +
                    (scope.row.toAvatar ? scope.row.toAvatar : 'default.png')
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="toName"
              label="收件人姓名"
              width="100"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="toUser"
              label="收件人ID"
              width="100"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="title"
              label="标题"
              width="120"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="content"
              label="内容"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="createTime"
              label="发送时间"
              width="150"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="readTime"
              label="收件时间"
              width="150"
            ></el-table-column>
          </el-table>

          <div style="text-align: right">
            <el-pagination
              background
              :current-page.sync="sent.pageNum"
              @current-change="querySent"
              layout="total,prev,pager,next"
              :page-size="sent.pageSize"
              :total="sent.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- <div class="bottom">技术支持</div> -->
  </el-container>
</template>

<script>
import ChangePassword from "./system/ChangePassword.vue";
import dailyPaperAction from "@/views/paperAction/DailyPaperAction";
const ALLOWED_TYPES = ["JPG", "JPEG", "PNG", "BMP"];

export default {
  name: "App",
  data: function () {
    return {
      isCollapse: true,
      currentTab: "0-0",
      tabs: [
        {
          icon: "icon-dilan-xitongguanli2",
          title: "",
          name: "0-0",
          close: false,
          component: () => import("./paperAction/DailyPaperAction"),
        },
      ],
      menus: [],
      timer: 0,
      showChangePassword: false,
      showSign: false,
      unread: 0,
      showMsgs: false,
      loadingMsgs: false,
      msgs: [],
      showMsg: false,
      title: "",
      msg: "",
      maxTabsCount: 8,
      todos: [],
      todosInterval: -1,
      isViewingTodos: false,
      showHandler: false,
      procId: "",
      loadingTodos: false,
      loading: false,
      showEditAvatar: false,
      unreadPrivateMsgs: [],
      privateMsgsInterval: -1,
      reading: false,
      msgPanelVisible: false,
      orgUserTree: [],
      msgDto: {
        toUsers: [],
        title: "",
        content: "",
      },
      msgRules: {
        toUsers: [
          { required: true, message: "请选择收件人", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入消息正文", trigger: "change" },
        ],
      },
      recv: {
        pageNum: 1,
        pageSize: 8,
        total: 0,
      },
      myReceived: [],
      sent: {
        pageNum: 1,
        pageSize: 8,
        total: 0,
      },
      mySent: [],
    };
  },
  components: {
    ChangePassword,
    dailyPaperAction,
  },
  mounted() {
    this.$tls.addWaterMark("法拍房");
    //this.initCodeList();

    //this.unreadCount();

    this.$bus.$on("openMenu", (index) => {
      console.log(index);
      this.handleSelect(index, {});
    });

    this.$key("ctrl+s", (e) => {
      console.log(e);
      this.$alert("ctrl+s");
      e.preventDefault();
    });

    this.$store.state.userMenus.map((item) => {
      /*let menu = {
        name: item.funcId,
        icon: item.icon,
        title: item.title,
        submenu: [],
      };
      item.submenu.map((sub) => {
        menu.submenu.push({
          title: sub.title,
          name: sub.funcId,
          icon: sub.icon,
          close: true,
          component: sub.component,
          extra: {},
        });
      });
      this.menus.push(menu);*/
      item.submenu.map((sub) => {
        this.menus.push({
          title: sub.title,
          name: sub.funcId,
          icon: sub.icon,
          close: true,
          component: sub.component,
          extra: {},
        });
      });
    });

    /*_$x.userMenus().then((resp) => {
      let userMenus = JSON.parse(resp);
      userMenus.map((item) => {
        let menu = {
          name: item.funcId,
          icon: item.icon,
          title: item.title,
          submenu: [],
        };
        item.submenu.map((sub) => {
          menu.submenu.push({
            title: sub.title,
            name: sub.funcId,
            icon: sub.icon,
            close: true,
            component: sub.component,
          });
        });
        this.menus.push(menu);
      });
      //加载通知
      setInterval(() => {
        this.unreadCount();
      }, 600000);
      this.unreadCount();
    });*/

    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 17) {
        this.isCtrlPressed = true;
      }
      if (this.isCtrlPressed && e.keyCode == 123) {
        //_$x.showDevTools();
      }
      if (this.isCtrlPressed && e.keyCode == 116) {
        window.location.reload();
      }
    });
    document.addEventListener("keyup", (e) => {
      if (e.keyCode == 17) {
        this.isCtrlPressed = false;
      }
    });

    this.getTodos();
    this.todosInterval = setInterval(() => {
      this.getTodos();
    }, 30000);
    this.$store.dispatch("getDvsTree");
    this.getUnreadMsgs();
    this.privateMsgsInterval = setInterval(() => {
      this.getUnreadMsgs();
    }, 30000);
  },
  beforeDestroy() {
    console.log("=========AboutToDestroy==========");
    clearInterval(this.todosInterval);
    clearInterval(this.privateMsgsInterval);
  },
  methods: {
    searchSent() {
      this.sent.pageNum = 1;
      this.sent.pageSize = 8;
      this.querySent();
    },
    querySent() {
      this.reading = true;
      let fd = new FormData();
      fd.append("pageNum", this.sent.pageNum);
      fd.append("pageSize", this.sent.pageSize);
      this.$http
        .post("/foreclosure/api/private/msgs/mysent", fd)
        .then((resp) => {
          this.reading = false;
          this.mySent = [];
          this.mySent = resp.data.data;
          this.sent.total = resp.data.total;
        })
        .catch((err) => {
          this.reading = false;
        });
    },
    searchReceived() {
      this.recv.pageNum = 1;
      this.recv.pageSize = 8;
      this.queryReceived();
    },
    queryReceived() {
      this.reading = true;
      let fd = new FormData();
      fd.append("pageNum", this.recv.pageNum);
      fd.append("pageSize", this.recv.pageSize);
      this.$http
        .post("/foreclosure/api/private/msgs/myreceived", fd)
        .then((resp) => {
          this.reading = false;
          this.myReceived = [];
          this.myReceived = resp.data.data;
          this.recv.total = resp.data.total;
        })
        .catch((err) => {
          this.reading = false;
        });
    },
    cancelMsg() {
      if (
        this.msgDto.toUsers.length > 0 ||
        this.msgDto.title ||
        this.msgDto.content
      ) {
        this.$confirm("是否确认放弃编辑？", "提示")
          .then((accept) => {
            this.clearMsgInput();
            this.msgPanelVisible = false;
          })
          .catch((deny) => {});
      } else {
        this.clearMsgInput();
        this.msgPanelVisible = false;
      }
    },
    sendMsg() {
      this.$refs["msgform"].validate((valid) => {
        if (valid) {
          let userIds = [];
          this.msgDto.toUsers.map((item) => {
            userIds.push(item.id);
          });
          let prms = {
            toUsers: userIds,
            title: this.msgDto.title,
            content: this.msgDto.content,
          };
          this.reading = true;
          this.$http
            .post("/foreclosure/api/private/msgs/send", prms)
            .then((resp) => {
              this.reading = false;
              this.$message.success("消息发送成功");
              this.clearMsgInput();
            })
            .catch((err) => {
              this.reading = false;
            });
        }
      });
    },
    clearMsgInput() {
      this.$refs["orgUserTree"].setCheckedKeys([]);
      this.msgDto.toUsers = [];
      this.msgDto.title = "";
      this.msgDto.content = "";
    },
    orgUserTreeCheck(nodeData, checkdObjs) {
      //console.log(nodeData);
      //console.log(checkdObjs);
      this.msgDto.toUsers = [];
      this.msgDto.toUsers = checkdObjs.checkedNodes.filter((item) => {
        return item.nodeType == "user";
      });
    },
    showMsgPanel() {
      this.msgDto.toUsers = [];
      this.msgDto.title = "";
      this.msgDto.content = "";
      this.loadOrgUserTree(true);
    },
    loadOrgUserTree(isOpen) {
      this.$http
        .post("/foreclosure/api/orgs/org/user/tree")
        .then((resp) => {
          let ret = resp.data;
          this.orgUserTree = [];
          this.orgUserTree.push(ret.data);
          if (isOpen) {
            this.msgPanelVisible = true;
            this.$nextTick(() => {
              this.$refs["msgform"].clearValidate();
            });
          }
        })
        .catch((err) => {});
    },
    setRead(msgId) {
      this.reading = true;
      let fd = new FormData();
      fd.append("msgId", msgId);
      this.$http
        .post("/foreclosure/api/private/msgs/read", fd)
        .then((resp) => {
          this.reading = false;
          for (let i = 0; i < this.unreadPrivateMsgs.length; i++) {
            if (this.unreadPrivateMsgs[i].msgId == msgId) {
              this.unreadPrivateMsgs.splice(i, 1);
              break;
            }
          }
          if (this.msgPanelVisible) {
            this.queryReceived();
          }
        })
        .catch((err) => {
          this.reading = false;
        });
    },
    getUnreadMsgs() {
      this.$http
        .post("/foreclosure/api/private/msgs/unread")
        .then((resp) => {
          let ret = resp.data;
          this.unreadPrivateMsgs = [];
          ret.data.map((item) => {
            this.unreadPrivateMsgs.push(item);
          });
        })
        .catch((err) => {});
    },
    fileChange(evt) {
      let file = evt.target.files[0];
      let name = file.name;
      let arr = name.split(".");
      let suffix = arr[arr.length - 1].toUpperCase();
      evt.target.value = "";
      if (ALLOWED_TYPES.indexOf(suffix) < 0) {
        this.$message.error("请选择JPG,JPEG,PNG,BMP格式的图片");
        return;
      }
      if (file.size < 5000) {
        this.$message.error("图片小于5K,请重新选择");
        return;
      }
      if (file.size > 20000000) {
        this.$message.error("图片大于20M,请重新选择");
        return;
      }
      if (file) {
        let fr = new FileReader();
        fr.onloadend = () => {
          //console.log(fr.result);
          this.uploadPhoto(fr.result);
        };
        fr.readAsDataURL(file);
      }
    },
    uploadPhoto(photo) {
      this.loading = true;
      let fd = new FormData();
      fd.append("photo", photo);
      this.$http
        .post("/foreclosure/api/user/avatar/save", fd)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          //this.user.avatar = ret.data["avatar"];
          //this.user.avatarFull = ret.data["avatarFull"];
          //if(this.user.userId==this.$store.state.userId){
          this.$store.commit("modifyAvatar", ret.data);
          //}
          //this.query();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleDropClick(cmd) {
      console.log(cmd);
      if (cmd == "logout") {
        this.logout();
      }
      if (cmd == "uploadPhoto") {
        this.showEditAvatar = true;
      }
    },
    processDone() {
      this.showHandler = false;
      this.getTodos();
    },
    handleTodo(row) {
      this.isViewingTodos = false;
      console.log(row);
      if (!row.funcId) {
        this.showHandler = true;
        this.$nextTick().then(() => {
          this.$refs["gfh"].loadData(row.processInstanceId);
        });
      } else {
        this.openMenu(row.funcId, row.prms);
      }
    },
    getTodos() {
      this.loadingTodos = true;
      this.$http
        .post("/foreclosure/api/todos")
        .then((resp) => {
          this.loadingTodos = false;
          let ret = resp.data;
          this.todos = ret.data;
        })
        .catch((err) => {
          this.loadingTodos = false;
        });
    },
    logout() {
      this.$confirm("是否确定退出系统?", "提示")
        .then((accept) => {
          this.loading = true;
          this.$http
            .get("/foreclosure/api/logout")
            .then((resp) => {
              this.loading = false;
              this.$store.commit("logout");
              this.$router.push({ name: "login" });
            })
            .catch((err) => {
              this.loading = false;
              this.$store.commit("logout");
              this.$router.push({ name: "login" });
            });
        })
        .catch((deny) => {});
    },
    openList() {
      if (this.msgs.length > 0) {
        return;
      }
      this.loadMsgs();
    },
    closeAllTabs() {
      this.$confirm("是否确定关闭所有已打开的菜单?", "提示")
        .then((accept) => {
          while (this.tabs.length > 1) {
            this.tabs.pop();
          }
          this.currentTab = "0-0";
        })
        .catch((deny) => {});
    },
    handleSelect: function (index, prmMap) {
      let menu;
      /*this.menus.map((item) => {
        item.submenu.map((subitem) => {
          if (subitem.name === index) {
            menu = { ...subitem };
          }
        });
      });*/
      this.menus.map((item) => {
        if (item.name === index) {
          menu = { ...item };
        }
      });
      if (menu) {
        //看是否已经打开
        let open = false;
        this.tabs.map((item) => {
          if (item.name === menu.name) {
            open = true;
          }
        });
        //如果已经打开,则显示tab
        if (open) {
          this.currentTab = index;
        } else {
          if (this.tabs.length > this.maxTabsCount) {
            this.$message.error(
              "最多只能同时打开" + this.maxTabsCount + "个菜单!"
            );
            return;
          }
          //console.log(menu);
          this.tabs.push(menu);
          this.currentTab = index;
        }
      }
    },
    openMenu: function (index, prmObject) {
      let menu;
      this.menus.map((item) => {
        item.submenu.map((subitem) => {
          if (subitem.name === index) {
            menu = { ...subitem };
          }
        });
      });
      if (menu) {
        this.removeTab(index);
        menu.extra = prmObject;
        this.tabs.push(menu);
        this.currentTab = index;
      } else {
        this.$message.error("模块不存在！");
      }
    },
    removeTab: function (name) {
      if (name === "8001" && sessionStorage.getItem("changeFlage") > 0) {
        this.$confirm("数据有变动但未保存，是否需要保存？", "提示", {
          cancelButtonText: "否",
          confirmButtonText: "是",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {
            for (let i = 0; i < this.tabs.length; i++) {
              if (this.tabs[i].name === name) {
                let tab = this.tabs[i + 1] || this.tabs[i - 1];
                this.currentTab = tab.name;
                this.tabs.splice(i, 1);
                break;
              }
            }
            sessionStorage.setItem("changeFlage", 0);
          });
      } else {
        for (let i = 0; i < this.tabs.length; i++) {
          if (this.tabs[i].name === name) {
            let tab = this.tabs[i + 1] || this.tabs[i - 1];
            this.currentTab = tab.name;
            this.tabs.splice(i, 1);
            break;
          }
        }
      }
    },
  },
};
</script>
<style>
.el-tabs__item {
  padding: 0;
}
.a-style {
  display: block;
  text-align: center;
  color: #fff;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  box-sizing: border-box;
}
.a-style:hover {
  background: #000;
}
.el-main {
  padding: 0;
}

.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 30px;
  line-height: 30px;
  color: #bcbcbc;
  width: 100%;
  text-align: center;
}
.jf-list {
  display: block;
  margin: 0;
  padding: 10px;
  height: 70px;
  width: 100%;
  border-bottom: solid 1px #d7dfe6ff;
  box-sizing: border-box;
  line-height: 26px;
}
.todo-indicator {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 5px;
  font-size: 24px;
}
.private-msg {
  width: 320px;
  height: 180px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 -2px 5px #bcbcbc;
  overflow: hidden;
  background: #ffffff;
  z-index: 9999;
}
</style>
