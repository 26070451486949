<!--
 * @Date: 2023-02-07 16:38:30
 * @LastEditors: 328888362 328888362@qq.com
 * @LastEditTime: 2023-02-12 22:32:24
 * @FilePath: \xn-foreclosure-ui\src\views\system\Orgs.vue
-->
<template>
  <XPage v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card v-auto="100">
          <div slot="header">组织机构</div>
          <el-tree
            :data="treeData"
            default-expand-all
            :expand-on-click-node="false"
            @current-change="queryOrgDetail"
          ></el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card v-auto="100">
          <el-form
            style="margin-top: 15px"
            :model="org"
            :rules="rules"
            ref="theform"
            label-width="120px"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="机构名称" prop="orgName">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.orgName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="上级机构" prop="parentOrgId">
                  <treeselect
                    :options="treeData"
                    v-model="org.parentOrgId"
                    :disabled="!canEdit || !isNew"
                    placeholder="请选择"
                    :defaultExpandLevel="3"
                  ></treeselect>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="行政区划代码" prop="poolArea">
                  <treeselect
                    :options="$store.state.dvsTree"
                    v-model="org.poolArea"
                    :disabled="!canEdit"
                    placeholder="请选择"
                    :defaultExpandLevel="3"
                  ></treeselect>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="组织机构代码">
                  <el-input :disabled="!canEdit" v-model="org.cert"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="法人姓名">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.lgName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="法人电话">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.lgPhone"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="联系人">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.contact"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.contactPhone"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地址">
                  <el-input
                    :disabled="!canEdit"
                    v-model="org.address"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: right">
              <el-form-item label="">
                <el-button
                  icon="el-icon-check"
                  :disabled="!canEdit"
                  type="primary"
                  @click="save"
                >
                  保存
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  :disabled="isNew"
                  @click="addNew"
                >
                  新增
                </el-button>
              </el-form-item>
            </div>
          </el-form>

          <el-table :data="users" stripe v-auto max-height="680">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column label="登录名" prop="userId"></el-table-column>
            <el-table-column label="姓名" prop="aac003"></el-table-column>
            <el-table-column label="身份证号" prop="aac002"></el-table-column>
            <el-table-column label="手机号" prop="mobile"></el-table-column>
            <el-table-column label="状态" width="220">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.aae100"
                  :disabled="true"
                  active-value="1"
                  active-text="正常"
                  inactive-value="0"
                  inactive-text="停用"
                  @change="modifyStatus(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </XPage>
</template>

<script>
export default {
  name: "Orgs",
  data() {
    return {
      loading: false,
      treeData: [],
      isNew: false,
      canEdit: false,
      org: {
        orgId: "",
        orgName: "",
        parentOrgId: null,
        poolArea: "",
        lgName: "",
        lgPhone: "",
        cert: "",
        contact: "",
        contactPhone: "",
        address: "",
      },
      users: [],
      rules: {
        orgName: [
          { required: true, message: "请输入机构名称", trigger: "change" },
        ],
        parentOrgId: [
          { required: true, message: "请选择上级机构", trigger: "change" },
        ],
        poolArea: [
          { required: true, message: "请输入统筹区号", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    save() {
      this.$refs["theform"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/foreclosure/api/orgs/save", this.org)
            .then((resp) => {
              this.loading = false;
              this.$message.success("保存成功");
              this.queryMyTree();
              this.org.orgId = "";
              this.org.orgName = "";
              this.org.parentOrgId = null;
              this.org.address = "";
              this.org.lgName = "";
              this.org.lgPhone = "";
              this.org.contact = "";
              this.org.contactPhone = "";
              this.org.cert = "";
              this.users = [];
              this.$refs["theform"].clearValidate();
              this.isNew = false;
              this.canEdit = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    addNew() {
      this.org.orgId = "";
      this.org.orgName = "";
      this.org.parentOrgId = null;
      this.org.poolArea = "";
      this.org.address = "";
      this.org.lgName = "";
      this.org.lgPhone = "";
      this.org.contact = "";
      this.org.contactPhone = "";
      this.org.cert = "";
      this.$refs["theform"].clearValidate();
      this.users = [];
      this.isNew = true;
      this.canEdit = true;
    },
    queryOrgDetail(data, node) {
      this.loading = true;
      console.log(data);
      this.$http
        .get("/foreclosure/api/orgs/users/" + data.id)
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          let org = resp.data.data.org;
          this.org = org;
          this.users = resp.data.data.users;
          this.canEdit = true;
          this.isNew = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryMyTree() {
      this.loading = true;
      this.$http
        .get("/foreclosure/api/orgs/myorgs/tree")
        .then((resp) => {
          this.loading = false;
          this.treeData = [];
          let ret = resp.data;
          this.treeData.push(ret.data);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.queryMyTree();
  },
};
</script>

<style></style>
