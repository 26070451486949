<template>
  <x-page v-loading="loading">
    <el-card class="box-card">
    <div style="text-align: right">
      <el-button type="success" icon="el-icon-plus" @click="add">
        新增
      </el-button>
      <el-button type="default" icon="el-icon-refresh" @click="refreshCache">
        刷新缓存
      </el-button>
    </div>
  </el-card>
  <el-card>
    <div>
      <el-row>
        <el-col :span="6" v-for="item in banners" :key="item.id">
          <el-card style="margin: 10px">
            <div slot="header">
              <span>{{ item.title }}</span>
              <span><img :src="item.coverImg" style="width: 100%; height: 100%" /></span>
              <span style="float: right">
                <!-- <span>是否展示:</span> -->
                <!-- <el-switch v-model="item.enabled" active-value="1" inactive-value="0" @change="enabledChange(item)">
                </el-switch> -->
                <el-switch v-model="item.published" active-value="1" active-text="启用" inactive-value="0"
                  inactive-text="不启用" @change="enabledChange(item)"></el-switch>
              </span>
              <span>
                  <el-button type="text" icon="el-icon-delete" @click="deleteItem(item)">
                    删除
                  </el-button>
                </span>
            </div>
            <img :src="item.url" style="width: 100%" />
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-card>

    <el-dialog title="新增轮播" :close-on-click-modal="false" width="500px" :visible.sync="show">
      <el-form ref="theform" :model="params" :rules="rules" label-width="100px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="params.title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="封面图" prop="photo">
          <div v-if="params.photo == ''">
            <input type="file" @change="fileChange" />
          </div>
          <span v-else>
            <img :src="params.photo" style="width: 355px" />
            <div>
              {{ fname }}
              <el-button type="text" @click="params.photo = ''">删除</el-button>
            </div>
          </span>
          <el-input v-model="params.photo" placeholder="" style="display: none"></el-input>
        </el-form-item>
        <el-form-item label="详情图" prop="photo1">
          <div v-if="params.photo1 == ''">
            <input type="file" @change="fileChange1" />
          </div>
          <span v-else>
            <img :src="params.photo1" style="width: 355px" />
            <div>
              {{ fname1 }}
              <el-button type="text" @click="params.photo1 = ''">删除</el-button>
            </div>
          </span>
          <el-input v-model="params.photo1" placeholder="" style="display: none"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button type="primary" icon="el-icon-check" :loading="loading" @click="save">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </x-page>
</template>

<script>
const ALLOWED_TYPES = ["JPG", "JPEG", "PNG", "BMP"];

export default {
  name: "Banners",
  data() {
    return {
      banners: [],
      loading: false,
      show: false,
      params: {
        title: "",
        photo: "",
        title1: "",
        photo1: "",
        files: [],
        files1: [],
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        photo: [
          { required: true, message: "请选择上传图片", trigger: "change" },
        ],
        photo1: [
          { required: true, message: "请选择上传图片", trigger: "change" },
        ],
      },
      fname: "",
      fname1: "",
    };
  },
  methods: {
    add() {
      this.params.title = "";
      this.params.photo = "";
      this.params.photo1 = "";
      this.params.files = [];
      this.params.files1 = [];
      this.show = true;
    },
    refreshCache() {
      this.$confirm("该操作将同步发布信息到设备上，是否继续执行？", "提示")
        .then((accept) => {
          this.loading = true;
          this.$http
            .post("/admin/survey/carousel/refreshCache")
            .then((resp) => {
              this.loading = false;
              this.$message.success("刷新缓存成功");
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((deny) => { });
    },

    enabledChange(row) {
      this.loading = true;
      let fd = new FormData();
      fd.append("id", row.id);
      fd.append("published", row.published);
      this.$http
        .post("/foreclosure/api/banners/published", fd)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("修改状态失败" + err);
          row.published = row.published === "1" ? "0" : "1";
        });
    },
    deleteItem(row) {
      this.$confirm("该操作不可会退，是否继续？", "提示")
        .then((accept) => {
          this.loading = true;
          let fd = new FormData();
          fd.append("id", row.id);
          this.$http
            .post("/foreclosure/api/banners/delete", fd)
            .then((resp) => {
              this.loading = false;
              this.$message.success("删除成功");
              this.query();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((deny) => { });
    },
    query() {
      this.loading = true;
      this.$http
        .post("/foreclosure/api/banners/query")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.banners = [];
          ret.data.map((item) => {
            this.banners.push(item);
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fileChange(evt) {
      this.params.files = [];
      let file = evt.target.files[0];
      let name = file.name;
      let arr = name.split(".");
      let suffix = arr[arr.length - 1].toUpperCase();
      evt.target.value = "";
      if (ALLOWED_TYPES.indexOf(suffix) < 0) {
        this.$message.error("请选择JPG,JPEG,PNG,BMP格式的图片");
        return;
      }
      if (file.size < 5000) {
        this.$message.error("图片小于5K,请重新选择");
        return;
      }
      if (file.size > 20000000) {
        this.$message.error("图片大于20M,请重新选择");
        return;
      }
      if (file) {
        this.params.files.push(file);
        let fr = new FileReader();
        fr.onloadend = () => {
          this.params.photo = fr.result;
        };
        fr.readAsDataURL(file);
        this.fname = file.name;
      }
    },

    fileChange1(evt) {
      this.params.files1 = [];
      let file = evt.target.files[0];
      let name = file.name;
      let arr = name.split(".");
      let suffix = arr[arr.length - 1].toUpperCase();
      evt.target.value = "";
      if (ALLOWED_TYPES.indexOf(suffix) < 0) {
        this.$message.error("请选择JPG,JPEG,PNG,BMP格式的图片");
        return;
      }
      if (file.size < 5000) {
        this.$message.error("图片小于5K,请重新选择");
        return;
      }
      if (file.size > 20000000) {
        this.$message.error("图片大于20M,请重新选择");
        return;
      }
      if (file) {
        this.params.files1.push(file);
        let fr = new FileReader();
        fr.onloadend = () => {
          this.params.photo1 = fr.result;
        };
        fr.readAsDataURL(file);
        this.fname1 = file.name;
      }
    },

    save() {
      this.$refs["theform"].validate((valid) => {
        //alert(valid)
        if (valid) {
          let fd = new FormData();
          fd.append("title", this.params.title);
          fd.append("file", this.params.files[0]);
          fd.append("file1", this.params.files1[0]);
          this.loading = true;
          this.$http
            .post("/foreclosure/api/banners/upload", fd)
            .then((resp) => {
              this.loading = false;
              this.$message.success("保存成功");
              this.show = false;
              this.query();
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style>
</style>
