
import XSelect from './XSelect.vue'
import ITag from './ITag.vue'
import IClient from './IClient.vue'
import IHCard from './IHCard.vue'
import TopHeader from './TopHeader.vue'
import BlockEchartBar from './BlockEchartBar.vue'
import BlockEchartBar1 from './BlockEchartBar1.vue'
import BlockEchartPie from './BlockEchartPie.vue'
const compArray = [XSelect, ITag, IClient, IHCard, TopHeader,BlockEchartBar,BlockEchartBar1,BlockEchartPie]

const Comps = {
  name: 'Comps',
  install: function (Vue, option) {
    compArray.map(item => {
      Vue.component(item.name, item)
    })
  }
}

export default Comps
