<template>
  <x-page v-loading="loading">
    <!-- <el-card class="box-card">
                Welcome!
              </el-card> -->
    <el-card>
      <el-row :gutter="5">
        <el-col :span="6">
          <!-- <div class="drawDiv_4">
              <span class="block-contentho_2"><i class="el-icon-menu"></i>快捷菜单</span>
            </div> -->
          <div class="drawDiv_4">
            <el-tag><span><i class="el-icon-menu"></i>首页</span>
            </el-tag>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :span="6">
          <div class="drawDiv_1">
            <span class="block-contentho_2"><i class="el-icon-s-custom"></i>客户信息</span>
            <span class="block-contentho_1"> 现有客户：353（人）</span>
            <span class="block-contentho_1"> 上周新增： 12 （人）</span>
            <span class="block-contentho_1"> 本月新增： 32 （人）</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_1">
            <span class="block-contentho_2"><i class="el-icon-s-home"></i>房源信息</span>
            <span class="block-contentho_1"> 现有房源：527 （户）</span>
            <span class="block-contentho_1"> 上周新增： 34 （户）</span>
            <span class="block-contentho_1"> 本月新增： 90 （户）</span>

          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_1">
            <span class="block-contentho_2"><i class="el-icon-s-claim"></i>成交信息</span>
            <span class="block-contentho_1"> 现有成交：430 （单）</span>
            <span class="block-contentho_1"> 上周新增： 22 （单）</span>
            <span class="block-contentho_1"> 本月新增： 35 （单）</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_1">
            <span class="block-contentho_2"><i class="el-icon-s-check"></i>合同信息</span>
            <span class="block-contentho_1"> 现有签订：430 （份）</span>
            <span class="block-contentho_1"> 上周新增： 22 （份）</span>
            <span class="block-contentho_1"> 本月新增： 35 （份）</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row :gutter="5">
        <el-col :span="6">
          <!-- <div class="drawDiv_4">
                <span class="block-contentho_2"><i class="el-icon-menu"></i>新增房源</span>
              </div> -->
          <div class="drawDiv_4">
            <el-tag><span><i class="el-icon-menu"></i>新增房源</span>
            </el-tag>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :span="24">
          <div class="drawDiv_2">
            <dv-scroll-board :config="t2" style="width:100%;height:400px;margin-top: 30px;" />
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row :gutter="5">
        <el-col :span="6">
          <!-- <div class="drawDiv_4">
              <span class="block-contentho_2"><i class="el-icon-menu"></i>快捷菜单</span>
            </div> -->
          <div class="drawDiv_4">
            <el-tag><span><i class="el-icon-menu"></i>快捷菜单</span>
            </el-tag>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :span="6">
          <div class="drawDiv_3">
            <el-button round class="botton_1" @click="$bus.$emit('openMenu', '2001')"><i
                class="el-icon-s-home"></i>房源信息库</el-button>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_3">
            <el-button round class="botton_1" @click="$bus.$emit('openMenu', '9001')"><i
                class="el-icon-user"></i>客户信息库</el-button>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_3">
            <el-button round class="botton_1" @click="$bus.$emit('openMenu', '3002')"><i
                class="el-icon-s-promotion"></i>工作日志维护</el-button>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="drawDiv_3">
            <el-button round class="botton_1" @click="$bus.$emit('openMenu', '3005')"><i
                class="el-icon-s-order"></i>每周小结维护</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
</x-page>
</template>

<script>
import router from '@/router';


export default {
  name: "Welcome",
  data() {
    return {
      loading: false,
      T1: {
        id: "",
        aae013: "",
      },
      t2: {},
      T2: [
        ['<span class="indexxG2">'+'龙湖天曜城'+'</span>', '<span class="indexxG2">'+'浦口浦江街道'+'</span>', '<span class="indexxG2">'+'130m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'碧桂园凤凰城'+'</span>', '<span class="indexxG2">'+'雨花台区'+'</span>', '<span class="indexxG2">'+'160m²'+'</span>', '<span class="indexxG2">'+'450w'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'丽天香都'+'</span>', '<span class="indexxG2">'+'碧桂园路38号'+'</span>', '<span class="indexxG2">'+'95m²'+'</span>', '<span class="indexxG2">'+'220W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'南海天成'+'</span>', '<span class="indexxG2">'+'香都大道'+'</span>', '<span class="indexxG2">'+'220m²'+'</span>', '<span class="indexxG2">'+'250w'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'越秀丽枫'+'</span>', '<span class="indexxG2">'+'越秀街19号'+'</span>', '<span class="indexxG2">'+'150m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'保利文化'+'</span>', '<span class="indexxG2">'+'文化大道'+'</span>', '<span class="indexxG2">'+'130m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'琥珀云台'+'</span>', '<span class="indexxG2">'+'校场大道'+'</span>', '<span class="indexxG2">'+'170m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'凯越丽都'+'</span>', '<span class="indexxG2">'+'龙湖路'+'</span>', '<span class="indexxG2">'+'180m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'国际天都'+'</span>', '<span class="indexxG2">'+'香山大道'+'</span>', '<span class="indexxG2">'+'130m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],
        ['<span class="indexxG2">'+'中海观闻澜'+'</span>', '<span class="indexxG2">'+'南海路'+'</span>', '<span class="indexxG2">'+'130m²'+'</span>', '<span class="indexxG2">'+'320W'+'</span>','<span class="indexxG2">'+'250w'+'</span>', '<span class="indexxG2">'+'2.5W'+'</span>'],

        // ['<span class="indexxG2">'+'中海观闻澜'+'</span>', '雨花台区', '160m²', '520W', '450w', '5W'],
        // ['<span class="indexxG2">'+'碧桂园凤凰城'+'</span>', '碧桂园路38号', '95m²', '220W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'丽天香都'+'</span>', '香都大道', '220m²', '370W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'南海天成'+'</span>', '南海路', '150m²', '320W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'越秀丽枫'+'</span>', '越秀街19号', '130m²', '280W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'保利文化'+'</span>', '文化大道', '170m²', '420W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'琥珀云台'+'</span>', '校场大道', '180m²', '610W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'凯越丽都'+'</span>', '龙湖路', '130m²', '320W', '250w', '2.5W'],
        // ['<span class="indexxG2">'+'国际天都'+'</span>', '香山大道', '130m²', '320W', '250w', '2.5W'],
      ],
    };
  },
  methods: {
    query() {
      this.loading = true;
      this.T1 = {
        id: "",
        aae013: "",
      };
      this.t2 = {};
      this.$http
        .post("/foreclosure/api/home/query")
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          if (!G.isEmpty(ret.T1)) {
            this.T1 = ret.T1;
          }
          this.drawT2();
        })
        .catch((err) => {
          this.loading = false;
        });

    },
    drawT2() {
      this.t2 = {
        headerHeight: 55,
        waitTime: 4000,
        carousel: 'page',
        header: ['<span class="indexxG1">房源名称</span>', '<span class="indexxG1">房源地址</span>', '<span class="indexxG1">房屋面积</span>', '<span class="indexxG1">评估价</span>', '<span class="indexxG1">起拍价</span>', '<span class="indexxG1">保证金</span>'],
        data: this.T2,
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center'],
        // headerBGC: "#7F6D8B",
        // oddRowBGC: "#BFB8CB",
        // evenRowBGC: "#a9b5c6",
        headerBGC: "#d4d3d3",
        oddRowBGC: "#e1eaf7",
        evenRowBGC: "#d3dfee",
        color: "#000000",
        rowNum: 5,
        //indexHeader: '排名',
        //index: true,
      };
    },
  },
  mounted() {
    this.query();
  },
  components: { router }
}
</script>

<style>
.grid-content {
  border-radius: 4px;
  min-height: 70px;
  text-align: center;
}

.block-contentho_1 {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #000000;
}

.block-contentho_2 {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #409eff;
  /* background: #c9d3f4; */

}

.drawDiv_1 {
  height: 170px;
  width: 96%;
  margin: 0 auto;
}

.drawDiv_2 {
  height: 400px;
  width: 96%;
  margin: 0 auto;
}

.drawDiv_3 {
  height: 120px;
  width: 96%;
  margin: 0 auto;
}

.drawDiv_4 {
  height: 25px;
  width: 96%;
  margin: 0 auto;
}

.drawDiv_5 {
  height: 500px;
  width: 96%;
  margin: 0 auto;
}

.botton_1 {
  height: 30px;
  width: 140px;
  margin-top: 50px;
  font-size: 16px;
  color: #ffffff;
  background: #3976cb;
}

.color_1 {
  color: #e1eaf7
}

.indexxG1 {
  font-size: 18px;
  color: #3976cb
    /* font-family: "微软雅黑"; */
}

.indexxG2 {
  font-size: 16px;
  color: #000000
    /* font-family: "微软雅黑"; */
}
</style>