<template>
  
</template>

<script>
export default {
  name: "Splash",
  mounted() {
  },
};
</script>

<style></style>
