const Tools = {
  name: 'Tools',
  /*判断是否为空*/
  isEmpty: function (val) {
    if (val === undefined || val === 'undefined') {
      return true
    }
    if (val instanceof Array) {
      if (val.length === 0) {
        return true
      } else {
        return false
      }
    } else {
      if (val == null || val === '') {
        return true
      }
      return false
    }
  },
  /*清除JSON,只支持一级*/
  clearJson(json) {
    for (let key in json) {
      json[key] = '';
    }
  },
  lowKey(json) {
    let tmp = {};
    for (let key in json) {
      tmp[key.toLowerCase()] = json[key];
    }
    return tmp;
  },
  upKey(json) {
    let tmp = {};
    for (let key in json) {
      tmp[key.toUpperCase()] = json[key];
    }
    return tmp;
  },
  upNumber(n) {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
      return '数据非法'
    }
    let unit = '千百拾亿千百拾万千百拾元角分'
    let str = ''
    n += '00'
    var p = n.indexOf('.')
    if (p >= 0) {
      n = n.substring(0, p) + n.substr(p + 1, 2)
    }
    unit = unit.substr(unit.length - n.length)
    for (var i = 0; i < n.length; i++) {
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i)
    }
    return str.replace(/零(千|百|拾|角)/g, '零')
      .replace(/(零)+/g, '零')
      .replace(/零(万|亿|元)/g, '$1')
      .replace(/(亿)万|壹(拾)/g, '$1$2')
      .replace(/^元零?|零分/g, '')
      .replace(/元$/g, '元整')
  },
  round(numberRound, roundDigit) { //四舍五入，保留位数为roundDigit
    if (numberRound >= 0) {
      var tempNumber = parseInt((numberRound * Math.pow(10, roundDigit) + 0.5)) / Math.pow(10, roundDigit);
      return tempNumber;
    } else {
      let numberRound1 = -numberRound;
      var tempNumber = parseInt((numberRound1 * Math.pow(10, roundDigit) + 0.5)) / Math.pow(10, roundDigit);
      return -tempNumber;
    }
  },
  /*添加全页面水印 */
  addWaterMark(str) {
    let can = document.createElement("canvas");
    can.width = 400;
    can.height = 200;
    can.height = can.width / 2;
    can.style.display = "none";
    let cans = can.getContext("2d");
    cans.rotate((-25 * Math.PI) / 180);
    cans.font = "16px Microsoft YaHei";
    cans.fillStyle = "rgba(180, 180, 180, 0.3)";
    cans.textAlign = "left";
    cans.textBaseline = "Middle";
    cans.fillText(str, 5, can.height);
    let div = document.createElement("div");
    div.style.pointerEvents = "none";
    div.style.top = "0";
    div.style.left = "0";
    div.style.position = "fixed";
    div.style.zIndex = "9999";
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.background =
      "url(" + can.toDataURL("image/png") + ") left top repeat";
    document.body.appendChild(div);
  },
  getTime(date) {
    var year = date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month =
      date.getMonth() + 1 < 10 ?
      "0" + (date.getMonth() + 1) :
      date.getMonth() + 1;
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // 拼接
    return year +""+ month +""+ day +""+ hours +""+ minutes +""+ seconds;
  },
  getTimeFormat(date) {
    var year = date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month =
      date.getMonth() + 1 < 10 ?
      "0" + (date.getMonth() + 1) :
      date.getMonth() + 1;
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // 拼接
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
  },

  getDayFormat(date) {
    var year = date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month =
      date.getMonth() + 1 < 10 ?
      "0" + (date.getMonth() + 1) :
      date.getMonth() + 1;
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // 拼接
    return year + "-" + month + "-" + day ;
  },

  getAge(csrq) {
    var birthdays = new Date(csrq);
    var d = new Date();
    var age =
      d.getFullYear() -
      birthdays.getFullYear() -
      (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate()) ?
        1 :
        0);
    return age;
  },

  //获取二级代码value
  getAa02(key, aa01Key, aa02Key) {
    var aa01List = JSON.parse(localStorage.getItem(key));
    var ret = "";
    aa01List.forEach((item) => {
      if (item.aa01Key == aa01Key) {
        var aa02List = item.aa02List;

        aa02List.forEach((aa02) => {
          if (aa02.aa02Key == aa02Key) {
            ret = aa02;
          }
        });
      }
    });
    return ret;
  },

}

//定义format函数
String.prototype.format = function (args) {
  let result = this;
  if (arguments.length > 0) {
    if (arguments.length == 1 && typeof args == "object") {
      for (var key in args) {
        if (args[key] != undefined) {
          var reg = new RegExp("({" + key + "})", "g");
          result = result.replace(reg, args[key]);
        } else {
          var reg = new RegExp("({" + key + "})", "g");
          result = result.replace(reg, "");
        }
      }
    } else {
      for (var i = 0; i < arguments.length; i++) {
        if (arguments[i] != undefined) {
          var reg = new RegExp("({)" + i + "(})", "ig");
          result = result.replace(reg, arguments[i]);
        } else {
          var reg = new RegExp("({)" + i + "(})", "ig");
          result = result.replace(reg, "");
        }
      }
    }
  }
  return result;
};




window.G = Tools

export default Tools