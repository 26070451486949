<template>
  <x-page style="width: 92%; left: 4%; position: relative">
    <el-form
      :model="prms"
      :inline="true"
      style="text-align: center; margin-top: 20px"
      size="medium"
    >
      <el-form-item>
        <vs-input
          primary
          v-model="alcPrms.userName"
          icon-after
          label-placeholder="客户成功经理"
          @click-icon="openSltClient"
          readonly
        >
          <template #icon>
            <i class="el-icon-search"></i>
          </template>
        </vs-input>
        <!-- <el-input
          v-model="alcPrms.userName"
          :readonly="true"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="openSltClient"
          ></el-button>
        </el-input>-->
      </el-form-item>
      <el-form-item>
        <vs-input
          primary
          label-placeholder="客户关键词"
          v-model="prms.clientWord"
        />
        <!--
        <span class="item-label">客户关键词</span>
        <el-input
          class="input-with-select"
          v-model="prms.clientWord"
          placeholder="请输入客户信息"
          clearable
        ></el-input>-->
      </el-form-item>

      <el-form-item>
        <vs-button style="margin-top: 0px" @click="search"> 查一查 </vs-button>
        <!--  <el-button
          type="primary"
          icon="el-icon-search"
          class="btn"
          @click="search"
          round
        >
          查一查
        </el-button>-->
      </el-form-item>

      <el-form-item>
        <vs-input
          primary
          v-model="alcPrms.alcName"
          icon-after
          label-placeholder="分配人员"
          @click-icon="openAlcClient"
          readonly
        >
          <template #icon>
            <i class="el-icon-search"></i>
          </template>
        </vs-input>
      </el-form-item>
      <el-form-item>
        <vs-button
          border
          :active="true"
          style="margin-top: 0px"
          @click="alcSelected"
        >
          选中分配
        </vs-button>
      </el-form-item>
      <el-form-item>
        <vs-button border danger style="margin-top: 0px" @click="alcAll">
          全部分配
        </vs-button>
      </el-form-item>
    </el-form>

    <!-- <el-card shadow="hover" body-style="padding:0 0 0 18px!important">
      <el-form :inline="true" style="margin-top: 18px" size="medium">
        <el-form-item label="分配人员">
          <el-input
            step=""
            v-model="alcPrms.alcName"
            :readonly="true"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="openAlcClient"
            ></el-button> </el-input
        ></el-form-item>
        <el-form-item>
          <el-button
            style="border-right: 1px solid #fff"
            type="success"
            @click="alcSelected"
            round
            >选中分配</el-button
          ></el-form-item
        >
        <el-form-item>
          <el-button
            style="border-right: 1px solid #fff"
            type="warning"
            @click="alcAll"
            round
            >全部分配</el-button
          ></el-form-item
        ></el-form
      >
    </el-card>-->

    <div :style="{ height: windowHeight - 200 + 'px' }" class="content-box">
      <div
        class="m-itemlist-item grid-item"
        v-for="(cinfo, index) in clist"
        v-bind:key="cinfo.clientId"
        :name="index"
      >
        <div @click="checkItems(cinfo.clientId, index)">
          <ICheckBox
            :checked="true"
            :clientId="cinfo.clientId"
            @checkItems="checkItems"
            :clientIndex="index"
            @keyup.space.stop.native
            @click.stop.native
            ref="icheck"
            style="float: right"
          >
          </ICheckBox>

          <span style="margin-top: 20px; font-weight: 700; color: #000">
            {{ cinfo.clientName }}</span
          >

          <el-descriptions :column="1" class="el-des">
            <el-descriptions-item label="客户编号">
              {{ cinfo.clientId }}
            </el-descriptions-item>
            <el-descriptions-item label="所属经理">
              <el-tag effect="dark">
                {{ getUserName(cinfo.ownerOptId) }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="联系方式">
              {{
                cinfo.tel
                  ? cinfo.tel
                  : cinfo.wechat
                  ? cinfo.wechat
                  : cinfo.tiktok
              }}
            </el-descriptions-item>
            <el-descriptions-item label="客户等级">{{
              getAa10("CLIENT_LVL", cinfo.clientLvl)
            }}</el-descriptions-item>
            <el-descriptions-item label="客户状态">{{
              getAa10("CLIENT_STAS", cinfo.status)
            }}</el-descriptions-item>
          </el-descriptions>
          <!--  <el-checkbox
              :key="index"
              :label="cinfo.clientId"
              style="font-weight: 600; width: 93%; float: left"
              @change="test(cinfo, $event)"
            >
              <span class="cbb"> {{ cinfo.clientName }}</span>

              <el-tag effect="dark" style="margin-left: 10px">
                {{ getUserName(cinfo.ownerOptId) }}
              </el-tag>
            </el-checkbox> 
            
          </div>
          <div style="margin-top: 30px; position: relative">
            <el-descriptions :column="6">
              <el-descriptions-item label="客户编号">
                <el-tag
                  v-if="
                    cinfo.clientId.indexOf(prms.keyword) >= 0 &&
                    prms.keyword != ''
                  "
                  type="danger"
                  effect="dark"
                  >{{ cinfo.clientId }}</el-tag
                >
                <span v-else>{{ cinfo.clientId }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="客户姓名">
                <el-tag
                  v-if="
                    cinfo.clientName.indexOf(prms.keyword) >= 0 &&
                    prms.keyword != ''
                  "
                  type="danger"
                  effect="dark"
                  >{{ cinfo.clientName }}</el-tag
                >
                <span v-else>{{ cinfo.clientName }}</span></el-descriptions-item
              >
              <el-descriptions-item label="性别">{{
                getAa10("GEND", cinfo.gend)
              }}</el-descriptions-item>
              <el-descriptions-item label="联系方式">
                <el-tag
                  size="small"
                  :effect="
                    (cinfo.tel
                      ? cinfo.tel
                      : cinfo.wechat
                      ? cinfo.wechat
                      : cinfo.tiktok
                    ).indexOf(prms.keyword) >= 0 && prms.keyword != ''
                      ? 'dark'
                      : 'plain'
                  "
                  :type="
                    (cinfo.tel
                      ? cinfo.tel
                      : cinfo.wechat
                      ? cinfo.wechat
                      : cinfo.tiktok
                    ).indexOf(prms.keyword) >= 0 && prms.keyword != ''
                      ? 'danger'
                      : ''
                  "
                  >{{
                    cinfo.tel
                      ? cinfo.tel
                      : cinfo.wechat
                      ? cinfo.wechat
                      : cinfo.tiktok
                  }}</el-tag
                >
              </el-descriptions-item>
              <el-descriptions-item label="客户等级">{{
                getAa10("CLIENT_LVL", cinfo.clientLvl)
              }}</el-descriptions-item>
              <el-descriptions-item label="客户状态">{{
                getAa10("CLIENT_STAS", cinfo.status)
              }}</el-descriptions-item>
            </el-descriptions>
              <el-descriptions :column="3">
              <el-descriptions-item label="预算范围(万元)">
                <el-tag effect="plain" size="small"
                  >{{ cinfo.budgetLow }}
                </el-tag>
                &nbsp;&nbsp;-
                <el-tag effect="plain" size="small">
                  {{ cinfo.budgetHigh }}</el-tag
                ></el-descriptions-item
              >
              <el-descriptions-item label="偏好/意向">
                <el-tag
                  v-for="(int, index) in cinfo.intList"
                  :key="index"
                  :type="
                    int.content.indexOf(prms.keyword) >= 0 && prms.keyword != ''
                      ? 'danger'
                      : ''
                  "
                  :effect="
                    int.content.indexOf(prms.keyword) >= 0 && prms.keyword != ''
                      ? 'dark'
                      : 'plain'
                  "
                  >{{ int.content }}</el-tag
                >
              </el-descriptions-item>
            </el-descriptions>-->
        </div>
      </div>
    </div>

    <!-- <vs-pagination
      v-model="currentPage"
      circle
      :length="Math.ceil(prms.total / prms.size)"
    />-->
    <el-pagination
      style="float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="prms.page"
      :page-size="prms.size"
      :total="prms.total"
      layout="total, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      width="50%"
      title="选择客户成功经理"
      :visible.sync="showSltClient"
    >
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable>
      </el-input>
      <el-card style="margin-top: 10px">
        <el-tree
          :data="treeData"
          default-expand-all
          show-checkbox
          node-key="id"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :check-on-click-node="true"
          ref="tree"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <el-tag v-if="data.isUser == true" type="" effect="dark">
              {{ node.label }}
            </el-tag>
            <el-tag
              v-else
              effect="plain"
              :type="data.disabled == true ? 'info' : ''"
            >
              {{ node.label }}
            </el-tag>
          </span>
        </el-tree></el-card
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSltClient = false">取 消</el-button>
        <el-button type="primary" @click="getUserCheckedNodes">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="50%" title="选择分配人员" :visible.sync="showAlcClient">
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterAlcText"
        clearable
      >
      </el-input>
      <el-card style="margin-top: 10px">
        <el-tree
          :data="treeData"
          default-expand-all
          show-checkbox
          node-key="id"
          @check="changeAlc"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :check-on-click-node="true"
          ref="alcTree"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <el-tag v-if="data.isUser == true" type="" effect="dark">
              {{ node.label }}
            </el-tag>
            <el-tag
              v-else
              effect="plain"
              :type="data.disabled == true ? 'info' : ''"
            >
              {{ node.label }}
            </el-tag>
          </span>
        </el-tree></el-card
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAlcClient = false">取 消</el-button>
        <el-button type="primary" @click="getAlcCheckedNodes">确 定</el-button>
      </div>
    </el-dialog>
  </x-page>
</template>
 

<script>
import ICheckBox from "@/components/ICheckBox";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    filterAlcText(val) {
      this.$refs.alcTree.filter(val);
    },
    currentPage(newName, oldName) {
      this.prms.page = newName;
      this.query();
    },
  },
  components: {
    ICheckBox,
  },
  name: "Allocate",
  data() {
    return {
      prms: {
        userId: "",
        // clientId: "",
        // clientName: "",
        // tel: "",
        clientWord: "",
        page: 1,
        size: 20,
        total: 0,
      },
      clist: [],
      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度

      currentPage: 1,

      alcPrms: {
        userName: "",
        alcName: "",
        userIds: [],
        alcIds: [],
        clientIds: [],
        alcType: "",
      },

      showSltClient: false,
      showAlcClient: false,

      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      filterText: "",
      filterAlcText: "",

      userList: [],
    };
  },
  methods: {
    handleSizeChange: function (val) {
      this.prms.size = val;
      this.query();
    },
    handleCurrentChange: function (val) {
      this.prms.page = val;
      this.query();
    },
    handleCheckedCitiesChange(value) {
      //this.alcPrms.clientIds = value;
    },
    checkItems(clientId, index) {
      console.log(clientId);
      let isChecked = false;
      if (this.alcPrms.clientIds.indexOf(clientId) > -1) {
        this.alcPrms.clientIds.splice(
          this.alcPrms.clientIds.indexOf(clientId),
          1
        );
        this.clist.forEach((item) => {
          if (item.clientId == clientId) {
            item.checked = false;
          }
        });
      } else {
        this.alcPrms.clientIds.push(clientId);
        this.clist.forEach((item) => {
          if (item.clientId == clientId) {
            item.checked = true;
            isChecked = true;
          }
        });
      }
      this.$forceUpdate();
      this.$refs["icheck"][index].setChecked(isChecked);
    },
    test(myVal, e, l) {
      if (e) {
        this.alcPrms.clientIds.push(myVal.clientId);
      } else {
        this.alcPrms.clientIds.splice(
          this.alcPrms.clientIds.indexOf(myVal.clientId),
          1
        );
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    changeAlc(data, e, l) {
      this.$refs.alcTree.setCheckedKeys([data.id]);
    },
    getUserCheckedNodes() {
      let list = this.$refs.tree.getCheckedNodes();
      this.alcPrms.userIds = [];
      let nameList = [];
      list.forEach((item) => {
        if (item.isUser) {
          this.alcPrms.userIds.push(item.id);
          nameList.push(item.label);
        }
      });
      this.alcPrms.userName = nameList.join(",");
      this.showSltClient = false;

      //自动查询
      this.prms.userId = this.alcPrms.userIds.join(",");
      this.query();
    },
    getAlcCheckedNodes() {
      let list = this.$refs.alcTree.getCheckedNodes();
      this.alcPrms.alcIds = [];
      let nameList = [];
      list.forEach((item) => {
        if (item.isUser) {
          this.alcPrms.alcIds.push(item.id);
          nameList.push(item.label);
        }
      });
      this.alcPrms.alcName = nameList.join(",");
      this.showAlcClient = false;
    },
    search() {
      this.prms.page = 1;
      this.query();
    },
    queryOrgData() {
      this.loading = true;
      this.$http
        .get("/foreclosure/api/orgs/myorgs/tree")
        .then((resp) => {
          this.loading = false;
          this.treeData = [];
          let ret = resp.data;
          this.treeData.push(ret.data);
          this.queryAllUsers();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryAllUsers() {
      this.loading = true;
      let input = {
        aac003: "",
        page: 1,
        size: 9999,
      };
      this.$http
        .post("/foreclosure/api/users", input)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data.data;
          this.userList = ret;
          //处理树状结构
          this.treeData.forEach((item) => {
            this.loopTree(item, ret);
          });
          this.query();
          //  console.log(this.treeData);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loopTree(item, ret) {
      ret.forEach((user) => {
        if (user.orgId == item.id) {
          if (!item.children) {
            item.children = [];
          }
          item.children.unshift({
            id: user.userId,
            label: user.aac003,
            isUser: true,
          });
        }
      });
      if (item.children && item.children.length > 0) {
        item.children.forEach((item) => {
          this.loopTree(item, ret);
        });
      } else {
        /* ret.forEach((user) => {
          if (user.orgId == item.id) {
            if (!item.children) {
              item.children = [];
            }
            item.children.push({
              id: user.userId,
              label: user.aac003,
              isUser: true,
            });
          }
        });*/
        //没有子节点，而且不是用户
        if (!item.isUser) {
          item.isUser = false;
          item.disabled = true;
        }
      }
    },
    query() {
      this.loading = true;
      this.$http
        .post("/foreclosure/client/allocate/list", this.prms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.clist = ret.data;
          this.clist.forEach((item) => {
            item.checked = false;
          });
          this.prms.total = ret.total;
          this.currentPage = ret.page;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getUserName(userid) {
      let user = this.userList.find((item) => item.userId == userid);
      if (user != null) {
        return user.aac003;
      } else {
        return "";
      }
      //return this.userList.find((item) => item.userId == userid).aac003;
    },
    alcSelected() {
      if (this.alcPrms.clientIds.length == 0) {
        /* this.$notify({
          title: "警告",
          message: "请至少选择一条客源信息！",
          type: "warning",
        });*/
        const noti = this.$vs.notification({
          duration: 3000,
          position: "top-right",
          progress: "auto",
          title: "友情提示",
          text: `请至少选择一条客源信息！`,
        });
        return;
      }
      if (this.alcPrms.alcIds.length == 0) {
        /* this.$notify({
          title: "警告",
          message: "分配人员信息不能为空！",
          type: "warning",
        });*/
        const noti = this.$vs.notification({
          duration: 3000,
          position: "top-right",
          progress: "auto",
          title: "友情提示",
          text: `分配人员信息不能为空！`,
        });
        return;
      }
      this.loading = true;
      this.alcPrms.alcType = "S";
      this.$http
        .post("/foreclosure/client/allocate/save", this.alcPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          /* this.$notify({
            title: "成功",
            message: "分配成功！",
            type: "success",
          });*/
          const noti = this.$vs.notification({
            duration: 3000,
            position: "top-right",
            progress: "auto",
            title: "成功",
            text: `分配成功！`,
          });
          this.query();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    alcAll() {
      console.log(this.alcPrms.userIds);
      if (this.alcPrms.userIds.length == 0) {
        /*this.$notify({
          title: "警告",
          message: "请至少选择一个被分配人员！",
          type: "warning",
        });*/
        const noti = this.$vs.notification({
          duration: 3000,
          position: "top-right",
          progress: "auto",
          title: "友情提示",
          text: `请至少选择一个被分配人员！`,
        });
        return;
      }
      if (this.alcPrms.alcIds.length == 0) {
        /* this.$notify({
          title: "警告",
          message: "分配人员信息不能为空！",
          type: "warning",
        });*/
        const noti = this.$vs.notification({
          duration: 3000,
          position: "top-right",
          progress: "auto",
          title: "友情提示",
          text: `分配人员信息不能为空！`,
        });
        return;
      }
      this.loading = true;
      this.alcPrms.alcType = "A";
      this.$http
        .post("/foreclosure/client/allocate/save", this.alcPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          /* this.$notify({
            title: "成功",
            message: "分配成功！",
            type: "success",
          });*/
          const noti = this.$vs.notification({
            duration: 3000,
            position: "top-right",
            progress: "auto",
            title: "成功",
            text: `分配成功！`,
          });
          this.query();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openSltClient() {
      this.showSltClient = true;
    },
    openAlcClient() {
      this.showAlcClient = true;
    },
    getAa10(code, value) {
      let list = JSON.parse(localStorage.getItem(code));
      let res = value;
      list.forEach((item) => {
        if (item.aaa102 == value) {
          res = item.aaa103;
        }
      });
      return res;
    },
  },
  mounted() {
    this.queryOrgData();
    var that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };
  },
};
</script>

<style>
.item-label {
  line-height: 30px;
  margin: 0 15px 0 15px;
}

.el-checkbox {
  font-weight: 600;
  color: #000;
}

.cbb {
  border-bottom: 2px solid #409eff;
}

.input-with-select {
  width: 300px;
}

.input-with-select .el-input-group__prepend {
  background-color: #409eff;
  color: #fff;
}

.input-with-select .el-input-group__append {
  background-color: #409eff;
  color: #fff;
}

.content-box {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
  grid-auto-rows: 200px;
  grid-gap: 2rem;
  /**padding-left: 2%;**/
  padding-right: 2%;
  margin-top: 0px;
  background: #fff;
  color: #444;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 17px 0 hsl(0deg 0% 91% / 50%);
  padding: 12px;
  user-select: none;
}

.grid-name {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
}

.el-des {
  margin-top: 10px;
}

.el-des .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding: bottom 5px !important;
}

/******* import *******/
.btn {
  border: none;
  background-color: #2ba8fb;
  color: white;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: translate(1) translate(0, 0);
  transition: transform 225ms, box-shadow 225ms;
}

.btn:hover {
  transform: scale(1.05) translate(0, -0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35);
}

.btn:active {
  transform: scale(1) translate(0, 0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.content-box::-webkit-scrollbar {
  width: 4px;
}
.content-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #6fbaed;
}
.content-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #dae6ee;
}
</style>