<template>
  <x-page style="width: 92%; left: 4%; position: relative">
    <el-form
      :model="prms"
      :inline="true"
      style="text-align: center"
      size="medium"
    >
      <el-form-item>
        <el-input
          style="width: 400px; height: 30px"
          v-model="prms.keyword"
          placeholder="请输入搜索内容"
          clearable
          @change="search"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          icon="el-icon-search"
          class="btn"
          @click="search"
          round
        >
          查一查
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="btn"
          @click="addClient"
          round
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <div class="m-nav">
      <div class="group">
        <div class="row row-first">
          <div class="head">
            <h4>
              <span class="title" title="风格">客户等级</span>
              ：
            </h4>
          </div>
          <div class="body">
            <div class="items">
              <a
                :class="prms.clientLvl == '1' ? itemClassActive : itemClass"
                title="普通客户"
              >
                <span class="text" @click="pointClientLvl('1')">普通客户</span>
              </a>
              <a
                :class="prms.clientLvl == '2' ? itemClassActive : itemClass"
                title="已签约客户"
              >
                <span class="text" @click="pointClientLvl('2')"
                  >已签约客户</span
                >
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="head">
            <h4>
              <span class="title" title="预算范围">预算范围</span>
              ：
            </h4>
          </div>
          <div class="body">
            <div class="items">
              <a
                :class="prms.budgetLow == '0' ? itemClassActive : itemClass"
                title="50万以下"
              >
                <span class="text" @click="pointBudget('0', '50')"
                  >50万以下</span
                >
              </a>
              <a
                :class="prms.budgetLow == '50' ? itemClassActive : itemClass"
                title="50-100万"
              >
                <span class="text" @click="pointBudget('50', '100')"
                  >50-100万</span
                >
              </a>
              <a
                :class="prms.budgetLow == '100' ? itemClassActive : itemClass"
                title="100-500万"
              >
                <span class="text" @click="pointBudget('100', '500')"
                  >100-500万</span
                >
              </a>
              <a
                :class="prms.budgetLow == '500' ? itemClassActive : itemClass"
                title="500万以上"
              >
                <span class="text" @click="pointBudget('500', '-1')"
                  >500万以上</span
                >
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="head">
            <h4>
              <span class="title" title="面积范围">面积范围</span>
              ：
            </h4>
          </div>
          <div class="body">
            <div class="items">
              <a
                :class="prms.areaLow == '0' ? itemClassActive : itemClass"
                title="50m²以下"
              >
                <span class="text" @click="pointArea('0', '50')">50m²以下</span>
              </a>
              <a
                :class="prms.areaLow == '50' ? itemClassActive : itemClass"
                title="50-100m²"
              >
                <span class="text" @click="pointArea('50', '100')"
                  >50-100m²</span
                >
              </a>
              <a
                :class="prms.areaLow == '100' ? itemClassActive : itemClass"
                title="100-150m²"
              >
                <span class="text" @click="pointArea('100', '150')"
                  >100-150m²</span
                >
              </a>
              <a
                :class="prms.areaLow == '150' ? itemClassActive : itemClass"
                title="150m²以上"
              >
                <span class="text" @click="pointArea('150', '-1')"
                  >150m²以上</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-sortbar">
      <div class="sort-row">
        <div class="sort-inner">
          <ul class="sorts">
            <li class="sort" @click="pointSort('')">
              <a :class="prms.sortColumn == '' ? sortClassActive : sortClass">{{
                "综合排序"
              }}</a>
            </li>
            <li class="sort" @click="pointSort('clientLvl')">
              <a
                :class="
                  prms.sortColumn == 'clientLvl' ? sortClassActive : sortClass
                "
                >{{
                  prms.sortColumn == "clientLvl"
                    ? "客户等级由低到高"
                    : "客户等级"
                }}</a
              >
            </li>
            <li class="sort" @click="pointSort('createDate')">
              <a
                :class="
                  prms.sortColumn == 'createDate' ? sortClassActive : sortClass
                "
                >{{
                  prms.sortColumn == "createDate"
                    ? "创建时间由晚到早"
                    : "创建时间"
                }}</a
              >
            </li>
            <li class="sort" @click="pointSort('recordDate')">
              <a
                :class="
                  prms.sortColumn == 'recordDate' ? sortClassActive : sortClass
                "
                >{{
                  prms.sortColumn == "recordDate"
                    ? "跟进时间由晚到早"
                    : "跟进时间"
                }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div :style="{ height: windowHeight - 340 + 'px' }" class="content-view">
      <div
        class="m-itemlist-item"
        v-for="(cinfo, index) in clist && clist.length > 0 ? clist : []"
        v-bind:key="cinfo.clientId"
        :name="index"
      >
        <div>
          <div class="block-title"></div>
          <span style="font-weight: 600; width: 93%; float: left">{{
            cinfo.clientName
          }}</span>
          <el-button
            type="primary"
            @keyup.enter.stop.native="view(cinfo)"
            @keyup.space.stop.native
            @click.stop.native
            @click.stop.prevent="view(cinfo)"
            style="float: left"
            round
          >
            详情
          </el-button>
        </div>
        <div>
          <el-descriptions :column="6">
            <el-descriptions-item label="客户编号">
              <el-tag
                v-if="
                  cinfo.clientId.indexOf(prms.keyword) >= 0 &&
                  prms.keyword != ''
                "
                type="danger"
                effect="dark"
                >{{ cinfo.clientId }}</el-tag
              >
              <span v-else>{{ cinfo.clientId }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="客户姓名">
              <el-tag
                v-if="
                  cinfo.clientName.indexOf(prms.keyword) >= 0 &&
                  prms.keyword != ''
                "
                type="danger"
                effect="dark"
                >{{ cinfo.clientName }}</el-tag
              >
              <span v-else>{{ cinfo.clientName }}</span></el-descriptions-item
            >
            <el-descriptions-item label="性别">{{
              getAa10("GEND", cinfo.gend)
            }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">
              <el-tag
                size="small"
                :effect="
                  (cinfo.tel
                    ? cinfo.tel
                    : cinfo.wechat
                    ? cinfo.wechat
                    : cinfo.tiktok
                  ).indexOf(prms.keyword) >= 0 && prms.keyword != ''
                    ? 'dark'
                    : 'plain'
                "
                :type="
                  (cinfo.tel
                    ? cinfo.tel
                    : cinfo.wechat
                    ? cinfo.wechat
                    : cinfo.tiktok
                  ).indexOf(prms.keyword) >= 0 && prms.keyword != ''
                    ? 'danger'
                    : ''
                "
                >{{
                  cinfo.tel
                    ? cinfo.tel
                    : cinfo.wechat
                    ? cinfo.wechat
                    : cinfo.tiktok
                }}</el-tag
              >
            </el-descriptions-item>
            <el-descriptions-item label="客户等级">{{
              getAa10("CLIENT_LVL", cinfo.clientLvl)
            }}</el-descriptions-item>
            <el-descriptions-item label="客户状态">{{
              getAa10("CLIENT_STAS", cinfo.status)
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="3">
            <el-descriptions-item label="预算范围(万元)">
              <el-tag v-if="cinfo.budgetLow" effect="plain" size="small"
                >{{ cinfo.budgetLow }}
              </el-tag>
              &nbsp;&nbsp;-
              <el-tag v-if="cinfo.budgetHigh" effect="plain" size="small">
                {{ cinfo.budgetHigh }}</el-tag
              ></el-descriptions-item
            >
            <el-descriptions-item label="面积范围(m²)">
              <el-tag v-if="cinfo.areaLow" effect="plain" size="small"
                >{{ cinfo.areaLow }}
              </el-tag>
              &nbsp;&nbsp;-
              <el-tag v-if="cinfo.areaHigh" effect="plain" size="small">
                {{ cinfo.areaHigh }}</el-tag
              ></el-descriptions-item
            >
            <el-descriptions-item label="偏好/意向">
              <el-tag
                v-for="(int, index) in cinfo.intList"
                :key="index"
                :type="
                  getAa10('HOUSE_FORM', int.content).indexOf(prms.keyword) >=
                    0 && prms.keyword != ''
                    ? 'danger'
                    : ''
                "
                :effect="
                  getAa10('HOUSE_FORM', int.content).indexOf(prms.keyword) >=
                    0 && prms.keyword != ''
                    ? 'dark'
                    : 'plain'
                "
                >{{ getAa10("HOUSE_FORM", int.content) }}</el-tag
              >
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
    <div style="text-align: right; margin-top: 10px">
      <!--<el-pagination
        @size-change="query"
        @current-change="query"
        :current-page.sync="prms.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size.sync="prms.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="prms.total"
      ></el-pagination>-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="prms.page"
        :page-sizes="[10, 15, 20, 30, 40]"
        :page-size="prms.size"
        :total="prms.total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>

    <!--<div style="text-align: right">
      <el-pagination
        @size-change="query"
        @current-change="query"
        :current-page.sync="prms.page"
        :page-sizes="[15, 30, 45, 60]"
        :page-size.sync="prms.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="prms.total"
      ></el-pagination>
    </div>-->

    <el-dialog
      title="客户基本信息"
      :visible.sync="baseInfoShow"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="50px"
      :center="true"
    >
      <el-form
        :model="client"
        :rules="rules"
        label-width="120px"
        style="width: 100%"
        ref="clientForm"
      >
        <el-card shadow="hover">
          <el-row>
            <el-col :span="6">
              <el-form-item label="客户编号" prop="clientId">
                <el-input
                  v-model="client.clientId"
                  disabled
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称" prop="clientName">
                <el-input v-model="client.clientName" style="width: 100%">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="性别" prop="gend">
                <XSelect
                  v-model="client.gend"
                  style="width: 100%"
                  code="GEND"
                  clearable
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="年龄" prop="age">
                <el-input v-model="client.age" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="来源/途径" prop="source">
                <XSelect
                  v-model="client.source"
                  style="width: 100%"
                  code="CLIENT_SOURCE"
                  clearable
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="意向等级" prop="intLvl">
                <XSelect
                  v-model="client.intLvl"
                  style="width: 100%"
                  code="INT_LVL"
                  clearable
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户状态" prop="status">
                <XSelect
                  v-model="client.status"
                  style="width: 100%"
                  code="CLIENT_STAS"
                  clearable
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户等级" prop="clientLvl">
                <XSelect
                  v-model="client.clientLvl"
                  style="width: 100%"
                  code="CLIENT_LVL"
                  clearable
                ></XSelect>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="tel">
                <el-input v-model="client.tel" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="微信号" prop="wechat">
                <el-input
                  v-model="client.wechat"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="抖音号" prop="tiktok">
                <el-input
                  v-model="client.tiktok"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="6">
              <el-form-item label="当前进展" prop="progress">
                <XSelect
                  v-model="client.progress"
                  style="width: 100%"
                  code="PROGRESS"
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户行业" prop="clientIndu">
                <el-input
                  v-model="client.clientIndu"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="客户身份" prop="clientIdet">
                <el-input
                  v-model="client.clientIdet"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="付款方式" prop="payType">
                <XSelect
                  v-model="client.payType"
                  style="width: 100%"
                  code="PAY_TYPE"
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="房屋户型" prop="houseForm">
                <XSelect
                  v-model="client.houseForm"
                  collapse-tags
                  :multiple="true"
                  :collapseTags="true"
                  style="width: 100%"
                  code="HOUSE_FORM"
                ></XSelect>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="预算范围(万元)">
                <el-col :span="10">
                  <el-input-number
                    v-model="client.budgetLow"
                    :step="10"
                    :min="0"
                    style="width: 100%"
                  ></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">至</el-col>
                <el-col :span="10">
                  <el-input-number
                    v-model="client.budgetHigh"
                    :step="10"
                    :min="0"
                    style="width: 100%"
                  ></el-input-number>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="面积范围(m²)">
                <el-col :span="10">
                  <el-input-number
                    v-model="client.areaLow"
                    :step="10"
                    :min="0"
                    style="width: 100%"
                  ></el-input-number>
                </el-col>
                <el-col :span="4" style="text-align: center">至</el-col>
                <el-col :span="10">
                  <el-input-number
                    v-model="client.areaHigh"
                    :step="10"
                    :min="0"
                    style="width: 100%"
                  ></el-input-number>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="客户特征描述" prop="description">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入客户特征描述"
                  style="width: 100%"
                  v-model="client.description"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card shadow="hover" style="margin-top: 10px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="客户意向">
                <!--  <el-button type="success" icon="el-icon-plus" @click="openInt">
                  新增
                </el-button>
                <el-button
                  type="danger"
                  icon="el-icon-close"
                  @click="addClient"
                >
                  作废
                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <template slot="label">
                  <i
                    class="el-icon-place"
                    style="font-weight: 500; margin-right: 5px"
                  ></i
                  >区域
                </template>
                <Itag :tags="intAreaList"></Itag>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <template slot="label">
                  <i
                    class="el-icon-guide"
                    style="font-weight: 500; margin-right: 5px"
                  ></i
                  >板块
                </template>
                <Itag :tags="intBlockList"></Itag>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="">
                <template slot="label">
                  <i
                    class="el-icon-school"
                    style="font-weight: 500; margin-right: 5px"
                  ></i
                  >学区
                </template>
                <Itag :tags="intSchoolList"></Itag>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="">
                <template slot="label">
                  <i
                    class="el-icon-truck"
                    style="font-weight: 500; margin-right: 5px"
                  ></i
                  >交通
                </template>
                <Itag :tags="intTrafficList"></Itag>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-dialog
          width="30%"
          title="新增客户意向"
          :visible.sync="innerVisible"
          append-to-body
        >
          <el-form
            :model="intInfo"
            :inline="true"
            :rules="intRules"
            style="text-align: center"
            ref="intForm"
          >
            <el-form-item label="意向类型" prop="intType">
              <XSelect
                v-model="intInfo.intType"
                style="width: 100%"
                code="INT_TYPE"
              ></XSelect>
            </el-form-item>
            <el-form-item label="意向内容" prop="content">
              <el-input
                style="width: 100%"
                v-model="intInfo.content"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button
                type="success"
                icon="el-icon-plus"
                @click="addInt"
                round
              >
                保存
              </el-button>
            </el-form-item>
          </el-form>
        </el-dialog>

        <el-card shadow="hover" style="margin-top: 10px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="首次接待描述" prop="firstReception">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入首次接待描述"
                  style="width: 100%"
                  v-model="client.firstReception"
                  :disabled="editFirst"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-form-item
          label=""
          style="text-align: right; margin-left: 30px; margin-top: 10px"
        >
          <el-button
            type="primary"
            icon="el-icon-check"
            @click="saveClient"
            round
          >
            保存
          </el-button>
          <el-button icon="el-icon-close" @click="closeClient" round>
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-drawer
      title="客户信息详情页"
      :visible.sync="drawerShow"
      direction="rtl"
      size="90%"
      style="height: 100%; overflow: auto"
    >
      <el-row>
        <el-col :span="16">
          <div>
            <el-row>
              <el-col :span="8">
                <span style="margin-left: 25px">
                  <el-tag size="small" style="margin-right: 20px"
                    >客户编号</el-tag
                  >
                  {{ cinfo.clientId }}</span
                >
              </el-col>
              <el-col :span="8">
                <span style="margin-left: 70px">
                  <el-tag size="small" style="margin-right: 20px"
                    >建档人</el-tag
                  >
                  {{ cinfo.createOperator }}</span
                >
              </el-col>
              <el-col :span="8">
                <span style="margin-left: 25px">
                  <el-tag size="small" style="margin-right: 20px"
                    >建档日期</el-tag
                  >
                  {{
                    cinfo.createDate ? cinfo.createDate.substr(0, 10) : ""
                  }}</span
                >
                <el-button
                  style="margin: -5px 25px 0 25px; float: right"
                  type="primary"
                  @click="editClient"
                  round
                >
                  修改
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div :style="{ height: windowHeight - 60 + 'px' }" class="drawScroll">
            <el-card style="margin: 10px">
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <el-tag size="small"> <span>基本信息</span></el-tag>
              </div>
              <div style="float: left; width: 90%">
                <el-descriptions :column="3">
                  <el-descriptions-item label="客户姓名"
                    ><span style="font-weight: 600">{{
                      cinfo.clientName
                    }}</span></el-descriptions-item
                  >
                  <el-descriptions-item label="性别">{{
                    getAa10("GEND", cinfo.gend)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="来源/途径">{{
                    getAa10("CLIENT_SOURCE", cinfo.source)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="客户等级">{{
                    getAa10("CLIENT_LVL", cinfo.clientLvl)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="客户状态">{{
                    getAa10("CLIENT_STAS", cinfo.status)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="当前进展">{{
                    getAa10("PROGRESS", cinfo.progress)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="客户行业">{{
                    cinfo.clientIndu
                  }}</el-descriptions-item>
                  <el-descriptions-item label="客户身份">{{
                    cinfo.clientIdet
                  }}</el-descriptions-item>
                  <el-descriptions-item label="付款方式">{{
                    getAa10("PAY_TYPE", cinfo.payType)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="预算范围">{{
                    cinfo.budgetLow + "-" + cinfo.budgetHigh + "万元"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="面积范围">{{
                    cinfo.areaLow
                      ? cinfo.areaLow + "-" + cinfo.areaHigh + "m²"
                      : ""
                  }}</el-descriptions-item>
                  <el-descriptions-item label="房屋户型">{{
                    cinfo.houseStr
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>

            <el-card style="margin: 10px">
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <el-tag size="small"> <span>客户特征</span></el-tag>
              </div>
              <div style="float: left; width: 90%">
                <el-descriptions :column="3" class="noColon">
                  <el-descriptions-item :label="cinfo.description">
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>

            <el-card style="margin: 10px">
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <el-tag size="small"> <span>联系方式</span></el-tag>
              </div>
              <div style="float: left; width: 90%">
                <el-descriptions :column="3">
                  <el-descriptions-item label="联系电话"
                    >{{ cinfo.tel }}
                  </el-descriptions-item>
                  <!--<el-descriptions-item v-if="cinfo.wechat" label="微信号"
                    >{{ cinfo.wechat }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="cinfo.tiktok" label="抖音号"
                    >{{ cinfo.tiktok }}
                  </el-descriptions-item>-->
                </el-descriptions>
              </div>
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <el-tag size="small"> <span>微信账号</span></el-tag>
              </div>
              <div style="float: left; width: 90%">
                <el-descriptions :column="3">
                  <el-descriptions-item label="微信状态">
                    <el-tag
                      size="small"
                      v-if="
                        cinfo.unionid == null ||
                        cinfo.unionid == 'null' ||
                        cinfo.unionid == ''
                      "
                      effect="plain"
                      type="info"
                      style="margin-right: 20px"
                      >未绑定</el-tag
                    >
                    <el-tag
                      size="small"
                      v-else
                      effect="dark"
                      type="success"
                      style="margin-right: 20px"
                      >已绑定</el-tag
                    >

                    <span
                      v-if="
                        cinfo.unionid == null ||
                        cinfo.unionid == 'null' ||
                        cinfo.unionid == ''
                      "
                    >
                      <el-popover
                        width="800"
                        placement="bottom"
                        trigger="click"
                        v-model="visibleS"
                      >
                        <el-form
                          :model="wechatPrms"
                          :inline="true"
                          label-width="120px"
                        >
                          <el-form-item label="姓名/昵称">
                            <el-input
                              style="width: 100%"
                              v-model="wechatPrms.name"
                              clearable
                            ></el-input>
                          </el-form-item>
                          <el-form-item label="联系方式">
                            <el-input
                              style="width: 100%"
                              v-model="wechatPrms.tel"
                              clearable
                            ></el-input>
                          </el-form-item>
                          <el-form-item label="">
                            <el-button
                              type="primary"
                              icon="el-icon-search"
                              @click="searchWechat"
                              round
                            >
                              查询
                            </el-button>
                          </el-form-item>
                        </el-form>
                        <el-table
                          :data="wechatList"
                          stripe
                          ref="wechatTable"
                          :header-cell-style="{ textAlign: 'center' }"
                          :cell-style="{ textAlign: 'center' }"
                          max-height="550"
                        >
                          <el-table-column
                            label="序号"
                            width="50"
                            type="index"
                          ></el-table-column>
                          <el-table-column prop="nickName" label="微信昵称">
                          </el-table-column>
                          <el-table-column prop="realName" label="真实姓名">
                          </el-table-column>
                          <el-table-column prop="mobile" label="联系电话">
                          </el-table-column>
                          <el-table-column
                            fixed="right"
                            label="操作"
                            width="100"
                          >
                            <template slot-scope="scope">
                              <!-- <el-button
                                type="success"
                                icon="el-icon-check"
                                @click="selectWechatUser(scope.row)"
                                circle
                              ></el-button>-->
                              <el-button
                                type="text"
                                @click="selectWechatUser(scope.row)"
                                >绑定</el-button
                              >
                            </template>
                          </el-table-column>
                        </el-table>
                        <div style="text-align: right">
                          <el-pagination
                            @size-change="queryWechat"
                            @current-change="queryWechat"
                            :current-page.sync="wechatPrms.page"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size.sync="wechatPrms.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="wechatPrms.total"
                          ></el-pagination>
                        </div>
                        <el-button slot="reference" round type="primary"
                          >绑定</el-button
                        >
                      </el-popover></span
                    >
                    <el-popover
                      v-else
                      width="200"
                      placement="bottom"
                      trigger="click"
                      v-model="visibleC"
                    >
                      <p>确定解除微信绑定信息吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button
                          size="mini"
                          type="text"
                          @click="visibleC = false"
                          >取消</el-button
                        >
                        <el-button type="primary" size="mini" @click="unbind"
                          >确定</el-button
                        >
                      </div>
                      <el-button slot="reference" type="danger" round
                        >解绑</el-button
                      >
                    </el-popover>
                  </el-descriptions-item>
                  <el-descriptions-item v-if="cinfo.wechat" label="微信号"
                    >{{ cinfo.wechat }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>

            <el-card style="margin: 10px">
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <el-tag size="small"> <span>客户意向</span></el-tag>
              </div>
              <div
                style="float: left; width: 90%; position: relative; top: -1px"
              >
                <el-descriptions :column="1">
                  <el-descriptions-item>
                    <template slot="label">
                      <i
                        class="el-icon-place"
                        style="font-weight: 500; margin-right: 5px"
                      ></i
                      >区域
                    </template>
                    <el-tag
                      v-for="tag in intAreaList"
                      :key="tag"
                      effect="plain"
                    >
                      {{ tag }}</el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i
                        class="el-icon-guide"
                        style="font-weight: 500; margin-right: 5px"
                      ></i
                      >板块
                    </template>
                    <el-tag
                      v-for="tag in intBlockList"
                      :key="tag"
                      effect="plain"
                    >
                      {{ tag }}</el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i
                        class="el-icon-school"
                        style="font-weight: 500; margin-right: 5px"
                      ></i
                      >学区
                    </template>
                    <el-tag
                      v-for="tag in intSchoolList"
                      :key="tag"
                      effect="plain"
                    >
                      {{ tag }}</el-tag
                    >
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i
                        class="el-icon-truck"
                        style="font-weight: 500; margin-right: 5px"
                      ></i
                      >交通
                    </template>
                    <el-tag
                      v-for="tag in intTrafficList"
                      :key="tag"
                      effect="plain"
                    >
                      {{ tag }}</el-tag
                    >
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>

            <el-card style="margin: 10px; padding-bottom: 5px">
              <div
                style="
                  float: left;
                  width: 10%;
                  display: table-cell;
                  vertical-align: middle;
                  line-height: 30px;
                "
              >
                <el-tag size="small">
                  <span> 跟进记录</span>
                </el-tag>
                <!--<div style="margin-top: 15px">
                  <el-tag
                    @click="addRecord"
                    effect="plain"
                    type="success"
                    class="tagSuccessButton"
                  >
                    新增
                  </el-tag>
                  <el-tag
                    @click="delRecord"
                    effect="plain"
                    type="danger"
                    class="tagDangerButton"
                    >作废
                  </el-tag>
                </div>-->
              </div>

              <div
                style="
                  float: right;
                  margin-bottom: 10px;
                  margin-top: -5px;
                  line-height: 30px;
                "
              >
                <el-button
                  style="margin: 0 0 0 10px; float: right"
                  type="danger"
                  @click="delRecord"
                  :disabled="
                    !(recordList.length > 0 && cinfo.ownerOptId == userId)
                  "
                  round
                >
                  作废
                </el-button>
                <el-button
                  style="margin: 0 0 0 10px; float: right"
                  type="primary"
                  :disabled="
                    !(recordList.length > 0 && cinfo.ownerOptId == userId)
                  "
                  @click="editRecord"
                  round
                >
                  修改
                </el-button>
                <el-button
                  style="margin: 0 0 0 10px; float: right"
                  type="success"
                  @click="addRecord"
                  round
                >
                  新增
                </el-button>

                <!--      <el-tag
                  @click="addRecord"
                  effect="plain"
                  type="success"
                  class="tagSuccessButton"
                >
                  新增
                </el-tag>
                <el-tag
                  @click="delRecord"
                  effect="plain"
                  type="danger"
                  class="tagDangerButton"
                  >作废
                </el-tag>-->
              </div>
              <div style="float: right; width: 90%">
                <el-table
                  :data="recordList"
                  stripe
                  ref="recordTable"
                  @selection-change="handleSelectionChange"
                  @row-click="clickRow"
                  :header-cell-style="{ textAlign: 'center' }"
                  :cell-style="{ textAlign: 'center' }"
                  max-height="550"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column label="次数" width="50" type="index">
                    <template slot-scope="scope">
                      <el-tag
                        v-if="scope.row.isFirst == '1'"
                        type="danger"
                        effect="dark"
                      >
                        首次
                      </el-tag>
                      <span v-else>{{ scope.$index + 1 }} </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="recordDate" label="日期" width="100">
                  </el-table-column>
                  <el-table-column prop="operator" label="对接人" width="100">
                  </el-table-column>
                  <el-table-column
                    prop="followType"
                    label="对接方式"
                    class-name="FOLLOW_TYPE"
                    width="100"
                    :formatter="tableColumnFomat"
                  >
                  </el-table-column>
                  <el-table-column prop="content" label="跟进记录">
                  </el-table-column>
                </el-table>
                <div style="text-align: right">
                  <el-pagination
                    @size-change="queryRecord"
                    @current-change="queryRecord"
                    :current-page.sync="recordPrms.page"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size.sync="recordPrms.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="recordPrms.total"
                  ></el-pagination>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8" style="padding-right: 10px">
          <!-- <div>
            <span style="margin-left: 15px">
              <el-tag size="small">匹配推荐</el-tag>
            </span>
            <el-button
              style="float: right; margin-right: 15px; margin-top: -5px"
              type="danger"
              @click="editClient"
            >
              作废
            </el-button>
            <el-button
              style="float: right; margin-right: 15px; margin-top: -5px"
              type="success"
              @click="editClient"
            >
              房源匹配
            </el-button>
          </div>-->

          <el-tabs
            v-model="houseActive"
            style="margin-top: -10px"
            class="houseTabs"
          >
            <el-tab-pane name="taste">
              <span slot="label">
                <el-tag size="small">感兴趣</el-tag>
              </span>
              <div>
                <div
                  :style="{ height: windowHeight - 158 + 'px' }"
                  class="drawScroll"
                >
                  <ul
                    class="infinite-list"
                    v-infinite-scroll="loadTaste"
                    style="overflow: auto"
                    infinite-scroll-distance="10"
                    infinite-scroll-delay="500"
                  >
                    <li
                      v-for="(house, index) in houseTasteList"
                      :key="index"
                      class="infinite-list-item"
                    >
                      <el-card class="m-itemlist-item">
                        <!-- <div
                          style="
                            float: left;
                            margin-left: 10px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions :column="1">
                            <el-descriptions-item label="名称">{{
                              house.title
                            }}</el-descriptions-item>
                            <el-descriptions-item label="小区">{{
                              house.commName
                            }}</el-descriptions-item>
                          </el-descriptions>
                        </div>-->
                        <div style="float: left; width: 60px; height: 60px">
                          <el-image
                            :src="house.url"
                            style="
                              float: left;
                              width: 60px;
                              height: 60px;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                          </el-image>
                          <el-tag
                            @click="showHouseDetail(house.houseId)"
                            effect="plain"
                            type="primary"
                            style="margin: 10px 0 0 10px"
                            class="tagPrimaryButton"
                          >
                            详情
                          </el-tag>
                          <el-tag
                            effect="dark"
                            :type="house.flag == '9' ? 'danger' : 'warning'"
                            style="margin: 5px 0 0 10px"
                          >
                            {{ house.flag == "9" ? "推荐" : "自选" }}
                          </el-tag>
                        </div>
                        <div
                          style="
                            float: left;
                            margin: -5px 0 5px 10px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions
                            :column="1"
                            class="smallDescriptions"
                          >
                            <el-descriptions-item label="名称"
                              ><span style="font-weight: 600">{{
                                house.title
                              }}</span></el-descriptions-item
                            >
                            <el-descriptions-item label="小区">{{
                              house.commName
                            }}</el-descriptions-item>
                            <el-descriptions-item
                              label="标签"
                              contentStyle="line-height :30px "
                            >
                              <span
                                v-for="reg in house.region"
                                :key="reg"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  v-if="reg"
                                  :type="
                                    intAreaList.includes(reg) ? '' : 'info'
                                  "
                                  >{{ reg }}
                                </el-tag>
                              </span>
                              <span
                                v-for="pla in house.plate"
                                :key="pla"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  v-if="pla"
                                  :type="
                                    intBlockList.includes(pla) ? '' : 'info'
                                  "
                                  >{{ pla }}
                                </el-tag>
                              </span>
                              <span
                                v-for="sch in house.schDis"
                                :key="sch"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  :key="sch"
                                  :type="
                                    intSchoolList.includes(sch) ? '' : 'info'
                                  "
                                  >{{ sch }}
                                </el-tag>
                              </span>
                              <span
                                v-for="tra in house.traDis"
                                :key="tra"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  :key="tra"
                                  :type="
                                    intTrafficList.includes(tra) ? '' : 'info'
                                  "
                                  >{{ tra }}
                                </el-tag>
                              </span></el-descriptions-item
                            >
                            <el-descriptions-item
                              label="匹配度"
                              :labelStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                              :contentStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                            >
                              <el-rate
                                v-model="house.sort"
                                disabled
                                allow-half
                                :colors="colors"
                              >
                              </el-rate>
                            </el-descriptions-item>
                          </el-descriptions>
                          <el-descriptions :column="2">
                            <el-descriptions-item label="市场价"
                              >{{ house.budget }} 万元
                            </el-descriptions-item>

                            <el-descriptions-item label="起拍价"
                              >{{ house.startingPrice }} 万元
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </el-card>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="match">
              <span slot="label">
                <el-tag size="small">推荐</el-tag>
              </span>
              <div>
                <div
                  :style="{ height: windowHeight - 158 + 'px' }"
                  class="drawScroll"
                >
                  <ul
                    class="infinite-list"
                    v-infinite-scroll="loadMatch"
                    style="overflow: auto"
                    infinite-scroll-distance="10"
                  >
                    <li
                      v-for="(house, index) in houseMatchList"
                      :key="index"
                      class="infinite-list-item"
                    >
                      <el-card class="m-itemlist-item">
                        <!-- <div
                          style="
                            float: left;
                            margin-left: 10px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions :column="1">
                            <el-descriptions-item label="名称">{{
                              house.title
                            }}</el-descriptions-item>
                            <el-descriptions-item label="小区">{{
                              house.commName
                            }}</el-descriptions-item>
                          </el-descriptions>
                        </div>-->
                        <div style="float: left; width: 60px; height: 60px">
                          <el-image
                            :src="house.url"
                            style="
                              float: left;
                              width: 60px;
                              height: 60px;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                          </el-image>
                          <el-tag
                            @click="showHouseDetail(house.houseId)"
                            effect="plain"
                            type="primary"
                            style="margin: 5px 0 0 10px"
                            class="tagPrimaryButton"
                          >
                            详情
                          </el-tag>
                          <el-tag
                            @click="sendRecord(house)"
                            effect="plain"
                            type="danger"
                            style="margin: 5px 0 0 10px"
                            class="tagPrimaryButton"
                          >
                            推荐
                          </el-tag>
                        </div>
                        <div
                          style="
                            float: left;
                            margin: -5px 0 5px 10px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions
                            :column="1"
                            class="smallDescriptions"
                          >
                            <el-descriptions-item label="名称"
                              ><span style="font-weight: 600">{{
                                house.title
                              }}</span></el-descriptions-item
                            >
                            <el-descriptions-item label="小区">{{
                              house.commName
                            }}</el-descriptions-item>
                            <el-descriptions-item
                              label="标签"
                              contentStyle="line-height :30px "
                            >
                              <span
                                v-for="reg in house.region"
                                :key="reg"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  v-if="reg"
                                  :type="
                                    intAreaList.includes(reg) ? '' : 'info'
                                  "
                                  >{{ reg }}
                                </el-tag>
                              </span>
                              <span
                                v-for="pla in house.plate"
                                :key="pla"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  v-if="pla"
                                  :type="
                                    intBlockList.includes(pla) ? '' : 'info'
                                  "
                                  >{{ pla }}
                                </el-tag>
                              </span>
                              <span
                                v-for="sch in house.schDis"
                                :key="sch"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  :key="sch"
                                  :type="
                                    intSchoolList.includes(sch) ? '' : 'info'
                                  "
                                  >{{ sch }}
                                </el-tag>
                              </span>
                              <span
                                v-for="tra in house.traDis"
                                :key="tra"
                                style="margin-left: 5px"
                              >
                                <el-tag
                                  effect="dark"
                                  size="small"
                                  :key="tra"
                                  :type="
                                    intTrafficList.includes(tra) ? '' : 'info'
                                  "
                                  >{{ tra }}
                                </el-tag>
                              </span></el-descriptions-item
                            >
                            <el-descriptions-item
                              label="匹配度"
                              :labelStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                              :contentStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                            >
                              <el-rate
                                v-model="house.sort"
                                disabled
                                allow-half
                                :colors="colors"
                              >
                              </el-rate>
                            </el-descriptions-item>
                          </el-descriptions>
                          <el-descriptions :column="2">
                            <el-descriptions-item label="市场价"
                              >{{ house.budget }} 万元
                            </el-descriptions-item>

                            <el-descriptions-item label="起拍价"
                              >{{ house.startingPrice }} 万元
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </el-card>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane>
              <span slot="label">
                <el-tag size="small">人工推荐</el-tag>
              </span>
              <div>
                <el-button
                  type="primary"
                  style="float: right; margin-top: -30px"
                  round
                >
                  详情
                </el-button>
                <div
                  :style="{ height: windowHeight - 158 + 'px' }"
                  class="drawScroll"
                >
                  <ul
                    class="infinite-list"
                    v-infinite-scroll="loadSuggest"
                    style="overflow: auto"
                    infinite-scroll-distance="10"
                  >
                    <li
                      v-for="(house, index) in houseSuggestList"
                      :key="index"
                      class="infinite-list-item"
                    >
                      <el-card> 
                        <div style="float: left; width: 60px; height: 60px">
                          <el-image
                            :src="house.url"
                            style="
                              float: left;
                              width: 60px;
                              height: 60px;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                          </el-image>
                          <el-tag
                            @click="addRecord"
                            effect="plain"
                            type="primary"
                            style="margin: 5px 0 0 10px"
                            class="tagPrimaryButton"
                          >
                            详情
                          </el-tag>
                          <el-tag
                            @click="sendRecord"
                            effect="plain"
                            type="danger"
                            style="margin: 5px 0 0 10px"
                            class="tagPrimaryButton"
                          >
                            推荐
                          </el-tag>
                        </div>
                        <div
                          style="
                            float: left;
                            margin: -5px 0 5px 10px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions
                            :column="1"
                            class="smallDescriptions"
                          >
                            <el-descriptions-item label="名称">{{
                              house.title
                            }}</el-descriptions-item>
                            <el-descriptions-item label="小区">{{
                              house.commName
                            }}</el-descriptions-item>
                            <el-descriptions-item label="匹配度">
                              <el-tag
                                effect="dark"
                                size="small"
                                v-if="house.region"
                                :type="
                                  intAreaList.includes(house.region)
                                    ? 'danger'
                                    : ''
                                "
                                >{{ house.region }}
                              </el-tag>
                              <el-tag
                                effect="dark"
                                size="small"
                                v-if="house.plate"
                                :type="
                                  intBlockList.includes(house.plate)
                                    ? 'danger'
                                    : ''
                                "
                                >{{ house.plate }}
                              </el-tag>
                              <el-tag
                                effect="dark"
                                size="small"
                                v-if="house.schDis"
                                :type="
                                  intSchoolList.includes(house.schDis)
                                    ? 'danger'
                                    : ''
                                "
                                >{{ house.schDis }}
                              </el-tag></el-descriptions-item
                            >
                            <el-descriptions-item label="市场价"
                              >{{ house.budget }} 万元
                            </el-descriptions-item>
                            <el-descriptions-item label="起拍价"
                              >{{ house.startingPrice }} 万元
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </el-card>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>-->
            <!-- <el-tab-pane>
              <span slot="label">
                <el-tag size="small">查询房源</el-tag>
              </span>
              <div><IHCard></IHCard></div>
            </el-tab-pane>-->
          </el-tabs>
        </el-col>
      </el-row>
    </el-drawer>

    <el-dialog width="50%" title="客户跟进记录" :visible.sync="recordShow">
      <el-form
        :model="record"
        :rules="recordRules"
        style="width: 100%"
        label-width="120px"
        ref="recordForm"
      >
        <el-form
          :model="record"
          :rules="recordRules"
          style="width: 100%"
          label-width="120px"
          ref="recordForm"
          :inline="true"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="日期" prop="recordDate">
                <el-input
                  style="width: 100%"
                  v-model="record.recordDate"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="跟进方式" prop="followType">
                <XSelect
                  v-model="record.followType"
                  style="width: 100%"
                  code="FOLLOW_TYPE"
                ></XSelect>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="对接人" prop="operator">
                <el-input
                  style="width: 100%"
                  v-model="record.operator"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="当前进展" prop="process">
                <XSelect
                  v-model="record.process"
                  style="width: 100%"
                  code="PROGRESS"
                ></XSelect> </el-form-item></el-col
          ></el-row>
        </el-form>

        <el-form-item label="跟进记录描述" prop="content" style="width: 100%">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入跟进记录描述"
            style="width: 100%"
            v-model="record.content"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          style="text-align: right; margin-left: 30px; margin-top: 10px"
        >
          <el-button
            type="primary"
            icon="el-icon-check"
            @click="saveRecord"
            round
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--<el-dialog width="50%" title="客户跟进记录" :visible.sync="recordShow">
      <IHCard :selectHouseRow="selectHouseRow"></IHCard>
    </el-dialog>-->
    <el-dialog
      top="50px"
      width="70%"
      title="房源详情页"
      :visible.sync="houseShow"
    >
      <HouseDet :houseId="houseId" ref="HouseDet"></HouseDet>
    </el-dialog>
  </x-page>
</template>

<script>
import IClient from "@/components/IClient.vue";
import HouseDet from "@/views/baseinfo/houseDet.vue";
export default {
  components: { IClient, HouseDet },
  name: "Clients",
  data() {
    return {
      loading: false,
      prms: {
        keyword: "", //搜索值
        clientLvl: "",
        budgetLow: "",
        budgetHigh: "",
        areaLow: "",
        areaHigh: "",
        sortColumn: "",
        page: 1,
        size: 10,
        total: 0,
      },
      pagesize: 5, //默认分页每页数据量
      currentPage: 1, //默认展示第一页数据

      filterPrms: {
        clientLvl: "",
        intArea: "",
        intSchool: "",
        intSection: "",
        budgetLow: 0,
        budgetHigh: 0,
        areaLow: 0,
        areaHigh: 0,
      },
      baseInfoShow: false,
      innerVisible: false,
      client: {
        clientId: "",
        clientName: "",
        gend: "",
        age: "",
        source: "",
        description: "",
        status: "",
        intLvl: "",
        clientLvl: "",
        tel: "",
        wechat: "",
        tiktok: "",
        progress: "",
        budgetLow: "",
        budgetHigh: "",
        areatLow: "",
        areaHigh: "",
        firstReception: "",
        payType: "",
        houseForm: [],
      },
      intInfo: {
        clientId: "",
        intId: "",
        intType: "",
        intName: "",
        content: "",
        valiFlag: "",
      },
      record: {
        clientId: "",
        recordId: "",
        recordDate: "",
        followType: "",
        operator: "",
        progress: "",
        content: "",
        valiFlag: "",
      },
      rules: {
        clientName: [
          { required: true, message: "姓名为必填项", trigger: "change" },
        ],
        source: [
          { required: true, message: "来源/途径为必填项", trigger: "change" },
        ],
        intLvl: [
          { required: true, message: "意向等级为必填项", trigger: "change" },
        ],
        status: [
          { required: true, message: "客户状态为必填项", trigger: "change" },
        ],
        clientLvl: [
          { required: true, message: "客户等级为必填项", trigger: "change" },
        ],
        progress: [
          { required: true, message: "当前进展为必填项", trigger: "change" },
        ],
        /* tel: [
          { required: true, message: "联系电话为必填项", trigger: "change" },
        ],*/
        description: [
          { required: true, message: "客户特征描述必填", trigger: "change" },
        ],
        firstReception: [
          { required: true, message: "首次接待描述必填", trigger: "change" },
        ],
      },
      intRules: {
        intType: [
          { required: true, message: "意向类型为必填项", trigger: "change" },
        ],
        content: [
          { required: true, message: "意向内容为必填项", trigger: "change" },
        ],
      },
      recordRules: {
        followType: [
          { required: true, message: "跟进方式为必填项", trigger: "change" },
        ],
        progress: [
          { required: true, message: "当前进展为必填项", trigger: "change" },
        ],
        content: [
          {
            required: true,
            message: "跟进记录描述为必填项",
            trigger: "change",
          },
        ],
      },
      activeNames: [],
      clientList: [],
      clist: [],
      intList: [],

      intAreaList: [],
      intBlockList: [],
      intSchoolList: [],
      intTrafficList: [],

      drawerShow: false,
      cinfo: {},
      editFirst: false,

      recordPrms: {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      },
      recordList: [],
      recordSelection: [],
      recordShow: false,
      flag: false,

      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度

      houseActive: "",
      housePrms: {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      },
      houseMatchList: [],
      isMore: true,

      tastePrms: {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      },
      houseTasteList: [],
      isTasteMore: true,

      /* suggestPrms: {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      },
      houseSuggestList: [],
      isSuggestMore: true,*/

      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],

      visibleS: false,
      visibleC: false,

      wechatPrms: {
        name: "",
        tel: "",
        page: 1,
        size: 5,
        total: 0,
      },
      wechatList: [],

      sortPrms: {
        column: "",
        order: "",
      },

      sortClassActive: "link active first",
      sortClass: "link",

      itemClassActive: "item icon-tag active",
      itemClass: "item icon-tag",

      houseId: "",
      houseShow: false,

      managerAuth: false,
      userId: "",
    };
  },
  methods: {
    calcSort(sort) {
      return Math.round((sort / 4) * 5, 1);
    },
    showHouseDetail(houseId) {
      this.houseId = houseId;
      this.houseShow = true;
      this.$nextTick(() => {
        this.$refs.HouseDet.queryHouseInfo(this.houseId);
      });
    },
    search() {
      this.currentPage = 1;
      this.prms.page = 1;
      this.query();
    },
    handleSizeChange: function (val) {
      //this.pagesize = val;
      this.prms.size = val;
      this.query();
    },
    handleCurrentChange: function (currentPage) {
      //this.currentPage = currentPage;
      this.prms.page = currentPage;
      this.query();
    },
    handleSelectionChange(val) {
      this.recordSelection = val;
    },
    clickRow(row, col, event) {
      row.flag = !row.flag;
      this.$refs.recordTable.toggleRowSelection(row, row.flag);
    },
    selectHouseRow() {},
    filterChange() {
      this.clist = this.clientList.filter((item) => {
        if (this.filterPrms.clientLvl != "") {
          if (item.clientLvl != this.filterPrms.clientLvl) {
            return false;
          }
        }
        if (this.filterPrms.intArea != "") {
          let index = 0;
          if (item.intList.length > 0) {
            item.intList.forEach((int) => {
              if (
                this.filterPrms.intArea.indexOf(int.content) >= 0 ||
                int.content.indexOf(this.filterPrms.intArea) >= 0
              ) {
                index = 1;
              }
            });
          }
          if (index == 0) {
            return false;
          }
        }
        if (this.filterPrms.intSchool != "") {
          let index = 0;
          if (item.intList.length > 0) {
            item.intList.forEach((int) => {
              if (
                this.filterPrms.intSchool.indexOf(int.content) >= 0 ||
                int.content.indexOf(this.filterPrms.intSchool) >= 0
              ) {
                index = 1;
              }
            });
          }
          if (index == 0) {
            return false;
          }
        }
        if (this.filterPrms.intSection != "") {
          let index = 0;
          if (item.intList.length > 0) {
            item.intList.forEach((int) => {
              if (
                this.filterPrms.intSection.indexOf(int.content) >= 0 ||
                int.content.indexOf(this.filterPrms.intSection) >= 0
              ) {
                index = 1;
              }
            });
          }
          if (index == 0) {
            return false;
          }
        }
        if (this.filterPrms.budgetLow != "") {
          if (item.budgetLow < this.filterPrms.budgetLow) {
            return false;
          }
        }
        if (this.filterPrms.budgetHigh != "") {
          if (item.budgetHigh > this.filterPrms.budgetHigh) {
            return false;
          }
        }
        return true;
      });
    },
    pointClientLvl(val) {
      if (this.prms.clientLvl == val) {
        this.prms.clientLvl = "";
      } else {
        this.prms.clientLvl = val;
      }
      this.query();
    },
    pointBudget(low, high) {
      if (this.prms.budgetLow == low) {
        this.prms.budgetLow = "";
        this.prms.budgetHigh = "";
      } else {
        this.prms.budgetLow = low;
        this.prms.budgetHigh = high;
      }
      this.query();
    },
    pointArea(low, high) {
      if (this.prms.areaLow == low) {
        this.prms.areaLow = "";
        this.prms.areaHigh = "";
      } else {
        this.prms.areaLow = low;
        this.prms.areaHigh = high;
      }
      this.query();
    },
    pointSort(val) {
      this.prms.sortColumn = val;
      this.query();
    },
    query() {
      this.activeNames = [];
      this.loading = true;
      console.log(this.prms);
      this.$http
        .post("/foreclosure/client/info/list", this.prms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.clientList = ret.data;
          this.prms.total = ret.total;
          let n = 0;
          this.clientList.forEach((item) => {
            this.activeNames.push(n);
            n++;
          });
          this.clist = this.clientList;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryRecord() {
      this.loading = true;
      this.$http
        .post("/foreclosure/client/record/list", this.recordPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.recordList = ret.data;
          this.recordPrms.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadMatch() {
      if (this.isMore) {
        this.housePrms.page = this.housePrms.page + 1;
        this.queryMatch();
      }
    },

    queryMatch() {
      this.loading = true;
      this.$http
        .post("/foreclosure/house/match/list", this.housePrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          if (ret.data.length == 0) {
            this.isMore = false;
          } else {
            //处理list
            ret.data.forEach((item) => {
              //字符串转数组
              let regionStr = item.region
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(regionStr)) {
                item.region = [];
              } else {
                item.region = regionStr.split(",").filter((v) => {
                  // v是变量 固定写法 newArr是需要过滤的数组
                  return (
                    Object.values(v).filter((i) => {
                      return i != null;
                    }).length === Object.values(v).length
                  );
                });
              }
              let plateStr = item.plate
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(plateStr)) {
                item.plate = [];
              } else {
                item.plate = plateStr.split(",");
              }
              let schDisStr = item.schDis
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(schDisStr)) {
                item.schDis = [];
              } else {
                item.schDis = schDisStr.split(",");
              }
              let traDisStr = item.traDis
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(traDisStr)) {
                item.traDis = [];
              } else {
                item.traDis = traDisStr.split(",");
              }
            });
            // if (this.houseMatchList.length > 0) {
            this.houseMatchList = this.houseMatchList.concat(ret.data);

            // } else {
            //   this.houseMatchList = ret.data;
            // }
            //this.houseMatchList = [this.houseMatchList, ...ret.data];
            this.housePrms.total = ret.total;
            this.isMore = true;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadTaste() {
      if (this.isSuggestMore) {
        this.suggestPrms.page = this.suggestPrms.page + 1;
        this.queryTaste();
      }
    },
    queryTaste() {
      this.loading = true;
      this.$http
        .post("/foreclosure/house/taste/list", this.tastePrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          if (ret.data.length == 0) {
            this.isTasteMore = false;
          } else {
            //处理list
            ret.data.forEach((item) => {
              //字符串转数组
              let regionStr = item.region
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(regionStr)) {
                item.region = [];
              } else {
                item.region = regionStr.split(",");
              }
              let plateStr = item.plate
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(plateStr)) {
                item.plate = [];
              } else {
                item.plate = plateStr.split(",");
              }
              let schDisStr = item.schDis
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(schDisStr)) {
                item.schDis = [];
              } else {
                item.schDis = schDisStr.split(",");
              }
              let traDisStr = item.traDis
                .replace("[", "")
                .replace("]", "")
                .replace(" ", "");
              if (G.isEmpty(traDisStr)) {
                item.traDis = [];
              } else {
                item.traDis = traDisStr.split(",");
              }
            });
            this.houseTasteList = this.houseTasteList.concat(ret.data);
            this.tastePrms.total = ret.total;
            this.isTasteMore = true;
          }

          console.log(this.houseTasteList);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /* loadSuggest() {
      if (this.isSuggestMore) {
        this.suggestPrms.page = this.suggestPrms.page + 1;
        this.querySuggest();
      }
    },
    querySuggest() {
      this.loading = true;
      this.$http
        .post("/foreclosure/house/suggest/list", this.suggestPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          if (ret.data.length == 0) {
            this.isSuggestMore = false;
          } else {
            // if (this.houseMatchList.length > 0) {
            this.houseSuggestList = this.houseSuggestList.concat(ret.data);
            // } else {
            //   this.houseMatchList = ret.data;
            // }
            //this.houseMatchList = [this.houseMatchList, ...ret.data];
            this.suggestPrms.total = ret.total;
            this.isSuggestMore = true;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },*/
    searchWechat() {
      this.wechatPrms.page = 1;
      this.queryWechat();
    },
    queryWechat() {
      this.loading = true;
      this.$http
        .post("/foreclosure/client/wechat/user/list", this.wechatPrms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.wechatList = ret.data;
          this.wechatPrms.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    selectWechatUser(row) {
      this.loading = true;
      console.log(row);
      this.$http
        .get(
          "/foreclosure/client/wechat/user/save/" +
            this.cinfo.clientId +
            "/" +
            row.unionId
        )
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.cinfo.unionid = row.unionId;
          this.visibleC = false;
          this.visibleS = false;
          this.$notify({
            title: "成功",
            message: "微信绑定成功！",
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    unbind() {
      this.loading = true;
      this.$http
        .get("/foreclosure/client/wechat/user/unbind/" + this.cinfo.clientId)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.cinfo.unionid = "";
          this.visibleC = false;
          this.visibleS = false;
          this.$notify({
            title: "成功",
            message: "微信解绑成功！",
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    addClient() {
      this.intList = [];
      this.intAreaList = [];
      this.intBlockList = [];
      this.intSchoolList = [];
      this.intTrafficList = [];
      G.clearJson(this.client);
      this.client.budgetLow = "0";
      this.client.budgeHigh = "0";
      this.client.areaLow = "0";
      this.client.areaHigh = "0";
      this.editFirst = false;
      this.baseInfoShow = true;
    },
    closeClient() {
      this.baseInfoShow = false;
    },
    editClient() {
      this.intList = [];
      this.intAreaList = [];
      this.intBlockList = [];
      this.intSchoolList = [];
      this.intTrafficList = [];
      G.clearJson(this.client);
      this.client = JSON.parse(JSON.stringify(this.cinfo));
      this.client.intList.forEach((item) => {
        if (item.intType == "1") {
          this.intAreaList.push(item.content);
        }
        if (item.intType == "2") {
          this.intBlockList.push(item.content);
        }
        if (item.intType == "3") {
          this.intSchoolList.push(item.content);
        }
        if (item.intType == "4") {
          this.intTrafficList.push(item.content);
        }
      });
      this.editFirst = true;
      this.baseInfoShow = true;
    },
    addRecord() {
      G.clearJson(this.record);
      this.record.recordDate = G.getDayFormat(new Date());
      this.record.operator = this.$store.state.userName;
      this.record.clientId = this.cinfo.clientId;
      this.record.valiFlag = "1";
      this.recordShow = true;
    },
    editRecord() {
      if (this.recordSelection.length != 1) {
        this.$notify({
          title: "提示",
          message: "请选择一条跟进记录进行修改！",
          type: "warning",
        });
        return;
      }
      this.record = this.recordSelection[0];
      this.recordShow = true;
    },
    openInt() {
      G.clearJson(this.intInfo);
      this.intInfo.client_id = this.client.clientId;
      this.intInfo.vali_flag = "1";
      this.innerVisible = true;
    },
    addInt(intInfo) {
      /*this.$refs["intForm"].validate((valid) => {
        if (valid) {
          this.innerVisible = false;
          if (this.intInfo.intType == "1") {
            this.intAreaList.push(this.intInfo);
          }
          if (this.intInfo.intType == "2") {
            this.intBlockList.push(this.intInfo);
          }
          if (this.intInfo.intType == "3") {
            this.intSchoolList.push(this.intInfo);
          }
          this.intList.push(this.intInfo);
        } else {
          return false;
        }
      });*/
    },
    saveClient() {
      if (this.client.budgetLow == "0" && this.client.budgetHigh == "0") {
        this.$notify({
          title: "提示",
          message: "预算范围不能同时为0！",
          type: "warning",
        });
        return false;
      }
      if (this.client.areaLow == "0" && this.client.areaHigh == "0") {
        this.$notify({
          title: "提示",
          message: "面积范围不能同时为0！",
          type: "warning",
        });
        return false;
      }
      this.$refs["clientForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let input = {};
          input.info = this.client;
          //处理bug
          if (this.client.houseForm == "") {
            this.client.houseForm = [];
          }

          input.intList = [
            ...this.getIntList(this.intAreaList, "1"),
            ...this.getIntList(this.intBlockList, "2"),
            ...this.getIntList(this.intSchoolList, "3"),
            ...this.getIntList(this.intTrafficList, "4"),
            ...this.getIntList(this.client.houseForm, "11"),
          ];
          this.cinfo = JSON.parse(JSON.stringify(this.client));
          this.$http
            .post("/foreclosure/client/info/save", input)
            .then((resp) => {
              this.loading = false;
              this.$notify({
                title: "成功",
                message: "客户信息保存成功！",
                type: "success",
              });
              this.baseInfoShow = false;
              this.$nextTick(() => {
                this.query();
              });
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    saveRecord() {
      this.$refs["recordForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/foreclosure/client/record/save", this.record)
            .then((resp) => {
              this.loading = false;
              this.$notify({
                title: "成功",
                message: "跟进记录保存成功！",
                type: "success",
              });
              this.recordShow = false;
              this.queryRecord();

              //这里要手动刷新一下
              if (this.record.isFirst == "1") {
                this.cinfo.firstReception = this.record.content;
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    delRecord() {
      //校验如果包含首次记录，不允许删除
      let delAble = true;
      this.recordSelection.forEach((item) => {
        if (item.isFirst == "1") {
          this.$notify({
            title: "提示",
            message: "首次跟进记录不允许删除！",
            type: "warning",
          });
          delAble = false;
        }
      });

      if (delAble) {
        this.loading = true;
        this.$http
          .post("/foreclosure/client/record/del", this.recordSelection)
          .then((resp) => {
            this.loading = false;
            this.$notify({
              title: "成功",
              message: "跟进记录作废成功！",
              type: "success",
            });
            this.queryRecord();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    view(cinfo) {
      //G.clearJson(this.client);
      this.cinfo = cinfo;
      this.intList = [];
      this.intAreaList = [];
      this.intBlockList = [];
      this.intSchoolList = [];
      this.intTrafficList = [];
      this.houseMatchList = [];
      this.houseTasteList = [];
      //this.houseSuggestList = [];

      this.recordPrms = {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      };
      this.recordPrms.clientId = this.cinfo.clientId;

      this.housePrms = {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      };
      this.housePrms.clientId = this.cinfo.clientId;
      this.isMore = true;

      this.tastePrms = {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      };
      this.tastePrms.clientId = this.cinfo.clientId;
      this.isTasteMore = true;

      /*this.suggestPrms = {
        clientId: "",
        page: 1,
        size: 5,
        total: 0,
      };
      this.suggestPrms.clientId = this.cinfo.clientId;
      this.isSuggestMore = true;*/

      //this.client = JSON.parse(JSON.stringify(this.cinfo));
      if (G.isEmpty(this.cinfo.intList)) {
        this.cinfo.intList = [];
      }
      this.cinfo.houseForm = [];
      this.cinfo.houseStr = "";
      this.cinfo.intList.forEach((item) => {
        if (item.intType == "1") {
          this.intAreaList.push(item.content);
        }
        if (item.intType == "2") {
          this.intBlockList.push(item.content);
        }
        if (item.intType == "3") {
          this.intSchoolList.push(item.content);
        }
        if (item.intType == "4") {
          this.intTrafficList.push(item.content);
        }
        if (item.intType == "11") {
          this.cinfo.houseForm.push(item.content);
        }
      });

      this.cinfo.houseForm.sort();
      let str = [];
      this.cinfo.houseForm.forEach((item) => {
        str.push(this.getAa10("HOUSE_FORM", item));
      });

      this.cinfo.houseStr = str.join(",");

      this.queryRecord();
      this.queryTaste();
      this.queryMatch();
      //this.querySuggest();

      this.wechatPrms = {
        name: "",
        tel: "",
        page: 1,
        size: 5,
        total: 0,
      };
      this.wechatList = [];
      this.houseActive = "taste";
      this.drawerShow = true;
    },
    tableColumnFomat(row, column) {
      return this.getAa10(column.className, row[column.property]);
    },
    getAa10(code, value) {
      let list = JSON.parse(localStorage.getItem(code));
      let res = value;
      list.forEach((item) => {
        if (item.aaa102 == value) {
          res = item.aaa103;
        }
      });
      return res;
    },
    getIntList(list, type) {
      let res = [];
      list.forEach((item) => {
        let int = {};
        int.intType = type; //1区域 2板块 3学校
        int.content = item;
        int.valiFlag = "1";
        res.push(int);
      });
      return res;
    },
    sendRecord(house) {
      this.loading = true;
      let input = {
        clientId: this.cinfo.clientId,
        houseId: house.houseId,
      };
      this.$http
        .post("/foreclosure/house/suggest/save", input)
        .then((resp) => {
          this.loading = false;
          this.$notify({
            title: "成功",
            message: "房源信息推荐成功！",
            type: "success",
          });
          // this.view(this.cinfo);
          //前台手动处理，避免刷新速度太慢
          this.houseMatchList.splice(this.houseMatchList.indexOf(house), 1);
          this.houseTasteList.push(house);
          this.loadMatch();
          this.loadTaste();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sortColumn(column, order) {
      this.sortPrms.column = column;
      this.sortPrms.order = order;

      //排序
      if (order == "upSort") {
        this.clist.sort(function (a, b) {
          let x = a[column];
          let y = b[column];
          return x > y ? 1 : -1;
        });
      } else {
        this.clist.sort(function (a, b) {
          let x = a[column];
          let y = b[column];
          return x < y ? 1 : -1;
        });
      }
    },
    testSort(column, order) {
      if (this.sortPrms.column == column && this.sortPrms.order == order) {
        return "  el-sort " + order + " enter";
      } else {
        return "  el-sort " + order;
      }
    },
  },
  mounted() {
    var that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };

    //获取用户权限类型
    let userType = this.$store.state.userType;
    this.managerAuth = userType == "4" ? true : false;
    this.userId = this.$store.state.userId;

    this.search();
  },
};
</script>

<style>
.title {
  font-weight: 700;
}
.el-divider--horizontal {
  margin: 10px 0;
  height: 0.5px !important;
}
.el-descriptions-item {
  margin-left: 20px;
  font-size: 12px;
}
.el-descriptions__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.el-descriptions__body .el-descriptions__table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
.el-descriptions__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.el-descriptions-item__label.has-colon:after {
  content: ":";
  position: relative;
  top: -0.5px;
  left: 5px;
}
.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 12px;
}
.el-descriptions-item__label:not(.is-bordered-label) {
  margin-right: 10px;
}
.el-descriptions-item__container .el-descriptions-item__content {
  flex: 1;
}
.el-dialog__body {
  padding: 10px 20px !important;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.noColon .el-descriptions-item__label.has-colon:after {
  content: "";
}
.noColon {
  position: relative;
  top: -1px;
}
.tagSuccessButton:focus,
.tagSuccessButton:hover {
  background-color: #67c23a;
  border-color: #67c23a;
  color: #fff;
  cursor: pointer;
}
.tagSuccessButton:active {
  background: #5daf34;
  border-color: #5daf34;
  color: #fff;
  outline: none;
  cursor: pointer;
}
.tagDangerButton:focus,
.tagDangerButton:hover {
  background-color: #f56c6c;
  border-color: #f56c6c;
  color: #fff;
  cursor: pointer;
}
.tagDangerButton:active {
  background: #dd6161;
  border-color: #dd6161;
  color: #fff;
  outline: none;
  cursor: pointer;
}
.tagPrimaryButton:focus,
.tagPrimaryButton:hover {
  background: #409eff;
  border-color: #409eff;
  color: #fff;
  cursor: pointer;
}
.tagPrimaryButton:active {
  background: #3a8ee6;
  border-color: #3a8ee6;
  color: #fff;
  outline: none;
  cursor: pointer;
}
.drawScroll {
  width: 100%;
  overflow-y: auto;
}

.drawScroll::-webkit-scrollbar {
  width: 0 !important;
}
.el-drawer__header {
  margin-bottom: 10px;
}

.block-title {
  height: 18px;
  background-color: #3a8ee6;
  border: 1px solid #3a8ee6;
  width: 2px;
  margin-right: 5px;
  float: left;
}

.houseTabs .el-tabs__header {
  margin: 0 0 5px !important;
}
.houseTabs .el-tabs__item {
  padding: 0 10px !important;
}

.houseTabs .el-tabs__nav-wrap:after {
  width: 0 !important;
}

.infinite-list {
  height: 99%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.smallDescriptions .el-descriptions-item {
  padding-bottom: 5px !important;
}
.smallDescriptions {
  min-height: 120px;
}
.keyClass {
  color: #f56c6c;
}

.shortItem {
  margin-bottom: 0px !important;
}

.upSort {
  width: 20px;
  height: 20px;
  margin: 5px 0 0 0px;
}
.downSort {
  width: 20px;
  height: 20px;
  margin: -20px 0 0 30px;
}

.enter {
  background-color: #409eff !important;
  color: #fff !important;
}

.enter :hover {
  background-color: #40a0ffd6;
  color: #fff;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  border-radius: 50%;
}

.el-sort {
  /* position: fixed; */
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  color: #409eff;
  z-index: 5;
}

.el-sort:hover {
  background-color: #f2f6fc;
}

/* *** */
.m-nav {
  margin-top: 1px;
}
.m-nav .group {
  border: 1px solid #e8e8e8;
  margin-top: -1px;
}
.m-nav .group .head {
  left: 20px;
  top: 0px;
  width: 8%;
  color: #999;
  position: relative;
}
.m-nav .group .head .title {
  float: left;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-nav .group .row {
  position: relative;
  border-top: 1px dashed #dedede;
  margin: 0 8px;
}
.m-nav .group .row-first,
.m-nav .group .row:first-child {
  border-top: none;
}
.m-nav .group .body {
  padding: 0 100px 10px 112px;
  display: block;
  width: 90%;
  margin-top: -48px;
}
.m-nav .group .body .items {
  height: 36px;
  overflow: hidden;
}
.m-nav .group .body .item {
  float: left;
  margin: 9px 40px 9px 0;
  height: 18px;
  color: #000;
  text-decoration: none;
}
.m-nav .group .body .icon-btn-check-small {
  display: none;
  float: left;
  margin: 4px 5px 0 0;
}

.m-nav .group .body .item .text:hover {
  color: #f40;
  cursor: pointer;
}
.m-nav .group .body .active {
  color: #f40 !important;
}
.m-nav .group .body .item {
  float: left;
  margin: 9px 40px 9px 0;
  height: 18px;
  color: #000;
  font-family: arial, "Hiragino Sans GB", "\5b8b\4f53", sans-serif;
  font: 12px/1.5 tahoma, arial, "Hiragino Sans GB", "\5b8b\4f53", sans-serif;
  text-decoration: none;
}

.m-nav .group .body .text {
  float: left;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-sortbar {
  margin-top: 10px;
}
.m-sortbar .sort-row {
  height: 41px;
}
.m-sortbar .sort-row .sort-inner {
  height: 39px;
  background-color: #f5f5f5;
  border: solid 1px #e8e8e8;
}
.m-sortbar .sorts {
  float: left;
  line-height: 39px;
  display: block;
}
.m-sortbar .sorts .sort {
  float: left;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.m-sortbar .sorts .link {
  float: left;
  display: block;
  height: 39px;
  margin-left: -1px;
  padding: 0 29px;
  border-left: solid 1px #f5f5f5;
  border-right: solid 1px #f5f5f5;
  text-decoration: none;
  color: #6d6d6d;
}
.m-sortbar .sorts .active,
.m-sortbar .sorts .link:hover {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-color: #e5e5e5;
  color: #f50;
}
.m-sortbar .sorts .first {
  margin-left: 0;
  border-left: none;
}

.m-itemlist-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 10px 20px 0;
}

/******* import *******/
.btn {
  border: none;
  color: white;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: translate(1) translate(0, 0);
  transition: transform 225ms, box-shadow 225ms;
}

.btn:hover {
  transform: scale(1.05) translate(0, -0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35);
}

.btn:active {
  transform: scale(1) translate(0, 0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.content-view {
  overflow-y: auto;
}

.m-itemlist-item {
  transition: all 0.3s ease-out;
  border-radius: 10px;
  box-shadow: 0 4px 17px 0 hsl(0deg 0% 91% / 50%);
  background-color: #fff;
  padding: 10px;
  margin: 5px;
}

.m-itemlist-item:hover {
  transform: translateY(-10px) scale(1.005) translateZ(0);
  box-shadow: 0 20px 36px 0 hsl(0deg 0% 91% / 50%);
}

.content-view::-webkit-scrollbar {
  width: 4px;
}
.content-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #6fbaed;
}
.content-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #dae6ee;
}
</style>


