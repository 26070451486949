<template>
  <x-page v-loading="loading">
    <el-form :model="prms" :inline="true">
      <el-form-item label="交易摘要">
        <input v-model="prms.digest" />
      </el-form-item>
      <el-form-item label="客户姓名">
        <input v-model="prms.buyerName" />
      </el-form-item>
      <el-form-item label="客户手机">
        <input v-model="prms.buyerPhone" />
      </el-form-item>
      <el-form-item label="资产名称">
        <input v-model="prms.houseName" />
      </el-form-item>
      <el-form-item label="当前状态">
        <el-select v-model="prms.state" clearable>
          <el-option label="已关闭" value="1"></el-option>
          <el-option label="进行中" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="search">
          检索
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="deals" stripe v-auto="150">
      <el-table-column label="#" width="50" type="index"></el-table-column>
      <el-table-column
        label="资产ID"
        prop="houseId"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="资产名称"
        prop="houseName"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="交易摘要"
        prop="digest"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="当前环节"
        prop="currentKey"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="客户姓名"
        prop="buyerName"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="客户手机号"
        prop="buyerPhone"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="创建人"
        prop="creator"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="办结人"
        prop="achiever"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="办结时间"
        prop="achieveTime"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="color: green" v-if="scope.row.state == '0'">进行中</span>
          <span style="color: crimson" v-else>已关闭</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-files"
            @click="viewTasks(scope.row)"
          >
            查看流程
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right">
      <el-pagination
        @size-change="query"
        @current-change="query"
        :current-page.sync="prms.page"
        :page-sizes="[15, 30, 45, 60]"
        :page-size.sync="prms.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :title="title" :visible.sync="show">
      <el-table :data="tasks" border stripe>
        <el-table-column
          label="#"
          type="index"
          width="40"
          header-align="center"
        ></el-table-column>
        <el-table-column
          width="150"
          property="name"
          label="流程节点"
          header-align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          width="150"
          property="assignee"
          label="经办人"
          header-align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="comment"
          label="办理意见"
          header-align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="开始时间"
          header-align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.startTime }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip
          label="完成时间"
          header-align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.endTime }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </x-page>
</template>

<script>
export default {
  name: "Process",
  data() {
    return {
      loading: false,
      prms: {
        houseName: "",
        buyerName: "",
        buyerPhone: "",
        digest: "",
        creator: "",
        state: "",
        currentKey: "",
        page: 1,
        size: 15,
      },
      total: 0,
      deals: [],
      tasks: [],
      show: false,
      title:''
    };
  },
  methods: {
    search() {
      this.prms.page = 1;
      this.query();
    },
    query() {
      this.loading = true;
      this.$http
        .post("/foreclosure/api/deal/search/page", this.prms)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.deals = ret.data;
          this.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    viewTasks(row) {
      this.loading = true;
      let fd = new FormData();
      fd.append("dealId", row.dealId);
      this.$http
        .post("/foreclosure/api/deal/history", fd)
        .then((resp) => {
          this.loading = false;
          this.tasks = resp.data.data;
          this.title = row.digest;
          this.show = true;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted(){
    this.search();
  }
};
</script>

<style></style>
