<template>
  <XPage v-loading="loading">
    <el-form :model="prm" :inline="true">
      <el-form-item label="姓名">
        <el-input v-model="prm.aac003"></el-input>
      </el-form-item>
      <el-form-item label="所属部门">
        <treeselect
          :options="treeData"
          v-model="prm.orgId"
          placeholder="请选择"
          :defaultExpandLevel="3"
          clearable
          style="width:180px;"
        ></treeselect>
      </el-form-item>
      <el-form-item label="用户类型">
        <x-select
          code="USER_TYPE"
          v-model="prm.type"
          placeholder=""
          clearable
        ></x-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="prm.aae100" clearable>
          <el-option label="正常" value="1"></el-option>
          <el-option label="停用" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          icon="el-icon-search"
          :loading="loading"
          @click="search"
        >
          检索
        </el-button>
        <!-- <el-button
          type="warning"
          icon="el-icon-refresh"
          :loading="loading"
          @click="clear"
        >
          清屏
        </el-button> -->
        <el-button type="danger" icon="el-icon-plus" @click="add">
          新增
        </el-button>

        <span style="color: #e0e0e0; font-size: 11px; margin: 0 15px">
          {{ eventId }}
        </span>
      </el-form-item>
    </el-form>

    <el-table :data="users" stripe v-auto>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="头像" width="60">
        <template slot-scope="scope">
          <img
            style="height: 24px; width: 24px; border-radius: 50%"
            :src="
              '/public/' + (scope.row.avatar ? scope.row.avatar : 'default.png')
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="登录名" prop="userId"></el-table-column>
      <el-table-column label="姓名" prop="aac003"></el-table-column>
      <el-table-column label="性别" width="80">
        <template slot-scope="scope">
          {{ scope.row.gender | transCode("GEND") }}
        </template>
      </el-table-column>
      <el-table-column label="身份证号" prop="aac002" show-overflow-tooltip></el-table-column>
      <el-table-column label="手机号" prop="mobile" show-overflow-tooltip></el-table-column>
      <el-table-column label="所属部门" prop="orgName" show-overflow-tooltip></el-table-column>
      <el-table-column label="用户类型">
        <template slot-scope="scope">
          {{ scope.row.type | transCode("USER_TYPE") }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="220">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.aae100"
            :disabled="scope.row.userId == $store.state.userid"
            active-value="1"
            active-text="正常"
            inactive-value="0"
            inactive-text="停用"
            @change="modifyStatus(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            :disabled="scope.row.id == '0000'"
            @click="edit(scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-refresh"
            @click="reset(scope.row)"
          >
            重置密码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <el-pagination
        :total="total"
        :current-page.sync="prm.page"
        :page-size="prm.size"
        @current-change="query"
        layout="total,prev,pager,next"
        background
      ></el-pagination>
    </div>

    <el-dialog :title="title" width="720px" :visible.sync="show">
      <el-form
        :model="user"
        ref="userForm"
        label-width="100px"
        style="width: 660px; margin: 10px auto"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属机构:" prop="orgId">
              <treeselect
                style="width: 100%"
                :options="treeData"
                v-model="user.orgId"
                placeholder="请选择"
                :defaultExpandLevel="3"
              ></treeselect>
            </el-form-item>
            <el-form-item label="登录账号:" prop="userId">
              <el-input
                v-model="user.userId"
                :disabled="operation !== 'new'"
                placeholder=""
              ></el-input>
            </el-form-item>
            <el-form-item label="登录密码:" prop="password">
              <el-input v-model="user.password" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="姓名:" prop="aac003">
              <el-input v-model="user.aac003" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="gender">
              <x-select
                code="GEND"
                v-model="user.gender"
                placeholder=""
              ></x-select>
            </el-form-item>
            <!-- prop="aac002" -->
            <el-form-item label="身份证号:">
              <el-input v-model="user.aac002" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <img
              style="
                width: 160px;
                height: 160px;
                display: block;
                margin: 10px auto;
                border-radius: 50%;
                border: 4px solid #409eff;
              "
              :src="
                '/public/' + (user.avatarFull ? user.avatarFull : 'default.png')
              "
              v-loading="loading"
            />
            <div style="text-align: center; margin-top: 10px">
              <el-button
                :loading="loading"
                type="success"
                round
                icon="el-icon-edit"
                @click="$refs['fileInput'].click()"
              >
                修改头像
              </el-button>
            </div>
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              @change="fileChange"
            />
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="用户类型:" prop="type">
              <x-select
                code="USER_TYPE"
                v-model="user.type"
                placeholder=""
              ></x-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号:">
              <el-input v-model="user.mobile" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户角色:" prop="roles">
              <el-checkbox-group v-model="user.roles">
                <el-checkbox
                  v-for="item in $store.state.allRoles"
                  :key="item.roleId"
                  :label="item.roleId"
                >
                  {{ item.roleName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="" style="text-align: right">
          <el-button type="primary" @click="save" icon="el-icon-check">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </XPage>
</template>

<script>
import XSelect from "@/components/XSelect.vue";
const ALLOWED_TYPES = ["JPG", "JPEG", "PNG", "BMP"];

export default {
  components: { XSelect },
  name: "Users",
  props: {
    aac003: String,
    eventId: String,
  },
  data() {
    const userIdValidator = (r, v, cb) => {
      if (this.operation != "new") {
        return cb();
      }
      if (v == "" || v == null || v == undefined) {
        return cb(new Error("请输入登录账号"));
      }
      let fd = new FormData();
      fd.append("userId", v);
      this.$http
        .post("/foreclosure/api/userid/exists", fd)
        .then((resp) => {
          let ret = resp.data;
          if (ret.data) {
            cb(new Error("登录账号已存在!"));
          } else {
            cb();
          }
        })
        .catch((err) => {
          cb(new Error(err));
        });
    };
    return {
      prm: {
        aac003: "",
        orgId: null,
        type: "",
        aae100: "",
        page: 1,
        size: 15,
      },
      loading: false,
      users: [],
      total: 0,
      title: "新增用户",
      show: false,
      user: {
        aac002: "",
        aac003: "",
        aae100: "1",
        password: "",
        roles: [],
        userId: "",
        mobile: "",
        akb020: "",
        orgId: null,
        type: "1",
        avatar: "",
        avatarFull: "",
        gender: "",
      },
      operation: "new",
      authShow: false,
      rules: {
        aac002: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        aac003: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        roles: [
          { required: true, message: "请选择用户角色", trigger: "change" },
        ],
        orgId: [
          { required: true, message: "请选择用户所属部门", trigger: "blur" },
        ],
        userId: [
          { required: true, message: "请输入登录账号", trigger: "change" },
          {
            pattern: /^[A-Za-z]{1}[A-Za-z0-9_-]{3,15}$/,
            message: "以字母开头，只能包含字母数字下划线和减号，4到16位",
            tigger: "change",
          },
          { validator: userIdValidator, tigger: "change" },
        ],
        type: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
      biz: [],
      qry: [],
      select: [],
      aab324s: [],
      loadingAuth: false,
      treeData: [],
    };
  },
  mounted() {
    this.loading = true;
    this.$http
      .get("/foreclosure/api/roles")
      .then((resp) => {
        this.loading = false;
        let ret = resp.data;
        this.$store.commit("updateRoleList", ret.data);
      })
      .catch((err) => {
        this.loading = false;
        this.$message.error("加载角色失败!");
      });

    /*this.$nextTick(function() {
      this.tableHeight = window.innerHeight - 180;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = window.innerHeight - 180;
      };
    });*/

    this.query();
    this.queryMyTree();
  },
  methods: {
    fileChange(evt) {
      let file = evt.target.files[0];
      let name = file.name;
      let arr = name.split(".");
      let suffix = arr[arr.length - 1].toUpperCase();
      evt.target.value = "";
      if (ALLOWED_TYPES.indexOf(suffix) < 0) {
        this.$message.error("请选择JPG,JPEG,PNG,BMP格式的图片");
        return;
      }
      if (file.size < 5000) {
        this.$message.error("图片小于5K,请重新选择");
        return;
      }
      if (file.size > 20000000) {
        this.$message.error("图片大于20M,请重新选择");
        return;
      }
      if (file) {
        let fr = new FileReader();
        fr.onloadend = () => {
          //console.log(fr.result);
          this.uploadPhoto(fr.result);
        };
        fr.readAsDataURL(file);
      }
    },
    uploadPhoto(photo) {
      this.loading = true;
      let fd = new FormData();
      fd.append("userId", this.user.userId);
      fd.append("photo", photo);
      this.$http
        .post("/foreclosure/api/admin/avatar/save", fd)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.user.avatar = ret.data["avatar"];
          this.user.avatarFull = ret.data["avatarFull"];
          if (this.user.userId == this.$store.state.userId) {
            this.$store.commit("modifyAvatar", ret.data);
          }
          this.query();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryMyTree() {
      this.loading = true;
      this.$http
        .get("/foreclosure/api/orgs/myorgs/tree")
        .then((resp) => {
          this.loading = false;
          this.treeData = [];
          let ret = resp.data;
          this.treeData.push(ret.data);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    auth(row) {},
    save() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let toSend = {};
          for (let key in this.user) {
            if (key == "role") {
              toSend[key] = this.user[key].join(",");
            } else {
              toSend[key] = this.user[key];
            }
          }
          this.$http
            .post("/foreclosure/api/user/save", toSend)
            .then((resp) => {
              this.loading = false;
              this.$message.success("保存成功");
              this.show = false;
              this.query();
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    reset(row) {
      this.$confirm("确定要重置[" + row.aac003 + "]的密码嘛?")
        .then(() => {
          this.loading = true;
          this.$http
            .get("/foreclosure/api/user/resetpwd/" + row.userId)
            .then((resp) => {
              this.loading = false;
              this.query();
              this.$message.success("密码已重置为123456");
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    edit(row) {
      this.title = "修改用户";
      //this.user = row;
      for (let key in row) {
        if (key == "role") {
          this.user["role"] = row[key].split(",");
        } else {
          this.user[key] = row[key];
        }
      }
      this.loading = true;
      this.$http
        .get("/foreclosure/api/user/roles/" + row.userId)
        .then((resp) => {
          this.loading = false;
          let ret = resp.data;
          this.user.roles = [];
          ret.data.map((item) => {
            this.user.roles.push(item.roleId);
            this.show = true;
          });
        })
        .catch((err) => {
          this.loading = false;
        });
      this.operation = "edit";
    },
    add() {
      this.title = "新增用户";
      this.$tls.clearJson(this.user);
      this.user.role = [];
      this.user.roles = [];
      this.user.aae100 = "1";
      this.show = true;
      this.operation = "new";
    },
    modifyStatus(row) {
      this.loading = true;
      this.$http
        .get("/foreclosure/api/user/status/" + row.userId + "/" + row.aae100)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("修改账号状态失败" + err);
          row.aae100 = row.aae100 === "1" ? "0" : "1";
        });
    },
    clear() {
      this.prm.aac003 = "";
      this.prm.page = 1;
      this.users = [];
      this.total = 0;
    },
    search() {
      this.prm.page = 1;
      this.query();
    },
    query() {
      this.loading = true;
      console.log(this.prm);
      this.$http
        .post("/foreclosure/api/users", this.prm)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          let ret = resp.data;
          this.users = ret.data;
          this.total = ret.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
