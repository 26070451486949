<template xmlns:el-col="http://www.w3.org/1999/html">
  <XPage v-loading="loading">
    <el-card>
      <span>任务分配时间 : </span>
      <span>
<!--          <el-date-picker value-format="yyyy-MM-dd" style="width:130px" v-model="prms.startDate" type="date"
                          placeholder="选择日期">
          </el-date-picker>
          至
          <el-date-picker value-format="yyyy-MM-dd" style="width:130px" v-model="prms.endDate" type="date"
                          placeholder="选择日期">
          </el-date-picker>-->

                <el-date-picker
                    v-model="queryDate.queryDates"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
               </el-date-picker>
        <span style="margin-left: 10px">确认状态 : </span>
     <el-select v-model="queryDate.confirmStatus" placeholder="请选择" style="width: 100px">
                  <el-option
                      v-for="item in jobConfirmStatusl"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>

                <el-button type="primary" icon="el-icon-search" @click="querySendTask" round style="margin-left:20px">
            查询
          </el-button>
             <el-button type="success" icon="el-icon-s-platform" @click="addTask" round
             >
            新增
          </el-button>
          <el-button type="success" icon="el-icon-success" @click="sendTask" round
          >
            发送
          </el-button>
        </span>
    </el-card>
    <div v-loading="drawloading" style="height:auto;">
      <el-form :model="addTaskCollecyion" ref="userForms">
        <el-table :data="addTaskCollecyion.addTaskCollecyions" stripe style="height: 600px;" auto :height=600>

          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <!--                <el-table-column label="姓名" align="center">
                            <template slot-scope="scope">
                              <el-form-item size="small" :prop="'editWorkCollecyions.' + scope.$index + '.employeeName'"
                                            style="margin-top: 20px">
                                <el-input v-model="scope.row.employeeName"></el-input>
                              </el-form-item>
                            </template>
                          </el-table-column>-->
          <el-table-column label="日报时间" align="center" width="150px">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.reportTime'"
                            :rules="rules.reportTime" style="margin-top: 20px">
                <el-date-picker value-format="yyyy-MM-dd" style="width:140px" v-model="scope.row.reportTime"
                                type="date" placeholder="选择日期"

                >
                </el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="工作属性" align="center">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.jobProperties'"
                            :rules="rules.jobProperties" style="margin-top: 20px">
                <el-select v-model="scope.row.jobProperties" placeholder="请选择" style="width: 100px">
                  <el-option
                      v-for="item in jobPropertiesOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="日报详细内容" prop="details" align="center" width="380px">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.details'"
                            style="margin-top: 20px">
                <el-input v-model="scope.row.details" type="textarea" :rows="2" style="width: 350px"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="要求完成时间" prop="completionTime" align="center" width="230px">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.completionTime'"
                            style="margin-top: 20px;width: 100px">
                <el-date-picker
                    v-model="scope.row.completionTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    :picker-options="pickerOptions"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    default-time="12:00:00"
                >
                </el-date-picker>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="责任人" align="center">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.acceptTaskMan'"
                            :rules="rules.acceptTaskMan" style="margin-top: 20px">
                <el-select v-model="scope.row.acceptTaskMan" placeholder="请选择" style="width: 100px">
                  <el-option
                      v-for="item in allValidUsers"
                      :key="item.userId"
                      :label="item.aac003"
                      :value="item.userId">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="确认状态" align="center">
            <template slot-scope="scope">
              <el-form-item size="small" :prop="'addTaskCollecyions.' + scope.$index + '.confirmStatus'"
                            style="margin-top: 20px">
                <el-select v-model="scope.row.confirmStatus==null?'0':scope.row.confirmStatus=null" placeholder="请选择" style="width: 100px" >
                  <el-option
                      v-for="item in jobConfirmStatusl"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="details" align="right">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-delete" @click="removeTask(scope.row, scope.$index)">

              </el-button>
            </template>
          </el-table-column>

        </el-table>

      </el-form>

    </div>

  </XPage>
</template>

<script>


export default {
  name: "AssignmentAction",
  data() {
    return {
      loading: false,
      drawloading: false,
      allValidUsers: [],
      addTaskCollecyion: {
        addTaskCollecyions: []
      },
      queryDate: {
        queryDates: [],
        confirmStatus: "",
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      jobConfirmStatusl: [{
        value: '-1',
        label: '退回'
      }, {
        value: '1',
        label: '确认'
      }, {
        value: '0',
        label: '未确认'
      }],
      jobPropertiesOptions: [{
        value: '1',
        label: '客户信息维护'
      }, {
        value: '2',
        label: '房屋信息维护'
      }, {
        value: '3',
        label: '外出看房'
      }, {
        value: '4',
        label: '外出接洽客户'
      }, {
        value: '5',
        label: '视频资料编辑'
      }, {
        value: '6',
        label: '尽职报告编写'
      }, {
        value: '7',
        label: '其他工作'
      }],
      rules: {
        reportTime: [{
          required: true,
          message: "请选择日报日期",
          trigger: ["change"],
        }, {
          validator: function (rule, value, callback) {
            // if (value > 24 || value < 0) {
            //   callback(new Error("工作时间填写有误"));
            // } else (
            //     callback()
            // )

            var now = new Date();
            var y = now.getFullYear();
            var m = now.getMonth() + 1;
            var d = now.getDate();
            if (m <= 9) {
              m = "0" + m;
            }
            if (d <= 9) {
              d = "0" + d;
            }
            var start = y + "-" + m + "-" + d;
            var selectDate = new Date(Date.parse(value));
            var date = new Date(Date.parse(start));
            if (date > selectDate) {
              callback(new Error("不可分配之前的工作！"));
            } else {
              callback()
            }
          },
          trigger: ["blur", "change"],
        }],
        jobProperties: [{
          required: true,
          message: "请选择工作属性",
          trigger: ["blur", "change"],
        }],
        /*       finishState: [{
                 required: true,
                 message: "请选择完成情况",
                 trigger: ["blur", "change"],
               }],
               jobNature: [{
                 required: true,
                 message: "请选择工作性质",
                 trigger: ["blur", "change"],
               }],*/
        acceptTaskMan: [{
          required: true,
          message: "请选择责任人",
          trigger: ["blur", "change"],
        }]

      }
    }
  },

  mounted() {
    this.initShowFlag()
  },
  methods: {
    initShowFlag() {
      this.queryDate.queryDates.push(this.newTodayHandle());
      this.queryDate.queryDates.push(this.newTodayHandle());
      this.$http.get("/foreclosure/report/daily/initshowflag").then
      ((resp) => {
        if (resp.data.data.allValidUsers.length > 0) {
          resp.data.data.allValidUsers.forEach(item => {
            if (item.userId != this.employeeNo) {
              this.allValidUsers.push(item)
            }
          })
        }
      }).catch((err) => {
        this.$message.info("获取人员权限失败")
      })
    },
    /**
     * 分配任务删除按钮
     * @param row
     * @param index
     */
    removeTask(row, index) {
      this.drawloading = true
      debugger
      if (row.tsnId != null) {
        this.$http.post("/foreclosure/report/daily/removeTask", row.tsnId)
            .then((resp) => {
              this.drawloading = false;
              this.querySendTask()
            })
            .catch((err) => {
              this.$message.error("删除失败")
            })
      } else {
        this.drawloading = false;
        this.querySendTask()
      }

    },
    /**
     * 保存日志信息，并给员工发送消息
     */
    sendTask() {
      this.$refs.userForms.validate((valid) => {
        if (valid) {
          this.addTaskCollecyion.addTaskCollecyions.forEach(item => {
            item.queryDates = []
          }),
              this.drawloading = true;
          this.$http.post("/foreclosure/report/daily/sendTask", this.addTaskCollecyion.addTaskCollecyions)
              .then((resp) => {
                this.drawloading = false;
                this.$message.success("任务分配成功")
                this.querySendTask()
              }).catch((err) => {
            this.$message.success("任务分配失败")
            this.drawloading = false;
          })
        }
      })
    },
    /**
     * 新增任务分配列表
     */
    addTask() {
      this.$refs.userForms.validate((valid) => {

        if (valid) {
          this.addTaskCollecyion.addTaskCollecyions.push({
            reportTime: this.newTodayHandle(),
            completionTime: this.getCurrentTime()
          })
        }
      });
    },
    /**
     * 查询任务分配明细
     */
    querySendTask() {
      this.drawloading = true
      this.$http.post("/foreclosure/report/daily/querySendTask", this.queryDate).then(
          (resp) => {
            this.addTaskCollecyion.addTaskCollecyions = resp.data.data;
            this.drawloading = false
          }
      ).catch(
          (err) => {
            this.$message.info("查询任务分配失败！")
            this.drawloading = false
          }
      )
    },
    /**
     * 计算是几号
     * @param row
     */
    newTodayHandle(day) {
      if (day == null || day == '') {
        var now = new Date();
      } else {
        var now = new Date(day);
      }

      var y = now.getFullYear();
      var m = now.getMonth() + 1;
      var d = now.getDate();
      if (m <= 9) {
        m = "0" + m;
      }
      if (d <= 9) {
        d = "0" + d;
      }
      var start = y + "-" + m + "-" + d;

      return start;
    },
    getCurrentTime() {
      var date = new Date();//当前时间
      var year = date.getFullYear() //返回指定日期的年份
      var month = this.repair(date.getMonth() + 1);//月
      var day = this.repair(date.getDate());//日
      var hour = this.repair(date.getHours());//时
      var minute = this.repair(date.getMinutes());//分
      var second = this.repair(date.getSeconds());//秒

      //当前时间
      var curTime = year + "-" + month + "-" + day
          + " " + hour + ":" + "00" + ":" + "00";
      return curTime;
    },
    //补0

    repair(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    }

  },
};
</script>

<style>


</style>


