<template>
  <x-page v-loading="loading" style="width: 92%; left: 4%; position: relative">
    <!--    <py-script>import pyodide</py-script>-->
    <!--    <py-script>r = pyodide.http.open_url("http://localhost:24000/").read()</py-script>-->
    <!--    <py-script>print(r)</py-script>-->
    <!--    <py-script>print("1123123", r.__dict__)</py-script>-->
    <el-form :model="prms" :inline="true" label-width="80px" style="text-align: center" size="medium">
      <el-form-item>
        <el-input v-model="prms.title" clearable @change="enterClick" placeholder="请输入搜索内容"
                  style="width: 400px; height: 30px"/>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="queryHouseInfo" round>
          查一查
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="success" icon="el-icon-plus" @click="addHouseInfo" round>
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <div class="m-nav">
      <div class="group">
<!--        <div class="row row-first">-->
<!--          <div class="head">-->
<!--            <h4>-->
<!--              <span class="title" title="面积范围">面积范围</span>-->
<!--              ：-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div class="body">-->
<!--            <div class="items">-->
<!--              <a-->
<!--                  :class="prms.builtAreaLow === '0' ? itemClassActive : itemClass"-->
<!--                  title="50m²以下"-->
<!--              >-->
<!--                <span class="text" @click="pointArea('0', '50')">50m²以下</span>-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.builtAreaLow === '50' ? itemClassActive : itemClass"-->
<!--                  title="50-100m²"-->
<!--              >-->
<!--                <span class="text" @click="pointArea('50', '100')"-->
<!--                >50-100m²</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.builtAreaLow === '100' ? itemClassActive : itemClass"-->
<!--                  title="100-150m²"-->
<!--              >-->
<!--                <span class="text" @click="pointArea('100', '150')"-->
<!--                >100-150m²</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.builtAreaLow === '150' ? itemClassActive : itemClass"-->
<!--                  title="150m²以上"-->
<!--              >-->
<!--                <span class="text" @click="pointArea('150', '')"-->
<!--                >150m²以上</span-->
<!--                >-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="head">-->
<!--            <h4>-->
<!--              <span class="title" title="预算范围">预算范围</span>-->
<!--              ：-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div class="body">-->
<!--            <div class="items">-->
<!--              <a-->
<!--                  :class="prms.priceLow === '0' ? itemClassActive : itemClass"-->
<!--                  title="100万以下"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('0', '100')"-->
<!--                >100万以下</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.priceLow === '100' ? itemClassActive : itemClass"-->
<!--                  title="100-200万"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('100', '200')"-->
<!--                >100-200万</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.priceLow === '200' ? itemClassActive : itemClass"-->
<!--                  title="200-300万"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('200', '300')"-->
<!--                >200-300万</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.priceLow === '300' ? itemClassActive : itemClass"-->
<!--                  title="300-400万"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('300', '400')"-->
<!--                >300-400万</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.priceLow === '400' ? itemClassActive : itemClass"-->
<!--                  title="400-500万"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('400', '500')"-->
<!--                >400-500万</span-->
<!--                >-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.priceLow === '500' ? itemClassActive : itemClass"-->
<!--                  title="500万以上"-->
<!--              >-->
<!--                <span class="text" @click="pointRange('500', '')"-->
<!--                >500万以上</span-->
<!--                >-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <div class="head">-->
<!--            <h4>-->
<!--              <span class="title" title="维护状态">维护状态</span>-->
<!--              ：-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div class="body">-->
<!--            <div class="items">-->
<!--              <a-->
<!--                  :class="prms.mainStatus === '1' ? itemClassActive : itemClass"-->
<!--                  title="已维护"-->
<!--              >-->
<!--                <span class="text" @click="pointMainStatus('1')">已维护</span>-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.mainStatus === '0' ? itemClassActive : itemClass"-->
<!--                  title="未维护"-->
<!--              >-->
<!--                <span class="text" @click="pointMainStatus('0')">未维护</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <div class="head">-->
<!--            <h4>-->
<!--              <span class="title" title="拍卖阶段">拍卖阶段</span>-->
<!--              ：-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div class="body">-->
<!--            <div class="items">-->
<!--              <a-->
<!--                  :class="prms.status === '一拍' ? itemClassActive : itemClass"-->
<!--                  title="一拍"-->
<!--              >-->
<!--                <span class="text" @click="pointStatus('一拍')">一拍</span>-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.status === '二拍' ? itemClassActive : itemClass"-->
<!--                  title="二拍"-->
<!--              >-->
<!--                <span class="text" @click="pointStatus('二拍')">二拍</span>-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.status === '再次拍卖' ? itemClassActive : itemClass"-->
<!--                  title="再次拍卖"-->
<!--              >-->
<!--                <span class="text" @click="pointStatus('再次拍卖')">重新拍卖</span>-->
<!--              </a>-->
<!--              <a-->
<!--                  :class="prms.status === '变卖' ? itemClassActive : itemClass"-->
<!--                  title="变卖"-->
<!--              >-->
<!--                <span class="text" @click="pointStatus('变卖')">变卖</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="row row-first">
          <div class="head">
            <h4>
              <span class="title" title="拍卖状态">拍卖状态</span>
              ：
            </h4>
          </div>
          <div class="body">
            <div class="items">
              <el-row>
                <el-col :span="2">
                  <a
                      :class="prms.timeSort === '正在进行' ? itemClassActive : itemClass"
                      title="正在进行"
                  >
                    <span class="text" @click="pointTime('正在进行')">正在进行</span>
                  </a>
                </el-col>
                <el-col :span="2">
                  <a
                      :class="prms.timeSort === '即将开始' ? itemClassActive : itemClass"
                      title="即将开始"
                  >
                    <span class="text" @click="pointTime('即将开始')">即将开始</span>
                  </a>
                </el-col>
                <el-col :span="2">
                  <a
                      :class="prms.timeSort === '已结束' ? itemClassActive : itemClass"
                      title="已结束"
                  >
                    <span class="text" @click="pointTime('已结束')">已结束</span>
                  </a>
                </el-col>
                <el-col :span="2">
                  <a
                      :class="prms.timeSort === '中止' ? itemClassActive : itemClass"
                      title="中止"
                  >
                    <span class="text" @click="pointTime('中止')">中止</span>
                  </a>
                </el-col>
                <el-col :span="2">
                  <a
                      :class="prms.timeSort === '撤回' ? itemClassActive : itemClass"
                      title="撤回"
                  >
                    <span class="text" @click="pointTime('撤回')">撤回</span>
                  </a>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="head">
            <h4>
              <span class="title" title="所在地">所在地</span>
              ：
            </h4>
          </div>
          <div class="body">
            <div style="overflow: hidden;">
              <el-row>
                <el-col :span="2">
                  <a
                      :class="prms.disPoolArea === '' ? itemClassActive : itemClass"
                      title="全市"
                  >
                    <span class="text" @click="pointPool('')">全市</span>
                  </a>
                </el-col>
                <el-col :span="2" v-for="val in this.areaLst" :key="val.name">
                  <a
                      :class="prms.disPoolArea === val.code ? itemClassActive : itemClass"
                      :key="val.name"
                      :title="val.name"
                  >
                    <span class="text" @click="pointPool(val.code)">{{ val.name }}</span>
                  </a>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-sortbar">
      <div class="sort-row">
        <div class="sort-inner">
          <ul class="sorts">
            <li class="sort" @click="pointSort('')">
              <a :class="prms.sortColumn === '' ? sortClassActive : sortClass">{{
                  "综合排序"
                }}</a>
            </li>
            <li class="sort" @click="pointSort('startTime')">
              <a
                  :class="
                  prms.sortColumn === 'startTime' ? sortClassActive : sortClass
                "
              >{{
                  prms.sortColumn === 'startTime'
                      ? (prms.sortColumnType === 0 ? "距开拍日期由近到远 ↑" : "距开拍日期由远到近 ↓")
                      : "开拍日期"

                }}</a>
            </li>
            <li class="sort">
              <el-dropdown @command="pointStatus">
                <span :class="prms.status === '' ? sortClass : sortClassActive" style="text-align: center">{{
                    prms.status === "" ? "拍卖阶段" : prms.status
                  }}</span>
                <el-dropdown-menu slot="dropdown" style="width: 120px;">
                  <el-dropdown-item command="一拍" style="font-size: 14px;">一拍</el-dropdown-item>
                  <el-dropdown-item command="二拍" style="font-size: 14px;">二拍</el-dropdown-item>
                  <el-dropdown-item command="再次拍卖" style="font-size: 14px;">再次拍卖</el-dropdown-item>
                  <el-dropdown-item command="变卖" style="font-size: 14px;">变卖</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="sort">
              <el-dropdown @command="pointMainStatus">
                <span :class="prms.mainStatus === '' ? sortClass : sortClassActive" style="text-align: center">{{
                    prms.mainStatus === "" ? "维护状态" : (prms.mainStatus === "1" ? "已维护" : "未维护")
                  }}</span>
                <el-dropdown-menu slot="dropdown" style="width: 120px;">
                  <el-dropdown-item command="1" style="font-size: 14px;">已维护</el-dropdown-item>
                  <el-dropdown-item command="0" style="font-size: 14px;">未维护</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="sort">
              <el-dropdown @command="pointRange">
                <span :class="prms.priceLow === '' ? sortClass : sortClassActive " style="text-align: center">{{
                    prms.priceLow === "" ? "预算范围" :
                        (prms.priceLow === "0" ? "100万以下" :
                            (prms.priceLow === "100" ? "100-200万" :
                                (prms.priceLow === "200" ? "200-300万" :
                                    (prms.priceLow === "300" ? "300-400万" :
                                        (prms.priceLow === "400" ? "400-500万" : (
                                            prms.priceLow === "500" ? "500万以上" : ""))))))
                  }}</span>
                <el-dropdown-menu slot="dropdown" style="width: 120px;">
                  <el-dropdown-item command="0|100" style="font-size: 14px;">100万以下</el-dropdown-item>
                  <el-dropdown-item command="100|200" style="font-size: 14px;">100-200万</el-dropdown-item>
                  <el-dropdown-item command="200|300" style="font-size: 14px;">200-300万</el-dropdown-item>
                  <el-dropdown-item command="300|400" style="font-size: 14px;">300-400万</el-dropdown-item>
                  <el-dropdown-item command="400|500" style="font-size: 14px;">400-500万</el-dropdown-item>
                  <el-dropdown-item command="500" style="font-size: 14px;">500万以上</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="sort">
              <el-dropdown @command="pointArea">
                <span :class="prms.builtAreaLow === '' ? sortClass : sortClassActive" style="text-align: center">{{
                    prms.builtAreaLow === "" ? "面积范围" :
                        (prms.builtAreaLow === "0.1" ? "50m²以下" :
                            (prms.builtAreaLow === "50" ? "50-100m²" :
                                (prms.builtAreaLow === "100" ? "100-150m²" :
                                    (prms.builtAreaLow === "150" ? "150m²以上" : ""))))
                  }}</span>
                <el-dropdown-menu slot="dropdown" style="width: 120px;">
                  <el-dropdown-item command="0.1|50" style="font-size: 14px;">50m²以下</el-dropdown-item>
                  <el-dropdown-item command="50|100" style="font-size: 14px;">50-100m²</el-dropdown-item>
                  <el-dropdown-item command="100|150" style="font-size: 14px;">100-150m²</el-dropdown-item>
                  <el-dropdown-item command="150" style="font-size: 14px;">150m²以上</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li class="sort">
              <el-dropdown @command="pointNature">
                <span :class="prms.proNature === '' ? sortClass : sortClassActive" style="text-align: center">{{
                    prms.proNature === "" ? "房屋性质" :
                        (prms.proNature === "0" ? "住宅" :
                            (prms.proNature === "1" ? "公寓" :
                                (prms.proNature === "2" ? "别墅" :
                                    (prms.proNature === "3" ? "商铺" :
                                        (prms.proNature === "4" ? "车位" : (
                                            prms.proNature === "5" ? "写字楼" : (
                                                prms.proNature === "6" ? "工业用房" : "")))))))
                  }}</span>
                <el-dropdown-menu slot="dropdown" style="width: 120px;">
                  <el-dropdown-item command="0" style="font-size: 14px;">住宅</el-dropdown-item>
                  <el-dropdown-item command="1" style="font-size: 14px;">公寓</el-dropdown-item>
                  <el-dropdown-item command="2" style="font-size: 14px;">别墅</el-dropdown-item>
                  <el-dropdown-item command="3" style="font-size: 14px;">商铺</el-dropdown-item>
                  <el-dropdown-item command="4" style="font-size: 14px;">车位</el-dropdown-item>
                  <el-dropdown-item command="5" style="font-size: 14px;">写字楼</el-dropdown-item>
                  <el-dropdown-item command="6" style="font-size: 14px;">工业用房</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>


<!--          <ul class="sorts">-->
<!--            <li class="sort" @click="pointSort('')">-->
<!--              <a :class="prms.sortColumn === '' ? sortClassActive : sortClass">{{-->
<!--                  "综合排序"-->
<!--                }}</a>-->
<!--            </li>-->
<!--            <li class="sort" @click="pointSort('startTime')">-->
<!--              <a-->
<!--                  :class="-->
<!--                  prms.sortColumn === 'startTime' ? sortClassActive : sortClass-->
<!--                "-->
<!--              >{{-->
<!--                  prms.sortColumn === 'startTime'-->
<!--                      ? (prms.sortColumnType === 0 ? "距开拍日期由近到远 ↑" : "距开拍日期由远到近 ↓")-->
<!--                      : "开拍日期"-->

<!--                }}</a>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
      </div>
    </div>

    <div v-model="activeNames" :style="{ height: windowHeight - 340 + 'px' }" class="content-view">
      <div
          class="m-itemlist-item"
          v-for="(cinfo, index) in houseList"
          v-bind:key="cinfo.url+index"
          :name="index"
          style="margin-top: 15px"
      >
        <div style="margin-bottom: 40px">
          <div class="block-title"></div>
          <span style="font-weight: 600; width: 93%; float: left">
            <el-tag
                v-show="cinfo.houseDetailsInfoRepo.status"
                effect="plain"
                style="margin-right: 3px"
            >{{ cinfo.houseDetailsInfoRepo.status }}
            </el-tag>{{
              cinfo.title
            }}
          <el-tag
              effect="dark"
              :key="tagKey"
              style="margin-left: 10px;"
              :type="cinfo.isStart === '正在进行' ? 'danger': cinfo.isStart === '即将开始' ? 'success' : 'info'"
          >{{ cinfo.isStart }}
            </el-tag>
          <el-tag
              effect="dark"
              key="mainStatus"
              style="margin-left: 10px;"
              :type="cinfo.mainStatus === '1' ? 'success': 'info'"
          >{{ cinfo.mainStatus === '1' ? '已维护' : '未维护' }}
          </el-tag>
          </span>
          <el-button
              type="primary"
              @keyup.enter.stop.native="detailedInfo(cinfo)"
              @keyup.space.stop.native
              @click.stop.native
              @click.stop.prevent="detailedInfo(cinfo)"
              style="float: left;"
              round
          >
            详情
          </el-button>
        </div>
        <div style="display: flex">
          <div>
            <el-image :src="cinfo.url === '' ? cinfo.detailedUrl[0] : cinfo.url"
                      style="width: 150px; height: 95px"
                      :preview-src-list="cinfo.detailedUrl"
            ></el-image>
          </div>
          <div style="margin-left: 40px">
            <el-descriptions :column="4">
              <el-descriptions-item label="小区名称" label-class-name="descFount" content-class-name="descFount">{{
                  cinfo.houseDetailsInfoRepo.commName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="建筑面积" label-class-name="descFount" content-class-name="descFount">{{
                  cinfo.houseDetailsInfoRepo === "" ? "暂无数据" :
                      (cinfo.houseDetailsInfoRepo.builtArea === "0" ? "暂无数据" :
                          cinfo.houseDetailsInfoRepo.builtArea + "平方米")

                }}
              </el-descriptions-item>
              <el-descriptions-item :label="cinfo.houseDetailsInfoRepo.status === '变卖'? '变卖价' : '起拍价'"
                                    label-class-name="descFount" content-class-name="descFount">{{
                  cinfo.initialPriceBig
                }}
              </el-descriptions-item>

              <el-descriptions-item label="位置" label-class-name="descFount" content-class-name="descFount">{{
                  cinfo.houseDetailsInfoRepo.locationRegion
                }}
              </el-descriptions-item>
              <el-descriptions-item label="开拍时间">{{
                  cinfo.startTime
                }}
              </el-descriptions-item>
              <el-descriptions-item label="有无租赁">{{
                  cinfo.houseDetailsInfoRepo.isLease === "1" ? "有" : (cinfo.houseDetailsInfoRepo.isLease === "0" ? "无" : "")
                }}
              </el-descriptions-item>
              <el-descriptions-item label="是否凶宅">{{
                  cinfo.houseDetailsInfoRepo.hanutedHouse === "1" ? "是" : (cinfo.houseDetailsInfoRepo.hanutedHouse === "0" ? "否" : "")
                }}
              </el-descriptions-item>
              <el-descriptions-item label="是否成交">{{
                  cinfo.isDeal
                }}
              </el-descriptions-item>
              <el-descriptions-item label="交通">
                <el-tag
                    effect="plain"
                    v-for="(itraffic, itrafficIndex) in cinfo.itrafficList"
                    :key="'glanItraffic'+itrafficIndex"
                >{{ itraffic.content }}
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="区域">
                <el-tag
                    effect="plain"
                    v-for="(intArea, intAreaIndex) in cinfo.intAreaList"
                    :key="'glanIntArea'+intAreaIndex"
                >{{ intArea.content }}
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="学区">
                <el-tag
                    effect="plain"
                    v-for="(intSchool, intSchoolIndex) in cinfo.intSchoolList"
                    :key="'glanIntSchool'+intSchoolIndex"
                >{{ intSchool.content }}
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="板块">
                <el-tag
                    effect="plain"
                    v-for="(intBlock, intBlockIndex) in cinfo.intBlockList"
                    :key="'glanIntBlock'+intBlockIndex"
                >{{ intBlock.content }}
                </el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: right; margin-top: 10px">
      <el-pagination
          @size-change="changeSize"
          @current-change="changePageFn"
          :current-page.sync="prms.page"
          :page-sizes="[10, 15, 20, 30, 40]"
          :page-size.sync="prms.size"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <el-drawer :visible.sync="houseDetailsDialog" size="90%" direction="rtl">
      <div style="display: flex">
        <div style="width: 100%">
          <div style="margin-bottom: 20px">
                <span style="margin-left: 55px" class="descFount">
                  <el-tag size="small" style="margin-right: 20px">房源标题</el-tag>
                  {{ houseDetails.title }}</span
                >
            <div style="float: right; margin-right: 6%">
              <el-button
                  style="width: 100px"
                  type="primary"
                  @click="openMap"
                  icon="el-icon-location-outline"
              >
                百度地图
              </el-button>
              <el-button
                  style="width: 100px"
                  type="primary"
                  @click="openEditDialog(houseDetails)"
                  icon="el-icon-edit"
              >
                信息维护
              </el-button>
              <el-button
                  style="width: 100px"
                  type="danger"
                  @click="conTran(houseDetails)"
                  icon="el-icon-key"
                  :disabled="houseDetails.isDeal === '是'"
              >
                成交确认
              </el-button>
            </div>
          </div>
          <el-row>
            <el-col :span="24">
              <el-card style=" margin-bottom: 20px; margin-left: 20px">
                <div style="margin-left: 20px;">
                  <div style="margin-bottom: 10px; vertical-align: middle;">
                    <el-row>
                      <el-col :span="8">
                        <el-tag size="small"><span style="font-weight: 700">房源信息</span>
                        </el-tag>
                        <el-tag
                            effect="dark"
                            :key="tagKey"
                            style="margin-left: 10px;"
                            :type="houseDetails.isStart === '正在进行' ? 'danger': houseDetails.isStart === '即将开始' ? 'success' : 'info'"
                        >{{ houseDetails.isStart }}
                        </el-tag>
                      </el-col>
                      <el-col :span="8" style="font-weight: 700">
                        <el-tag size="small"><span style="font-weight: 700">开拍时间</span>
                        </el-tag>
                        {{
                          houseDetails.startTime
                        }}
                      </el-col>
                      <el-col :span="8" style="font-weight: 700">
                        <el-tag size="small"><span style="font-weight: 700">{{
                            houseDetails.houseDetailsInfoRepo.status === '变卖' ? '变卖价' : '起拍价'
                          }}</span>
                        </el-tag>
                        {{
                          houseDetails.initialPriceBig
                        }}
                      </el-col>
                    </el-row>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <div>
                      <el-image :src="houseDetails.url === '' ? houseDetails.detailedUrl[0] : houseDetails.url"
                                style="width: 150px; height: 100px"
                                :preview-src-list="houseDetails.detailedUrl"
                      ></el-image>
                    </div>
                    <div style="margin-left: 20px;">
                      <el-descriptions :column="3">
                        <!--                        <el-descriptions-item label="是否法拍">{{-->
                        <!--                            houseDetails.houseDetailsInfoRepo.frenchBeat-->
                        <!--                          }}-->
                        <!--                        </el-descriptions-item>-->
                        <el-descriptions-item label="拍卖阶段" label-class-name="descRedFount"
                                              content-class-name="descRedFount">{{
                            houseDetails.houseDetailsInfoRepo.status
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="变卖预缴款"
                                              v-if="houseDetails.houseDetailsInfoRepo.status === '变卖'? true : false">{{
                            houseDetails.initialPriceBig
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="保证金">{{
                            houseDetails.houseDetailsInfoRepo.bond
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="评估价">{{
                            houseDetails.consultPrice ? parseFloat(houseDetails.consultPrice / 10000).toFixed(2) + "万元" : "暂无数据"
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="所在位置">{{
                            houseDetails.houseDetailsInfoRepo.itemAddress
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="优先购买权人">{{
                            houseDetails.houseDetailsInfoRepo.priorityPurchase ? houseDetails.houseDetailsInfoRepo.priorityPurchase.split(" ")[0] : ""
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="加价幅度">{{
                            houseDetails.houseDetailsInfoRepo.markupRange
                          }}
                        </el-descriptions-item>
                        <el-descriptions-item label="建筑面积">{{
                            houseDetails.houseDetailsInfoRepo.builtArea === "0" ? "暂无数据" : houseDetails.houseDetailsInfoRepo.builtArea + "平方米"

                          }}
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: right; margin-right: 5%;">
                    <el-button style="width: 100px" icon="el-icon-tickets" type="primary" @click="uploadFile('1')">尽职报告
                    </el-button>
                    <el-button type="primary" icon="el-icon-connection" @click="toAliUrl(houseDetails)"
                               style="width: 100px; margin-left: 10px">
                      阿里链接
                    </el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-card style="margin-top: 20px; margin-left: 20px">
            <div style="margin-left: 20px">
              <el-tabs v-model="activeName">
                <el-tab-pane label="补充信息" name="first">
                  <div style="margin-left: 20px">
                    <el-descriptions :column="4" label-class-name="descriptionsFont"
                                     content-class-name="descriptionsFont">
                      <el-descriptions-item label="小区名称" label-class-name="desc14pxFount"
                                            content-class-name="desc14pxFount">{{
                          houseDetails.houseDetailsInfoRepo.commName
                        }}
                      </el-descriptions-item>
<!--                      <el-descriptions-item label="所属街道">{{-->
<!--                          houseDetails.houseDetailsInfoRepo.street-->
<!--                        }}-->
<!--                      </el-descriptions-item>-->
                      <el-descriptions-item label="均价">{{
                          houseDetails.houseDetailsInfoRepo.averPrie
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="市场价">{{
                          houseDetails.marketPriceB
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="建筑面积">{{
                          houseDetails.houseDetailsInfoRepo.builtArea === "0" ? "暂无数据" : houseDetails.houseDetailsInfoRepo.builtArea + "平方米"
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="房屋性质">{{
                          getProNature(houseDetails.houseDetailsInfoRepo.proNature)
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="房屋户型">{{
                          getRepApar(houseDetails.houseDetailsInfoRepo.repApartments)
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="是否凶宅">{{
                          houseDetails.houseDetailsInfoRepo.hanutedHouse === "1" ? "是" : (houseDetails.houseDetailsInfoRepo.isLease === "0" ? "否" : "")
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="有无租赁">{{
                          houseDetails.houseDetailsInfoRepo.isLease === "1" ? "有" : (houseDetails.houseDetailsInfoRepo.isLease === "0" ? "无" : "")
                        }}
                      </el-descriptions-item>


                      <el-descriptions-item label="土地性质">{{
                          houseDetails.houseDetailsInfoRepo.purpose
                        }}
                      </el-descriptions-item>
<!--                      <el-descriptions-item label="临街状况">{{-->
<!--                          houseDetails.houseDetailsInfoRepo.fronState-->
<!--                        }}-->
<!--                      </el-descriptions-item>-->
                      <el-descriptions-item label="房屋朝向">{{
                          houseDetails.houseDetailsInfoRepo.repHouseOri
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="装修情况">{{
                          getRepDec(houseDetails.houseDetailsInfoRepo.repDecoration)
                        }}
                      </el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions :column="1" label-class-name="descriptionsFont"
                                     content-class-name="descriptionsFont">
                      <el-descriptions-item label="核心卖点" label-class-name="desc14pxBlackFount"
                                            content-class-name="desc14pxBlackFount">{{
                          houseDetails.houseDetailsInfoRepo.sellPoints
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="医疗信息">{{
                          houseDetails.houseDetailsInfoRepo.medInfo
                        }}
                      </el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions :column="4" label-class-name="descriptionsFont"
                                     content-class-name="descriptionsFont">
                      <el-descriptions-item label="板块">
                        <el-tag
                            effect="plain"
                            v-for="(int, index) in houseDetails.intBlockList"
                            :key="'intBlock'+index"
                            class="tagFont14"
                        >{{ int.content }}
                        </el-tag
                        >
                      </el-descriptions-item>
                      <el-descriptions-item label="区域">
                        <el-tag
                            effect="plain"
                            v-for="(int, index) in houseDetails.intAreaList"
                            :key="'intArea'+index"
                            class="tagFont14"
                        >{{ int.content }}
                        </el-tag
                        >
                      </el-descriptions-item>
                      <el-descriptions-item label="学区">
                        <el-tag
                            effect="plain"
                            v-for="(int, index) in houseDetails.intSchoolList"
                            :key="'school'+index"
                            class="tagFont14"
                        >{{ int.content }}
                        </el-tag
                        >
                      </el-descriptions-item>
                      <el-descriptions-item label="交通">
                        <el-tag
                            effect="plain"
                            v-for="(int, index) in houseDetails.itrafficList"
                            :key="'itraffic'+index"
                            class="tagFont14"
                        >{{ int.content }}
                        </el-tag
                        >
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <div style="display: flex; justify-content: right; margin-right: 5%;">
                    <el-button style="width: 100px" icon="el-icon-video-camera" type="primary" @click="uploadFile('2')">
                      视频上传
                    </el-button>
                    <el-button type="primary" icon="el-icon-connection" @click="toBkUrl(houseDetails)"
                               style="width: 100px; margin-left: 10px">
                      贝壳链接
                    </el-button>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="小区介绍" name="sixth">
                  <el-descriptions :column="1" :colon="false" label-class-name="descriptionsFont"
                                   content-class-name="descriptionsFont">
                    <el-descriptions-item>
                      {{ houseDetails.houseDetailsInfoRepo.commIntr }}
                    </el-descriptions-item>
                  </el-descriptions>
                </el-tab-pane>
                <!--                <el-tab-pane label="教育信息" name="second">-->
                <!--                  <el-descriptions :column="1" :colon="false">-->
                <!--                    <el-descriptions-item>-->
                <!--                    </el-descriptions-item>-->
                <!--                  </el-descriptions>-->
                <!--                </el-tab-pane>-->
                <el-tab-pane label="交通信息" name="third">
                  <el-descriptions :column="1" :colon="false" label-class-name="descriptionsFont"
                                   content-class-name="descriptionsFont">
                    <el-descriptions-item>
                      {{ houseDetails.houseDetailsInfoRepo.traffic }}
                    </el-descriptions-item>
                  </el-descriptions>
                </el-tab-pane>
                <!--                <el-tab-pane label="医疗信息" name="fourth">-->
                <!--                  <el-descriptions :column="1" :colon="false">-->
                <!--                  </el-descriptions>-->
                <!--                </el-tab-pane>-->
                <el-tab-pane label="周边配套" name="fifth">
                  <el-descriptions :column="1" :colon="false" label-class-name="descriptionsFont"
                                   content-class-name="descriptionsFont">
                    <el-descriptions-item>
                      {{ houseDetails.houseDetailsInfoRepo.periphery }}
                    </el-descriptions-item>
                  </el-descriptions>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>

          <!--          <el-card style="margin-top: 20px; margin-left: 20px">-->
          <!--            <div style="display: flex">-->
          <!--              <div style="margin-left: 20px">-->
          <!--                <el-tag size="small"><span>尽职调查</span>-->
          <!--                </el-tag>-->
          <!--              </div>-->

          <!--            </div>-->
          <!--            <div style="text-align: right; margin-right: 5%;">-->
          <!--              <el-button type="primary" icon="el-icon-upload2" @click="dilUp" style="width: 100px">-->
          <!--                上 传-->
          <!--              </el-button>-->
          <!--              <el-button type="primary" icon="el-icon-download" @click="dilDown" style="width: 100px">-->
          <!--                下 载-->
          <!--              </el-button>-->
          <!--              <el-button type="primary" icon="el-icon-search" @click="dilSee" style="width: 100px">-->
          <!--                查 看-->
          <!--              </el-button>-->
          <!--            </div>-->
          <!--          </el-card>-->

          <el-card style="margin-top: 20px; margin-left: 20px">
            <div>
              <div style="display: flex">
                <div style="margin-bottom: 10px; margin-left: 20px">
                  <el-tag size="small"><span>预计成交价</span>
                  </el-tag>
                </div>
                <div style="margin-bottom: 10px; margin-left: 25%">
                  报名人数
                  <el-input-number
                      v-model="signPeoNum"
                      :step="5"
                      :min="0"
                      style="width: 50%"
                  ></el-input-number>
                </div>
                <div>
                  <el-button type="success" @click="calculation(houseDetails.houseDetailsInfoRepo.insideArea)">
                    计 算
                  </el-button>
                </div>
              </div>

              <el-table :data="forInvisList" stripe style="width: 90%; margin-right: 2%; margin-left: 2%">
                <el-table-column
                    property="peoNum"
                    show-overflow-tooltip
                    label="报名人数"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    property="forTranPrice"
                    show-overflow-tooltip
                    label="预测成交价"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    property="dj"
                    show-overflow-tooltip
                    label="单价"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    property="qs3"
                    show-overflow-tooltip
                    label="一套房"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    property="qs4"
                    show-overflow-tooltip
                    label="二套房"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    property="qs5"
                    show-overflow-tooltip
                    label="三套房"
                    align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>

        <el-col :span="12" style="padding-right: 10px; margin-left: 20px">
          <el-tabs v-model="activeNameRec" class="houseTabs" style="margin-top: -10px">
            <el-tab-pane>
              <span slot="label">
                <el-tag size="small">已推荐</el-tag>
              </span>
              <div style="float: left; width: 90%">
                <el-form
                    :model="CommandedqueryPrms"
                    :inline="true"
                    style="text-align: center"
                    size="mini"
                >
                  <el-form-item>
                    <el-input
                        style="width: 200px; height: 20px"
                        v-model="CommandedqueryPrms.keyword"
                        placeholder="请输入搜索内容"
                        clearable
                        @change="getCommandedSearch"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" icon="el-icon-search" @click="getCommandedSearch" round>
                      查一查
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div>
                <div v-for="item in ReCommandedpeoLst" style="display: block">
                  <div style="margin-top: 10px">
                    <div
                        class="drawScroll"
                    >
                      <div style="display: flex; position: relative; float: left;" class="m-itemlist-item">
                        <div
                            style=" width: 60px; height: 60px; position: absolute; top: 50%; transform: translateY(-50%)">
                          <el-tag
                              @click="addRecord(item)"
                              effect="plain"
                              type="primary"
                              class="tagPrimaryButton"
                          >
                            详情
                          </el-tag>
                        </div>
                        <div
                            style="
                            width: calc(100% - 60px);
                            margin-left: 60px;
                          "
                        >
                          <el-descriptions :column="2">
                            <el-descriptions-item label="名称">{{
                                item.clientName
                              }}
                            </el-descriptions-item>
                            <el-descriptions-item label="编号">{{
                                item.clientId
                              }}
                            </el-descriptions-item>
                            <el-descriptions-item label="预算">{{
                                item.budgetLow + "万 - " + item.budgetHigh + "万"
                              }}
                            </el-descriptions-item>
                          </el-descriptions>
                          <el-descriptions :column="1">
                            <el-descriptions-item label="标签" contentStyle="line-height :30px">
                            <span
                                v-for="lb in item.labelLst"
                                :key="'recommend'+lb"
                                style="margin-left: 5px"
                            >
                              <el-tag
                                  effect="dark"
                                  size="small"
                                  :type="
                                      houseLableLst.includes(lb)
                                        ? ''
                                        : 'info'
                                    "
                              >{{ lb }}
                              </el-tag>
                            </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                label="匹配度"
                                :labelStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                                :contentStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                            >
                              <el-rate
                                  v-model="item.score"
                                  disabled
                                  allow-half
                                  :colors="colors"
                              >
                              </el-rate>
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align: center; margin-top: 20px">
                <el-pagination
                    @size-change="CommandedhandleSizeChange"
                    @current-change="CommandedhandleCurrentChange"
                    :current-page="Commandedpage"
                    :page-sizes="[4, 8, 16]"
                    background
                    :page-size="Commandedlimit"
                    layout="total, sizes, prev, pager, next"
                    :total="Commandedtotal"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label">
                <el-tag size="small">拟推荐</el-tag>
              </span>
              <div style="float: left; width: 90%">
                <el-form
                    :model="queryPrms"
                    :inline="true"
                    style="text-align: center"
                    size="mini"
                >
                  <el-form-item>
                    <el-input
                        style="width: 200px; height: 20px"
                        v-model="queryPrms.keyword"
                        placeholder="请输入搜索内容"
                        clearable
                        @change="getMatPeoAndSearch"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" icon="el-icon-search" @click="getMatPeoAndSearch" round>
                      查一查
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>

              <div>
                <div v-for="item in tableList" style="display: block" v-if="item.loading"
                     :key="'proRecommend'+item.clientId ">
                  <div style="margin-top: 10px">
                    <div
                        class="drawScroll"
                    >
                      <div style="display: flex; position: relative; float: left;" class="m-itemlist-item">
                        <div
                            style=" width: 60px; height: 60px; position: absolute; top: 45%; transform: translateY(-50%)">
                          <el-tag
                              @click="addRecord"
                              effect="plain"
                              type="primary"
                              style="margin: 10px 0 0 10px"
                              class="tagPrimaryButton"
                          >
                            详情
                          </el-tag>
                          <el-tag
                              @click="recClient(item)"
                              effect="plain"
                              :type="item.recommend === '已推荐' ? 'info' : 'danger'"
                              style="margin: 10px 0 0 10px"
                              class="tagPrimaryButton"
                          >
                            {{ item.recommend }}
                          </el-tag>
                        </div>
                        <div
                            style="
                            margin-left: 70px;
                            width: calc(100% - 70px);
                          "
                        >
                          <el-descriptions :column="2">
                            <el-descriptions-item label="名称">{{
                                item.clientName
                              }}
                            </el-descriptions-item>
                            <el-descriptions-item label="编号">{{
                                item.clientId
                              }}
                            </el-descriptions-item>
                            <el-descriptions-item label="预算" :span="2">{{
                                item.budgetLow + "万 - " + item.budgetHigh + "万"
                              }}
                            </el-descriptions-item>
                          </el-descriptions>
                          <el-descriptions :column="1">
                            <el-descriptions-item label="标签" contentStyle="line-height :30px">
                            <span
                                v-for="lb in item.labelLst"
                                :key="'proRecommend'+lb+item.clientId"
                                style="margin-left: 5px"
                            >
                              <el-tag
                                  effect="dark"
                                  size="small"
                                  :type="
                                      houseLableLst.includes(lb)
                                        ? ''
                                        : 'info'
                                    "
                              >{{ lb }}
                              </el-tag>
                            </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                :span="2"
                                label="匹配度"
                                :labelStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                                :contentStyle="{
                                float: 'left',
                                'margin-top': '5px',
                              }"
                            >
                              <el-rate
                                  v-model="item.score"
                                  disabled
                                  allow-half
                                  :colors="colors"
                              >
                              </el-rate>
                            </el-descriptions-item>
                          </el-descriptions>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align: center; margin-top: 20px">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[4, 8, 16]"
                    background
                    :page-size="limit"
                    layout="total, sizes, prev, pager, next"
                    :total="commondtotal"
                >
                </el-pagination>
              </div>

            </el-tab-pane>
          </el-tabs>
        </el-col>
      </div>
    </el-drawer>
    <el-dialog
        title="房源基本信息"
        :visible.sync="houseEditDialog"
        width="70%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        top="10px"
        :center="true"
        ref="topDialog"
        :lock-scroll="false"
        :append-to-body="true"
    >
      <el-form
          :model="detailsHouseInfoSave"
          label-width="120px"
          style="width: 100%;"
          ref="recordForm"
          :rules="editRules"
      >
        <el-card>
          <div>
            <span class="title-font">补充信息</span>
            <el-row style="margin-top: 10px">
              <el-col :span="6">
                <el-form-item label="小区名称" prop="houseDetailsInfoRepo.commName">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.commName"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="市场价" prop="marketPrice">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.marketPrice"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="6">-->
<!--                <el-form-item label="成交价" prop="houseDetailsInfoRepo.tranPrice">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.tranPrice"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="同小区成交价" prop="houseDetailsInfoRepo.ideTranPrice">
                  <el-input
                      v-model="detailsHouseInfoSave.ideTranPrice"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否凶宅" prop="houseDetailsInfoRepo.hanutedHouse">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.hanutedHouse" placeholder="请选择">
                    <el-option
                        v-for="item in hanutedHouseLst"
                        :key="'hanutedHouse'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="有无租赁" prop="houseDetailsInfoRepo.isLease">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.isLease" placeholder="请选择">
                    <el-option
                        v-for="item in isLeaseLst"
                        :key="'isLease'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="所属街道" prop="houseDetailsInfoRepo.street">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.street"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="建筑面积" prop="houseDetailsInfoRepo.builtArea">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.builtArea"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="房屋户型" prop="houseDetailsInfoRepo.repApartments">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repApartments" placeholder="请选择">
                    <el-option
                        v-for="item in repAparLst"
                        :key="'Apar'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="临街状况" prop="houseDetailsInfoRepo.fronState">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.fronState"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="房屋朝向" prop="houseDetailsInfoRepo.repHouseOri">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repHouseOri"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="装修情况" prop="houseDetailsInfoRepo.repDecoration">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repDecoration" placeholder="请选择">
                    <el-option
                        v-for="item in repDecLst"
                        :key="'DecLst'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="房屋性质" prop="houseDetailsInfoRepo.proNature">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.proNature" placeholder="请选择">
                    <el-option
                        v-for="item in proList"
                        :key="'proNature'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="周边配套" prop="houseDetailsInfoRepo.periphery">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入周边配套"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.periphery"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="交通出行" prop="houseDetailsInfoRepo.traffic">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入交通出行"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.traffic"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="核心卖点" prop="houseDetailsInfoRepo.sellPoints">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入核心卖点"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.sellPoints"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="医疗信息" prop="houseDetailsInfoRepo.medInfo">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入医疗信息"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.medInfo"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intAreaList">
                  <template slot="label">
                    <i
                        class="el-icon-place"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >区域
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intAreaList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intBlockList">
                  <template slot="label">
                    <if
                        class="el-icon-guide"
                        style="font-weight: 500; margin-right: 5px"
                    ></if
                    >板块
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intBlockList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intSchoolList">
                  <template slot="label">
                    <i
                        class="el-icon-school"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >学区
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intSchoolList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="itrafficList">
                  <template slot="label">
                    <i
                        class="el-icon-odometer"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >交通
                  </template>
                  <Itag :tags="detailsHouseInfoSave.itrafficList"></Itag>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
                style="text-align: right; margin-left: 30px; margin-top: 10px"
            >
              <el-button size="small" type="primary" @click="uploadFile('1')">上传尽职调查</el-button>
            </el-form-item>
          </div>
        </el-card>

        <el-card style="margin-top: 20px">
          <div>
            <div>
              <span class="title-font">阿里信息</span>
              <el-button v-show="aLiOpen" type="primary" icon="el-icon-paperclip" @click="openALi"
                         style="float: right;">
                阿里链接
              </el-button>
            </div>

            <div style="display: flex; margin-top: 20px">
              <div style="margin-right: 40px">
                <el-image :src="aliHouseInfo.detailedUrl[0] === '' ? '/images/noData.png' : aliHouseInfo.detailedUrl[0]"
                          style="width: 150px; height: 95px"
                          :preview-src-list="aliHouseInfo.detailedUrl"
                ></el-image>
              </div>
              <div style="margin-left: 20px">
                <el-descriptions :column="3" label-class-name="descriptionsFont" content-class-name="descriptionsFont">
                  <el-descriptions-item label="名称">{{
                      aliHouseInfo.title
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="起拍价">{{
                      aliHouseInfo.initialPrice
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="评估价">{{
                      aliHouseInfo.consultPrice ? parseFloat(aliHouseInfo.consultPrice / 10000).toFixed(2) + "万元" : "暂无数据"
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="开拍时间">{{
                      aliHouseInfo.startTime
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="保证金">{{
                      aliHouseInfo.houseDetailsInfoRepo.bond
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="拍卖阶段">{{
                      aliHouseInfo.houseDetailsInfoRepo.status
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="阿里面积">{{
                      aliHouseInfo.houseDetailsInfoRepo.insideArea === "0" ? "暂无数据" : aliHouseInfo.houseDetailsInfoRepo.insideArea + "平方米"
                    }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </el-card>

        <el-card style="margin-top: 20px">
          <div>
            <div>
              <span class="title-font">贝壳信息</span>
              <el-button type="primary" icon="el-icon-paperclip" @click="openBeiKe"
                         style="float: right;">
                贝壳链接
              </el-button>
            </div>
            <div style="margin-top: 20px;">
              <div style="display: flex">
                <div>
                  <el-image :src="bkUrl"
                            style="width: 150px; height: 100px" v-show="bkUrl"></el-image>
                </div>
                <div style="margin-left: 20px">
                  <el-descriptions :column="3" label-class-name="descriptionsFont"
                                   content-class-name="descriptionsFont">
                    <el-descriptions-item label="小区名称">{{
                        beikeHouseInfo.houseDetailsInfoRepo.commName
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="所在区域">{{
                        beikeHouseInfo.houseDetailsInfoRepo.locationRegion
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="房屋户型">{{
                        beikeHouseInfo.houseDetailsInfoRepo.apartments
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="户型结构">{{
                        beikeHouseInfo.houseDetailsInfoRepo.structure
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="贝壳面积">{{
                        beikeHouseInfo.houseDetailsInfoRepo.area ? beikeHouseInfo.houseDetailsInfoRepo.area + "平方米" : ""
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="房屋朝向">{{
                        beikeHouseInfo.houseDetailsInfoRepo.houseOri
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="建筑结构">{{
                        beikeHouseInfo.houseDetailsInfoRepo.buildStru
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="配备电梯">{{
                        beikeHouseInfo.houseDetailsInfoRepo.equippedEle
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="梯户比例">{{
                        beikeHouseInfo.houseDetailsInfoRepo.proHlds
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="小区均价">{{
                        beikeHouseInfo.houseDetailsInfoRepo.averPrie
                      }}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <el-form-item
            label=""
            style="text-align: right; margin-left: 30px; margin-top: 10px"
        >
          <el-button type="primary" icon="el-icon-check" @click="editHouseInfo">
            保存
          </el-button>
          <el-button icon="el-icon-close" @click="editCancle">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog
        title="新增房源"
        :visible.sync="houseAddDialog"
        width="70%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        top="10px"
        :center="true"
        ref="topDialog"
        :lock-scroll="false"
        :append-to-body="true"
    >
      <el-form
          :model="detailsHouseInfoSave"
          label-width="120px"
          style="width: 100%"
          ref="addForm"
          :rules="addRules"
      >
        <el-card>
          <div>
            <div>
              <span class="title-font">阿里信息</span>
              <el-button v-show="aLiOpen" type="primary" icon="el-icon-paperclip" @click="openALi"
                         style="float: right;">
                阿里链接
              </el-button>
            </div>

            <div style="display: flex; margin-top: 20px">
              <div style="margin-right: 40px">
                <el-image :src="aliHouseInfo.detailedUrl[0] === '' ? '/images/noData.png' : aliHouseInfo.detailedUrl[0]"
                          style="width: 150px; height: 95px"
                          :preview-src-list="aliHouseInfo.detailedUrl"
                ></el-image>
              </div>
              <div style="margin-left: 20px">
                <el-descriptions :column="3" label-class-name="descriptionsFont" content-class-name="descriptionsFont">
                  <el-descriptions-item label="名称">{{
                      aliHouseInfo.title
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="起拍价">{{
                      aliHouseInfo.initialPrice
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="评估价">{{
                      aliHouseInfo.consultPrice ? parseFloat(aliHouseInfo.consultPrice / 10000).toFixed(2) + "万元" : "暂无数据"
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="开拍时间">{{
                      aliHouseInfo.startTime
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="保证金">{{
                      aliHouseInfo.houseDetailsInfoRepo.bond
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="拍卖阶段">{{
                      aliHouseInfo.houseDetailsInfoRepo.status
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="阿里面积">{{
                      aliHouseInfo.houseDetailsInfoRepo.insideArea === "0" ? "暂无数据" : aliHouseInfo.houseDetailsInfoRepo.insideArea + "平方米"
                    }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </el-card>

        <el-card style="margin-top: 20px">
          <div>
            <div>
              <span class="title-font">贝壳信息</span>
              <el-button v-show="aLiOpen" type="primary" icon="el-icon-paperclip" @click="openBeiKe"
                         style="float: right;">
                贝壳链接
              </el-button>
            </div>
            <div style="margin-top: 20px;">
              <div style="display: flex">
                <div>
                  <el-image :src="bkUrl"
                            style="width: 150px; height: 100px" v-show="bkUrl"></el-image>
                </div>
                <div style="margin-left: 20px">
                  <el-descriptions :column="3" label-class-name="descriptionsFont"
                                   content-class-name="descriptionsFont">
                    <el-descriptions-item label="小区名称">{{
                        beikeHouseInfo.houseDetailsInfoRepo.commName
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="所在区域">{{
                        beikeHouseInfo.houseDetailsInfoRepo.locationRegion
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="房屋户型">{{
                        getRepApar(beikeHouseInfo.houseDetailsInfoRepo.apartments)
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="户型结构">{{
                        beikeHouseInfo.houseDetailsInfoRepo.structure
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="贝壳面积">{{
                        beikeHouseInfo.houseDetailsInfoRepo.area ? beikeHouseInfo.houseDetailsInfoRepo.area + "平方米" : ""
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="房屋朝向">{{
                        beikeHouseInfo.houseDetailsInfoRepo.houseOri
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="建筑结构">{{
                        beikeHouseInfo.houseDetailsInfoRepo.buildStru
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="配备电梯">{{
                        beikeHouseInfo.houseDetailsInfoRepo.equippedEle
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="梯户比例">{{
                        beikeHouseInfo.houseDetailsInfoRepo.proHlds
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="小区均价">{{
                        beikeHouseInfo.houseDetailsInfoRepo.averPrie
                      }}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </div>
            </div>
          </div>
        </el-card>

        <el-card style="margin-top: 20px">
          <div>
            <span class="title-font">补充信息</span>
            <el-row style="margin-top: 10px">
              <el-col :span="6">
                <el-form-item label="小区名称" prop="houseDetailsInfoRepo.commName">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.commName"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="市场价" prop="marketPrice">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.marketPrice"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="是否凶宅" prop="houseDetailsInfoRepo.hanutedHouse">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.hanutedHouse" placeholder="请选择">
                    <el-option
                        v-for="item in hanutedHouseLst"
                        :key="'newHanutedHouse'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="有无租赁" prop="houseDetailsInfoRepo.isLease">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.isLease" placeholder="请选择">
                    <el-option
                        v-for="item in isLeaseLst"
                        :key="'newLsLease'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="成交价" prop="houseDetailsInfoRepo.tranPrice">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.tranPrice"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="同小区成交价" prop="houseDetailsInfoRepo.ideTranPrice">
                  <el-input
                      v-model="detailsHouseInfoSave.ideTranPrice"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="所属街道" prop="houseDetailsInfoRepo.street">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.street"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="房屋户型" prop="houseDetailsInfoRepo.repApartments">

                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repApartments" placeholder="请选择">
                    <el-option
                        v-for="item in repAparLst"
                        :key="'repApar'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
<!--              <el-col :span="6">-->
<!--                <el-form-item label="临街状况" prop="houseDetailsInfoRepo.fronState">-->
<!--                  <el-input-->
<!--                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.fronState"-->
<!--                      style="width: 100%"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="6">
                <el-form-item label="房屋朝向" prop="houseDetailsInfoRepo.repHouseOri">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repHouseOri"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="装修情况" prop="houseDetailsInfoRepo.repDecoration">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.repDecoration" placeholder="请选择">
                    <el-option
                        v-for="item in repDecLst"
                        :key="'repDecLst'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="建筑面积" prop="houseDetailsInfoRepo.builtArea">
                  <el-input
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.builtArea"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="房屋性质" prop="houseDetailsInfoRepo.proNature">
                  <el-select v-model="detailsHouseInfoSave.houseDetailsInfoRepo.proNature" placeholder="请选择">
                    <el-option
                        v-for="item in proList"
                        :key="'proNature'+item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="周边配套" prop="houseDetailsInfoRepo.periphery">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入周边配套"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.periphery"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="交通出行" prop="houseDetailsInfoRepo.traffic">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入交通出行"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.traffic"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="核心卖点" prop="houseDetailsInfoRepo.sellPoints">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入核心卖点"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.sellPoints"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="医疗信息" prop="houseDetailsInfoRepo.medInfo">
                  <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入医疗信息"
                      style="width: 100%"
                      v-model="detailsHouseInfoSave.houseDetailsInfoRepo.medInfo"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intAreaList">
                  <template slot="label">
                    <i
                        class="el-icon-place"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >区域
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intAreaList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intBlockList">
                  <template slot="label">
                    <i
                        class="el-icon-guide"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >板块
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intBlockList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="intSchoolList">
                  <template slot="label">
                    <i
                        class="el-icon-school"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >学区
                  </template>
                  <Itag :tags="detailsHouseInfoSave.intSchoolList"></Itag>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="itrafficList">
                  <template slot="label">
                    <i
                        class="el-icon-odometer"
                        style="font-weight: 500; margin-right: 5px"
                    ></i
                    >交通
                  </template>
                  <Itag :tags="detailsHouseInfoSave.itrafficList"></Itag>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
                style="text-align: right; margin-left: 30px; margin-top: 10px"
            >
              <el-button size="small" type="primary" @click="uploadFile('1')">上传尽职调查</el-button>
            </el-form-item>
          </div>
        </el-card>

        <el-form-item
            label=""
            style="text-align: right; margin-left: 30px; margin-top: 10px"
        >
          <el-button type="primary" icon="el-icon-check" @click="saveHouseInfo">
            保存
          </el-button>
          <el-button icon="el-icon-close" @click="saveCancle">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        title="上传附件"
        :visible.sync="uploadFileDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        top="10px"
        width="50%"
        :center="true"
        ref="topDialog"
        :lock-scroll="false"
        :append-to-body="true"
    >
      <!--      <el-upload-->
      <!--          multiple-->
      <!--          action="/"-->
      <!--          :http-request="uploadFile"-->
      <!--          :on-success="fileUpload"-->
      <!--          :on-remove="handleRemove"-->
      <!--          :file-list="fileList"-->
      <!--          list-type="picture">-->
      <div style="display:flex; justify-content: center; border-bottom: 1px solid #c8c9cc">
        <el-upload
            drag
            action=""
            :file-list="fileList"
            :http-request="uploadFileFun"
            :on-progress="progress"
            multiple
            class="uploadClass"
            :show-file-list=false>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <el-progress
            type="circle"
            :percentage="progressPercent"
            :status="progressPercent === 100 ? 'success' : (isError ? 'exception' : null)"
            :width="180"
        ></el-progress>
      </div>


      <!--      <el-progress :stroke-width="16" :percentage="progressPercent"></el-progress>-->
      <div style="display: flex; justify-content: left; border-bottom: 1px solid #c8c9cc">
        <span style="margin: 10px 0 10px 10px">尽职报告</span>
        <div v-for="item in fileList">
          <el-button
              v-show="item.isFile === '1'"
              type="text"
              style="font-size: 14px; margin-left: 10px; margin-top: 5px"
              @click="openWord(item)">
            {{ item.fileName }}
          </el-button>
          <el-button
              v-show="item.isFile === '1'"
              @click="handleRemove(item)"
              type="text"
              icon="el-icon-delete"
              style="color: red"></el-button>
        </div>
      </div>

      <div style="display: flex; justify-content: left; border-bottom: 1px solid #c8c9cc">
        <span style="margin: 10px 0 10px 10px">视频讲解</span>
        <div v-for="item in fileList">
          <el-button
              v-show="item.isVideo === '1'"
              type="text"
              style="font-size: 14px; margin-left: 10px; margin-top: 5px"
              @click="openVideo(item)">
            {{ item.fileName }}
          </el-button>
          <el-button
              v-show="item.isVideo === '1'"
              @click="handleRemove(item)"
              type="text"
              icon="el-icon-delete"
              style="color: red"></el-button>
        </div>
      </div>

      <!--      <div style="display: flex; justify-content: left; border-bottom: 1px solid #c8c9cc">-->
      <!--        <span style="margin: 10px 0 10px 10px">预览</span>-->
      <!--      </div>-->
    </el-dialog>

    <el-dialog
        title="视频预览"
        :visible.sync="videoDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="videoClose"
        top="10px"
        :center="true"
        ref="topDialog"
        :lock-scroll="false"
        :append-to-body="true">
      <div style="display: flex; justify-content: center">
        <video
            v-if="closeVideo"
            style="width: 100%; height: 700px"
            :src="videoUrl"
            controls
        ></video>
      </div>
    </el-dialog>

    <el-dialog
        title="房源详细信息"
        :visible.sync="houseMessageDiag"
        width="70%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        top="10px"
        :center="true"
    >
      <IClient :cinfo="detailsHouseInfot" :clientId="clientIds">
      </IClient>

    </el-dialog>
  </x-page>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import IClient from "../../components/IClient";
import axios from "axios";
// const mammoth = require("mammoth");


export default {
  name: 'HouseInfo',
  components: {
    IClient,
  },
  data() {
    return {
      closeVideo: false,
      isError: false,
      progressPercent: 0,
      videoUrl: "",
      isStart: "正在进行",
      queryPrms: {
        keyword: "", //搜索值
        clientLvl: "",
        priceLow: "",
        budgetHigh: "",
        sortColumn: "",
        page: 1,
        size: 300,
        total: 0,
      },
      CommandedqueryPrms: {
        keyword: "", //搜索值
        clientLvl: "",
        priceLow: "",
        budgetHigh: "",
        sortColumn: "",
        page: 1,
        size: 300,
        total: 0,
      },
      aLiOpen: true,
      houseLableLst: [],
      alDownloadPrm: {
        getAlUrlInfo: "",
      },
      bkDownloadPrm: {
        getBkUrlInfo: "",
      },
      uploadFileDialog: false,
      itemClassActive: "item icon-tag active",
      itemClass: "item icon-tag",
      sortClassActive: "link active first",
      sortClass: "link",
      addRules: {
        houseDetailsInfoRepo: {
          proNature: [
            {required: true, message: "房屋性质为必填项", trigger: "change"},
          ],
          commName: [
            {required: true, message: "小区名称为必填项", trigger: "change"},
          ],
          // street: [
          //   {required: true, message: "所属街道为必填项", trigger: "change"},
          // ],
          hanutedHouse: [
            {required: true, message: "是否凶宅为必填项", trigger: "change"},
          ],
          isLease: [
            {required: true, message: "有无租赁为必填项", trigger: "change"},
          ],
          land: [
            {required: true, message: "土地性质为必填项", trigger: "change"},
          ],
          builtArea: [
            {required: true, message: "面积为必填项", trigger: "change"},
          ],
        },
        // marketPrice: [
        //   {required: true, message: "市场价为必填项", trigger: "change"},
        // ],

      },
      editRules: {
        houseDetailsInfoRepo: {
          proNature: [
            {required: true, message: "房屋性质为必填项", trigger: "change"},
          ],
          commName: [
            {required: true, message: "小区名称为必填项", trigger: "change"},
          ],
          // street: [
          //   {required: true, message: "所属街道为必填项", trigger: "change"},
          // ],
          hanutedHouse: [
            {required: true, message: "是否凶宅为必填项", trigger: "change"},
          ],
          isLease: [
            {required: true, message: "有无租赁为必填项", trigger: "change"},
          ],
          land: [
            {required: true, message: "土地性质为必填项", trigger: "change"},
          ],
          builtArea: [
            {required: true, message: "面积为必填项", trigger: "change"},
          ],
        },
        // marketPrice: [
        //   {required: true, message: "市场价为必填项", trigger: "change"},
        // ],

      },
      aliHouseInfo: {
        title: "",
        detailedUrl: [""],
        houseDetailsInfoRepo: {
          priorityPurchase: "",
          insideArea: "0",
          builtArea: "0",
        }
      },
      beikeHouseInfo: {
        houseDetailsInfoRepo: {
          priorityPurchase: ""
        }
      },
      videoDialog: false,
      // 字典表
      clientId: "",
      tableList: [],
      activeNameRec: '',
      activeName: 'first',
      invKeyList: [],
      houseTypeList: [],
      isLease: "",
      signPeoNum: 0,
      repDecLst: [{
        value: "1",
        label: "毛坯"
      }, {
        value: "2",
        label: "简装"
      }, {
        value: "3",
        label: "精装"
      }, {
        value: "4",
        label: "豪装"
      }],
      repAparLst: [{
        value: "1",
        label: "一室"
      }, {
        value: "2",
        label: "两室"
      }, {
        value: "3",
        label: "三室"
      }, {
        value: "4",
        label: "四室"
      }, {
        value: "9",
        label: "五室以上"
      }],
      proList: [{
        value: "0",
        label: "住宅"
      }, {
        value: "1",
        label: "公寓"
      }, {
        value: "2",
        label: "别墅"
      }, {
        value: "3",
        label: "商铺"
      }, {
        value: "4",
        label: "车位"
      }, {
        value: "5",
        label: "写字楼"
      }, {
        value: "6",
        label: "工业用房"
      }],
      isLeaseLst: [{
        value: "1",
        label: "有"
      }, {
        value: "0",
        label: "无"
      }
      ],
      unlucky: "",
      hanutedHouseLst: [{
        value: "1",
        label: "是"
      }, {
        value: "0",
        label: "否"
      }
      ],
      alUrl: "",
      bkUrl: "",
      intAreaList: [],
      intBlockList: [],
      intSchoolList: [],
      itrafficList: [],
      queryPrm: {
        intSchoolList: [],
        intBlockList: [],
        endRange: "",
        endArea: "",
        beginArea: "",
        beginRange: "",
        intAreaList: [],
      },
      fileList: [],
      title: "",
      regSelectBtnClass: "selectBtn",
      plaSelectBtnClass: "selectBtn",
      tagsIndex: 0,
      invisIndex: 0,
      houseTags: {},
      houseInvis: {},
      activeNames: [],
      houseTagsList: [],
      houseInvisList: [],
      forInvisList: [],
      imgUploadDialog: false,
      houseDetailsDialog: false,
      houseAddDialog: false,
      houseEditDialog: false,
      loading: false,
      houseMessageDiag: false,
      houseList: [],
      houseDetails: {
        dealPrice: "",
        isDeal: "",
        marketPrice: "",
        consultPrice: "",
        detailedUrl: "",
        intAreaList: [],
        intBlockList: [],
        intSchoolList: [],
        itrafficList: [],
        houseDetailsInfoRepo: {
          commIntr: "",
          traffic: "",
          periphery: "",
          commName: "",
          builtArea: "",
          hanutedHouse: "",
          frenchBeat: "",
          bond: "",
          itemAddress: "",
          othFee: "",
          priorityPurchase: "",
          markupRange: "",
          ideTranPrice: "",
          tranPrice: "",
        },
      },
      detailsHouseInfoSave:
          {
            intAreaList: [],
            intBlockList: [],
            intSchoolList: [],
            itrafficList: [],
            houseDetailsInfoRepo: {}
          }
      ,
      initialPrice: 0,
      peoLst: [],
      CommandedpeoLst: [],
      ReCommandedpeoLst: [],
      houseDetailsList: [],
      itemAddressList: [],
      detailsHouseInfo: [],
      detailsHouseInfot: {},
      clientIds: "",
      recTotal: 0,
      total: 0,
      Commandedtotal: 0,
      prms: {
        proNature: "",
        mainStatus: "",
        disPoolArea: "",
        status: "",
        title: "",
        houseType: "",
        sortColumn: "",
        sortColumnType: 0,
        builtAreaLow: "",
        builtAreaHigh: "",
        timeSort: "",
        priceLow: "",
        priceHigh: "",
        page: 1,
        size: 15,
      },
      detailsPrms: {
        houseId: "",
        page: 1,
        size: 15,
      },
      savePrms: {},
      newHouseInvis: {
        id: "",
        invKey: "",
        invValue: "",
        invDetail: "",
        invScore: "",
        invImg: "",
        // 控制按钮开关
        saveBtn: false,
        changeBtn: true,
      },
      proposed: [],
      manual: [],
      windowWidth: 0, //页面窗口宽度
      windowHeight: 0, //页面窗口高度
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      houseLstRe: [],
      commondtotal: 0,
      clients: {
        clientId: "",
        clientName: "",
        tel: "",
        page: 1,
        size: 99
      },
      limit: 4,
      Commandedlimit: 4,
      houseCollectRepoList: [],
      clientIntBList: [],
      records: "",
      recHouseInfo: [],
      text: '',
      mess: [],
      ws: null,
      src: '',
      page: 1,
      Commandedpage: 1,
      recommended: [],
      clientList: [],
      resultProposed: [],
      thisKey: 0,
      tagKey: "tagKey",
      fileType: "",
      areaLst: [],
    }
  },
  beforeDestroy() {
    this.ws.close()
    this.ws.onclose = () => {
      this.mess.push('WebSocket Closed')
    }
  },
  mounted() {
    this.ws = new WebSocket("ws://127.0.0.1:25052")
    this.ws.onopen = () => {
      this.mess.push('WebSocket Connected')
    }
    const that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
        //窗口缩放自动获取页面宽高
        window.fullWidth = document.documentElement.clientWidth;
        window.fullHeight = document.documentElement.clientHeight;
        that.windowWidth = window.fullWidth; //宽
        that.windowHeight = window.fullHeight; //高
      })();
    };
    this.queryHouseInfo()
    this.queryArea()
    for (let i = 0; i < 60; i++) {
      this.activeNames.push(i)
    }
  },
  methods: {
    videoClose(done) {
      this.closeVideo = false
      this.videoDialog = false
    },

    pointNature(val) {
      if (this.prms.proNature === val) {
        this.prms.proNature = ""
      } else {
        this.prms.proNature = val
      }
      this.queryHouseInfo()
    },

    pointMainStatus(val) {
      if (this.prms.mainStatus === val) {
        this.prms.mainStatus = ""
      } else {
        this.prms.mainStatus = val
      }
      this.queryHouseInfo()
    },

    getProNature(val) {
      for (let i in this.proList) {
        if (this.proList[i].value === val) {
          return this.proList[i].label
        }
      }
    },

    getRepDec(val) {
      for (let i in this.repDecLst) {
        if (this.repDecLst[i].value === val) {
          return this.repDecLst[i].label
        }
      }
    },

    getRepApar(val) {
      for (let i in this.repAparLst) {
        if (this.repAparLst[i].value === val) {
          return this.repAparLst[i].label
        }
      }
    },

    pointStatus(val) {
      if (this.prms.status === val) {
        this.prms.status = ""
      } else {
        this.prms.status = val
      }
      this.queryHouseInfo()
    },

    pointPool(val) {
      if (this.prms.disPoolArea === val) {
        this.prms.disPoolArea = ""
      } else {
        this.prms.disPoolArea = val
      }
      this.queryHouseInfo()
    },

    pointTime(val) {
      if (this.prms.timeSort === val) {
        this.prms.timeSort = ""
      } else {
        this.prms.timeSort = val
      }
      this.queryHouseInfo()
    },

    progress(event) {
      console.log(event)
    },

    openVideo(val) {
      this.closeVideo = true
      this.videoUrl = val.url
      this.videoDialog = true
    },

    openWord(val) {
      let url = "http://view.xdocin.com/xdoc?_xdoc=" + val.url
      const width = 1000;
      const height = 800
      const top = (window.screen.availHeight - height) / 2
      const left = (window.screen.availWidth - width) / 2
      window.open(url, '', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left)
    },

    // openWord(val) {
    //   let vm = this;
    //   let xhr = new XMLHttpRequest()
    //   xhr.open("get", val.url, true)
    //   xhr.responseType = "arraybuffer"
    //   xhr.onload = function () {
    //     if (xhr.status == 200) {
    //       mammoth.convertToHtml({arrayBuffer: new Uint8Array(xhr.response)})
    //           .then((resultObject) => {
    //         vm.$nextTick(() =>{
    //           vm.vHtml = resultObject.value;
    //         })
    //       })
    //     }
    //
    //   }
    // },
    queryArea() {
      this.$http
          .post("/foreclosure/api/houseInfo/queryArea")
          .then((resp) => {
            this.areaLst = resp.data.data
            console.log(this.areaLst)
          })
    },

    uploadFileFun(ops) {
      let files = new FormData()
      files.append("file", ops.file)
      files.append("houseId", this.houseDetails.houseId)
      files.append("fileType", this.fileType)
      if (this.fileType === "1") {
        if (ops.file.name.split(".")[1] !== "docx" && ops.file.name.split(".")[1] !== "doc") {
          this.$notify({
            title: '警告',
            message: '只允许上传word文件',
            type: 'warning'
          });
          return
        }
      } else if (this.fileType === "2") {
        let name = ops.file.name.split(".")
        if (name[name.length-1] !== "mp4") {
          this.$notify({
            title: '警告',
            message: '只允许上传mp4文件',
            type: 'warning'
          });
          return
        }
      }
      const config = {
        onUploadProgress: progressEvent => {
          this.progressPercent = Number((progressEvent.loaded / progressEvent.total * 80).toFixed(2))
        }
      }
      this.$http
          .post("/foreclosure/api/houseInfo/uploadFile/", files, config)
          .then((resp) => {
            this.$notify({
              title: '成功',
              message: '上传文件成功',
              type: 'success'
            });
            this.progressPercent = 100
            this.queryFile(this.houseDetails)
          }).catch(() => {
        this.$notify.error({
          title: '失败',
          message: '上传文件失败',
        });
        this.isError = true
      })
    },
    uploadFile(val) {
      this.fileType = val
      this.progressPercent = 0
      this.uploadFileDialog = true
    },
    pointArea(val) {
      let areaLst = val.split("|")
      if (this.prms.builtAreaLow === areaLst[0]) {
        this.prms.builtAreaLow = ""
        this.prms.builtAreaHigh = ""
      } else {
        this.prms.builtAreaLow = areaLst[0]
        this.prms.builtAreaHigh = areaLst[1]
      }
      this.queryHouseInfo()
    },
    pointRange(val) {
      let priceLst = val.split("|")
      if (this.prms.priceLow === priceLst[0]) {
        this.prms.priceLow = ""
        this.prms.priceHigh = ""
      } else {
        this.prms.priceLow = priceLst[0]
        this.prms.priceHigh = priceLst[1]
      }
      this.queryHouseInfo()
    },
    getCommandedSearch() {
      this.CommandedpeoLst = []
      for (let keys in this.peoLst) {
        if ((this.peoLst[keys].clientName.indexOf(this.CommandedqueryPrms.keyword) != -1) ||
            this.peoLst[keys].clientId.indexOf(this.CommandedqueryPrms.keyword) != -1) {
          this.CommandedpeoLst.push(this.peoLst[keys])
        }
        for (let labelListKey in this.peoLst[keys].labelLst) {
          if (this.peoLst[keys].labelLst[labelListKey] == this.CommandedqueryPrms.keyword) {
            this.CommandedpeoLst.push(this.peoLst[keys])
          }
        }
      }
      this.Commandedpage = 1;
      this.CommandedpageList();
    },
    pointSort(val) {
      if (this.prms.sortColumn === val) {
        if (this.prms.sortColumnType === 1) {
          this.prms.sortColumnType = 0
        } else {
          this.prms.sortColumnType += 1
        }
      } else {
        this.prms.sortColumn = val;
        this.prms.sortColumnType = 0
      }
      this.queryHouseInfo()
    },
    getMatPeoAndSearch() {
      this.page = 1;
      if (this.queryPrms.keyword == null || this.queryPrms.keyword == '') {
        this.getMatPeo()
      } else {
        this.search()
      }
    },
    openBeiKe() {
      if (this.detailsPrms.houseId === "") {
        this.$notify({
          title: '警告',
          message: '应先选取阿里基础数据',
          type: 'warning'
        });
        return
      }
      let sendInfo = "beike|" + this.detailsPrms.houseId + "|"
      this.ws.send(sendInfo);
      this.ws.onmessage = message => {
        this.$http
            .post("/foreclosure/api/houseInfo/queryHtmlInfo", this.detailsPrms)
            .then((resp) => {
              this.beikeHouseInfo = resp.data.data.records[0]
              // 初始化核心卖点 = 小区介绍
              this.detailsHouseInfoSave.houseDetailsInfoRepo.sellPoints = this.beikeHouseInfo.houseDetailsInfoRepo.commIntr
              this.detailsHouseInfoSave.houseDetailsInfoRepo.periphery = this.beikeHouseInfo.houseDetailsInfoRepo.periphery
              this.detailsHouseInfoSave.houseDetailsInfoRepo.traffic = this.beikeHouseInfo.houseDetailsInfoRepo.traffic
              this.detailsHouseInfoSave.houseDetailsInfoRepo.commIntr = this.beikeHouseInfo.houseDetailsInfoRepo.commIntr
              this.detailsHouseInfoSave.houseDetailsInfoRepo.commName = this.beikeHouseInfo.houseDetailsInfoRepo.commName
              this.detailsHouseInfoSave.houseDetailsInfoRepo.apartments = this.beikeHouseInfo.houseDetailsInfoRepo.apartments
              // 更新贝壳户型图
              this.aliHouseInfo.detailedUrl = this.beikeHouseInfo.detailedUrl
              this.$notify({
                title: '成功',
                message: '贝壳数据提取成功',
                type: 'success'
              });
            }).catch(() => {
          this.$notify.error({
            title: '错误',
            message: '贝壳数据提取失败',
          });
        }).finally(() => {
          setTimeout(() => {
          this.queryHouseInfo()
        }, 800)
        })
      }
    },
    openALi() {
      this.ws.send("ali|");
      this.ws.onmessage = message => {
        let msgData = message.data
        let sqlStas = msgData.split("-")[0]
        this.detailsPrms.houseId = msgData.split("-")[1]
        if (sqlStas === "UPDATE") {
          this.$http
              .post("/foreclosure/api/houseInfo/queryHtmlInfo", this.detailsPrms)
              .then((resp) => {
                this.aliHouseInfo = resp.data.data.records[0];
                this.beikeHouseInfo = resp.data.data.records[0];
                this.detailsHouseInfoSave = resp.data.data.records[0]

                let row = JSON.parse(JSON.stringify(this.detailsHouseInfoSave));
                this.detailsHouseInfoSave.intAreaList = []
                this.detailsHouseInfoSave.intBlockList = []
                this.detailsHouseInfoSave.intSchoolList = []
                this.detailsHouseInfoSave.itrafficList = []
                for (let i = 0; i < row.intAreaList.length; i++) {
                  this.detailsHouseInfoSave.intAreaList.push(row.intAreaList[i].content)
                }
                for (let i = 0; i < row.intBlockList.length; i++) {
                  this.detailsHouseInfoSave.intBlockList.push(row.intBlockList[i].content)
                }
                for (let i = 0; i < row.intSchoolList.length; i++) {
                  this.detailsHouseInfoSave.intSchoolList.push(row.intSchoolList[i].content)
                }
                for (let i = 0; i < row.itrafficList.length; i++) {
                  this.detailsHouseInfoSave.itrafficList.push(row.itrafficList[i].content)
                }
                // if (this.detailsHouseInfoSave.houseDetailsInfoRepo.intAreaList === "[]"){
                //   this.detailsHouseInfoSave.houseDetailsInfoRepo.intAreaList = []
                // } else {
                //   let areaList = this.detailsHouseInfoSave.houseDetailsInfoRepo.intAreaList.replace("[", "").replace("]", "").split(", ")
                //   this.detailsHouseInfoSave.intAreaList = []
                //   for (let i = 0; i < areaList.length; i++) {
                //     this.detailsHouseInfoSave.intAreaList.push(areaList[i])
                //   }
                // }
                //
                // if (this.detailsHouseInfoSave.houseDetailsInfoRepo.intBlockList === "[]"){
                //   this.detailsHouseInfoSave.houseDetailsInfoRepo.intBlockList = []
                // } else {
                //   let areaList = this.detailsHouseInfoSave.houseDetailsInfoRepo.intBlockList.replace("[", "").replace("]", "").split(", ")
                //   this.detailsHouseInfoSave.intBlockList = []
                //   for (let i = 0; i < areaList.length; i++) {
                //     this.detailsHouseInfoSave.intBlockList.push(areaList[i])
                //   }
                // }
                //
                // if (this.detailsHouseInfoSave.houseDetailsInfoRepo.intSchoolList === "[]"){
                //   this.detailsHouseInfoSave.houseDetailsInfoRepo.intSchoolList = []
                // } else {
                //   let areaList = this.detailsHouseInfoSave.houseDetailsInfoRepo.intSchoolList.replace("[", "").replace("]", "").split(", ")
                //   this.detailsHouseInfoSave.intSchoolList = []
                //   for (let i = 0; i < areaList.length; i++) {
                //     this.detailsHouseInfoSave.intSchoolList.push(areaList[i])
                //   }
                // }
                //
                // if (this.detailsHouseInfoSave.houseDetailsInfoRepo.itrafficList === "[]"){
                //   this.detailsHouseInfoSave.houseDetailsInfoRepo.itrafficList = []
                // } else {
                //   let areaList = this.detailsHouseInfoSave.houseDetailsInfoRepo.itrafficList.replace("[", "").replace("]", "").split(", ")
                //   this.detailsHouseInfoSave.itrafficList = []
                //   for (let i = 0; i < areaList.length; i++) {
                //     this.detailsHouseInfoSave.itrafficList.push(areaList[i])
                //   }
                // }


                this.$notify({
                  title: '提示',
                  message: '已有此房源信息, 已为您查询到当前房源信息',
                  type: 'warning'
                });
              }).catch(() => {
            this.$notify.error({
              title: '错误',
              message: '重复房源信息查询错误',
            });
          })
        } else {
          this.$http
              .post("/foreclosure/api/houseInfo/queryHtmlInfo", this.detailsPrms)
              .then((resp) => {
                this.aliHouseInfo = resp.data.data.records[0];
                this.$notify({
                  title: '成功',
                  message: '阿里数据提取成功',
                  type: 'success'
                });
              }).catch(() => {
            this.$notify.error({
              title: '错误',
              message: '阿里数据提取失败',
            });
          })
        }
      }
    },

    conTran(row) {
      let dealPrm = {
        houseId: row.houseId,
        isDeal: "是"
      }
      console.log(row)
      this.$http
          .post("/foreclosure/api/houseInfo/conTran", dealPrm)
          .then((resp) => {
            this.$notify({
              title: '成功',
              message: '已确认成交',
              type: 'success'
            });
          }).catch(() => {
        this.$notify.error({
          title: '错误',
          message: '成交确认失败',
        });
      })
    },
    enterClick() {
      this.queryHouseInfo()
    },
    openEditDialog(row) {
      this.aLiOpen = false
      if (this.$refs["recordForm"]) {
        this.$refs["recordForm"].clearValidate()
      }
      this.detailsHouseInfoSave = JSON.parse(JSON.stringify(row));
      this.detailsHouseInfoSave.intAreaList = []
      this.detailsHouseInfoSave.intBlockList = []
      this.detailsHouseInfoSave.intSchoolList = []
      this.detailsHouseInfoSave.itrafficList = []
      for (let i = 0; i < row.intAreaList.length; i++) {
        this.detailsHouseInfoSave.intAreaList.push(row.intAreaList[i].content)
      }
      for (let i = 0; i < row.intBlockList.length; i++) {
        this.detailsHouseInfoSave.intBlockList.push(row.intBlockList[i].content)
      }
      for (let i = 0; i < row.intSchoolList.length; i++) {
        this.detailsHouseInfoSave.intSchoolList.push(row.intSchoolList[i].content)
      }
      for (let i = 0; i < row.itrafficList.length; i++) {
        this.detailsHouseInfoSave.itrafficList.push(row.itrafficList[i].content)
      }
      this.houseEditDialog = true
      this.detailsPrms.houseId = row.houseId
      this.aliHouseInfo = row
      this.beikeHouseInfo = row
    },
    filterChange() {
      let priceLst = []
      let areaLst = []
      let schoolLst = []
      let blockLst = []
      this.houseList = this.houseLstRe
      for (let priceKey in this.houseList) {
        // 预算范围为0-0时此条件不生效
        if (this.queryPrm.endRange === 0 && this.queryPrm.beginRange === 0) {
          priceLst = JSON.parse(JSON.stringify(this.houseList))
          break
        }
        try {
          if (this.houseList[priceKey].initialPrice * 1 < this.queryPrm.endRange * 10000 && this.houseList[priceKey].initialPrice * 1 > this.queryPrm.beginRange * 10000) {
            priceLst.push(this.houseList[priceKey])
          }
        } catch (e) {
          console.log(e)
        }
      }

      for (let areaKey in priceLst) {
        // 面积范围为0-0时此条件不生效
        if (this.queryPrm.endArea === 0 && this.queryPrm.beginArea === 0) {
          areaLst = JSON.parse(JSON.stringify(priceLst))
          break
        }
        try {
          if (priceLst[areaKey].houseDetailsInfoRepo.builtArea * 1 < this.queryPrm.endArea && priceLst[areaKey].houseDetailsInfoRepo.builtArea * 1 > this.queryPrm.beginArea) {
            areaLst.push(priceLst[areaKey])
          }
        } catch (e) {
          console.log(e)
        }
      }

      // for (let schoolKey in priceLst) {
      //   try {
      //     // 学区为空时不生效
      //     if (this.queryPrm.intSchoolList === "") {
      //       schoolLst = JSON.parse(JSON.stringify(priceLst))
      //       break
      //     }
      //     for (let intSch in this.houseList[schoolKey].intSchoolList) {
      //       if (this.queryPrm.intSchoolList === priceLst[schoolKey].intSchoolList[intSch].content) {
      //         schoolLst.push(priceLst[schoolKey])
      //         break
      //       }
      //     }
      //   } catch (e) {
      //   }
      // }
      //
      // for (let blockKey in schoolLst) {
      //   try {
      //     // 为空时不生效
      //     if (this.queryPrm.intBlockList === "") {
      //       blockLst = JSON.parse(JSON.stringify(schoolLst))
      //       break
      //     }
      //     for (let intBlo in this.houseList[blockKey].intBlockList) {
      //       if (this.queryPrm.intBlockList === schoolLst[blockKey].intBlockList[intBlo].content) {
      //         blockLst.push(schoolLst[blockKey])
      //         break
      //       }
      //     }
      //   } catch (e) {
      //   }
      // }
      // for (let areakey in blockLst) {
      //   try {
      //     // 为空时不生效
      //     if (this.queryPrm.intAreaList === "") {
      //       areaLst = JSON.parse(JSON.stringify(blockLst))
      //       break
      //     }
      //     for (let intSch in this.houseList[areakey].intAreaList) {
      //       if (this.queryPrm.intAreaList === blockLst[areakey].intAreaList[areakey].content) {
      //         areaLst.push(blockLst[areakey])
      //         break
      //       }
      //     }
      //   } catch (e) {
      //   }
      // }
      this.houseList = areaLst
    },
    toAliUrl(row) {
      let sendMsg = "ali||" + '//sf-item.taobao.com/sf_item/695731906239.htm?track_id=aa080adf-6b24-47c4-b93f-72e222257313'
      this.ws.send(sendMsg);
    },
    toBkUrl(row) {
      let sendMsg = "beike||" + row.bkItemUrl
      this.ws.send(sendMsg);
    },
    addRecord(row) {
      row.page = 1;
      row.size = 1;
      this.clientIds = row.clientId;
      this.$http
          .post("/foreclosure/api/houseInfo/getMatPeo", row)
          .then((resp) => {
            this.detailsHouseInfot = resp.data.data.records[0];
            this.houseMessageDiag = true;
            this.$notify({
              type: 'success',
              message: '查询房源成功',
              title: "成功",
            });
          })
          .catch(() => {
            this.$notify.error({
              message: '查询房源失败',
              title: "错误",
            });
          })

    },
    recClient(row) {
      row.loading = false
      this.thisKey++;
      if (row.recommend === "已推荐") {
        return
      }
      row.houseId = this.recHouseId
      this.$http
          .post("/foreclosure/api/houseInfo/recClient", row)
          .then(() => {
            this.$notify({
              type: 'success',
              message: '推荐成功',
              title: "成功",
            });
            this.getMatPeo()

          })
          .catch(() => {
            this.$notify.error({
              message: '推荐失败',
              title: "错误",
            });

          })
    },
    openMap() {
      // let sendMsg = "baidu||https://map.baidu.com/"
      // this.ws.send(sendMsg);
      window.open('https://map.baidu.com/', '_blank')
    },
    calculation(builtArea) {
      let qs1 = 0.01
      let qs2 = 0.01
      if (parseFloat(builtArea) > 90) {
        qs1 = 0.015
        qs2 = 0.02
      }
      this.forInvisList = []
      for (let i = 0; i < this.signPeoNum; i = i + 5) {
        this.initialPrice = this.initialPrice * 1.1
        this.forInvisList.push({
          peoNum: i + "~" + (i + 5),
          forTranPrice: parseFloat(this.initialPrice / 10000).toFixed(2) + "万元",
          // qs1: parseFloat(this.initialPrice * 0.01).toFixed(2) + "元",
          // qs2: parseFloat(this.initialPrice * 0.01).toFixed(2) + "元",
          qs3: parseFloat(this.initialPrice * qs1).toFixed(2) + "元",
          qs4: parseFloat(this.initialPrice * qs2).toFixed(2) + "元",
          qs5: parseFloat(this.initialPrice * 0.03).toFixed(2) + "元",
          dj: parseFloat(this.initialPrice / parseFloat(builtArea)).toFixed(2) + "元",
        })
      }
    },
    getMatPeo() {
      this.$http
          .post("/foreclosure/api/houseInfo/getMatPeo", this.clients)
          .then((resp) => {
            this.proposed = resp.data.data.records
            this.peoLst = []
            for (let key in this.proposed) {
              let lableCount = 0
              for (let labKey in this.proposed[key].labelLst) {
                if (this.houseLableLst.includes(this.proposed[key].labelLst[labKey])) {
                  lableCount += 1
                }
              }

              //  计算评分
              if (lableCount === 0 || this.proposed[key].labelLst.length === 0) {
                this.proposed[key].score = 0
              } else {
                this.proposed[key].score = Math.round((lableCount / this.proposed[key].labelLst.length) * 5, 1)
              }

              this.proposed[key].recommend = "推荐"

              //  判断是否已经推荐
              for (let houseKey in this.proposed[key].bindHouseId) {
                if (this.recHouseInfo.houseId === this.proposed[key].bindHouseId[houseKey]) {
                  this.proposed[key].recommend = "已推荐"
                  this.peoLst.push(this.proposed[key])
                }
              }
            }
            this.resultProposed = [];
            for (let keys in this.proposed) {
              if (this.proposed[keys].recommend != "已推荐") {
                this.resultProposed.push(this.proposed[keys])
              }
            }

            this.CommandedpeoLst = [];
            for (let Commkeys in this.peoLst) {
              this.CommandedpeoLst.push(this.peoLst[Commkeys])
            }

            this.pageList()
            this.CommandedpageList()
          })
          .catch(() => {
          });
    },

    search() {
      this.currentPage = 1;
      this.queryPrms.page = 1;
      this.query();
    },
    query() {
      this.$http
          .post("/foreclosure/client/info/list", this.queryPrms)
          .then((resp) => {
            debugger
            this.resultProposed = [],
                this.clientList = [],
                this.clientList = resp.data.data;
                for (let keyvalue in this.clientList) {
                  for (let keyp in this.proposed) {
                    if ((this.clientList[keyvalue].clientId == this.proposed[keyp].clientId) && this.proposed[keyp].recommend != "已推荐") {

                      this.resultProposed.push(this.proposed[keyp]);
                    }
                  }
                }
            this.pageList();

          })
          .catch((err) => {
            this.loading = false;
          });
    },
    queryHouseInfo() {
      this.loading = true;
      // 获取当前时间
      let date = new Date().getTime()
      this.prms.sortColumnType += ''
      this.$http
          .post("/foreclosure/api/houseInfo/queryHouseInfo", this.prms)
          .then((resp) => {
            this.loading = false;
            let ret = resp.data.data;
            this.houseList = resp.data.data;
            console.log(this.houseList)
            // this.houseList = resp.data.data.records;
            for (let key in this.houseList) {

              this.houseList[key].houseLableLst = []
              // 转换成万元
              this.houseList[key].initialPriceBig = parseFloat(this.houseList[key].initialPrice / 10000).toFixed(2) + "万元"

              this.houseList[key].marketPriceB = parseFloat(this.houseList[key].marketPrice / 10000).toFixed(2) + "万元"

              // 开拍时间
              let startDate = Date.parse(this.houseList[key].startTime)
              // 结束时间
              let endDate = Date.parse(this.houseList[key].endTime)
              if (date >= startDate && date <= endDate) {
                this.houseList[key].isStart = "正在进行"
              } else if (date > endDate) {
                this.houseList[key].isStart = "已结束"
              } else if (startDate > date) {
                this.houseList[key].isStart = "即将开始"
              } else {
                this.houseList[key].isStart = "缺少数据"
              }

              if (this.houseList[key].houseDetailsInfoRepo === "") {
                continue
              }

              let areaList = this.houseList[key].houseDetailsInfoRepo.intAreaList.replace("[", "").replace("]", "").split(", ")
              if (!(areaList.length === 1 && areaList[0] === "")) {
                this.houseList[key].intAreaList = []
                for (let i = 0; i < areaList.length; i++) {
                  this.houseList[key].intAreaList.push({content: areaList[i]})
                  this.houseList[key].houseLableLst.push(areaList[i])

                }
              }

              let blockList = this.houseList[key].houseDetailsInfoRepo.intBlockList.replace("[", "").replace("]", "").split(", ")
              if (!(blockList.length === 1 && blockList[0] === "")) {
                this.houseList[key].intBlockList = []
                for (let i = 0; i < blockList.length; i++) {
                  this.houseList[key].intBlockList.push({content: blockList[i]})
                  this.houseList[key].houseLableLst.push(blockList[i])
                }
              }
              let schoolList = this.houseList[key].houseDetailsInfoRepo.intSchoolList.replace("[", "").replace("]", "").split(", ")
              if (!(schoolList.length === 1 && schoolList[0] === "")) {
                this.houseList[key].intSchoolList = []
                for (let i = 0; i < schoolList.length; i++) {
                  this.houseList[key].intSchoolList.push({content: schoolList[i]})
                  this.houseList[key].houseLableLst.push(schoolList[i])
                }
              }

              let itrafficList = this.houseList[key].houseDetailsInfoRepo.itrafficList.replace("[", "").replace("]", "").split(", ")
              if (!(itrafficList.length === 1 && itrafficList[0] === "")) {
                this.houseList[key].itrafficList = []
                for (let i = 0; i < itrafficList.length; i++) {
                  this.houseList[key].itrafficList.push({content: itrafficList[i]})
                  this.houseList[key].houseLableLst.push(itrafficList[i])
                }
              }
            }
            this.houseLstRe = JSON.parse(JSON.stringify(this.houseList))
            this.total = resp.data.total;
          })
          .catch(() => {
            this.loading = false;
          });
      this.prms.sortColumnType = this.prms.sortColumnType * 1
    },

    changePageFn(val) {
      this.prms.page = val
      this.queryHouseInfo()
    },
    changeRecPageFn(val) {
      this.clients.page = val
      this.getMatPeo()
    },

    queryHouseTags() {
      this.$http
          .post("/foreclosure/api/houseInfo/queryHouseTags", this.detailsPrms)
          .then((resp) => {
            let houseTagsList = resp.data.data;
            for (let i = 0; i < houseTagsList.length; i++) {
              this.houseTagsList.push({index: this.tagsIndex, tag: houseTagsList[i].tag, tagId: houseTagsList[i].tagId})
              this.tagsIndex += 1
            }
          })
    },

    queryHouseInvis() {
      this.$http
          .post("/foreclosure/api/houseInfo/queryHouseInvis", this.detailsPrms)
          .then((resp) => {
            let houseInvList = resp.data.data;
            for (let i = 0; i < houseInvList.length; i++) {
              this.houseInvisList.push({
                index: this.invisIndex,
                id: houseInvList[i].id,
                invKey: houseInvList[i].invKey,
                invValue: houseInvList[i].invValue,
                invDetail: houseInvList[i].invDetail,
                invScore: houseInvList[i].invScore,
                invImg: houseInvList[i].invImg,
                // 控制按钮开关
                saveBtn: true,
                changeBtn: false,
              })
              this.invisIndex += 1
            }
          })
    },

    changeSize(val) {
      this.prms.size = val
      this.queryHouseInfo()
    },

    addHouseInfo() {
      this.aLiOpen = true
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].resetFields()
        this.$refs["addForm"].clearValidate()
      }
      this.detailsPrms.houseId = ""
      this.aliHouseInfo = {
        title: "",
        detailedUrl: [""],
        houseDetailsInfoRepo: {
          priorityPurchase: "",
          insideArea: "0",
          builtArea: "0",
        }
      }
      this.detailsHouseInfoSave = {
        intAreaList: [],
        intBlockList: [],
        intSchoolList: [],
        itrafficList: [],
        houseDetailsInfoRepo: {}
      }
      this.aliHouseInfo = {
        title: "",
        detailedUrl: [""],
        houseDetailsInfoRepo: {
          priorityPurchase: "",
          insideArea: "0",
          builtArea: "0",
        }
      }
      this.beikeHouseInfo = {
        houseDetailsInfoRepo: {
          priorityPurchase: ""
        }
      },
          this.houseAddDialog = true
    },

    detailedInfo(row) {
      this.CommandedqueryPrms.keyword = '';
      this.queryPrms.keyword = '';
      this.signPeoNum = 0
      this.activeName = "first"
      this.recHouseInfo = row
      this.getMatPeo()
      this.forInvisList = []
      this.houseDetails = row
      if (this.houseDetails.houseDetailsInfoRepo === "") {
        this.houseDetails.houseDetailsInfoRepo = {
          insideArea: "0",
        }
      }
      let area = this.houseDetails.houseDetailsInfoRepo.builtArea
      let price = this.houseDetails.marketPrice
      if (area === "0" || area === "" || area === undefined || price === "" || price === "0" || price === undefined) {
        this.houseDetails.houseDetailsInfoRepo.unitPrice = "0元/m²"
      } else {
        this.houseDetails.houseDetailsInfoRepo.unitPrice = parseFloat(parseFloat(price) / parseFloat(area)).toFixed(2) + "元/m²"
      }
      this.initialPrice = row.initialPrice
      this.houseDetailsDialog = true
      // this.houseLableLst = row.houseLableLst
      this.recHouseId = row.houseId
      this.isError = false
      this.queryFile(row)
    },

    queryFile(row) {
      let fileQuery = {
        houseId: row.houseId
      }
      // 查询已上传文件
      this.$http
          .post("/foreclosure/api/houseInfo/queryFile", fileQuery)
          .then((resp) => {
            this.fileList = []
            let lst = resp.data.data
            for (let i in lst) {
              this.fileList.push(lst[i])
            }
            console.log(this.fileList)
          }).catch(() => {

      });
    },

    commiteInfo() {

    },

    clearNewHouseInvis() {
      this.newHouseInvis = {
        id: "",
        invKey: "",
        invValue: "",
        invDetail: "",
        invScore: "",
        invImg: "",
        // 控制按钮开关
        saveBtn: false,
        changeBtn: true,
      }
    },

    clearHouseData() {
      this.tagsIndex = 0
      this.invisIndex = 0
      this.houseTags = {}
      this.houseInvis = {}
      this.houseTagsList = []
      this.houseInvisList = []
    },

    addTagsInfo() {
      if (this.houseTags.tag !== "") {
        this.houseTagsList.push({index: this.tagsIndex, tag: this.houseTags.tag, tagId: this.tagId})
        this.tagsIndex += 1
      }
    },

    addInvInfo() {
      for (let i = 0; i < this.houseInvisList.length; i++) {
        if (this.houseInvisList[i].saveBtn === false) {
          this.$message({
            type: 'warning',
            message: '有在修改中的背调信息, 请先保存'
          })
          return
        }
      }
      // 先清空列表
      // this.clearNewHouseInvis()
      this.newHouseInvis.index = this.invisIndex
      this.houseInvisList.push(this.newHouseInvis)
      this.invisIndex += 1
      this.houseInvis = this.newHouseInvis
    },

    deleteTags(row) {
      for (let i = 0; i < this.houseTagsList.length; i++) {
        if (this.houseTagsList[i].index === row.index) {
          this.$confirm('是否确认删除此条背调信息？', '确认信息', {
            distinguishCancelAndClose: true,
            confirmButtonText: '删除',
            cancelButtonText: '取消'
          })
              .then(() => {
                this.houseTagsList.splice(i, 1)
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                })
              });
        }
      }
    },

    deleteInv(row) {
      for (let i = 0; i < this.houseInvisList.length; i++) {
        if (this.houseInvisList[i].index === row.index) {
          this.$confirm('是否确认删除此条背调信息？', '确认信息', {
            distinguishCancelAndClose: true,
            confirmButtonText: '删除',
            cancelButtonText: '取消'
          })
              .then(() => {
                this.houseInvisList.splice(i, 1)
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                })
              });
        }
      }
    },

    changeInv(row) {
      for (let i = 0; i < this.houseInvisList.length; i++) {
        if (this.houseInvisList[i].saveBtn === false) {
          this.$message({
            type: 'warning',
            message: '有在修改中的背调信息, 请先保存'
          })
          return
        }
      }
      this.houseInvis = row
      this.houseInvis.saveBtn = false
      this.houseInvis.changeBtn = true
    },

    saveInvInfo(row) {
      if (this.houseInvis.invKey !== undefined && this.houseInvis.invKey !== "") {
        // 判断是否有重复invKey
        for (let i = 0; i < this.houseInvisList.length; i++) {
          if (this.houseInvisList[i].index === row.index) {
            this.$message({
              message: '保存成功！',
              type: 'success'
            });
            this.houseInvis.saveBtn = true
            this.houseInvis.changeBtn = false
            // 先清空NewHouseInvis列表, 保存后, 将页面的input换绑
            this.clearNewHouseInvis()
            this.houseInvis = this.newHouseInvis
          } else if (this.houseInvisList[i].invKey === row.invKey) {
            this.$message({
              message: '背调类型不能重复！',
              type: 'warning'
            });
            return
          }
        }
      } else {
        this.$message({
          message: '背调类型不能为空！',
          type: 'warning'
        });
      }
    },

    quitFun() {
      this.$confirm('检测到未保存的内容，是否在离开页面前保存修改？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '保存',
        cancelButtonText: '放弃修改'
      })
          .then(() => {
            this.saveHouseInfo()
          })
          .catch(() => {
            this.$confirm('是否离开页面？', '确认信息', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确认',
              cancelButtonText: '取消'
            })
                .then(() => {
                  this.$message({
                    type: 'info',
                    message: '放弃保存并离开页面'
                  })
                  this.houseDetailsDialog = false
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '放弃保存'
                  })
                });
          });
    },

    editCancle() {
      this.houseEditDialog = false
      if (this.$refs["recordForm"]) {
        this.$refs["recordForm"].clearValidate()
      }
    },

    saveCancle() {
      this.houseAddDialog = false
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].clearValidate()
      }
    },

    saveHouseInfo() {
      if (this.aliHouseInfo.title === "") {
        this.$notify({
          title: '警告',
          message: '没有阿里基础数据无法新增',
          type: 'warning'
        });
        return
      }
      let houseId = this.aliHouseInfo.houseId
      this.detailsHouseInfoSave.houseId = houseId
      this.beikeHouseInfo.houseId = houseId
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.$http
              // 首先更新阿里数据, 获取house_id 才可进行之后的更新
              .post("/foreclosure/api/houseInfo/saveNewHouse", this.detailsHouseInfoSave)
              .then(() => {
                this.$notify({
                  title: '成功',
                  message: '保存成功',
                  type: 'success'
                });
                this.houseAddDialog = false
              }).catch(() => {
            this.$notify.error({
              message: '保存失败',
              title: "错误",
            });
            }).finally(() => {
              setTimeout(() => {
                this.queryHouseInfo()
              }, 800)
          })
        } else {
          return false
        }
      })
    },

    editHouseInfo() {
      this.$refs["recordForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http
              .post("/foreclosure/api/houseInfo/changeTags", this.detailsHouseInfoSave)
              .then(() => {
                this.$notify({
                  title: '消息提示',
                  message: '保存成功',
                  type: 'success'
                });
                this.houseEditDialog = false
                this.houseDetailsDialog = false
              })
              .catch(() => {
                this.$notify.error({
                  title: '错误',
                  message: '保存失败'
                });
              }).finally(() => {
                setTimeout(() => {
                  this.queryHouseInfo()
                }, 800)
          })
        } else {
          return false
        }
      })
      // for (let i = 0; i < this.houseInvisList.length; i++) {
      //   if (this.houseInvisList[i].saveBtn === false) {
      //     this.$message({
      //       type: 'warning',
      //       message: '有在修改中的背调信息, 请先保存'
      //     })
      //     return
      //   }
      // }
      // this.savePrms.houseTagsDtoList = this.houseTagsList
      // this.savePrms.houseInvisDtoList = this.houseInvisList
      //
      // this.savePrms.houseId = this.detailsHouseInfo.houseId
      // this.savePrms.builtArea = this.detailsHouseInfo.builtArea
      // this.savePrms.insideArea = this.detailsHouseInfo.insideArea
      // this.savePrms.itemAddress = this.detailsHouseInfo.itemAddress
      //
      // this.$http
      //     .post("/foreclosure/api/houseInfo/changeTags", this.savePrms)
      //     .then(() => {
      //       this.$message({
      //         type: 'success',
      //         message: '保存成功'
      //       });
      //       this.houseDetailsDialog = false
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: 'error',
      //         message: '保存失败'
      //       });
      //     })
    },

    uploadImg() {
      if (this.houseInvis.invKey === undefined) {
        this.$message({
          message: '背调类型不能为空！',
          type: 'warning'
        });
      } else {
        this.fileList = []
        let urlInfo = []
        // 判断是修改or新增
        for (let i = 0; i < this.houseInvisList.length; i++) {
          // 修改: 读取之前的文件url
          if (this.houseInvisList[i].invKey === this.houseInvis.invKey) {
            for (let j = 0; j < this.houseInvisList[i].invImg.split(";").length - 1; j++) {
              urlInfo = this.houseInvisList[i].invImg.split(";")[0]
              let fileName = urlInfo.split("~")[0]
              let url = urlInfo.split("~")[1]
              this.fileList.push({"name": fileName, "url": url})
            }
          }
        }
        this.imgUploadDialog = true
      }
    },

    handleRemove(file) {
      try {
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].name === file.name) {
            this.fileList.splice(i, 1)
          }
        }

        // 将valiFlag置为0
        this.$http
            .post("/foreclosure/api/houseInfo/deleteFile", file)
            .then((resp) => {
              this.$notify({
                title: '成功',
                message: '删除文件成功',
                type: 'success'
              });
            }).catch(() => {
          this.$notify.error({
            title: '错误',
            message: '删除文件失败',
          });
        });
      } catch (e) {
        this.$notify.error({
          title: '错误',
          message: '删除文件' + file.name + '失败',
        });
      }
    },

    fileUpload(response, file, fileList) {
      this.$notify({
        title: '成功',
        message: '上传文件' + file.name + '成功',
        type: 'success'
      });
      console.log(this.fileList)
      // let invImg = ""
      // for (let j = 0; j < fileList.length; j++) {
      //   invImg += fileList[j].name + "~" + fileList[j].url + ";"
      // }
      // this.houseInvis.invImg = invImg
    },

    plaChangeStyle() {
      this.plaSelectBtnClass = "notSelectBtn"
    },

    regChangeStyle() {
      this.regSelectBtnClass = "notSelectBtn"
    },

    // 未推荐分页操作
    //处理切换页码
    handleSizeChange(val) {
      this.limit = val;
      this.pageList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.pageList();
    },

    // 具体分页操作
    pageList() {

      this.tableList = []
      this.tableList = this.resultProposed.filter(
          (item, index) =>
              index < this.page * this.limit &&
              index >= this.limit * (this.page - 1)
      );
      for (let key in this.tableList) {
        this.tableList[key].loading = true
      }
      this.commondtotal = this.resultProposed.length;
    },


    // 已推荐分页操作
    //处理切换页码
    CommandedhandleSizeChange(val) {
      this.Commandedlimit = val;
      this.CommandedpageList();
    },
    CommandedhandleCurrentChange(val) {
      this.Commandedpage = val;
      this.CommandedpageList();
    },

    // 具体分页操作
    CommandedpageList() {
      this.ReCommandedpeoLst = []
      this.ReCommandedpeoLst = this.CommandedpeoLst.filter(
          (item, index) =>
              index < this.Commandedpage * this.Commandedlimit &&
              index >= this.Commandedlimit * (this.Commandedpage - 1)
      );
      this.Commandedtotal = this.CommandedpeoLst.length;
    },


  }
}
</script>

<style>

.title-font {
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  text-align: left;
  margin-left: 5px;
}

.title-div-style {
  margin-bottom: 20px;
}

.invStyle {
  width: 30%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.drawScroll {
  width: 100%;
  overflow-y: auto;
}

.el-drawer.rtl {
  overflow: scroll;
}

.el-drawer__container ::-webkit-scrollbar {
  display: none;
}

.block-title {
  height: 18px;
  background-color: #3a8ee6;
  border: 1px solid #3a8ee6;
  width: 2px;
  margin-right: 5px;
  float: left;
}

.houseTabs .el-tabs__header {
  margin: 0 0 5px !important;
}

.houseTabs .el-tabs__item {
  padding: 0 10px !important;
}

.descFount {
  font-weight: 700;
  font-size: 12px;
}

.desc14pxFount {
  font-weight: 700;
  font-size: 14px;
  color: #f50;
}

.desc14pxBlackFount {
  font-weight: 700;
  font-size: 14px;
}

.loading-div {
  width: 100%;
  position: absolute;
}

.m-sortbar {
  margin-top: 10px;
}

.m-sortbar .sort-row {
  height: 41px;
}

.m-sortbar .sort-row .sort-inner {
  height: 39px;
  background-color: #f5f5f5;
  border: solid 1px #e8e8e8;
}

.m-sortbar .sorts {
  float: left;
  line-height: 39px;
  display: block;
}

.m-sortbar .sorts .sort {
  float: left;
  height: 39px;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.m-sortbar .sorts .link {
  float: left;
  display: block;
  height: 39px;
  margin-left: -1px;
  padding: 0 29px;
  border-left: solid 1px #f5f5f5;
  border-right: solid 1px #f5f5f5;
  text-decoration: none;
  color: #6d6d6d;
}

.m-sortbar .sorts .active,
.m-sortbar .sorts .link:hover {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-color: #e5e5e5;
  color: #f50;
}

.m-sortbar .sorts .first {
  margin-left: 0;
  border-left: none;
}

.m-nav {
  margin-top: 1px;
}

.m-nav .group {
  border: 1px solid #e8e8e8;
  margin-top: -1px;
}

.m-nav .group .row .head {
  left: 20px;
  top: 0px;
  width: 80px;
  color: #999;
  position: relative;
}

.m-nav .group .head .title {
  float: left;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-nav .group .row {
  position: relative;
  border-top: 1px dashed #dedede;
  margin: 0 8px;
}

.m-nav .group .row-first,
.m-nav .group .row:first-child {
  border-top: none;
}

.m-nav .group .body {
  padding: 0 100px 10px 112px;
  display: block;
  width: 90%;
  margin-top: -48px;
}

.m-nav .group .body .items {
  height: 36px;
  overflow: hidden;
}

.m-nav .group .body .item {
  float: left;
  margin: 9px 0px 9px 0;
  height: 18px;
  color: #000;
  text-decoration: none;
}

.m-nav .group .body .icon-btn-check-small {
  display: none;
  float: left;
  margin: 4px 5px 0 0;
}

.m-nav .group .body .item .text:hover {
  color: #f40;
  cursor: pointer;
}

.m-nav .group .body .active {
  color: #f40 !important;
}

.m-nav .group .body .item {
  float: left;
  margin: 9px 40px 9px 0;
  height: 18px;
  color: #000;
  font-family: arial, "Hiragino Sans GB", "\5b8b\4f53", sans-serif;
  font: 12px/1.5 tahoma, arial, "Hiragino Sans GB", "\5b8b\4f53", sans-serif;
  text-decoration: none;
}

.m-nav .group .body .text {
  float: left;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-view::-webkit-scrollbar {
  width: 4px;
}

.content-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #6fbaed;
}

.content-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #dae6ee;
}

.m-itemlist-item {
  border-bottom: 1px solid #e5e5e5;
  transition: all 0.3s ease-out;
  border-radius: 10px;
  box-shadow: 0 4px 17px 0 hsl(0deg 0% 91% / 50%);
  background-color: #fff;
  padding: 20px 10px 20px 0;
  margin: 5px;
}

.m-itemlist-item:hover {
  transform: translateY(-10px) scale(1.005) translateZ(0);
  box-shadow: 0 20px 36px 0 hsl(0deg 0% 91% / 50%);
}

.tagFont14 {
  font-size: 14px;
  margin-bottom: 5px;
}

.descriptionsFont {
  font-size: 14px;
}

.descRedFount {
  font-weight: 700;
  font-size: 12px;
  color: #f40
}

.uploadClass .el-upload-dragger {
  margin-bottom: 10px;
  width: 360px;
  margin-right: 30px;
}

.progressClass {
}
</style>