import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    isAuthorized: false,
    userMenus: [],
    aac003: "",
    userName: "",
    userType: "",
    avatar: "",
    avatarFull: "",
    userId: "",
    allRoles: [],
    dvsTree: [],
  },
  mutations: {
    setDvsTree(state, payload) {
      state.dvsTree = [];
      state.dvsTree.push(payload);
    },
    modifyAvatar(state, payload) {
      state.avatar = payload["avatar"];
      state.avatarFull = payload["avatarFull"];
    },
    login(state, payload) {
      console.log(payload);
      state.token = payload.extInfo["token"];
      state.userMenus = payload.extInfo["menus"];
      state.isAuthorized = true;
      state.aac003 = payload.aac003;
      localStorage.setItem("orz_orz_orz", payload.extInfo["token"]);
      localStorage.setItem("orz_payload", JSON.stringify(payload));
      state.userName = payload.userName;
      state.userType = payload.userType;
      state.userId = payload.userId;
      state.avatar = payload.extInfo["avatar"];
      state.avatarFull = payload.extInfo["avatarFull"];
    },
    logout(state) {
      state.token = "";
      localStorage.removeItem("orz_orz_orz");
      localStorage.removeItem("orz_payload");
      state.isAuthorized = false;
      state.userMenus = [];
      state.aac003 = "";
      state.userName = "";
      state.userId = "";
      state.userType = "";
      state.avatar = "";
      state.avatarFull = "";
    },
    updateRoleList(state, payload) {
      state.allRoles = payload;
    },
  },
  actions: {
    getDvsTree(context) {
      axios
        .get("/foreclosure/app/com/division/tree")
        .then((resp) => {
          let ret = resp.data;
          if (ret.code == 0) {
            context.commit("setDvsTree", ret.data);
          } else {
            alert(ret.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  modules: {},
});
