<template>
  <x-page v-loading="loading">
    <!-- 预约看房结果 -->
  </x-page>
</template>

<script>
export default {
  name: "VisitResult",
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
