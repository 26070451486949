<template>
  <div id="customizedCalendar">
    <el-calendar
        :first-day-of-week=7
        v-model="value">
      <template
          slot="dateCell"
          slot-scope="{date, data}">
        <div slot="reference" class="div-Calendar" @click="clickCalendar(data)" style="position: relative;z-index: 10;">
          <p style="margin-left: 40%;margin-top: 10%">{{ data.day.split('-').slice(2).join('-') }}</p>
        </div>
        <div v-if="data.isSelected" id="selectP"></div>
      </template>
    </el-calendar>
    <div id="button">
      <el-button @click="skip('preYear')" type="primary" round size="mini" ><i class="el-icon-arrow-left"></i>年
      </el-button>
      <el-button @click="skip('preMonth')" type="warning" round size="mini"><i class="el-icon-arrow-left"></i>月
      </el-button>
<!--      <el-button @click="skip('preDay')" type="success" round size="mini"><i class="el-icon-arrow-left"></i>日
      </el-button>-->
      <el-button @click="skip('today')" type="info" round size="mini">今天</el-button>
<!--      <el-button @click="skip('postDay')" type="success" round size="mini">日<i class="el-icon-arrow-right"></i>
      </el-button>-->
      <el-button @click="skip('postMonth')" type="warning" round size="mini">月<i class="el-icon-arrow-right"></i>
      </el-button>
      <el-button @click="skip('postYear')" type="primary" round size="mini">年<i class="el-icon-arrow-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PubSub from 'pubsub-js'

export default {
  name: "Calendar",
  props: ['startFatherMessage'],
  data() {
    return {
      value: new Date(),
      initDtae:{day:"",isSelected: false, type: 'next-month'}
    }
  },
  computed: {
    selectDate() {
      return moment(this.value).format("YYYY-MM-DD");
    }
  },
  created() {
    this.initDate();
  },
  methods: {
    skip(flag) {
      if (flag === 'preYear') this.value = new Date(this.value.setFullYear(this.value.getFullYear() - 1));
      else if (flag === 'preMonth') this.value = new Date(this.value.setMonth(this.value.getMonth() - 1));
      else if (flag === 'preDay') this.value = new Date(this.value.setDate(this.value.getDate() - 1));
      else if (flag === 'today') this.value = new Date();
      else if (flag === 'postDay') this.value = new Date(this.value.setDate(this.value.getDate() + 1));
      else if (flag === 'postMonth') this.value = new Date(this.value.setMonth(this.value.getMonth() + 1));
      else if (flag === 'postYear') this.value = new Date(this.value.setFullYear(this.value.getFullYear() + 1));
      var fullYear = this.value.getFullYear();
      var fullmouth =this.value.getMonth()+1;
      var date = this.value.getDate();
      this.initDtae.day=fullYear+"-"+(fullmouth>9?fullmouth:"0"+fullmouth)+"-"+(date>9?date:"0"+date);
      this.clickCalendar(this.initDtae)
    },
    initDate(){
      var format = new Date();
      var fullYear = format.getFullYear();
      var fullmouth =format.getMonth()+1;
      var date = format.getDate();
      this.initDtae.day=fullYear+"-"+fullmouth+"-"+date;
      this.clickCalendar(this.initDtae)
    },
    clickCalendar(data) {
      console.log(data)
      this.startFatherMessage(data)
       PubSub.publish("uploadWarningResultWarningTime", data.day);
    }
  }
}
</script>

<style lang="scss" scoped>
#customizedCalendar {
  width: 100%;
  height: 100%;

  #button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #selectP {
    width: 22px;
    height: 22px;
    background-color: #15ff2c;
    position: absolute;
    border-radius: 100%;
    margin-left: 3px;
    //margin-bottom: 4%;
    opacity: 0.6;
  }

  ::v-deep .el-calendar__header {
    // 修改头部背景颜色
    background-color: #EEA371;
    padding: 3px 5px;
    border: none;
    display: flex;
    justify-content: center;

    .el-calendar__button-group {
      // 隐藏原生按钮
      display: none;
    }

    .el-calendar__title {
      // 修改头部标题的字体颜色
      color: white !important;
      font-size: 18px;
      font-weight: bolder;
    }
  }

  ::v-deep .el-calendar__body {
    // 修改主题部分
    padding: 0;
  }

  ::v-deep .el-calendar-table {
    thead {
      th {
        // 修改头部星期部分
        padding: 0;
        background-color: #EEA371;
        color: white;
      }
    }

    .is-selected {
      .el-calendar-day {
        p {
          // 选中日期颜色
          color: black;
        }
      }
    }

    .el-calendar-day {
      // 每天小块样式设置
      padding: 0;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        // 所有日期颜色
        color: black;
        z-index: 1;
        user-select: none;
        display: flex;
      }
    }
  }

  ::v-deep .el-calendar-table__row {
    .prev, .next {
      // 修改非本月
      .el-calendar-day {
        p {
          color: #f0d9d5;
        }
      }
    }

    td {
      // 修改每一个日期td标签
      &:first-child, &:last-child {
        background-color: #f5f5f5;
      }
    }
  }

  button {
    padding: 3px 10px;
  }
}

.div-Calendar{
  height: 100%;
  width: 100%;
  //height: 30px;
  //width: 57px;
  //margin-left: 40%;
  //margin-bottom: 25%;

}
</style>
