<template>
  <el-select
    placeholder=""
    :value="value"
    :disabled="disabled"
    :clearable="clearable"
    :readonly="readonly"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    filterable
    style="width: 100%"
    @input="selectVal"
    @change="onChange"
  >
    <el-option
      v-for="(item, index) in codelist"
      :label="item.aaa103"
      :value="item.aaa102"
      :key="code + index"
    ></el-option>
  </el-select>
</template>

<script>
import { Select } from "element-ui";
export default {
  name: "XSelect",
  props: {
    value: [String, Number, Array],
    code: {
      type: String,
      required: true,
    },
    condition: String,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    collapseTags: Boolean,
  },
  data: function () {
    return {
      str: "",
    };
  },
  created() {
    this.str = localStorage.getItem(this.code);
  },
  computed: {
    codelist: function () {
      if (this.str != null && this.str != "" && this.str != undefined) {
        let codes = JSON.parse(this.str);
        if (
          this.condition == null ||
          this.condition == "" ||
          this.condition == undefined
        ) {
          return codes;
        } else {
          if (this.condition.startsWith("IN|")) {
            let arr = this.condition.split("|")[1].split(",");
            return codes.filter((item) => {
              return arr.includes(item.aaa102);
            });
          } else if (this.condition.startsWith("NOTIN|")) {
            let arr = this.condition.split("|")[1].split(",");
            return codes.filter((item) => {
              return !arr.includes(item.aaa102);
            });
          } else if (this.condition.startsWith("LIKE|")) {
            let p = this.condition.split("|")[1];
            return codes.filter((item) => {
              return item.aaa102.startsWith(p);
            });
          } else {
            return codes;
          }
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    selectVal: function (v) {
      //console.log(v);
      this.$emit("input", v);
    },
    onChange: function (v) {
      //console.log(v);
      this.$emit("change", v);
    },
  },
};
</script>

<style></style>
