let _mixin_query = {
    data() {
        return {
            loading: false,
            rows: [],
            prms: {},
            page: 1,
            size: 10,
            total: 0,
            url: ''
        }
    },
    methods: {
        getUrl() {

        },
        _post() {
            this.page = 1;
            this.total = 0;
            this.post();
        },
        _get() {
            this.page = 1;
            this.total = 0;
            this.get();
        },
        get() {
            this.loading = true;
            this.rows = [];
            this.$http.get(this.getUrl(), {
                params: this.prms
            }).then(resp => {
                let ret = resp.data;
                this.rows = ret.data;
                this.total = ret.pageInfo.totalCount;
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        post() {
            this.loading = true;
            this.rows = [];
            this.$http.post(this.getUrl(), this.prms).then(resp => {
                let ret = resp.data;
                this.rows = ret.data;
                this.total = ret.pageInfo.totalCount;
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        }
    }
}

export default _mixin_query